import * as Bs from '@bootstrap-styled/v4'
import React from 'react'
import Countdown, { CountdownRenderProps, zeroPad } from 'react-countdown'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Icon, Headline4, FloatingText, FloatingTextProps } from '~ui'
import { useToggle } from '~utils'

const CountdownRenderer = ({ days, hours, minutes, seconds }: CountdownRenderProps) => {
  return (
    <Headline4>
      {zeroPad(days, 2)}d : {zeroPad(hours, 2)}h : {zeroPad(minutes, 2)}m : {zeroPad(seconds, 2)}s
    </Headline4>
  )
}

const Progress = styled(Bs.Progress)`
  background: ${({ theme }) => theme.colors.secondaryShade7} !important;
  border-radius: 13.5px !important;
  width: 100%;
`

const ProgressBar = styled(Bs.ProgressBar)<{ color?: string }>`
  background-color: ${({ theme, color }) => color ?? theme.colors.contrastPrimary} !important;
  border-radius: 13.5px;
`

const StyledDate = styled.div`
  margin-top: 10px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.secondarySemi};
`

const FloatingDate = ({ children, ...props }: FloatingTextProps) => (
  <FloatingText {...props}>
    <StyledDate>{children}</StyledDate>
  </FloatingText>
)

const FloatingLock = styled((props) => {
  const [tooltipOpened, tooltipToggle] = useToggle(false)
  const { t } = useTranslation('vault')

  return (
    <FloatingText {...props}>
      <FloatingInner>
        <Icon id={props.tooltipId ?? 'lock'} name="lock" />
        <Bs.Tooltip
          target={props.tooltipId ?? 'lock'}
          placement="bottom"
          isOpen={tooltipOpened}
          toggle={tooltipToggle}
        >
          {t(`You can't unstake your tokens until the date below`)}
        </Bs.Tooltip>
      </FloatingInner>
    </FloatingText>
  )
})`
  margin-top: -27px;
  margin-left: -25px;
`

const FloatingInner = styled.div`
  width: 50px;
  display: flex;
  justify-content: center;
`

const StyledFloatingDate = styled(FloatingDate)`
  margin-left: -25px;
`

const Spacer = styled.div`
  height: 20px;
`

export const VaultProgress = ({
  endDate,
  creationDate,
  unstakeDate,
  tooltipId,
  color
}: {
  endDate: Date
  creationDate: Date
  unstakeDate: Date
  tooltipId?: string
  color?: string
}) => {
  const unstakeProgress =
    ((unstakeDate.getTime() - creationDate.getTime()) /
      (endDate.getTime() - creationDate.getTime())) *
    100

  return (
    <>
      <Countdown date={endDate} renderer={CountdownRenderer} />
      <div className="position-relative w-100">
        <div className="d-flex justify-content-between position-relative align-items-center">
          <StyledDate>
            {creationDate.toLocaleDateString('en-US', { day: 'numeric', month: 'short' })}
          </StyledDate>
          <StyledDate>
            {endDate.toLocaleDateString('en-US', { day: 'numeric', month: 'short' })}
          </StyledDate>
        </div>
        <Progress>
          <ProgressBar
            color={color}
            height="27px"
            valueNow={
              ((Date.now() - creationDate.getTime()) /
                (endDate.getTime() - creationDate.getTime())) *
              100
            }
          />
        </Progress>
        {unstakeDate.getTime() - creationDate.getTime() > 3000 && (
          <>
            <FloatingLock left={unstakeProgress} inPercent tooltipId={tooltipId} />
            <div className="d-flex justify-content-between position-relative align-items-center">
              <Spacer />
              <StyledFloatingDate left={unstakeProgress} inPercent>
                <FloatingInner>
                  {unstakeDate.toLocaleDateString('en-US', { day: 'numeric', month: 'short' })}
                </FloatingInner>
              </StyledFloatingDate>
            </div>
          </>
        )}
      </div>
    </>
  )
}
