import * as Bs from '@bootstrap-styled/v4'

import { ColMargined } from '../../components'

import { LiquidityPool } from './LiquidityPool'

export const LiquidityPools = () => (
  <Bs.Row>
    <ColMargined xs={12} md={6}>
      <LiquidityPool />
    </ColMargined>
  </Bs.Row>
)
