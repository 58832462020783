import styled from 'styled-components'

import { Ticker } from '~enums'
import { useStaked } from '~state'
import { BodyL, BodyM, Headline3, Headline4, WithColor, WithGradient } from '~ui'
import { NumberFormatter } from '~utils'

const Container = styled.div`
  display: flex;
  margin-bottom: 30px;
`

const APYNumber = styled(Headline3)`
  margin-right: 5px !important;
`

const APYContainer = styled.div`
  font-weight: 600;
  display: flex;
  align-items: baseline;
  background: ${({ theme }) => theme.colors.secondaryShade1};
  border: 1px solid ${({ theme }) => theme.colors.secondaryShade1};
  padding: 20px;
  border-radius: 24px;
`

const TVLContainer = styled.div`
  padding: 13px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const TVL = () => {
  const { stakedTotal: tvl } = useStaked()
  return (
    <Container>
      <APYContainer>
        <APYNumber>
          <WithGradient $gradient="greenBlueish">120%</WithGradient>
        </APYNumber>
        <Headline4>APY</Headline4>
      </APYContainer>
      <TVLContainer>
        <BodyM>
          <WithColor $color="secondary">TVL:</WithColor>
        </BodyM>
        <BodyL $bold>
          {NumberFormatter.toComma(NumberFormatter.toDecimal(tvl, Ticker.LTT).toFixed(0))}{' '}
          {Ticker.LTT}
        </BodyL>
      </TVLContainer>
    </Container>
  )
}
