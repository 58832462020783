import { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { BodyM, Headline4 } from '../fonts'
import { Icon } from '../Icon'

import { mediaQuery, useToggle } from '~utils'

const ItemButton = styled.button`
  background: none;
  border: none;
`

const FaqBody = styled.div<{ opened: boolean }>`
  &:first-child {
    border-radius: 24px 24px 0 0;
  }

  &:last-child {
    border-radius: 0 0 24px 24px;
  }

  &:hover {
    background: ${({ theme }) => theme.gradients.lightPrimary2};
  }

  ${({ opened, theme }) => opened && `background: ${theme.gradients.lightPrimary2};`}
`

const TogglerIcon = styled(Icon)`
  cursor: pointer;
`

const Text = styled(BodyM)`
  margin-top: 15px;
  ${mediaQuery.lessThan('md')`
     margin-top: 10px;
    
  `}
`

export const FaqItem = styled(
  ({ className, title, children }: PropsWithChildren<{ className?: string; title: string }>) => {
    const [opened, toggle] = useToggle(false)

    return (
      <FaqBody className={className} opened={opened}>
        <div className="d-flex justify-content-between cursor-pointer" onClick={toggle}>
          <Headline4>{title}</Headline4>
          <ItemButton>
            <TogglerIcon name={opened ? 'minus' : 'plus'} />
          </ItemButton>
        </div>
        {opened && <Text>{children}</Text>}
      </FaqBody>
    )
  }
)`
  padding: 30px;

  ${mediaQuery.lessThan('md')`
    padding: 20px;
  `}
  &:not(&:last-child) {
    border-bottom: 2px solid ${({ theme }) => theme.colors.secondaryShade1};
  }
`
