import { useState } from 'react'
import styled from 'styled-components'

import { Card, CardProps } from './Card'

export type HoverCardProps = CardProps & { hoverStyle: 'grey' | 'gradient' }

const StyledCard = styled(Card)`
  ${({ theme, $hover, hoverStyle }) =>
    $hover &&
    `background:${
      hoverStyle === 'gradient' ? theme.colors.hoverPrimary : theme.colors.secondaryShade9
    } !important`};
`

export const HoverCard = (props: HoverCardProps) => {
  const [hovered, setHovered] = useState(false)
  return (
    <StyledCard
      {...props}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      $hover={hovered}
    />
  )
}
