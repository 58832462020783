import React from 'react'
import { BounceLoader } from 'react-spinners'
import styled from 'styled-components'

import { theme } from '../theme'

const SpinnerHolder = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Spinner = () => (
  <SpinnerHolder>
    <BounceLoader color={theme.$primary} />
  </SpinnerHolder>
)
