// Set of helper functions to facilitate wallet setup

import { nodes } from './getRpcUrl'

import { getChainId, TokensBEP20 } from '~config'
import { ConnectorNames, Ticker } from '~enums'

/**
 * Prompt the user to add BSC as a network on Metamask, or switch to BSC if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
export const setupNetworkMetamask = async () => {
  // http not supported
  if (!nodes.some((n) => n.includes('https'))) return false

  const provider = window.ethereum
  if (provider && provider.request) {
    const chainId = getChainId()
    try {
      await provider.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: `0x${chainId.toString(16)}`,
            chainName: `Binance Smart Chain ${chainId === 97 ? 'Testnet' : 'Mainnet'}`,
            nativeCurrency: {
              name: 'BNB',
              symbol: 'bnb',
              decimals: 18
            },
            rpcUrls: nodes,
            blockExplorerUrls: [`https://bscscan.com`]
          }
        ]
      })
      return true
    } catch (error) {
      console.error('Failed to setup the network in Metamask:', error)
      return false
    }
  } else {
    console.error("Can't setup the BSC network on metamask because window.ethereum is undefined")
    return false
  }
}

/**
 * Prompt the user to add BSC as a network on Binance Chain Wallet, or switch to BSC if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
export const setupNetworkBinance = async () => {
  const provider = window.BinanceChain
  if (provider && provider.switchNetwork) {
    try {
      await provider.switchNetwork(getChainId() === 97 ? 'bsc-testnet' : 'bsc-mainnet')
      return true
    } catch (error) {
      console.error('Failed to setup the network in Binance Chain Wallet:', error)
      return false
    }
  } else {
    console.error(
      "Can't setup the BSC network on Binance Chain Wallet because window.BinanceChain is undefined"
    )
    return false
  }
}

/**
 * Setups network based on connector type
 * @param cn Type of connector
 * @returns True if network is set up, false if failed
 */
export const setupNetwork = async (cn: ConnectorNames) => {
  let hasSetup = false
  if (cn === ConnectorNames.BSC) hasSetup = await setupNetworkBinance()
  else if (cn === ConnectorNames.Injected) hasSetup = await setupNetworkMetamask()
  return hasSetup
}

export const registerToken = (ticker: Ticker) => {
  const token = TokensBEP20.find((t) => t.ticker === ticker)
  if (!token) throw new Error('No token')

  return registerCustomToken(
    token.address,
    ticker,
    token.decimals,
    `https://lab.localtrade.cc/images/tokens/${ticker}.svg`
  )
}

export const registerCustomToken = (
  address: string,
  symbol: string,
  decimals: number,
  image?: string
) => {
  return window?.ethereum?.request?.({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20',
      options: {
        address,
        symbol,
        decimals,
        ...(image ? { image } : {})
      }
    }
  })
}
