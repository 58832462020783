import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Pair } from '@uniswap/sdk'
import BigNumber from 'bignumber.js'

export interface LttState {
  price: BigNumber
  unlocked: BigNumber
  pair?: Pair
}

export type LttSetPair = PayloadAction<Pair>

export type LttSetPrice = PayloadAction<BigNumber>

export type LttSetUnlocked = PayloadAction<BigNumber>

const initialState: LttState = {
  price: new BigNumber('0'),
  unlocked: new BigNumber('0')
}

export const lttSlice = createSlice({
  name: 'ltt',
  initialState,
  reducers: {
    startListening: (state) => state,
    endListening: (state) => state,
    setPair: (state, action: LttSetPair) => {
      state.pair = action.payload
    },
    setPrice: (state, action: LttSetPrice) => {
      state.price = action.payload
    },
    setUnlocked: (state, action: LttSetUnlocked) => {
      state.unlocked = action.payload
    }
  }
})

export const { actions: lttActions, reducer: lttReducer } = lttSlice
