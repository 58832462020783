import { BigNumber } from 'bignumber.js'
import { Contract } from 'ethers'
import { SagaIterator } from 'redux-saga'
import { all, call, fork, put, select, takeLatest, throttle } from 'redux-saga/effects'

import { createTerminatedSaga, getAllowance, onApprove, onExecute, onSetTXInfo } from '../utils'

import {
  onProvideLiqudityExecuted,
  onSetMaxValue,
  onSetTicker,
  onSetValue,
  onWeb3Set
} from './workers'

import { API_URL, CAKE_LP_LTT_BUSD_ADDRESS, LP_BROKER_ADDRESS } from '~config'
import ERC20 from '~contracts/ERC20.json'
import { Ticker } from '~enums'
import {
  AppState,
  blocksActions,
  contractsActions,
  lpActions,
  LPApproveSuccess,
  TransactionType,
  web3Actions
} from '~state/store'
import { getContract } from '~utils'

async function getBalance(contract: Contract, account: string): Promise<BigNumber> {
  return new BigNumber((await contract.balanceOf(account)).toString())
}

async function getTotalSupply(contract: Contract): Promise<BigNumber> {
  return new BigNumber((await contract.totalSupply()).toString())
}

function* fetchLPInfo(): SagaIterator {
  const {
    web3: { library, account },
    contracts: { bep20 },
    ltt: { pair },
    lp: {
      form: { ticker }
    }
  }: AppState = yield select()

  const apiResponse = yield call(async () => {
    const res = await fetch(`${API_URL}/ltt-info`)
    return res.json()
  })
  yield put(
    lpActions.setCachedInfo({
      volume: parseFloat(apiResponse.volume),
      reserves: {
        busd: new BigNumber(apiResponse.reserves.busd),
        ltt: new BigNumber(apiResponse.reserves.ltt)
      }
    })
  )

  if (!pair) return
  yield put(
    lpActions.setReserves({
      ltt: new BigNumber(pair.reserve0.raw.toString()),
      busd: new BigNumber(pair.reserve1.raw.toString())
    })
  )

  if (!library) return
  const lpContract = getContract(CAKE_LP_LTT_BUSD_ADDRESS, ERC20.abi, library)
  const totalSupply = yield call(getTotalSupply, lpContract)
  yield put(lpActions.setTotalSupply({ value: totalSupply }))

  if (!account || !bep20) return

  const balance = yield call(getBalance, lpContract, account)

  if (ticker !== Ticker.BNB) {
    const allowance = yield call(getAllowance, bep20[ticker], LP_BROKER_ADDRESS, account)

    yield put(lpActions.setAllowance({ allowance, ticker }))
  }
  yield put(lpActions.setBalance({ value: balance }))
}

function* onApproveSuccess({ payload }: LPApproveSuccess) {
  const {
    contracts: { bep20 },
    web3: { account },
    lp: {
      form: { value }
    }
  }: AppState = yield select()
  if (!bep20 || !account) return

  const allowance: BigNumber = yield call(() =>
    getAllowance(bep20[payload], LP_BROKER_ADDRESS, account)
  )

  yield put(lpActions.setAllowance({ ticker: payload, allowance }))

  // trigger onSetValue to rerender form
  yield put(lpActions.setValue(value))
}

const terminatedSaga = createTerminatedSaga(
  { start: lpActions.startListening, end: lpActions.endListening },
  [
    call(fetchLPInfo),
    takeLatest(contractsActions.setGeneral, fetchLPInfo),
    takeLatest(lpActions.provideLPExecuted, fetchLPInfo),
    takeLatest(lpActions.approveSuccess, fetchLPInfo),
    throttle(60000, blocksActions.set, fetchLPInfo)
  ]
)

export function* lpSaga(): SagaIterator {
  yield fork(terminatedSaga)

  yield all([
    yield takeLatest(lpActions.approve, (action) =>
      onApprove(
        action,
        () => lpActions.approveSuccess(action.payload.ticker),
        lpActions.approveFailure,
        () => lpActions.approve(action.payload)
      )
    ),
    yield takeLatest(lpActions.approveSuccess, onApproveSuccess),
    yield takeLatest(lpActions.setValue, onSetValue),
    yield takeLatest(lpActions.setTicker, onSetTicker),
    yield takeLatest(lpActions.executeProvideLP, () =>
      onSetTXInfo(TransactionType.ADD_LIQUIDITY, (s) => [
        { value: s.lp.form.value, ticker: s.lp.form.ticker }
      ])
    ),
    yield takeLatest(lpActions.executeProvideLP, (_action) =>
      onExecute(
        (s) => s.lp.provideLiquidityCall,
        lpActions.executeProvideLP,
        lpActions.provideLPExecuted,
        lpActions.provideLPFailed
      )
    ),
    yield takeLatest(lpActions.setMaxValue, onSetMaxValue),
    yield takeLatest(lpActions.provideLPExecuted, onProvideLiqudityExecuted),
    yield takeLatest(web3Actions.set, onWeb3Set)
  ])
}
