import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Contract } from 'ethers'

import { Ticker, TickerBEP20 } from '~enums'

export type GeneralContractsState = {
  masterCroco?: Contract
  stakingsManager?: Contract
  referralsTreeStorage?: Contract
  lusd?: Contract
  rewards?: {
    [Ticker.LTT]: Contract
    [Ticker.BUSD]: Contract
  }
  ltt?: Contract
  roundA?: Contract
  roundB?: Contract
  roundIDO?: Contract
  unstaker?: Contract
  lpBroker?: Contract
}

export type ContractsState = {
  bep20?: Record<TickerBEP20, Contract>
  general: GeneralContractsState
  securities?: Record<string, Contract>
}

export type ContractsSetBep20Action = PayloadAction<Record<TickerBEP20, Contract>>

export type ContractsSetSecuritiesAction = PayloadAction<Record<string, Contract>>

export type ContractsSetGeneralAction = PayloadAction<GeneralContractsState>

const initialState: ContractsState = {
  general: {}
}

export const contractsSlice = createSlice({
  name: 'contracts',
  initialState,
  reducers: {
    setBep20: (state, action: ContractsSetBep20Action) => {
      // @TODO fix somehow
      state.bep20 = action.payload as any
    },
    setSecurities: (state, action: ContractsSetSecuritiesAction) => {
      // @TODO fix somehow
      state.securities = action.payload as any
    },
    setGeneral: (state, action: ContractsSetGeneralAction) => {
      // @TODO fix somehow
      state.general = action.payload as any
    }
  }
})

export const { actions: contractsActions, reducer: contractsReducer } = contractsSlice
