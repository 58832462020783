import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { ReactComponent as Hacken } from '../Partners/images/hacken.svg'

import { Card as CardUi, BodyL, PrimaryButton } from '~ui'
import { mediaQuery } from '~utils'

const Card = styled(CardUi)`
  flex-direction: row !important;
  ${mediaQuery.lessThan('md')`
      flex-direction: column !important;
      gap:20px;
      padding: 20px 30px;
  `}
  align-items: center;
  padding: 40px 60px;
  justify-content: space-between;
`
const HackenLogo = styled(Hacken)`
  fill: white;
  max-height: 36px;
  margin-top: 20px;
  ${mediaQuery.lessThan('md')`
       max-height: 18px;
  margin-top: 10px;
  `}
`

const Title = styled(BodyL).attrs({ $bold: true })`
  color: ${({ theme }) => theme.colors.secondary};
  ${mediaQuery.lessThan('md')`
    text-align:center;
  `}
`

const Button = styled(PrimaryButton)`
  min-width: 197px;
`

export const SecurityAudit = () => {
  const { t } = useTranslation('products')

  return (
    <Card withBorder>
      <div>
        <Title>{t('Security Audit by')}</Title>
        <HackenLogo />
      </div>
      <Button onClick={() => window.open('/LocalTrade_04112021_SCAudit_Report.pdf', '_blank')}>
        {t('Download')}
      </Button>
    </Card>
  )
}
