import { all } from 'redux-saga/effects'

import { blocksSaga } from './blocks'
import { buySecuritiesSaga } from './buySecurities'
import { centralizedVaultsSaga } from './centralizedVaults'
import { contractsSaga } from './contracts'
import { dynamicVaultsSaga } from './dynamicVaults'
import { inviterSaga } from './inviter'
import { lpSaga } from './lp'
import { lttSaga } from './ltt'
import { referralsSaga } from './referrals'
import { rewardsSaga } from './rewards'
import { stakedSaga } from './staked'
import { staticVaultsSaga } from './staticVaults'
import { toastsSaga } from './toasts'
import { tokenizedSecuritiesSaga } from './tokenizedSecurities'
import { tokensSaga } from './tokens'
import { unstakerSaga } from './unstaker'
import { userSage } from './user'
import { web3Saga } from './web3'

export function* rootSaga() {
  yield all([
    web3Saga(),
    tokensSaga(),
    contractsSaga(),
    blocksSaga(),
    buySecuritiesSaga(),
    rewardsSaga(),
    stakedSaga(),
    referralsSaga(),
    inviterSaga(),
    toastsSaga(),
    tokenizedSecuritiesSaga(),
    lttSaga(),
    userSage(),
    dynamicVaultsSaga(),
    unstakerSaga(),
    staticVaultsSaga(),
    centralizedVaultsSaga(),
    lpSaga()
  ])
}
