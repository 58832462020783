import { Web3Provider } from '@ethersproject/providers'
import { Pair } from '@uniswap/sdk'

import { UniswapTokens } from '~config'
import { Ticker, TickerAddToLPFrom } from '~enums'

export function getLTTPair(library: Web3Provider) {
  const from = UniswapTokens[Ticker.BUSD]
  const to = UniswapTokens[Ticker.LTT]

  return Pair.fetchData(from, to, library)
}

export function getBUSDPair(ticker: TickerAddToLPFrom, library: Web3Provider): Promise<Pair> {
  const from = UniswapTokens[ticker === Ticker.BNB ? Ticker.WBNB : ticker]
  const to = UniswapTokens[Ticker.BUSD]

  return Pair.fetchData(from, to, library)
}
