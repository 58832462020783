import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { TVL } from './TVL'

import { MediaQueryBreakpoint } from '~enums'
import { Product } from '~interfaces'
import { PrimaryButton, SecondaryButton, HoverCard, Headline3, BodyL, Headline4, Icon } from '~ui'
import { mediaQuery } from '~utils'

const CardTitle = styled(Headline3)`
  flex: 1;
  margin-right: 15px;
  ${mediaQuery.lessThan('md')`
        margin-bottom:10px;
        margin-right: 0;  
    `}
`

const CardDesc = styled(BodyL)`
  flex: 1;
`

const ButtonsWrapper = styled.div<{ $both?: boolean }>`
  margin-top: 30px;
  flex-wrap: wrap;
  display: flex;
  gap: 15px;
  button {
    flex-grow: 1;
    margin: 0 !important;
  }

  ${mediaQuery.lessThan('lg')`
    gap: 10px;
    margin-top: 20px;
    `}
`

const Image = styled.img`
  max-width: 100%;
  border-radius: 24px;
  margin-bottom: 30px;
  ${mediaQuery.lessThan('lg')`
        margin-bottom: 20px;
      `}
`

const LabelWrapper = styled.div`
  background: ${({ theme }) => theme.colors.secondaryShade2};
  position: absolute;
  top: 25px;
  left: 0;
  margin-left: -60px;
  transform: rotate(-45deg);
  width: 237px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  ${mediaQuery.lessThan('lg')`
    height: 50px;
    margin-left: -70px;
    top: 20px;
  `}
`

const Label = styled(Headline4)`
  margin: 0;
  color: ${({ theme }) => theme.colors.tertiary};
`

const IconWrapper = styled.div`
  height: 50px;
  width: 100%;
  cursor: pointer;
  max-width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: ${({ theme }) => `1px solid ${theme.colors.secondaryShade1}`};
  background-color: ${({ theme }) => theme.colors.secondaryShade1};
`
const ActionImage = styled.img`
  max-height: 50px;
  cursor: pointer;
`

export const Card = (p: Product) => {
  const isSm = useMediaQuery({ maxWidth: MediaQueryBreakpoint.lg })
  const { t } = useTranslation('products')
  const history = useHistory()

  const renderAction = (action: Product['action'], key?: number) =>
    action &&
    (action.image ? (
      <ActionImage
        key={key}
        src={action.image.src}
        onClick={() => window.open(action?.image?.href)}
      />
    ) : (
      <PrimaryButton
        whiteHover
        key={key}
        onClick={() =>
          action!.button!.link
            ? history.push(action!.button!.link)
            : window.open(action!.button!.href, '_blank')
        }
        block={isSm}
      >
        {action.button?.text}
      </PrimaryButton>
    ))

  return (
    <HoverCard
      withBorder
      hoverStyle="gradient"
      className="position-relative  overflow-hidden h-auto"
    >
      {p.label && (
        <LabelWrapper className="d-none d-lg-flex">
          <Label>{p.label}</Label>
        </LabelWrapper>
      )}
      {p.tvl && <TVL />}
      <div className="position-relative overflow-hidden">
        <Image src={p.image} alt={p.title} className="w-100" />
        {p.label && (
          <LabelWrapper className="d-lg-none">
            <Label>{p.label}</Label>
          </LabelWrapper>
        )}
      </div>
      <div className="d-flex flex-column flex-xl-row  justify-content-between">
        <CardTitle>{p.title}</CardTitle>
        <CardDesc>{p.desc}</CardDesc>
      </div>
      {(p.more || p.action || p.actions) && (
        <ButtonsWrapper $both={!!p.action && !!p.more}>
          {p.more && (
            <SecondaryButton
              disabled={p.more.disabled}
              outline
              className="mw-none"
              onClick={() => window.open(p.more?.link, '_blank')}
              block={isSm}
            >
              {p.more.text ?? t('Read More')}
            </SecondaryButton>
          )}
          {p.actions && p.actions.map(renderAction)}
          {renderAction(p.action)}
          {p.info && (
            <IconWrapper>
              <Icon name="info" onClick={() => window.open(p.info!.href)} />
            </IconWrapper>
          )}
        </ButtonsWrapper>
      )}
    </HoverCard>
  )
}
