import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { TickerBuySecurityFrom } from '~enums'
import { Security } from '~interfaces'

export interface TokenizedSecuritiesState {
  shares: Security[] | null
  approving: boolean
}

type TokenizedSecuritiesSetAction = PayloadAction<Security[]>

export type TokenizedSecuritiesApproveAction = PayloadAction<{
  ticker: TickerBuySecurityFrom
  spender: string
}>

const initialState: TokenizedSecuritiesState = {
  shares: null,
  approving: false
}

export const tokenizedSecuritiesSlice = createSlice({
  name: 'tokenizedSecurities',
  initialState: initialState,
  reducers: {
    startListening: (state) => state,
    endListening: (state) => state,
    approve: (state, _action: TokenizedSecuritiesApproveAction) => {
      state.approving = true
    },
    approveSuccess: (state) => {
      state.approving = false
    },
    approveFailure: (state) => {
      state.approving = false
    },
    set: (state, action: TokenizedSecuritiesSetAction) => {
      return {
        ...state,
        shares: action.payload
      }
    },
    reset: () => {
      return initialState
    }
  }
})

export const { actions: tokenizedSecuritiesActions, reducer: tokenizedSecuritiesReducer } =
  tokenizedSecuritiesSlice
