import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en-US', 'ru-RU', 'es-ES', 'pt-PT'],
    fallbackLng: ['en-US', 'ru-RU', 'es-ES', 'pt-PT'],
    ns: [
      'auth',
      'account',
      'banners',
      'faq',
      'home',
      'products',
      'social',
      'balance',
      'sale',
      'advantages',
      'referrals',
      'rounds',
      'unlockingSchedule',
      'security',
      'common',
      'navbar',
      'vault',
      'lp'
    ]
  })

export default i18n
