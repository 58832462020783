import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'

import { WithColorInline } from './WithColorInline'

import { BUSD_ADDRESS, LTT_ADDRESS, getPancakeSwapLink, get1inchSwapLink } from '~config'
import { MediaQueryBreakpoint, Ticker } from '~enums'
import { A, Icon as IconUi, IconProps } from '~ui'
import { mediaQuery } from '~utils'

const Icon = styled(IconUi)`
  margin-right: 8px;
  ${mediaQuery.lessThan('sm')`
    margin-right: 0;
  `}
`

const Link = styled(
  ({
    url,
    name,
    iconName,
    className
  }: {
    url: string
    name: string
    iconName: IconProps['name']
    className?: string
  }) => {
    const isSm = useMediaQuery({
      maxWidth: MediaQueryBreakpoint.sm
    })
    return (
      <A href={url} target="_blank" color="white" className={className}>
        <Icon name={iconName} width={22} height={22} />
        {!isSm && name}
      </A>
    )
  }
)`
  display: flex;
  font-weight: 400;
  text-decoration: none;
  margin-left: 16px;
  ${mediaQuery.lessThan('sm')`
    margin-left: 10px;
  `}
`

export const SwapLinks = () => {
  const { t } = useTranslation('common')
  return (
    <div className="d-flex align-items-center">
      <WithColorInline $color="secondary">
        {t('Buy')} {Ticker.LTT}:
      </WithColorInline>
      <Link url={getPancakeSwapLink(BUSD_ADDRESS, LTT_ADDRESS)} iconName="pancake" name="Pancake" />
      <Link url={get1inchSwapLink(BUSD_ADDRESS, LTT_ADDRESS)} iconName="oneInch" name="1inch" />
    </div>
  )
}
