import React from 'react'
import styled from 'styled-components'

import { Block, SmartMargin } from '../../../components'
import { VaultProgress } from '../components'

import { PositionInfo, PositionInfoProps } from './PositionInfo'

import { StaticVault } from '~interfaces'
import { Card } from '~ui'

const StyledCard = styled(Card)`
  backdrop-filter: none;
  padding: 20px !important;
`

export const Position = ({
  position,
  unstakeOffset,
  rewardInterval,
  className,
  ...props
}: {
  className?: string
} & Pick<StaticVault['contract'], 'unstakeOffset' | 'secondlyUnlockPer1e16' | 'rewardInterval'> &
  PositionInfoProps) => {
  return (
    <StyledCard className={className}>
      <SmartMargin top>
        <Block>
          <VaultProgress
            tooltipId={`static-vault-${position.index}-lock`}
            creationDate={position.startDate}
            unstakeDate={new Date(position.startDate.getTime() + unstakeOffset.toNumber() * 1000)}
            endDate={new Date(position.startDate.getTime() + rewardInterval.toNumber() * 1000)}
          />
        </Block>
      </SmartMargin>
      <PositionInfo
        position={position}
        rewardInterval={rewardInterval}
        unstakeOffset={unstakeOffset}
        {...props}
      />
    </StyledCard>
  )
}
