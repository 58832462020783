import { BaseApi } from './BaseApi'

import { NewsDataApi } from '~interfaces'

export class NewsApi extends BaseApi {
  async get(): Promise<NewsDataApi> {
    const response = await fetch(`${this.baseUrl}/news`)
    const data = await response.json()
    return data
  }
}
