import * as Bs from '@bootstrap-styled/v4'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Stat } from './Stat'

import { A, BodyM, Card, Headline3 } from '~components'
import { Security } from '~interfaces'
import { mediaQuery } from '~utils'

const Title = styled(Headline3)`
  margin-bottom: 12px;
`

const SubTitle = styled(BodyM)`
  margin-bottom: 30px;
  ${A} {
    margin-top: 20px;
    display: block;
  }
`

const Col = styled(Bs.Col).attrs({ xs: 12, md: 6 })`
  border-bottom: ${({ border, theme }) =>
    border ? `1px solid ${theme.colors.secondaryShade1}` : 'none'};
  padding: 15px;
  ${mediaQuery.greaterThan('md')`
        &:nth-child(1),
  :nth-child(2) {
    padding-top: 0;
  }
  `}
`

export const AboutCompany = ({
  description,
  more,
  industry,
  foundationYear,
  valuation,
  annualYieldPercent,
  ipoExpected,
  leadInvestors
}: Pick<
  Security,
  | 'description'
  | 'more'
  | 'industry'
  | 'foundationYear'
  | 'valuation'
  | 'annualYieldPercent'
  | 'ipoExpected'
  | 'leadInvestors'
>) => {
  const { t } = useTranslation('security')
  return (
    <Card withBorder className="h-auto">
      <Title>{t('About company')}</Title>
      <SubTitle>
        {description}
        <A href={more}>{more}</A>
      </SubTitle>
      <Bs.Row noGutters>
        <Col border>
          <Stat title={t('Industry')} value={industry} align="left" />
        </Col>
        <Col border>
          <Stat title={t('Founded')} value={foundationYear} align="left" />
        </Col>
        <Col border>
          <Stat
            title={t('Valuation')}
            value={`${valuation.toNumber().toLocaleString('en-US')} USD`}
            align="left"
          />
        </Col>
        <Col border>
          <Stat title={t('Annual Yield')} value={`+${annualYieldPercent}%`} align="left" />
        </Col>
        <Col>
          <Stat title={t('IPO Expected')} value={ipoExpected} align="left" />
        </Col>
        <Col>
          <Stat title={t('Lead Investors')} value={leadInvestors.join('\n')} align="left" />
        </Col>
      </Bs.Row>
    </Card>
  )
}
