import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'

export interface UnstakerState {
  value: BigNumber
  unstaking: boolean
}

export type UnstakerUnstakeAction = PayloadAction

export type UnstakerUnstakeFailedAction = PayloadAction

export type UnstakerSetAction = PayloadAction<{ value: BigNumber }>

export type UnstakerUnstakedAction = PayloadAction

const initialState: UnstakerState = { unstaking: false, value: new BigNumber('0') }

export const unstakerSlice = createSlice({
  name: 'unstaker',
  initialState,
  reducers: {
    startListening: (state) => state,
    endListening: (state) => state,
    unstake: (state, action: UnstakerUnstakeAction) => {
      state.unstaking = true
    },
    unstakeFailed: (state, action: UnstakerUnstakeFailedAction) => {
      state.unstaking = false
    },
    unstaked: (state, action: UnstakerUnstakedAction) => {
      state.unstaking = false
    },
    set: (state, action: UnstakerSetAction) => {
      state.value = action.payload.value
    },
    reset: () => initialState
  }
})

export const { actions: unstakerActions, reducer: unstakerReducer } = unstakerSlice
