import { createSlice } from '@reduxjs/toolkit'

export interface ModalState {
  opened: boolean
}

const initialState: ModalState = {
  opened: false
}

export const createModalSlice = (name: string) =>
  createSlice({
    name,
    initialState,
    reducers: {
      open: (state) => {
        state.opened = true
      },
      close: (state) => {
        state.opened = false
      },
      toggle: (state) => {
        state.opened = !state.opened
      }
    }
  })
