import * as Bs from '@bootstrap-styled/v4'
import BigNumber from 'bignumber.js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'

import { Amount, Label, MarginWrapper, SmartMargin, Button } from '../../../components'

import { MediaQueryBreakpoint, Ticker } from '~enums'
import { CentralizedVault, DynamicVault } from '~interfaces'
import { FormGroup, Input, SecondaryButton as SecondaryButtonUI } from '~ui'
import { NumberFormatter } from '~utils'

const SecondaryButton = styled(SecondaryButtonUI)`
  padding: 10px !important;
`

export const UnstakeLTT = ({
  unstakeValue,
  onUnstakeValueChanged,
  back,
  vault,
  unstakeFee,
  bnbBalance,
  executeUnstake,
  unstaking,
  color,
  colorShade
}: {
  unstakeValue: string
  unstaking: boolean
  bnbBalance: BigNumber
  unstakeFee?: BigNumber
  executeUnstake: () => void
  onUnstakeValueChanged: (value: string) => void
  vault: DynamicVault | CentralizedVault
  back: () => void
  color?: string
  colorShade?: string
}) => {
  const { t } = useTranslation(['vault', 'common', 'referrals'])
  const isLg = useMediaQuery({ maxWidth: MediaQueryBreakpoint.lg })

  const getButtonProps = () => {
    if (unstakeFee && bnbBalance.lt(unstakeFee)) {
      return {
        disabled: true,
        children: [t('common:Not enough {ticker} to pay the fee', { ticker: Ticker.BNB })]
      }
    }
    if (
      vault.contract.staked.lt(NumberFormatter.fromDecimal(unstakeValue || '0', vault.stakeTicker))
    )
      return { disabled: true, children: [t('common:Insufficient balance')] }
    if (!unstakeFee)
      return {
        children: [t('Withdraw {ticker} from Pool', { ticker: Ticker.LTT })],
        disabled: true
      }

    return { children: [t('Withdraw {ticker} from Pool', { ticker: Ticker.LTT })] }
  }

  return (
    <Bs.Form>
      <FormGroup>
        <div className="d-flex justify-content-between">
          <Amount>{t('I want to withdraw')}:</Amount>
          <div className="d-flex align-items-center">
            <Label>{t('common:Balance')}:</Label>{' '}
            <Amount
              className="cursor-pointer"
              onClick={() =>
                onUnstakeValueChanged(
                  NumberFormatter.formatDecimal(vault.contract.staked, vault.stakeTicker)
                )
              }
            >
              {NumberFormatter.formatDecimal(vault.contract.staked, vault.stakeTicker)}{' '}
              {vault.stakeTicker}
            </Amount>
          </div>
        </div>
        <SmartMargin top>
          <Input
            type="number"
            onKeyDown={(e: any) => {
              if (
                e.key === '-' ||
                (e.key === 'ArrowDown' && (e.target.value === '0' || e.target.value === ''))
              )
                e.preventDefault()
            }}
            onWheel={(e: any) => {
              e.target.blur()
            }}
            onChange={(e: any) => {
              if (!NumberFormatter.isValidFormatDecimals(e.target.value, vault.stakeTicker)) return
              onUnstakeValueChanged(e.target.value)
            }}
            value={unstakeValue}
          />
        </SmartMargin>
      </FormGroup>
      {unstakeFee && (
        <SmartMargin top>
          <div className="d-flex align-items-center">
            <Label>{t('common:Network Fee')}: </Label>
            <Amount>
              {NumberFormatter.formatDecimal(unstakeFee, Ticker.BNB)} {Ticker.BNB}
            </Amount>
          </div>
        </SmartMargin>
      )}
      <MarginWrapper>
        <Bs.Row noGutters>
          <Bs.Col xs={12} lg={6}>
            <SmartMargin right>
              <SecondaryButton wide outline onClick={back}>
                ← {t('Back')}
              </SecondaryButton>
            </SmartMargin>
          </Bs.Col>
          <Bs.Col xs={12} lg={6}>
            <SmartMargin top={isLg} left>
              <Button
                onClick={executeUnstake}
                loading={unstaking}
                backgroundColor={colorShade}
                hoverBackgroundColor={color}
                {...getButtonProps()}
              />
            </SmartMargin>
          </Bs.Col>
        </Bs.Row>
      </MarginWrapper>
    </Bs.Form>
  )
}
