import styled from 'styled-components'

import { mediaQuery } from '~utils'

export const Row = styled.div`
  display: flex;
  margin-right: -20px;
  margin-left: -20px;
  flex-wrap: wrap;
  ${mediaQuery.lessThan('md')`
     gap: 20px;
  `}
`
