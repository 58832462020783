import {
  NoBscProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorBSC
} from '@binance-chain/bsc-connector'
import { UnsupportedChainIdError } from '@web3-react/core'
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected
} from '@web3-react/injected-connector'
import {
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
  WalletConnectConnector
} from '@web3-react/walletconnect-connector'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { LocalStorageKey } from '~config'
import { ConnectorNames } from '~enums'
import { useActiveWeb3React } from '~hooks/useActiveWeb3React'
import { useAppDispatch, web3Actions } from '~state'
import { connectorsByName, setupNetwork, toastError } from '~utils'

function setProviderLocalStorage(cn: ConnectorNames) {
  window.localStorage.setItem(LocalStorageKey.ConnectorName, cn)
}

export const useAuth = () => {
  const { t } = useTranslation('auth')
  const { activate, deactivate } = useActiveWeb3React()
  const dispatch = useAppDispatch()

  const login = useCallback(
    (cn: ConnectorNames) => {
      const connector = connectorsByName[cn]
      if (connector) {
        // bad auth with walletconnect fix
        activate(connector, undefined, true)
          .then(({ account }) => {
            // first connect
            setProviderLocalStorage(cn)
            if (account) dispatch(web3Actions.walletConnect({ wallet: cn, address: account }))
          })
          .catch(async (error: Error) => {
            if (error instanceof UnsupportedChainIdError) {
              const hasSetup = await setupNetwork(cn)
              if (hasSetup) {
                activate(connector)
                  .then(({ account }) => {
                    setProviderLocalStorage(cn)
                    if (account)
                      dispatch(web3Actions.walletConnect({ wallet: cn, address: account }))
                  })
                  .catch((e: Error) => {
                    dispatch(web3Actions.walletConnect({ wallet: cn, failedReason: e.message }))
                  })
              }
            } else {
              dispatch(web3Actions.walletConnect({ wallet: cn, failedReason: error.message }))
              window.localStorage.removeItem(LocalStorageKey.ConnectorName)
              if (error instanceof NoEthereumProviderError || error instanceof NoBscProviderError) {
                toastError(t('Provider Error'), t('No provider was found'))
              } else if (
                error instanceof UserRejectedRequestErrorInjected ||
                error instanceof UserRejectedRequestErrorWalletConnect ||
                error instanceof UserRejectedRequestErrorBSC
              ) {
                if (connector instanceof WalletConnectConnector) {
                  const walletConnector = connector as WalletConnectConnector
                  walletConnector.walletConnectProvider = null
                }
              } else {
                toastError(error.name, error.message)
              }
            }
          })
      } else {
        toastError(t('Unable to find connector'), t('The connector config is wrong'))
      }
    },
    [activate, t]
  )

  const logout = useCallback(() => {
    deactivate()
    // This localStorage key is set by @web3-react/walletconnect-connector
    // and null setting has to be handled by connectors
    if (window.localStorage.getItem('walletconnect')) {
      connectorsByName[ConnectorNames.WalletConnect].close()
      connectorsByName[ConnectorNames.WalletConnect].walletConnectProvider = null
    }
    window.localStorage.removeItem(LocalStorageKey.ConnectorName)
  }, [deactivate])

  return {
    login,
    logout,
    loginLC: (cn: ConnectorNames) => {
      window.localStorage.removeItem('walletconnect')
      login(cn)
    }
  }
}
