import * as Bs from '@bootstrap-styled/v4'
import BigNumber from 'bignumber.js'
import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'

import {
  Amount,
  Button,
  SecondaryButton,
  Label,
  SmartMargin,
  MarginWrapper
} from '../../../components'

import { MediaQueryBreakpoint, Ticker } from '~enums'
import { TickerIcon } from '~ticker'
import { Headline3, Modal as ModalUI, ModalBody, BodyM } from '~ui'
import { mediaQuery, NumberFormatter } from '~utils'

const StyledModal = styled(ModalUI)`
  ${mediaQuery.greaterThan('sm')`
    .modal-dialog {
      max-width: 495px;
    }
  `}
`

const FooterText = styled(BodyM)`
  margin-top: 30px;
  text-align: center;
`

const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 10px;
  background-color: ${({ theme }) => theme.colors.secondaryShade1};
  border-radius: 12px;
  flex: 1 1 50%;
`

const Divider = styled(Amount)`
  margin: 0 30px;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

const Title = styled(Headline3)`
  margin-bottom: 30px;
  margin-top: 10px;
  text-align: center;
`

const SubTitle = styled(Label)`
  text-align: center;
`

export const StakeModal = ({
  close,
  isOpen,
  executeStake,
  color,
  stakeValue,
  stakeTicker,
  spendValue,
  spendTicker,
  stakeFee,
  staking,
  averagePrice,
  colorShade,
  validate,
  children
}: PropsWithChildren<{
  close: () => void
  isOpen: boolean
  executeStake: () => void
  color?: string
  colorShade?: string
  stakeValue: BigNumber
  stakeTicker: Ticker
  spendValue: string
  spendTicker: Ticker
  stakeFee?: BigNumber
  staking: boolean
  validate?: () => Error | undefined
  averagePrice: BigNumber | undefined
}>) => {
  const { t } = useTranslation(['vault', 'common'])
  const isLg = useMediaQuery({ maxWidth: MediaQueryBreakpoint.lg })
  const getButtonProps = () => {
    const possibleError = validate?.()
    if (possibleError) {
      return {
        disabled: true
      }
    }

    return {
      children: [t('Add {ticker} to Pool', { ticker: spendTicker })]
    }
  }

  return (
    <StyledModal onClose={close} isOpen={isOpen} borderColor={color}>
      <ModalBody>
        <SubTitle>{t('You want to stake')}</SubTitle>
        <Title>
          {NumberFormatter.formatDecimal(stakeValue, stakeTicker)} {stakeTicker}
        </Title>
        <Row>
          <Card>
            <TickerIcon width={22} height={22} ticker={spendTicker} />
            <Amount>
              {spendValue} {spendTicker}
            </Amount>
          </Card>
          {spendTicker !== stakeTicker && (
            <>
              <Divider>~</Divider>
              <Card>
                <TickerIcon width={22} height={22} ticker={stakeTicker} />
                <Amount>
                  {NumberFormatter.formatDecimal(stakeValue, stakeTicker)} {stakeTicker}
                </Amount>
              </Card>
            </>
          )}
        </Row>
        {stakeFee && (
          <Row>
            <Card>
              <Label>{t('common:Network Fee')}:</Label>
              <Amount>
                {NumberFormatter.formatDecimal(stakeFee, Ticker.BNB)} {Ticker.BNB}
              </Amount>
            </Card>
          </Row>
        )}
        {children}
        <MarginWrapper>
          <Bs.Row noGutters>
            <Bs.Col xs={12} lg={6}>
              <SmartMargin right>
                <SecondaryButton wide outline onClick={close}>
                  ← {t('common:Back')}
                </SecondaryButton>
              </SmartMargin>
            </Bs.Col>
            <Bs.Col xs={12} lg={6}>
              <SmartMargin top={isLg} left>
                <Button
                  onClick={() => {
                    executeStake()
                    close()
                  }}
                  loading={staking}
                  backgroundColor={colorShade}
                  hoverBackgroundColor={color}
                  {...getButtonProps()}
                />
              </SmartMargin>
            </Bs.Col>
          </Bs.Row>
        </MarginWrapper>
        {averagePrice && (
          <FooterText>
            1 {stakeTicker} ={' '}
            {NumberFormatter.formatDecimal(new BigNumber(1).div(averagePrice), stakeTicker)}{' '}
            {spendTicker}
          </FooterText>
        )}
      </ModalBody>
    </StyledModal>
  )
}
