import React from 'react'
import styled from 'styled-components'

const CircleBackground = styled.circle`
  fill: none;
  stroke: ${({ theme }) => theme.colors.secondaryShade3};
`

const CircleProgress = styled.circle<{ gradient: CircularProgressBarProps['gradient'] }>`
  fill: none;
  stroke: ${({ gradient }) => `url('#${gradient}')`};
  stroke-linecap: round;
  stroke-linejoin: round;
`
const CircleText = styled.text`
  fill: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  font-size: 24px;
`

const LabelText = styled.text`
  fill: ${({ theme }) => theme.colors.white};
  font-size: 14px;
`

export type CircularProgressBarProps = {
  strokeWidth: number
  size: number
  percentage: number
  label?: string
  className?: string
  gradient?: 'primary' | 'secondary'
}

export const CircularProgressBar = ({
  strokeWidth,
  size,
  percentage,
  label,
  className,
  gradient = 'primary'
}: CircularProgressBarProps) => {
  // Size of the enclosing square

  // SVG centers the stroke width on the radius, subtract out so circle fits in square
  const radius = (size - strokeWidth) / 2
  // Enclose cicle in a circumscribing square
  const viewBox = `0 0 ${size} ${size}`
  // Arc length at 100% coverage is the circle circumference
  const dashArray = radius * Math.PI * 2
  // Scale 100% coverage overlay with the actual percent
  const dashOffset = dashArray - (dashArray * percentage) / 100

  return (
    <svg width={size} height={size} viewBox={viewBox} className={className}>
      <CircleBackground cx={size / 2} cy={size / 2} r={radius} strokeWidth={`${strokeWidth}px`} />
      <CircleProgress
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
        // Start progress marker at 12 O'Clock
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
        gradient={gradient}
        style={{
          strokeDasharray: dashArray,
          strokeDashoffset: dashOffset
        }}
      />
      <CircleText x="50%" y="50%" dy={label ? '0px' : '12px'} textAnchor="middle">
        {`${percentage}%`}
      </CircleText>
      {label && (
        <LabelText x="50%" y="50%" dy="20px" textAnchor="middle">
          {label}
        </LabelText>
      )}
      <linearGradient id="primary" gradientUnits="userSpaceOnUse">
        <stop stop-color="#C3F780" />
        <stop offset="1" stop-color="#F7D580" />
      </linearGradient>
      <linearGradient id="secondary" gradientUnits="userSpaceOnUse">
        <stop stop-color="#E88DFF" />
        <stop offset="1" stop-color="#9D8DFF" />
      </linearGradient>
    </svg>
  )
}
