import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { PrimaryButton, ButtonText } from '~ui'

export const WhitepaperButton = styled(({ className }) => {
  const { t } = useTranslation('account')

  return (
    <PrimaryButton
      onClick={() => {
        window.open(`/LocalTrade-Whitepaper-EN.pdf`, '_blank')
      }}
      className={className}
    >
      <ButtonText className="text-center w-100">{t('Whitepaper')}</ButtonText>
    </PrimaryButton>
  )
})`
  height: 50px;
  padding: 8px 10px !important;
  justify-content: space-between;
`
