import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import { SagaIterator } from 'redux-saga'
import { all, race, take, cancel } from 'redux-saga/effects'

export function createTerminatedSaga(
  actions: { start: ActionCreatorWithPayload<any>; end: ActionCreatorWithPayload<any> },
  effects: any
): () => SagaIterator {
  return function* (): SagaIterator {
    let tasks = null
    let counter = 0

    while (true) {
      const { start, end } = yield race({
        start: take(actions.start),
        end: take(actions.end)
      })
      if (start) {
        if (!counter) {
          tasks = yield all(effects)
        }
        counter++
      }
      if (end) {
        counter--
        if (!counter && tasks) {
          for (const t of tasks) {
            yield cancel(t)
          }
        }
      }
    }
  }
}
