import * as Bs from '@bootstrap-styled/v4'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Label } from './Label'
import { Value } from './Value'

import { Ticker } from '~enums'
import { useBalance } from '~state'
import { Card, SecondaryButton } from '~ui'
import { NumberFormatter, mediaQuery } from '~utils'

const Row = styled(Bs.Row)`
  ${mediaQuery.lessThan('md')`
     text-align:center;
     gap: 20px;
      `}
`

export const LocalBalanceInfo = () => {
  const balance = useBalance(Ticker.LUSD)
  const { t } = useTranslation('balance')

  return (
    <Card>
      <Row className="align-items-center">
        <Bs.Col md={6} xs={12}>
          <Label>{t('LocalUSD Balance')}:</Label>
          <Value>
            {NumberFormatter.formatDecimal(balance, Ticker.LUSD).toString()} {Ticker.LUSD}
          </Value>
        </Bs.Col>
        <Bs.Col md={{ size: 5, offset: 1 }} xs={12}>
          <SecondaryButton
            outline
            wide
            size="sm"
            onClick={() => window.open('https://docs.localtrade.cc/token-sale/what-is-localusd')}
          >
            {t('Learn more')} →
          </SecondaryButton>
        </Bs.Col>
      </Row>
    </Card>
  )
}
