import { TransactionResponse } from '@ethersproject/providers'
import { CaseReducer } from '@reduxjs/toolkit'
import { SagaIterator } from 'redux-saga'
import { call, put, select } from 'redux-saga/effects'

import { AppState, transactionActions, transactionModalActions, TransactionStatus } from '~state'

export function* onExecute(
  getCall: (s: AppState) => (() => Promise<TransactionResponse>) | undefined,
  retryCall: () => void,
  success: () => ReturnType<CaseReducer>,
  fail: () => ReturnType<CaseReducer>
): SagaIterator {
  const state: AppState = yield select()
  const {
    web3: { gasPrice }
  } = state
  const contractCall = yield call(getCall, state)
  if (!contractCall || !gasPrice) return yield put(fail())
  yield put(transactionActions.setStatus(TransactionStatus.ACCEPT))
  yield put(transactionActions.setRetryCall(retryCall))
  yield put(transactionModalActions.open())
  try {
    const tx: TransactionResponse = yield call(contractCall)
    yield put(transactionActions.setStatus(TransactionStatus.PENDING))
    yield put(transactionActions.setTXHash(tx.hash))
    yield call(() => tx.wait())
    yield put(transactionActions.setStatus(TransactionStatus.SUCCESS))
    yield put(success())
  } catch (e) {
    console.error(e)
    yield put(transactionActions.setStatus(TransactionStatus.FAIL))
    yield put(fail())
  }
}
