import React from 'react'
import styled from 'styled-components'

import { AcceptBody } from './AcceptBody'
import { FailBody } from './FailBody'
import { PendingBody } from './PendingBody'
import { SuccessBody } from './SuccessBody'

import { TransactionStatus, useTransactionModal } from '~state'
import { Modal, ModalBody, ModalClose } from '~ui'

const StyledModalBody = styled(ModalBody)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledModalClose = styled(ModalClose)`
  position: absolute;
  right: 20px;
  top: 20px;
`

export const TransactionModal = () => {
  const {
    modal: [isOpen, { close }],
    status,
    inputValues,
    txHash,
    txType,
    retry,
    ticker
  } = useTransactionModal()

  return (
    <Modal onClose={close} isOpen={isOpen}>
      <StyledModalBody>
        <StyledModalClose onClick={() => close()} />
        {status === TransactionStatus.ACCEPT && (
          <AcceptBody txType={txType} values={inputValues} ticker={ticker} />
        )}
        {status === TransactionStatus.PENDING && <PendingBody />}
        {status === TransactionStatus.SUCCESS && (
          <SuccessBody txType={txType} txHash={txHash} values={inputValues} ticker={ticker} />
        )}
        {status === TransactionStatus.FAIL && <FailBody retry={retry} />}
      </StyledModalBody>
    </Modal>
  )
}
