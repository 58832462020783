import * as Bs from '@bootstrap-styled/v4'
import BigNumber from 'bignumber.js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import SliderUI from 'react-slick'
import styled from 'styled-components'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { Connect, MarginWrapper, Button } from '../../../components'
import { Footer } from '../components'

import { APRMultiplier } from './APRMultiplier'
import { Info } from './Info'
import { Position } from './Position'
import { Stake } from './Stake'
import { VaultStats } from './VaultStats'

import { Ticker, TickerBEP20, TickerStaking } from '~enums'
import { useActiveWeb3React } from '~hooks'
import { StaticVaultsState, useBalance } from '~state'
import { A2, Headline3 as Headline3Ui, Card } from '~ui'
import { mediaQuery, useToggle } from '~utils'

const Headline3 = styled(Headline3Ui)`
  color: ${({ theme }) => theme.colors.secondaryShade2};
  margin-bottom: 5px !important;
`

const CardSubtitle = styled(Bs.CardSubtitle)`
  color: ${({ theme }) => theme.colors.secondarySemi};
`

const StyledHr = styled.hr`
  border-color: ${({ theme }) => theme.colors.secondaryShade7};
`

export enum Action {
  Home = 'home',
  Add = 'add',
  Withdraw = 'withdraw'
}

const MarginedCol = styled(Bs.Col)`
  ${mediaQuery.lessThan('lg')`
  margin-bottom: 20px;
  `}
`

const StyledSliderUI = styled(SliderUI)`
  // breaking the ux occasionally
  margin-left: -30px;
  width: calc(100% + 60px);
  ${mediaQuery.lessThan('md')`
    margin-left: -20px;
    width: calc(100% + 40px);
  `}
  // hack to leave space between slides
  .slick-slide {
    padding: 0 8px;
    box-sizing: border-box;
  }
  // dots color change. beautiful library
  & > .slick-dots li {
    margin: 0 0;
    width: 12px;
  }
  & > .slick-dots li.slick-active button:before {
    color: ${({ theme }) => theme.colors.white};
  }
  & > .slick-dots button {
    height: 20px;
  }
  & > .slick-dots button:before {
    font-size: 8px;
    width: 12px;
    height: 20px;
    color: ${({ theme }) => theme.colors.secondaryShade5};
  }
`

const Note = styled(A2)`
  margin-top: 25px;
  display: block;
  ${mediaQuery.lessThan('md')`
  margin-top: 10px;
  `}
`

const Spacer = styled.div`
  height: 30px;
`

const VaultCard = styled(Card)`
  height: auto;
  ${({ margin }) => (margin ? 'margin-top: 100px;' : '')}
  ${({ margin }) =>
    margin
      ? mediaQuery.lessThan('xl')`
    margin-top: 70px;
  `
      : ''}
  ${({ margin }) =>
    margin
      ? mediaQuery.lessThan('lg')`
    margin-top: 50px;
  `
      : ''}
`

type StaticVaultProps = {
  approve: (ticker: TickerBEP20) => void
  allowances: Partial<Record<TickerBEP20, BigNumber>>
  unstakeFee: (index: number) => BigNumber | undefined
  executeUnstake: (index: number) => void
  inviter: string
  isInviterMutable: boolean
  onInviterChanged: (inviter: string) => void
  executeStake: () => void
  stakeFee?: BigNumber
  stakeValue: BigNumber
  onSetSpend: (value: string) => void
  onSetTicker: (ticker: TickerStaking) => void
  spendValue: string
  spendTicker: TickerStaking
  executeClaim: () => void
  averagePrice?: BigNumber
} & StaticVaultsState['']

export const StaticVault: React.FC<StaticVaultProps> = ({
  approving,
  approve,
  unstakeFee,
  executeUnstake,
  executeStake,
  stakeFee,
  stakeValue,
  spendTicker,
  spendValue,
  inviter,
  isInviterMutable,
  onInviterChanged,
  onSetSpend,
  onSetTicker,
  executeClaim,
  averagePrice,
  ...v
}) => {
  const { account } = useActiveWeb3React()
  const { t } = useTranslation('vault')
  const [isAddVisible, toggleAddTokensVisible] = useToggle(false)
  const bnbBalance = useBalance(Ticker.BNB)
  const spendBalance = useBalance(spendTicker)

  const isAprMultiplierVisible = v.aprMultiplier && account && v.contestWinners?.includes(account)

  return (
    <VaultCard withBorder margin={isAprMultiplierVisible}>
      {isAprMultiplierVisible && (
        <>
          <APRMultiplier apr={v.apr} aprMultiplier={v.aprMultiplier!} />
          <Spacer />
        </>
      )}
      <Bs.Row noGutters>
        <MarginedCol xs={12} lg={6}>
          <Headline3>{v.title}</Headline3>
          <CardSubtitle>{v.subtitle}</CardSubtitle>
          <Note href="https://docs.localtrade.cc/products/staking-vaults#static-apr-vaults">
            {t('What is static APY Pool?')}
          </Note>
        </MarginedCol>
        <Bs.Col xs={12} lg={6}>
          <VaultStats vault={v} />
        </Bs.Col>
      </Bs.Row>
      {account ? (
        <>
          {v.contract.positions.length !== 0 && (
            <>
              <MarginWrapper>
                <StyledSliderUI
                  centerPadding="20px"
                  focusOnSelect
                  infinite={false}
                  dots
                  centerMode
                  arrows={false}
                >
                  {v.contract.positions.map((p, i) => (
                    <Position
                      key={i}
                      position={p}
                      unstakeOffset={v.contract.unstakeOffset}
                      secondlyRewardPer1e16={v.contract.secondlyRewardPer1e16}
                      secondlyUnlockPer1e16={v.contract.secondlyUnlockPer1e16}
                      rewardInterval={v.contract.rewardInterval}
                      unstakeFee={unstakeFee(p.index)}
                      hasDao={!v.singleStaker}
                      executeUnstake={() => executeUnstake(p.index)}
                      rewardTicker={v.rewardTicker}
                      stakeTicker={v.stakeTicker}
                      bnbBalance={bnbBalance}
                      unstaking={v.positionsCallsInfo[p.index].unstaking}
                    />
                  ))}
                </StyledSliderUI>
              </MarginWrapper>
              <MarginWrapper>
                <Info vault={v} claiming={v.claiming} executeClaim={executeClaim} />
              </MarginWrapper>
              <MarginWrapper>
                <StyledHr />
              </MarginWrapper>
            </>
          )}
          {isAddVisible && (
            <>
              <MarginWrapper>
                <Stake
                  approve={approve}
                  averagePrice={averagePrice}
                  vault={v}
                  allowances={v.allowances}
                  stakeFromTokens={v.stakeFromTokens}
                  staking={v.staking}
                  approving={approving}
                  back={() => toggleAddTokensVisible()}
                  inviter={inviter}
                  isInviterMutable={isInviterMutable}
                  onInviterChanged={onInviterChanged}
                  bnbBalance={bnbBalance}
                  spendBalance={spendBalance}
                  stakeValue={stakeValue}
                  stakeFee={stakeFee}
                  spendValue={spendValue}
                  spendTicker={spendTicker}
                  executeStake={executeStake}
                  onSetSpend={onSetSpend}
                  onSetTicker={onSetTicker}
                  validate={() => {
                    if (stakeValue.gt(v.contract.capacity))
                      return new Error(t('Insufficient capacity'))
                  }}
                />
              </MarginWrapper>
            </>
          )}
          {!isAddVisible && (
            <MarginWrapper>
              <Button onClick={() => toggleAddTokensVisible()}>+ {t('Add Tokens')}</Button>
            </MarginWrapper>
          )}
        </>
      ) : (
        <MarginWrapper>
          <Connect />
        </MarginWrapper>
      )}
      <MarginWrapper>
        <StyledHr />
      </MarginWrapper>
      <Footer vault={v} />
    </VaultCard>
  )
}
