import * as Bs from '@bootstrap-styled/v4'
import styled from 'styled-components'

import { SupplyStaked } from './SupplyStaked'
import { TotalLocked } from './TotalLocked'

import { mediaQuery } from '~utils'

const Row = styled(Bs.Row)`
  ${mediaQuery.lessThan('md')`
    gap: 20px;
      `}
`

export const StakingInfo = () => {
  return (
    <Row>
      <Bs.Col md={6} xs={12}>
        <TotalLocked />
      </Bs.Col>
      <Bs.Col md={6} xs={12}>
        <SupplyStaked />
      </Bs.Col>
    </Row>
  )
}
