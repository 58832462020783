import * as Bs from '@bootstrap-styled/v4'
import emailValidator from 'email-validator'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import DefaultAvatar from './defaultAvatar.svg'

import { useCloudinaryWidget } from '~hooks'
import { useUpdatePersonal, useUser } from '~state'
import { FormGroup, Input, BodyM, PrimaryButton, ModalSubTitle, SecondaryButton } from '~ui'

const Image = styled.img`
  height: 60px;
  width: 60px;
  border-radius: 24px;
`
const Label = styled(BodyM)`
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.secondary};
`

const FlexColumn = styled(Bs.Form)`
  display: flex;
  flex-direction: column;
`

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
`

const UploadButton = styled(SecondaryButton)`
  max-width: 200px;
  color: ${({ theme }) => theme.colors.white} !important;
  margin-left: 20px;
`

const FormGroupWithMargin = styled(FormGroup)`
  margin-bottom: 30px;
`
const ColumnFormGroup = styled(FormGroup)`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`

export const UserPersonalForm = () => {
  const [loading, updatePersonal] = useUpdatePersonal()
  const { t } = useTranslation(['profile', 'vault'])
  const user = useUser()
  const { widget, cloudinaryUrl } = useCloudinaryWidget()
  const [error, setError] = useState<{ name: string | null; email: string | null }>({
    name: null,
    email: null
  })
  const [email, setEmail] = useState<string | undefined>(user.data?.personal?.email)
  const [name, setName] = useState<string | undefined>(user.data?.personal?.name)
  useEffect(() => {
    setError({ name: null, email: null })
    if (!name) {
      return setError({ name: t('Name is required'), email: null })
    }
    if (name.length < 2) {
      return setError({ name: t('Name min length is 2'), email: null })
    }
    if (name.length > 16) {
      return setError({ name: t('Name max length is 16'), email: null })
    }
    if (email) {
      if (!emailValidator.validate(email))
        return setError({ email: t('Invalid email'), name: null })
    }
    if (name === user.data?.personal?.name || email === user.data?.personal?.email) {
      if (name !== user.data?.personal?.name) return
      if (email && email !== user.data?.personal?.email) return
      if (cloudinaryUrl) return
      return setError(t('Nothing to change'))
    }
  }, [name, email, cloudinaryUrl])

  return (
    <FlexColumn>
      <ModalSubTitle>
        {t('This data would be available for other DeFi Ecosystem users.')}
      </ModalSubTitle>
      <ColumnFormGroup>
        <Label>{t('Your Public Nickname') + '*'}</Label>
        <Input
          placeholder={'J.Doe'}
          key={user.data?.personal?.name}
          defaultValue={user.data?.personal?.name}
          onChange={(e: any) => setName(e.target.value)}
          state={error.name && 'danger'}
        />
      </ColumnFormGroup>
      <ColumnFormGroup>
        <Label>{t('vault:Your email on LocalTrade CEX')}</Label>
        <Input
          placeholder={'example@example.com'}
          key={user.data?.personal?.email}
          defaultValue={user.data?.personal?.email}
          onChange={(e: any) => setEmail(e.target.value)}
          state={error.email && 'danger'}
        />
      </ColumnFormGroup>
      <FormGroupWithMargin>
        <Label>{t('Your Profile Picture')}</Label>
        <ImageWrapper>
          <Image src={cloudinaryUrl || user.data?.personal?.pictureUrl || DefaultAvatar} />
          <UploadButton onClick={() => widget && widget.open()} outline block>
            {t('Upload image')}
          </UploadButton>
        </ImageWrapper>
      </FormGroupWithMargin>
      <PrimaryButton
        disabled={error.name || error.email}
        size="sm"
        loading={loading}
        onClick={() => {
          return name && updatePersonal({ name, pictureUrl: cloudinaryUrl, email })
        }}
      >
        {error.name || error.email || (user.data ? t('Change Profile') : t('Create Profile'))}
      </PrimaryButton>
    </FlexColumn>
  )
}
