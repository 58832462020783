import * as Bs from '@bootstrap-styled/v4'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Label as LabelUI } from '../../../components'

import { Input } from '~components'

const Note = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.secondaryShade1};
  border-radius: 12px;
  margin-bottom: 30px;
`

const Label = styled(LabelUI)`
  margin-bottom: 5px;
`

export const StakeModalForm = ({
  setEmail,
  email
}: {
  setEmail: (v: any) => void
  email?: string
}) => {
  const { t } = useTranslation('vault')
  return (
    <Bs.Form>
      <Note>
        {t('You’ll be able to claim CRU rewards on LocalTrade CEX in the end of farming period')}
      </Note>
      <Label>{t('Your email on LocalTrade CEX')}</Label>
      <Input
        type="email"
        placeholder={'example@example.com'}
        defaultValue={email}
        onChange={(e: any) => setEmail(e.target.value)}
      />
    </Bs.Form>
  )
}
