import { HTMLAttributes, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useMediaQueries } from '~components/banners/useMediaQueries'
import { MediaQueryBreakpoint } from '~enums'
import { mediaQuery } from '~utils'

// xs is required
type MediaQueryDependentImageProps<
  TBreakpointKeys extends string = keyof typeof MediaQueryBreakpoint,
  TRequiredKey extends string = 'xs'
> = Record<Extract<TBreakpointKeys, TRequiredKey>, string> &
  Partial<Record<Exclude<TBreakpointKeys, TRequiredKey>, string>>

const BannerImg = styled(
  ({
    xs,
    sm,
    xl,
    md,
    lg,
    xxl,
    ...rest
  }: HTMLAttributes<HTMLImageElement> & MediaQueryDependentImageProps) => {
    const {
      lg: lgBreaked,
      md: mdBreaked,
      sm: smBreaked,
      xl: xlBreaked,
      xxl: xxlBreaked
    } = useMediaQueries()
    let src = xs
    if (smBreaked && sm) src = sm
    if (mdBreaked && md) src = md
    if (lgBreaked && lg) src = lg
    if (xlBreaked && xl) src = xl
    if (xxlBreaked && xxl) src = xxl

    return <img src={src} alt="banner" {...rest} />
  }
)`
  width: 100%;
  border-radius: 24px;
`

export type BannerProps = PropsWithChildren<{
  className?: string
  href: string
  srcList: Record<string, MediaQueryDependentImageProps>
}>

export const Banner = styled(({ children, srcList, href, ...rest }: BannerProps) => {
  const { i18n } = useTranslation()
  const { xs, sm, md, lg, xl, xxl } = srcList[i18n.language]
  return (
    <a href={href} {...rest} target="_blank">
      {children}
      <BannerImg xs={xs} xl={xl} sm={sm} md={md} xxl={xxl} lg={lg} />
    </a>
  )
})`
  display: flex;
  font-weight: 700;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  user-select: none;
  ${mediaQuery.lessThan('sm')`
    align-items: baseline;
  `}
`
