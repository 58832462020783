import { useEffect } from 'react'

import { ColMargined } from '../../components'

import { DynamicVault } from './DynamicVault'

import { TickerBEP20 } from '~enums'
import { useDynamicVaults, useInviter } from '~state'

export const DynamicVaults = ({ setLoading }: { setLoading: (value: boolean) => void }) => {
  const {
    dynamicVaults,
    approve,
    allowances,
    stakeFee,
    unstakeFee,
    executeClaim,
    executeStake,
    executeUnstake,
    stakeValue,
    unstakeValue,
    onSetSpend,
    onSetTicker,
    onSetUnstakeValue,
    spendTicker,
    spendValue,
    averagePrice
  } = useDynamicVaults()
  const [inviter, setInviter] = useInviter()
  useEffect(() => {
    if (dynamicVaults.length !== 0) return setLoading(false)
    setLoading(true)
  }, [dynamicVaults.length])
  return (
    <>
      {dynamicVaults.map((v, i) => (
        <ColMargined xs={12} md={6} key={i} order={v.order}>
          <DynamicVault
            {...v}
            allowances={allowances(v.address)}
            approve={(ticker: TickerBEP20) => approve(v.address, ticker)}
            inviter={inviter.value}
            isInviterMutable={inviter.mutable}
            onInviterChanged={setInviter}
            executeClaim={() => executeClaim(v.address)}
            stakeValue={stakeValue(v.address)}
            unstakeValue={unstakeValue(v.address)}
            onSetSpend={(value) => onSetSpend(v.address, value)}
            onSetTicker={(ticker) => onSetTicker(v.address, ticker)}
            onSetUnstakeValue={(value) => onSetUnstakeValue(v.address, value)}
            stakeFee={stakeFee(v.address)}
            unstakeFee={unstakeFee(v.address)}
            executeStake={() => executeStake(v.address)}
            executeUnstake={() => executeUnstake(v.address)}
            spendValue={spendValue(v.address)}
            spendTicker={spendTicker(v.address)}
            averagePrice={averagePrice(v.address)}
          />
        </ColMargined>
      ))}
    </>
  )
}
