import styled from 'styled-components'

import { AmbisafePartnership } from '../../AmbisafePartnership'
import { Logo } from '../../Logo'

import { RaisedProgress } from './RaisedProgress'
import { Stats } from './Stats'

import { Security } from '~interfaces'
import { Headline2 } from '~ui'
import { mediaQuery, WithRequired } from '~utils'

const StyledAmbisafePartnership = styled(AmbisafePartnership)`
  margin-bottom: 20px;
  ${mediaQuery.lessThan('md')`
    margin-bottom: 24px;
  `}
`

const Title = styled(Headline2)`
  margin-top: 15px;
  margin-bottom: 20px;
  ${mediaQuery.lessThan('md')`
        margin-bottom: 15px; 
  `}
`

const ColorCard = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  background-color: ${({ color }) => color};
  border-radius: 24px;
  ${mediaQuery.lessThan('md')`
       padding: 20px 15px;
  `}
`

export const Info = ({
  title,
  logoURL,
  contract,
  color,
  ...props
}: {
  title: string
} & Pick<
  WithRequired<Security, 'contract'>,
  'valuation' | 'contract' | 'annualYieldPercent' | 'foundationYear' | 'logoURL' | 'color'
>) => {
  return (
    <ColorCard color={color}>
      <Logo src={logoURL} />
      <Title>{title}</Title>
      <StyledAmbisafePartnership />
      <RaisedProgress
        value={contract.raised}
        initialPrice={contract.initialPrice}
        total={contract.total}
        decimals={contract.decimals}
        bgColor="secondaryShade5"
        progressColor="secondaryShade2"
      />
      <Stats {...props} />
    </ColorCard>
  )
}
