import styled from 'styled-components'

export const Block = styled.div<{ direction?: 'column' | 'row'; margined?: boolean }>`
  background: ${({ theme }) => theme.colors.secondaryShade1};
  ${({ margined }) => margined && 'margin-top: 30px;'}
  border-radius: 12px;
  display: flex;
  flex-direction: ${({ direction }) => direction ?? 'column'};
  align-items: center;
  justify-content: center;
  padding: 16px;
`
