import BigNumber from 'bignumber.js'

import { BaseApi } from './BaseApi'

import { SecurityFromAPI } from '~interfaces'

export class Api extends BaseApi {
  async getSecurities(): Promise<SecurityFromAPI[]> {
    const response = await fetch(`${this.baseUrl}/securities`)
    const securities = await response.json()
    return securities.map(({ valuation, softcap, ...rest }: any) => ({
      valuation: valuation,
      softcap: new BigNumber(softcap),
      ...rest
    }))
  }

  async getSignedMoonpayUrl(address: string): Promise<string> {
    const response = await fetch(`${this.baseUrl}/moonpay/sign`, {
      method: 'POST',
      body: JSON.stringify({ address }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return response.text()
  }
}
