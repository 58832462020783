import React from 'react'
import { useTranslation } from 'react-i18next'

import { MarginWrapper } from './ui'

import { Button } from '~components/staking/components'
import { Headline3, Icon } from '~ui'

export const FailBody = ({ retry }: { retry?: () => void }) => {
  const { t } = useTranslation('common')
  return (
    <>
      <Icon name="failed" />
      <MarginWrapper x={2}>
        <Headline3>{t('Transaction failed')}</Headline3>
      </MarginWrapper>
      <MarginWrapper>
        <Button disabled={!retry} onClick={retry}>
          {t('Try again')}
        </Button>
      </MarginWrapper>
    </>
  )
}
