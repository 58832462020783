import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Connect, MarginWrapper } from '../../../components'

import { ChooseAction } from './ChooseAction'
import { Confirm } from './Confirm'
import { Footer } from './Footer'
import { Info } from './Info'
import { LiquidityManager } from './LiquidityManager'
import logo from './logo.png'

import { PANCAKE_LIQUIDITY_LINK } from '~config'
import { Ticker } from '~enums'
import { useActiveWeb3React } from '~hooks'
import { LPAction, useBalance, useLP } from '~state'
import { BodyL, BodyM, Card, Headline3 } from '~ui'

const CardSubtitle = styled(BodyL).attrs({ $bold: true })`
  color: ${({ theme }) => theme.colors.secondary};
`

const StyledHr = styled.hr`
  border-color: ${({ theme }) => theme.colors.secondaryShade7};
`

const LPLogo = styled.img`
  width: 87px;
  margin-right: 40px;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 346px;
`

export const LiquidityPool = () => {
  const { account } = useActiveWeb3React()
  const { t } = useTranslation('lp')
  const {
    volume,
    reserves,
    change,
    balance,
    allowances,
    approving,
    approve,
    form: { state: action, ticker, value, lttValue, busdValue },
    onSetValue,
    onSetMaxValue,
    onSetTicker,
    pair,
    totalSupply,
    provideLiquidityFee,
    execute,
    executing,
    setAction,
    refresh
  } = useLP()
  const spendBalance = useBalance(ticker)
  const bnbBalance = useBalance(Ticker.BNB)
  let actionNode = null

  if (account) {
    actionNode = (
      <ChooseAction
        add={() => setAction(LPAction.AddLiquidity)}
        remove={() => window.open(PANCAKE_LIQUIDITY_LINK, '_blank')}
      />
    )

    if (action === LPAction.AddLiquidity)
      actionNode = (
        <MarginWrapper>
          <LiquidityManager
            bnbBalance={bnbBalance}
            confirm={() => setAction(LPAction.Confirm)}
            back={() => setAction(LPAction.Home)}
            approving={approving}
            addTicker={ticker}
            allowed={ticker === Ticker.BNB || allowances[ticker]?.gt(spendBalance)}
            approve={approve}
            onChange={onSetValue}
            onMax={onSetMaxValue}
            onChangeTicker={onSetTicker}
            balance={spendBalance}
            value={value}
            provideLiquidityFee={provideLiquidityFee}
          />
        </MarginWrapper>
      )
  }

  return (
    <Card withBorder className="flex-column h-auto">
      {action === LPAction.Confirm ? (
        <Confirm
          back={() => setAction(LPAction.AddLiquidity)}
          totalSupply={totalSupply}
          pair={pair}
          fee={provideLiquidityFee}
          lttAmount={lttValue}
          busdAmount={busdValue}
          execute={execute}
          executing={executing}
          refresh={refresh}
        />
      ) : (
        <>
          <div className="d-flex align-items-center">
            <LPLogo src={logo} alt="logo" />
            <Header>
              <Headline3>
                {t('Add Liquidity to {tickers} Pool', {
                  tickers: 'BUSD/LTT',
                  interpolation: { escapeValue: false }
                })}
              </Headline3>
              <CardSubtitle>{t('Use')} BNB, BUSD, wBNB (BEP20)</CardSubtitle>
            </Header>
          </div>
          <MarginWrapper>
            <BodyM>
              {t(
                'Receive BUSD-LTT LP tokens and earn a part of fees from each LTT swap, proportional to your share of the pool'
              )}
              .
            </BodyM>
          </MarginWrapper>
          <MarginWrapper>
            <Info
              volume={volume}
              liquidity={reserves.busd}
              change={change}
              balance={account ? balance : null}
            />
          </MarginWrapper>
          {!account && (
            <MarginWrapper>
              <Connect />
            </MarginWrapper>
          )}
          {actionNode}
          <MarginWrapper>
            <StyledHr />
          </MarginWrapper>
          <Footer reserveBUSD={reserves.busd} reserveLTT={reserves.ltt} />
        </>
      )}
    </Card>
  )
}
