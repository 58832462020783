import * as Bs from '@bootstrap-styled/v4'
import BigNumber from 'bignumber.js'
import React, { Dispatch } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  FormGroup,
  FormLabel,
  Input,
  BodyM,
  BodyL,
  TickerInputGroup as TickerInputGroupUI,
  TickerInputGroupAddon,
  Headline4,
  PrimaryButton
} from '~components'
import { BuySecurityFromTokens } from '~config'
import { Ticker, TickerBuySecurityFrom } from '~enums'
import { mediaQuery, NumberFormatter } from '~utils'

const Label = styled(BodyM)`
  color: ${({ theme }) => theme.colors.secondary};
  margin-bottom: 8px;
`

const Value = styled(BodyL).attrs({ $bold: true })`
  color: ${({ theme }) => theme.colors.white};
`

const TickerInputGroup = styled(TickerInputGroupUI)`
  margin-top: 12px;
  margin-bottom: 20px;
  ${mediaQuery.lessThan('sm')`
        margin-top: 8px;
        margin-bottom: 16px;
    `}
`

const BuyStats = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  ${mediaQuery.lessThan('sm')`
        margin-top: 16px;
        margin-bottom: 8px;
    `}
  & div {
    font-size: 14px;
  }
`

const Button = styled(PrimaryButton)`
  width: 100%;
`

type Props = {
  onSubmit?: () => void
  approve: (() => any) | null
  decimals: number
  spendBalance: BigNumber
  receiveBalance: BigNumber
  allowed: boolean
  spendTicker: TickerBuySecurityFrom
  onSpendValueChanged: (v: string) => void
  onReceiveValueChanged: (v: string) => void
  onSpendTickerChanged: (v: TickerBuySecurityFrom) => void
  onSpendMax?: () => void
  error: null | string
  receiveValue: string
  receiveTicker: string
  spendValue: string
  price?: string
  fee?: BigNumber
  approving: boolean
  setActivePercent: Dispatch<number | undefined>
  activePercent?: number
  className?: string
  executing: boolean
  receiveTickerLogoURL: string
}

const Title = styled(Headline4)`
  margin-bottom: 20px;
`

export const Form = styled(
  ({
    spendBalance,
    receiveBalance,
    allowed,
    approve,
    approving,
    error,
    fee,
    activePercent,
    decimals,
    setActivePercent,
    onSpendMax,
    executing,
    spendTicker,
    onSpendTickerChanged,
    onSpendValueChanged,
    onReceiveValueChanged,
    spendValue,
    onSubmit,
    receiveValue,
    receiveTickerLogoURL,
    receiveTicker,
    className
  }: Props) => {
    const { t } = useTranslation(['security', 'common'])

    const getButtonProps = () => {
      if (!allowed)
        return {
          children: [t('common:Allow')],
          onClick: () => approve?.(),
          loading: approving
        }
      if (error) return { children: [error], disabled: true }
      if (!spendValue)
        return {
          children: [t('Buy')],
          disabled: true
        }
      if (!fee || executing) {
        return {
          disabled: true,
          loading: true
        }
      }

      if (allowed)
        return {
          children: [t('Buy')],
          onClick: () => onSubmit?.(),
          disabled: !onSubmit
        }
    }

    return (
      <Bs.Form className={className}>
        <Title className="text-center">{t('Buy shares')}</Title>
        <FormGroup>
          <FormLabel
            title={t('I want to spend')}
            balance={spendBalance}
            ticker={spendTicker}
            labelColor="secondary"
            onClick={() =>
              onSpendValueChanged(NumberFormatter.formatDecimal(spendBalance, spendTicker))
            }
          />
          <TickerInputGroup>
            <Input
              className="text-left"
              value={spendValue}
              type="number"
              onKeyDown={(e: any) => {
                if (
                  e.key === '-' ||
                  (e.key === 'ArrowDown' && (e.target.value === '0' || e.target.value === ''))
                )
                  e.preventDefault()
              }}
              onWheel={(e: any) => {
                e.target.blur()
              }}
              onChange={(e: any) => {
                if (!NumberFormatter.isValidFormatDecimals(e.target.value, spendTicker)) return
                onSpendValueChanged(e.target.value)
              }}
            />
            <TickerInputGroupAddon
              activeTicker={spendTicker}
              textColor="white"
              onChange={(option) => onSpendTickerChanged(option!.value as TickerBuySecurityFrom)}
              tokens={BuySecurityFromTokens}
            />
          </TickerInputGroup>
        </FormGroup>
        <FormGroup>
          <FormLabel
            title={t('I want to buy')}
            balance={receiveBalance}
            decimals={decimals}
            tickerString={receiveTicker}
            labelColor="secondary"
          />

          <TickerInputGroup>
            <Input
              value={receiveValue}
              type="number"
              onKeyDown={(e: any) => {
                if (
                  e.key === '-' ||
                  (e.key === 'ArrowDown' && (e.target.value === '0' || e.target.value === ''))
                )
                  e.preventDefault()
              }}
              onWheel={(e: any) => {
                e.target.blur()
              }}
              onChange={(e: any) => {
                if (!NumberFormatter.isValidFormatDecimals(e.target.value, spendTicker)) return
                onReceiveValueChanged(e.target.value)
              }}
              className="text-left"
            />
            <TickerInputGroupAddon
              activeTicker={receiveTicker as Ticker}
              colorfulActiveTicker
              isDisabled
              textColor="tertiaryShade3"
              tokens={[
                {
                  ticker: receiveTicker as Ticker,
                  decimals,
                  formatDecimals: 2,
                  src: receiveTickerLogoURL
                }
              ]}
            />
          </TickerInputGroup>
        </FormGroup>
        {receiveValue && (
          <BuyStats className="d-flex flex-column justify-content-between">
            <div className="d-flex flex-column flex-sm-row">
              <Label>{t('You buy')}:</Label>
              <Value className="ml-sm-1">{receiveValue} token(s)</Value>
            </div>
            <div className="d-flex flex-column flex-sm-row">
              <Label>{t('Total payable')}:</Label>
              <Value className="ml-sm-1">
                {spendValue} {spendTicker}
              </Value>
            </div>
          </BuyStats>
        )}
        <FormGroup>
          {fee && (
            <div className="d-flex flex-column flex-sm-row">
              <Label>{t('common:Network Fee')}: </Label>{' '}
              <Value className="ml-sm-1">
                {NumberFormatter.formatDecimal(fee, Ticker.BNB)} {Ticker.BNB}
              </Value>
            </div>
          )}
        </FormGroup>
        <Button onClick={onSubmit} {...getButtonProps()} />
      </Bs.Form>
    )
  }
)`
  margin-top: 20px;
  ${Input} {
    height: 60px;
    text-align: center;
    padding-right: 106px !important;
    &:focus {
      border-color: ${({ theme }) => theme.colors.tertiaryShade1};
    }
    ${mediaQuery.lessThan('sm')`
        padding-right: 86px !important;
    `}
  }
`
