import BigNumber from 'bignumber.js'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppState, lttActions } from '../store'

export function useLttPrice(): BigNumber {
  const dispatch = useDispatch()
  const ltt = useSelector((state: AppState) => state.ltt)

  useEffect(() => {
    dispatch(lttActions.startListening())
    return () => {
      dispatch(lttActions.endListening())
    }
  }, [])

  return ltt.price
}

export function useLttUnlocked(): BigNumber {
  const dispatch = useDispatch()
  const ltt = useSelector((state: AppState) => state.ltt)

  useEffect(() => {
    dispatch(lttActions.startListening())
    return () => {
      dispatch(lttActions.endListening())
    }
  }, [])

  return ltt.unlocked
}
