import { TransactionResponse } from '@ethersproject/providers'
import BigNumber from 'bignumber.js'

import { estimateTokenizedSecurityBuyCall, getTokenizedSecurityBuyCall } from './contract-calls'

import { TickerBuySecurityFrom } from '~enums'
import { Security } from '~interfaces'
import { Direction, transformBN } from '~state/sagas/utils'

export type PurchaseSecurityCall = { gas: BigNumber; execute: () => Promise<unknown> }

export class PurchaseSecurity {
  constructor(
    private readonly data: Required<Security>['contract'],
    readonly ticker: TickerBuySecurityFrom,
    readonly value: BigNumber,
    readonly direction: Direction
  ) {}

  estimateCallGas(account: string): Promise<BigNumber> {
    return estimateTokenizedSecurityBuyCall(
      this.data.value,
      this.ticker,
      this.direction === Direction.OUT
        ? this.value.multipliedBy(this.data.initialPrice)
        : this.value,
      account
    )
  }

  getCall(gas: BigNumber, gasPrice: BigNumber): () => Promise<TransactionResponse> {
    return getTokenizedSecurityBuyCall(
      this.data.value,
      this.ticker,
      this.direction === Direction.OUT
        ? this.value.multipliedBy(this.data.initialPrice)
        : this.value,
      gasPrice,
      gas
    )
  }

  async getReceiveValue(): Promise<BigNumber> {
    return this.direction === Direction.OUT
      ? this.value.multipliedBy(this.data.initialPrice)
      : this.data.sellable
      ? this.value.div(transformBN(await this.data.value.price()))
      : this.value.div(this.data.initialPrice)
  }
}
