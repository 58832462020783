import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'

import { middlewares } from '../middlewares'

import { blocksReducer } from './blocks'
import { buySecuritiesReducer } from './buySecurities'
import { centralizedVaultsReducer } from './centralizedVaults'
import { config } from './config'
import { contractsReducer } from './contracts'
import { dynamicVaultsReducer } from './dynamicVaults'
import { inviterReducer } from './inviter'
import { lpReducer } from './lp'
import { lttReducer } from './ltt'
import { createModalSlice } from './modal'
import { newsReducer } from './news'
import { referralsReducer } from './referrals'
import { rewardsReducer } from './rewards'
import { stakedReducer } from './staked'
import { staticVaultsReducer } from './staticVaults'
import { tokenizedSecuritiesReducer } from './tokenizedSecurities'
import { tokensReducer } from './tokens'
import { transactionReducer } from './transaction'
import { unstakerReducer } from './unstaker'
import { userReducer } from './user'
import { web3Reducer } from './web3'

export const { actions: accountModalActions, reducer: accountModalReducer } =
  createModalSlice('account-modal')

export const { actions: profileModalActions, reducer: profileModalReducer } =
  createModalSlice('profile-modal')

export const { actions: transactionModalActions, reducer: transactionModalReducer } =
  createModalSlice('transaction')

export const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    ltt: lttReducer,
    tokens: tokensReducer,
    blocks: blocksReducer,
    web3: web3Reducer,
    contracts: contractsReducer,
    accountModal: accountModalReducer,
    profileModal: profileModalReducer,
    transactionModal: transactionModalReducer,
    transaction: transactionReducer,
    buySecurities: buySecuritiesReducer,
    rewards: rewardsReducer,
    staked: stakedReducer,
    referrals: referralsReducer,
    user: userReducer,
    tokenizedSecurities: tokenizedSecuritiesReducer,
    dynamicVaults: dynamicVaultsReducer,
    staticVaults: staticVaultsReducer,
    inviter: inviterReducer,
    unstaker: unstakerReducer,
    centralizedVaults: centralizedVaultsReducer,
    lp: lpReducer,
    news: newsReducer,
    [config.reducerPath]: config.reducer
  },
  middleware: (m) => m({ serializableCheck: false }).concat([config.middleware, ...middlewares])
})

export type AppDispatch = typeof store.dispatch
export type AppState = ReturnType<typeof store.getState>
export const useAppDispatch = () => useDispatch<AppDispatch>()

export * from './blocks'
export * from './contracts'
export * from './tokens'
export * from './web3'
export * from './rewards'
export * from './staked'
export * from './referrals'
export * from './tokenizedSecurities'
export * from './buySecurities'
export * from './ltt'
export * from './user'
export * from './dynamicVaults'
export * from './staticVaults'
export * from './inviter'
export * from './unstaker'
export * from './vaults'
export * from './centralizedVaults'
export * from './lp'
export * from './news'
export * from './transaction'
