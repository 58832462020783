import { useSelector } from 'react-redux'

import { transactionModalActions, AppState, useAppDispatch, TransactionState } from '~state'

export function useTransactionModal(): {
  modal: [boolean, { toggle: () => void; open: () => void; close: () => void }]
  retry?: () => void
} & Omit<TransactionState, 'retryCall'> {
  const dispatch = useAppDispatch()

  const modalState = useSelector((state: AppState) => state.transactionModal)
  const txState = useSelector((state: AppState) => state.transaction)

  return {
    ...txState,
    modal: [
      modalState.opened,
      {
        toggle: () => dispatch(transactionModalActions.toggle()),
        close: () => dispatch(transactionModalActions.close()),
        open: () => dispatch(transactionModalActions.open())
      }
    ],
    retry: txState.retryCall ? () => dispatch(txState.retryCall?.()) : undefined
  }
}
