import { SagaIterator } from 'redux-saga'
import { call, put, select, fork, takeLatest } from 'redux-saga/effects'

import { createTerminatedSaga, getInviter } from './utils'

import { ZERO_ADDRESS } from '~config'
import { AppState, contractsActions, inviterActions } from '~state/store'
import { getInviterFromCookie } from '~utils'

export function* onBlockSet(): SagaIterator {
  const {
    contracts: { general },
    web3: { account },
    inviter: inviterState
  }: AppState = yield select()
  if (!general.referralsTreeStorage || !account) return

  const inviter = yield call(getInviter, general.referralsTreeStorage, account)
  if (inviter !== ZERO_ADDRESS) {
    yield put(inviterActions.setInviter({ value: inviter, mutable: false }))
  } else if (inviterState.value !== getInviterFromCookie()) {
    yield put(inviterActions.setInviter({ value: '', mutable: true }))
  }
}

const pollingSaga = createTerminatedSaga(
  { start: inviterActions.startListening, end: inviterActions.endListening },
  [call(onBlockSet), takeLatest(contractsActions.setGeneral, onBlockSet)]
)

export function* inviterSaga() {
  yield fork(pollingSaga)
}
