const colors = {
  white: '#fff',
  body: '#20232E',
  bodyShade1: '#20232E',
  black: '#000',
  blue: '#0169FF',
  blueShade1: '#1B92FF',
  blueShade2: 'rgba(27, 146, 255, 0.2)',
  danger: '#DE1D11',
  dangerShade1: '#FF5A5F',
  violet: '#DB00FF',
  turquoise: '#00D7FF',
  lightGreen: '#CEDFDC',
  red: '#CD3E3E',
  green: '#37B86B',
  primary: '#00957B',
  hoverPrimary: '#33AA95',
  contrastPrimary: '#07C3A3',
  lightPrimary: '#23B49C',
  primaryShade1: '#DAECEA',
  primaryShade2: '#233C3D',
  tertiary: '#2F3343',
  tertiaryShade1: 'rgba(0,0,0,0.04)',
  tertiaryShade2: 'rgba(47,51,67,0.6)',
  tertiaryShade3: 'rgba(0,0,0,0.3)',
  tertiaryShade4: 'rgba(0, 0, 0, 0.1)',
  tertiaryShade5: '#7c8793',
  tertiaryShade6: 'rgba(47, 51, 67, 0.1)',
  tertiaryShade7: 'rgba(0, 0, 0, 0.25)',
  tertiaryShade8: 'rgba(0, 0, 0, 0.15)',
  tertiaryBlue: '#222e36',
  secondary: 'rgba(255, 255, 255, 0.5)',
  secondaryShade1: 'rgba(255, 255, 255, 0.04)',
  secondaryShade2: '#fff',
  secondaryShade3: 'rgba(255, 255, 255, 0.1)',
  secondaryShade4: 'rgba(255, 255, 255, 0.3)',
  secondaryShade5: 'rgba(255, 255, 255, 0.2)',
  secondaryShade6: 'rgba(255, 255, 255, 0.05)',
  secondaryShade7: 'rgba(255, 255, 255, 0.15)',
  secondaryShade8: 'rgba(255, 255, 255, 0.12)',
  secondaryShade9: 'rgba(255, 255, 255, 0.09)',
  secondaryShade10: 'rgba(255, 255, 255, 0.8)',
  secondarySemi: 'rgba(255, 255, 255, 0.5)',
  bannerAdditional: '#6D6D6D',
  toastError: '#F84343',
  toastWarning: '#FF7438',
  toastSuccess: '#46FF90',
  toastInfo: '#4690FF'
}

const createGradients = (radialPercents: string = '25.52% 68.93%') => ({
  lightPrimary: `radial-gradient(${radialPercents} at 52.67% 84.11%, rgba(0, 0, 0, 0.1) 0%, rgba(37, 106, 95, 0.1) 100%), linear-gradient(142.08deg, #F0F6F7 2.34%, #D7E1E3 79.28%);`,
  lightBlue: `radial-gradient(72.47% 95.36% at 50% 77.5%, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(135deg, #00d7ff 0%, #0038ff 100%);`,
  primary: `radial-gradient(${radialPercents} at 50% 84.11%, rgba(0, 0, 0, 0.1) 0%, rgba(37, 106, 95, 0.1) 100%), linear-gradient(143.6deg, #2C4F48 0%, #227366 78.78%);`,
  turquoise: `radial-gradient(${radialPercents} at 50% 84.11%, rgba(0, 0, 0, 0.1) 0%, rgba(37, 106, 95, 0.1) 100%), linear-gradient(143.6deg, #2C4A4F 0%, #226473 78.78%);`,
  violet: `radial-gradient(${radialPercents} at 50% 84.11%, rgba(0, 0, 0, 0.1) 0%, rgba(37, 106, 95, 0.1) 100%), linear-gradient(143.6deg, rgba(128, 102, 137, 0.6) 0%, rgba(100, 70, 120, 0.6) 78.78%);`,
  darkViolet: `radial-gradient(60.37% 26.52% at 50% 84.11%, rgba(0, 0, 0, 0.1) 0%, rgba(37, 106, 95, 0.1) 100%), linear-gradient(143.6deg, rgba(128, 102, 137, 0.3) 0%, rgba(100, 70, 120, 0.3) 78.78%);`,
  grey: 'radial-gradient(60.37% 26.52% at 50% 84.11%, rgba(0, 0, 0, 0.1) 0%, rgba(37, 106, 95, 0.1) 100%), linear-gradient(143.6deg, rgba(128, 102, 137, 0.3) 0%, rgba(100, 70, 120, 0.3) 78.78%);',
  greyShade1: 'linear-gradient(91.83deg, #343548 0%, #312E43 100%)',
  blue: `radial-gradient(${radialPercents} at 50% 84.11%, rgba(0, 0, 0, 0.1) 0%, rgba(37, 106, 95, 0.1) 100%), linear-gradient(143.6deg, rgba(119, 127, 155, 0.6) 0%, rgba(48, 93, 183, 0.6) 78.78%);`,
  blueGreenish: `radial-gradient(${radialPercents} at 50% 84.11%, rgba(0, 0, 0, 0.1) 0%, rgba(37, 106, 95, 0.1) 100%), linear-gradient(143.6deg, #81B49E 0%, #25596F 78.78%);`,
  green: `radial-gradient(${radialPercents} at 50% 84.11%, rgba(0, 0, 0, 0.1) 0%, rgba(37, 106, 95, 0.1) 100%), linear-gradient(143.6deg, #94B481 0%, #256F50 78.78%);`,
  lightPrimary2: `linear-gradient(95.74deg, #00957B 0%, #45A8A2 100%)`,
  bannerLight: `linear-gradient(180deg, rgba(165, 193, 188, 0.2) 0%, rgba(48, 177, 154, 0) 157.13%), rgba(255, 255, 255, 0.3);`,
  brown: `radial-gradient(60.37% 26.52% at 50% 84.11%, rgba(0, 0, 0, 0.1) 0%, rgba(37, 106, 95, 0.1) 100%), linear-gradient(143.6deg, rgba(224, 179, 176, 0.3) 0%, rgba(250, 44, 44, 0.3) 78.78%);`,
  flame: `linear-gradient(0deg, #FF9D18 0%, #ED691D 47.92%, #DA3122 100%);`,
  nft: `radial-gradient(${radialPercents} at 50% 84.11%, rgba(0, 0, 0, 0.1) 0%, rgba(37, 106, 95, 0.1) 100%), linear-gradient(143.6deg, #2C4F48 0%, #227366 78.78%);`,
  yellowGreenish: `linear-gradient(144.73deg, #C3F780 4.07%, #F7D580 102.17%);`,
  greenBlueish: `linear-gradient(109.67deg, #00D8A4 4.26%, rgba(1, 133, 255, 0.42) 184.29%);`,
  aprBonus: `linear-gradient(210deg, #3FE193 18.39%, #693FE1 81.35%);`,
  aprBonusActive: `linear-gradient(150deg, #FF8642 -7.93%, #4F4CE9 96.92%);`
})

export const theme = {
  colors,
  gradients: createGradients(),
  roundsPercentBarGradients: createGradients('41.2% 27%'),
  '$body-bg': 'transparent',
  '$body-color': '#fff',
  '$font-family-base': 'Inter',
  'grid-border-color': colors.tertiaryShade3,
  $primary: colors.primary,
  '$btn-font-size': '16px',
  '$btn-line-height': '20px',
  '$btn-font-weight': '700',
  '$btn-padding-y': '13px',
  '$btn-border-radius': '12px',
  '$btn-border-radius-sm': '12px',
  '$btn-padding-y-sm': '10.5px',
  '$btn-font-size-sm': '16px',
  '$form-label-margin-bottom': '10px',
  '$grid-gutter-width': '40px',
  '$grid-gutter-width-sm': '20px',
  '$container-max-widths': {
    sm: '540px',
    md: '720px',
    lg: '960px',
    xl: '1196px'
  },
  '$input-line-height': '20px',
  '$input-font-size': '16px',
  '$input-font-weight': '400',
  '$input-padding-y': '18px',
  '$input-padding-x': '30px',
  '$input-bg': colors.secondaryShade7,
  '$input-border-color': colors.secondaryShade9,
  '$input-disabled-bg': colors.secondaryShade1,
  '$input-disabled-border-color': colors.secondaryShade1,
  '$input-color': colors.white,
  '$input-border-width': '2px',
  '$input-box-shadow': 'none',
  '$input-border-radius': '12px',
  '$input-focus-border-color': colors.white,
  '$input-placeholder-color': colors.secondary,
  '$tooltip-max-width': '180px',
  '$tooltip-bg': colors.tertiaryShade3,
  '$tooltip-border-radius': '12px',
  '$tooltip-opacity': '1',
  '$tooltip-padding-y': '13px',
  '$tooltip-padding-x': '25px',
  '$tooltip-arrow-color': colors.tertiaryShade3
}
