import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { PrimaryButton as PrimaryButtonUI, BodyS } from './ui'

import { Ticker } from '~enums'
import { useActiveWeb3React } from '~hooks'
import { useBalance } from '~state'
import { shortenAddress, NumberFormatter } from '~utils'

const Address = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary};
  height: max-content;
  border-radius: 8px;
  margin-left: 20px;
  padding: 5px 10px 5px 5px;
  display: flex;
  align-items: center;
`

const AddressSquare = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.primaryShade2};
  margin-right: 10px;
`

const PrimaryButton = styled(PrimaryButtonUI)<{
  $connected: boolean
  size?: 'sm'
}>`
  padding: ${({ $connected }) => $connected && '5px 5px 5px 20px !important'};
  height: 54px !important;
`

export const ConnectButton = (props: React.HTMLAttributes<HTMLButtonElement>) => {
  const { account } = useActiveWeb3React()
  const { t } = useTranslation('account')
  const lttBalance = useBalance(Ticker.LTT)

  return (
    <PrimaryButton {...props} $connected={!!account}>
      {!account ? (
        t('Connect wallet')
      ) : (
        <>
          {NumberFormatter.formatDecimal(lttBalance, Ticker.LTT)} {Ticker.LTT}
          <Address>
            <AddressSquare />
            <BodyS $bold>{shortenAddress(account)}</BodyS>
          </Address>
        </>
      )}
    </PrimaryButton>
  )
}
