import { useTranslation } from 'react-i18next'
import styled, { ThemeColor } from 'styled-components'

import { Headline4 } from '~components'
import { SecurityStatus } from '~enums'
import { mediaQuery } from '~utils'

const LabelColor: Record<SecurityStatus, ThemeColor> = {
  [SecurityStatus.SOON]: 'tertiaryBlue',
  [SecurityStatus.ACTIVE]: 'primary',
  [SecurityStatus.FINISHED]: 'danger',
  [SecurityStatus.AVAILABLE]: 'primary'
}

const Logo = styled.img`
  width: 80px;
`

const LabelWrapper = styled.div<{ status: SecurityStatus }>`
  background: ${({ theme, status }) => theme.colors[LabelColor[status]]};
  position: absolute;
  top: 23px;
  left: 0;
  margin-left: -73px;
  transform: rotate(-45deg);
  width: 237px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  ${mediaQuery.lessThan('lg')`
    height: 50px;
    margin-left: -70px;
    top: 20px;
  `}
`

export const Header = styled(
  ({
    className,
    status,
    logoUrl
  }: {
    className?: string
    status: SecurityStatus
    logoUrl: string
    bgColor?: ThemeColor
    rawBgColor?: string
  }) => {
    const { t } = useTranslation('security')

    return (
      <div className={className}>
        <Logo src={logoUrl} />
        <LabelWrapper status={status}>
          <Headline4>{t(status)}</Headline4>
        </LabelWrapper>
      </div>
    )
  }
)`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
  border-radius: 24px;
  ${mediaQuery.greaterThan('sm')`
    height: 200px;
  `}
  background-color: ${({ theme, bgColor, rawBgColor }) =>
    rawBgColor ?? theme.colors[bgColor ?? 'violet']};
  position: relative;
`
