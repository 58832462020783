import { SagaIterator } from 'redux-saga'
import { call, put, select, take } from 'redux-saga/effects'

import { PurchaseSecurity } from '../utils/PurchaseSecurity'

import {
  AppState,
  buySecuritiesActions,
  BuySecuritiesSetFormSpendValueAction,
  tokenizedSecuritiesActions
} from '~state'
import { Direction } from '~state/sagas/utils'
import { NumberFormatter } from '~utils'

export function* onSetFormSpendValue(action: BuySecuritiesSetFormSpendValueAction): SagaIterator {
  if (action.payload.skipEffects || !action.payload.value || parseFloat(action.payload.value) === 0)
    return

  const {
    web3: { account },
    tokenizedSecurities: { shares },
    buySecurities: {
      form: { spendTicker }
    }
  }: AppState = yield select()
  const security = shares?.find((s) => s.id === action.payload.securityId)
  if (!security || !account || !security.contract) {
    throw new Error('No securityId or contracts or decimals or data or account')
  }

  const { contract } = security

  const purchase = new PurchaseSecurity(
    contract,
    spendTicker,
    NumberFormatter.fromDecimal(action.payload.value, spendTicker),
    Direction.IN
  )
  const value = yield call([purchase, purchase.getReceiveValue])

  yield put(
    buySecuritiesActions.setFormReceiveValue({
      value: NumberFormatter.formatCustomDecimal(value, contract.decimals, contract.decimals), // without Number() throws error is not a primitive number
      securityId: action.payload.securityId,
      skipEffects: true
    })
  )

  yield put(buySecuritiesActions.setPurchase(purchase))

  const spendValue = NumberFormatter.fromDecimal(action.payload.value, spendTicker)
  if (contract.allowance[spendTicker].lt(spendValue)) {
    yield take(tokenizedSecuritiesActions.approveSuccess)
  }

  try {
    const gas = yield call([purchase, purchase.estimateCallGas], account)
    yield put(buySecuritiesActions.setGas(gas))
  } catch (e) {
    console.error(e)
  }
}
