import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { NotEnoughStaked } from './NotEnoughStaked'
import { UserPersonalForm } from './UserPersonalForm'

import { useProfileModal, useStaked } from '~state'
import { Modal, ModalBody, ModalTitle, ModalClose } from '~ui'

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`

export const UserProfileModal = () => {
  const { t } = useTranslation('profile')
  const [isOpen, { close }] = useProfileModal()
  const { stakedOf } = useStaked()

  return (
    <Modal onClose={close} isOpen={isOpen}>
      <ModalBody>
        <Flex>
          <ModalTitle>{t('Create Croco Profile')}</ModalTitle>
          <ModalClose onClick={() => close()} />
        </Flex>
        {stakedOf.gt('200') ? <UserPersonalForm /> : <NotEnoughStaked closeModal={close} />}
      </ModalBody>
    </Modal>
  )
}
