import { Trade, Percent } from '@uniswap/sdk'
import BigNumber from 'bignumber.js'

export function getTradeValues(trade: Trade): {
  amountInMax: BigNumber
  amountOutMin: BigNumber
  averagePrice: BigNumber
  path: string[]
} {
  const slippage = new Percent('1', ' 100')
  const amountOutMin = trade.minimumAmountOut(slippage).raw.toString()
  const amountInMax = trade.maximumAmountIn(slippage).raw.toString() // // needs to be converted to e.g. hex
  const path = trade.route.path.map((t) => t.address)
  return {
    amountOutMin: new BigNumber(amountOutMin),
    amountInMax: new BigNumber(amountInMax),
    averagePrice: new BigNumber(trade.executionPrice.raw.toFixed(18)),
    path
  }
}
