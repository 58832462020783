import { en } from './en-US'
import { es } from './es-ES'
import { pt } from './pt-PT'
import { ru } from './ru-RU'

export const srcList = {
  ...en,
  ...es,
  ...pt,
  ...ru
}
