import { useSelector } from 'react-redux'

import { profileModalActions, AppState, useAppDispatch } from '../store'

export function useProfileModal(): [
  boolean,
  { toggle: () => void; open: () => void; close: () => void }
] {
  const dispatch = useAppDispatch()

  const state = useSelector((state: AppState) => state.profileModal)

  return [
    state.opened,
    {
      toggle: () => dispatch(profileModalActions.toggle()),
      close: () => dispatch(profileModalActions.close()),
      open: () => dispatch(profileModalActions.open())
    }
  ]
}
