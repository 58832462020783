import { TransactionResponse } from '@ethersproject/providers'
import { PayloadAction } from '@reduxjs/toolkit'
import { Contract } from 'ethers'
import { SagaIterator } from 'redux-saga'
import { call, put, select } from 'redux-saga/effects'

import { APPROVE_VALUE } from '~config'
import { TickerBEP20 } from '~enums'
import { AppState, transactionActions, TransactionType } from '~state'
import { onExecute } from '~state/sagas/utils/execute'

export function approve(contract: Contract, spender: string): Promise<TransactionResponse> {
  return contract.approve(spender, APPROVE_VALUE)
}

export function* onApprove<TAction extends PayloadAction<{ ticker: TickerBEP20; spender: string }>>(
  { payload }: TAction,
  success: () => any,
  fail: () => any,
  retryCall: () => void
): SagaIterator {
  const {
    contracts: { bep20 }
  }: AppState = yield select()
  const contract = bep20?.[payload.ticker]
  if (!contract?.signer) throw new Error('No signer or contract')
  yield put(
    transactionActions.setTXInfo({
      type: TransactionType.ALLOW,
      values: [],
      ticker: payload.ticker
    })
  )
  yield call(onExecute, () => () => approve(contract, payload.spender), retryCall, success, fail)
}
