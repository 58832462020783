import * as Bs from '@bootstrap-styled/v4'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Label } from './Label'
import { Value } from './Value'

import { Ticker } from '~enums'
import { useRewards } from '~state'
import { PrimaryButton, Card } from '~ui'
import { NumberFormatter, mediaQuery } from '~utils'

const Row = styled(Bs.Row)`
  ${mediaQuery.lessThan('md')`
   text-align:center;
   gap: 20px;
      `}
`

const ValueWithMargin = styled(Value)`
  margin-bottom: 5px;
`

const PrimaryButtonWithMargin = styled(PrimaryButton)`
  margin-bottom: 10px;
`

export const DAOInfo = () => {
  const { t } = useTranslation(['staking', 'common'])
  const [BUSDReward, claimBUSD, claimingBUSD] = useRewards(Ticker.BUSD)
  const [LTTReward, claimLTT, claimingLTT] = useRewards(Ticker.LTT)

  return (
    <Card>
      <Row>
        <Bs.Col md={6} xs={12}>
          <Label>{t('DAO Rewards')}</Label>
          <ValueWithMargin>
            {NumberFormatter.formatDecimal(LTTReward, Ticker.LTT)} {Ticker.LTT}
          </ValueWithMargin>
          <Value>
            {NumberFormatter.formatDecimal(BUSDReward, Ticker.BUSD)} {Ticker.BUSD}
          </Value>
        </Bs.Col>
        <Bs.Col md={{ size: 5, offset: 1 }} xs={12}>
          <PrimaryButtonWithMargin
            onClick={() => claimLTT()}
            loading={claimingLTT}
            size="sm"
            block
            disabled={claimingLTT || LTTReward.lte('0')}
          >
            {t('common:Claim')} LTT
          </PrimaryButtonWithMargin>
          <PrimaryButton
            onClick={() => claimBUSD()}
            loading={claimingBUSD}
            size="sm"
            block
            disabled={claimingBUSD || BUSDReward.lte('0')}
          >
            {t('common:Claim')} BUSD
          </PrimaryButton>
        </Bs.Col>
      </Row>
    </Card>
  )
}
