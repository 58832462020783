import * as Bs from '@bootstrap-styled/v4'
import { Pair, TokenAmount } from '@uniswap/sdk'
import BigNumber from 'bignumber.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  Block,
  MarginWrapper,
  SmartMargin,
  Label,
  Amount,
  SecondaryButton,
  Button
} from '~components/staking/components'
import { PANCAKE_LIQUIDITY_LINK, UniswapTokens } from '~config'
import { Ticker } from '~enums'
import { A, Headline3, Icon, WithColor } from '~ui'
import { NumberFormatter, useToggle } from '~utils'

const Plus = styled((props: any) => <Icon {...props} name="plus" />)`
  margin-left: 5%;
  margin-right: 5%;
`

const MarginedInfoIcon = styled((props: any) => <Icon name="info" {...props} />)`
  margin-right: 5px;
`

export const Confirm = ({
  totalSupply,
  pair,
  back,
  fee,
  lttAmount,
  busdAmount,
  execute,
  executing,
  refresh
}: {
  totalSupply: BigNumber
  pair?: Pair
  back: () => void
  fee?: BigNumber
  lttAmount: TokenAmount
  busdAmount: TokenAmount
  execute: () => void
  executing: boolean
  refresh: () => void
}) => {
  const { t } = useTranslation('lp')
  const [priceUpdated, togglePriceUpdated] = useToggle(false)
  const [tooltipOpened, tooltipToggle] = useToggle(false)
  const [updateTimerID, setTimerID] = useState(0)

  useEffect(() => {
    if (priceUpdated) return

    if (!executing) {
      const id = window.setTimeout(() => {
        if (!executing) togglePriceUpdated()
      }, 10000)

      setTimerID(id)
    } else clearTimeout(updateTimerID)
    return () => {
      clearTimeout(updateTimerID)
    }
  }, [priceUpdated, executing])

  const liquidityMinted = pair?.getLiquidityMinted(
    new TokenAmount(UniswapTokens.LP, totalSupply.toString()),
    busdAmount,
    lttAmount
  )

  return (
    <div className="d-flex flex-column align-items-center">
      <WithColor $color="secondary">{`${t('You will receive')} (${t('minimum')})`}</WithColor>
      <SmartMargin top>
        <Headline3>
          {liquidityMinted?.toSignificant(10)} {Ticker.BUSD}-{Ticker.LTT} {Ticker.LP}
        </Headline3>
      </SmartMargin>
      <MarginWrapper className="d-flex align-items-center w-100">
        <Block className="d-flex flex-row flex-grow-1">
          <SmartMargin className="d-flex align-items-center" right>
            <Icon name="ltt" />
          </SmartMargin>
          <Amount>
            {lttAmount.toSignificant()} {Ticker.LTT}
          </Amount>
        </Block>
        <Plus />
        <Block className="d-flex flex-row flex-grow-1">
          <SmartMargin className="d-flex align-items-center" right>
            <Icon height={22} width={22} name="busd" />
          </SmartMargin>
          <Amount>
            {busdAmount.toSignificant()} {Ticker.BUSD}
          </Amount>
        </Block>
      </MarginWrapper>
      {fee && (
        <SmartMargin className="w-100" top>
          <Block className="d-flex flex-row">
            <Label>{t('common:Network Fee')}: </Label>
            <Amount>
              {NumberFormatter.formatDecimal(fee, Ticker.BNB)} {Ticker.BNB}
            </Amount>
          </Block>
        </SmartMargin>
      )}
      {priceUpdated && (
        <MarginWrapper className="w-100">
          <Block className="d-flex justify-content-between flex-row">
            <Bs.Row noGutters className="w-100">
              <Bs.Col className="d-flex align-items-center" xs={12} lg={6}>
                <MarginedInfoIcon id="price-update-info" /> {t('Price updated')}
                <Bs.Tooltip
                  target="price-update-info"
                  placement="bottom"
                  isOpen={tooltipOpened}
                  toggle={tooltipToggle}
                >
                  {t('Price updates due to changes in the market')}
                </Bs.Tooltip>
              </Bs.Col>
              <Bs.Col xs={12} lg={6}>
                <Button
                  onClick={() => {
                    refresh()
                    togglePriceUpdated()
                  }}
                >
                  {t('Accept')}
                </Button>
              </Bs.Col>
            </Bs.Row>
          </Block>
        </MarginWrapper>
      )}
      <MarginWrapper className="w-100">
        <Bs.Row noGutters>
          <Bs.Col xs={12} lg={6}>
            <SmartMargin right>
              <SecondaryButton wide outline onClick={back}>
                ← {t('common:Back')}
              </SecondaryButton>
            </SmartMargin>
          </Bs.Col>
          <Bs.Col xs={12} lg={6}>
            <SmartMargin left>
              <Button onClick={execute} disabled={priceUpdated} loading={executing}>
                {t('common:Confirm')}
              </Button>
            </SmartMargin>
          </Bs.Col>
        </Bs.Row>
      </MarginWrapper>
      <MarginWrapper className="w-100">
        <Bs.Row>
          <Bs.Col className="d-flex justify-content-center" xs={12} lg={6}>
            1 {Ticker.LTT} = {pair?.reserve1.divide(pair?.reserve0).toSignificant(3)} {Ticker.BUSD}
          </Bs.Col>
          <Bs.Col className="d-flex justify-content-center" xs={12} lg={6}>
            1 {Ticker.BUSD} = {pair?.reserve0.divide(pair?.reserve1).toSignificant(3)} {Ticker.LTT}
          </Bs.Col>
        </Bs.Row>
      </MarginWrapper>
      <MarginWrapper className="w-100">
        <Block className="d-block text-center">
          {t('You will be able to remove liquidity only on')}{' '}
          <A href={PANCAKE_LIQUIDITY_LINK} target="_blank">
            PancakeSwap
          </A>
          .
          <br />
          {t('You will receive')} {Ticker.LTT} + {Ticker.BUSD}.
        </Block>
      </MarginWrapper>
    </div>
  )
}
