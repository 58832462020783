import Countdown, { CountdownRenderProps, zeroPad } from 'react-countdown'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Headline3 } from '~ui'

const Color = styled.span<{ color?: string }>`
  color: ${({ color }) => color};
`

const CountdownRenderer = ({
  color,
  hours,
  minutes
}: CountdownRenderProps & { color?: string }) => {
  const { t } = useTranslation('vault')
  return (
    <Headline3>
      <Color color={color}>
        {zeroPad(hours, 2)}h : {zeroPad(minutes, 2)}m
      </Color>{' '}
      {t('till start')}
    </Headline3>
  )
}

export const TillStart = ({ startDate, color }: { startDate: Date; color?: string }) => {
  return (
    <Countdown
      date={startDate}
      renderer={(props) => <CountdownRenderer color={color} {...props} />}
    />
  )
}
