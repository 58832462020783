/* eslint-disable @typescript-eslint/no-redeclare */
export type ObjectValuesType<T> = T[keyof T]

export const TickerNative = {
  BNB: 'BNB'
} as const

export type TickerNative = ObjectValuesType<typeof TickerNative>

export const TickerSwapBEP20 = {
  WBNB: 'WBNB'
} as const

export type TickerSwapBEP20 = ObjectValuesType<typeof TickerSwapBEP20>

export const TickerBEP20 = {
  ...TickerSwapBEP20,
  BUSD: 'BUSD',
  LTT: 'LTT',
  LUSD: 'LUSD',
  LP: 'LP'
} as const

export type TickerBEP20 = ObjectValuesType<typeof TickerBEP20>

export const TickerReward = {
  CRU: 'CRU',
  [TickerBEP20.LTT]: TickerBEP20.LTT
} as const

export type TickerReward = ObjectValuesType<typeof TickerReward>

export const TickerStaking = {
  ...TickerNative,
  ...TickerBEP20
} as const

export type TickerStaking = ObjectValuesType<typeof TickerStaking>

export const Ticker = {
  ...TickerNative,
  ...TickerBEP20,
  ...TickerReward
} as const

export type Ticker = ObjectValuesType<typeof Ticker>

export const TickerAddToLPFromWithoutBNB = {
  ...TickerSwapBEP20,
  BUSD: TickerBEP20.BUSD
}

export type TickerAddToLPFromWithoutBNB = ObjectValuesType<typeof TickerAddToLPFromWithoutBNB>

export const TickerAddToLPFrom = {
  ...TickerAddToLPFromWithoutBNB,
  ...TickerNative
}

export type TickerAddToLPFrom = ObjectValuesType<typeof TickerAddToLPFrom>

export const TickerBuySecurityFrom = {
  LUSD: TickerBEP20.LUSD,
  BUSD: TickerBEP20.BUSD
}

export type TickerBuySecurityFrom = ObjectValuesType<typeof TickerBuySecurityFrom>
