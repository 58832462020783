import { SagaIterator } from 'redux-saga'
import { put, select } from 'redux-saga/effects'

import { Ticker } from '~enums'
import { AppState, TickerValue, TransactionType, transactionActions } from '~state'

export function* onSetTXInfo(
  txType: TransactionType,
  getValues?: (s: AppState) => TickerValue[],
  getTicker?: (s: AppState) => Ticker
): SagaIterator {
  const state: AppState = yield select()
  yield put(
    transactionActions.setTXInfo({
      values: getValues ? getValues(state) : [],
      type: txType,
      ticker: getTicker?.(state)
    })
  )
}
