import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppState, stakedActions, StakedState } from '../store'

export function useStaked(): StakedState {
  const staked = useSelector((state: AppState) => state.staked)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(stakedActions.startListening())
    return () => {
      dispatch(stakedActions.endListening())
    }
  }, [])

  return staked
}
