import BigNumber from 'bignumber.js'

import usa from '../images/usa.svg'

import logo from './images/logo.svg'

import { SecurityStatus } from '~enums'
import { Security } from '~interfaces'

export const spacex: Security = {
  status: SecurityStatus.FINISHED,
  id: 'spacex',
  name: 'SpaceX',
  description:
    'SpaceX designs, manufactures, and launches advanced rockets and spacecraft. The company’s ultimate goal is to ' +
    'enable human life on other planets.',
  more: 'https://spacex.com/',
  city: 'Hawthorne',
  foundationYear: 2002,
  annualYieldPercent: 36,
  valuation: new BigNumber(46000000000),
  countryIconURL: usa,
  color: '#000000',
  typeOfEquity: '',
  industry: '',
  leadInvestors: [],
  softcap: new BigNumber(0),
  docs: {
    license: '',
    agreement: '',
    certificate: ''
  },
  tickerLogoURL: logo,
  logoURL: logo
}
