import { BscConnector } from '@binance-chain/bsc-connector'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { ethers } from 'ethers'

import getNodeUrl from './getRpcUrl'

import { getChainId } from '~config'
import { ConnectorNames } from '~enums'
import { toastWaitForNetworkChange } from '~ui'

const POLLING_INTERVAL = 12000
const rpcUrl = getNodeUrl()
const chainId = getChainId()

const injected = new InjectedConnector({ supportedChainIds: [chainId] })

// We're listening Web3ReactUpdate and not chainChanged, because chainChanged
// is handled by web3-react library.
// It also still emits deprecated networkChanged with base-10 chainID number
// so we ignore it
injected.on('Web3ReactUpdate', async ({ chainId: newChainId }: { chainId?: string }) => {
  // ignoring deprecated networkChanged event
  if (!newChainId?.startsWith('0x')) return
  if (chainId !== parseInt(newChainId, 16)) toastWaitForNetworkChange()
})

const walletconnect = new WalletConnectConnector({
  rpc: { [chainId]: rpcUrl },
  qrcode: true,
  pollingInterval: POLLING_INTERVAL
})

const bscConnector = new BscConnector({ supportedChainIds: [chainId] })

// Binance Wallet emitts instead of networkChanged event another chainChanged event
// So it emitts two same events...
;(bscConnector as any).__networkChangeCounter = 0
bscConnector.on('Web3ReactUpdate', async ({ chainId: newChainId }: { chainId?: string }) => {
  if (!newChainId?.startsWith('0x')) return
  if (
    chainId !== parseInt(newChainId, 16) &&
    (bscConnector as any).__networkChangeCounter++ % 2 === 0
  )
    toastWaitForNetworkChange()
})

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
  [ConnectorNames.BSC]: bscConnector
}

export const getLibrary = (
  provider: ethers.providers.ExternalProvider
): ethers.providers.Web3Provider => {
  const library = new ethers.providers.Web3Provider(provider)
  library.pollingInterval = POLLING_INTERVAL
  return library
}
