import styled from 'styled-components'

import { mediaQuery } from '~utils'

export const Logo = styled.img`
  height: 80px;
  max-width: 100%;
  ${mediaQuery.lessThan('md')`
       height: 64px;
  `}
`
