import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CircleLoader } from 'react-spinners'
import styled from 'styled-components'

import { Api } from '../../../api'

import { Button } from '~components/staking/components'
import { Modal as ModalUI } from '~ui'
import { Close } from '~ui/modal/Close'
import { mediaQuery } from '~utils'

const MoonPayIFrame = styled.iframe.attrs<{ src: string }>(({ src }) => ({
  allow: 'accelerometer; autoplay; camera; gyroscope; payment',
  frameBorder: '0',
  height: '600px',
  width: '100%',
  src
}))<{ src: string }>`
  border-radius: 24px;
  ${mediaQuery.lessThan('sm')`
    height: calc(100vh - 20px);
  `}
`

const StyledModal = styled(ModalUI)`
  ${Close} {
    margin-top: -25px;
    margin-left: -25px;
    left: 0;

    ${mediaQuery.lessThan('sm')`
      width: 20px;
      height: 20px;
      margin-top: -5px;
      margin-left: -5px;
    `}
  }
`

const CloseButton = styled(Button)`
  width: auto;
  margin: 20px;
`

export const MoonPayModal = ({
  open,
  close,
  address
}: {
  open: boolean
  close: () => void
  address: string
}) => {
  const [src, setSrc] = useState('')
  const { t } = useTranslation('common')
  useEffect(() => {
    const api = new Api()
    ;(async () => setSrc(await api.getSignedMoonpayUrl(address)))()
  }, [])
  return (
    <StyledModal onClose={close} isOpen={open}>
      {src ? <MoonPayIFrame src={src} /> : <CircleLoader />}
      <CloseButton onClick={close}>{t('Close')}</CloseButton>
    </StyledModal>
  )
}
