import { Info } from './Info'
import { Social } from './Social'

import { Container } from '~components'

export const Footer = () => {
  return (
    <Container $withHeading>
      <Social />
      <Info />
    </Container>
  )
}
