import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'

import { MediaQueryBreakpoint } from '~enums'
import { Icon } from '~ui'
import { mediaQuery } from '~utils'

const MarginBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
`

const HowToMargined = styled.span`
  margin-right: 23px;
  ${mediaQuery.lessThan('lg')`
   margin-right: 10px;
  `}
`

const GetLabel = styled.div`
  line-height: 1;
  margin-top: 3px;
  margin-left: 3px;
  font-size: 30px;
  text-transform: uppercase;
  ${mediaQuery.lessThan('lg')`
    font-size: 20px;
  `}
`

const XMultiplier = styled.span`
  display: inline-flex;
  align-items: center;
  border: 1px ${({ theme }) => theme.colors.white} solid;
  padding: 0 7px;
  border-radius: 11px;
`

const HowToBlock = styled.a`
  display: inline-flex;
  align-items: center;
  border: 1px ${({ theme }) => theme.colors.white} solid;
  padding: 0 7px;
  border-radius: 11px;
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: underline;
  }
`

const APR = styled.div`
  align-self: center;
  white-space: nowrap;
  line-height: 1;
  font-size: 45px;
  margin-left: 23px;
  ${mediaQuery.lessThan('xl')`
    margin-left: 10px;
  `}
  ${mediaQuery.lessThan('lg')`
    margin-left: 10px;
    font-size: 20px;
  `}
`

export const APRMultiplier = styled(
  ({
    aprMultiplier,
    apr,
    className
  }: {
    aprMultiplier: number
    apr: number
    className?: string
  }) => {
    const { t } = useTranslation('vault')
    const isLg = useMediaQuery({ maxWidth: MediaQueryBreakpoint.lg })
    return (
      <div className={className}>
        <div className="d-flex flex-column justify-space-between">
          <GetLabel>
            <XMultiplier>X{aprMultiplier}</XMultiplier>
            {' ' + t('Boost activated')}
          </GetLabel>
          <MarginBlock>
            <HowToBlock
              href="https://blog.localtrade.cc/maximize-your-apr-contest-results-9dcdba5f5a7b"
              target="_blank"
            >
              <HowToMargined>{t('How to claim')}</HowToMargined>
              <Icon name={isLg ? 'longRightArrowLg' : 'longRightArrow'} />
            </HowToBlock>
            <APR>{apr * aprMultiplier}% APR</APR>
          </MarginBlock>
        </div>
      </div>
    )
  }
)`
  display: flex;
  border-radius: 12px;
  background: ${({ theme }) => theme.gradients.aprBonusActive};
  padding: 7px;
  max-width: 85%;
  position: absolute;
  top: 0;
  margin-top: -65px;
  ${mediaQuery.lessThan('lg')`
    margin-top: -45px;
  `}
  ${`@media only screen and (max-width: 385px) {
    margin-top: -75px;
  }`}
  align-self: center;
`
