import React from 'react'
import { useTranslation } from 'react-i18next'

import { InfoBox } from './InfoBox'
import { MarginWrapper } from './ui'

import { BSC_SCAN_URL } from '~config'
import { Ticker } from '~enums'
import { TickerValue, TransactionType } from '~state'
import { A2, Headline3, Icon } from '~ui'

export const SuccessBody = ({
  txHash,
  values,
  txType,
  ticker
}: {
  txHash: string
  txType: TransactionType
  values: TickerValue[]
  ticker?: Ticker
}) => {
  const { t } = useTranslation('common')
  return (
    <>
      <Icon name="successCircled" />
      <MarginWrapper x={2}>
        <Headline3>{t('Transaction succeeded')}</Headline3>
      </MarginWrapper>
      <MarginWrapper>
        <A2 href={BSC_SCAN_URL + '/tx/' + txHash}>{t('View on BscScan')}</A2>
      </MarginWrapper>
      <MarginWrapper>
        <InfoBox txType={txType} values={values} ticker={ticker} />
      </MarginWrapper>
    </>
  )
}
