import BigNumber from 'bignumber.js'
import { SagaIterator } from 'redux-saga'
import { call, put, select } from 'redux-saga/effects'

import { LP_BROKER_ADDRESS } from '~config'
import { Ticker } from '~enums'
import { AppState, lpActions, LPSetTicker } from '~state'
import { getAllowance } from '~state/sagas/utils'

export function* onSetTicker({ payload: ticker }: LPSetTicker): SagaIterator {
  const {
    web3: { account },
    lp: { allowances },
    contracts: { bep20 }
  }: AppState = yield select()

  if (!bep20 || !account) throw new Error('No bep20 or account')

  if (ticker === Ticker.BNB) return

  if (!allowances[ticker]) {
    const allowance: BigNumber = yield call(getAllowance, bep20[ticker], LP_BROKER_ADDRESS, account)
    yield put(lpActions.setAllowance({ ticker, allowance }))
  }
}
