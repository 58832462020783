import styled from 'styled-components'

import { ArrowButton } from './buttons'

import { mediaQuery } from '~utils'

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  ${mediaQuery.lessThan('md')`
   align-items: center;
  `}
`

export type CarouselControlButtonsProps = {
  onPrevClick: () => void
  onNextClick: () => void
}

export const CarouselControlButtons = ({
  onPrevClick,
  onNextClick
}: CarouselControlButtonsProps) => {
  return (
    <Wrapper>
      <ArrowButton onClick={onPrevClick} />
      <ArrowButton direction="right" onClick={onNextClick} />
    </Wrapper>
  )
}
