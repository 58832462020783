import * as Bs from '@bootstrap-styled/v4'
import BigNumber from 'bignumber.js'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Amount,
  Button,
  Label,
  MarginWrapper,
  SecondaryButton,
  SmartMargin
} from '../../../components'

import { FormGroup, TickerInputGroup, TickerInputGroupAddon } from '~components'
import { AddLiquidityFromTokens } from '~config'
import { Ticker, TickerAddToLPFrom } from '~enums'
import { Input } from '~ui'
import { NumberFormatter } from '~utils'

const MAX_VALUES = {
  [Ticker.BUSD]: 1e6 - 1,
  [Ticker.BNB]: 2347.32,
  [Ticker.WBNB]: 2347.32
}

export const LiquidityManager = ({
  back,
  confirm,
  allowed,
  approve,
  approving,
  onChange,
  onChangeTicker,
  balance,
  addTicker,
  value,
  validate,
  provideLiquidityFee,
  bnbBalance,
  onMax
}: {
  back: () => void
  confirm: () => void
  allowed: boolean | undefined
  approve: () => void
  approving: boolean
  addTicker: TickerAddToLPFrom
  onChange: (value: string) => void
  onChangeTicker?: (ticker: TickerAddToLPFrom) => void
  onMax: () => void
  balance: BigNumber
  value: string
  validate?: () => Error | undefined
  provideLiquidityFee?: BigNumber
  bnbBalance: BigNumber
}) => {
  const { t } = useTranslation(['lp', 'common'])

  const getButtonProps = () => {
    if (!value) {
      return { disabled: true, children: [t('common:Confirm')] }
    }

    if (provideLiquidityFee && bnbBalance.lt(provideLiquidityFee)) {
      return {
        disabled: true,
        children: [t('common:Not enough {ticker} to pay the fee', { ticker: Ticker.BNB })]
      }
    }
    if (balance.lt(NumberFormatter.fromDecimal(value || '0', addTicker)))
      return { disabled: true, children: [t('common:Insufficient balance')] }

    if (!allowed) {
      return {
        children: [t('common:Allow')],
        loading: approving,
        onClick: approve
      }
    }

    if (!provideLiquidityFee) {
      return { disabled: true, children: [t('common:Confirm')] }
    }

    if (
      addTicker === Ticker.BNB &&
      NumberFormatter.fromDecimal(value, Ticker.BNB).plus(provideLiquidityFee).gt(bnbBalance)
    )
      return {
        disabled: true,
        children: [t('common:Not enough {ticker} to pay the fee', { ticker: Ticker.BNB })]
      }

    const possibleError = validate?.()

    if (possibleError) {
      return {
        children: [possibleError.message],
        disabled: true
      }
    }

    return {
      children: [t('common:Confirm')],
      onClick: confirm
    }
  }

  return (
    <Bs.Form>
      <FormGroup>
        <div className="d-flex justify-content-between">
          <Amount>{t(`I want to ${!addTicker ? 'remove' : 'add'}`)}:</Amount>
          <div className="d-flex align-items-center">
            <Label>{t('common:Balance')}:</Label>{' '}
            <Amount className="cursor-pointer" onClick={onMax}>
              {NumberFormatter.formatDecimal(balance, addTicker)} {addTicker}
            </Amount>
          </div>
        </div>
        <SmartMargin top>
          <TickerInputGroup>
            <Input
              type="number"
              onKeyDown={(e: any) => {
                if (
                  e.key === '-' ||
                  (e.key === 'ArrowDown' && (e.target.value === '0' || e.target.value === ''))
                )
                  e.preventDefault()
              }}
              onWheel={(e: any) => {
                e.target.blur()
              }}
              onChange={(e: any) => {
                if (!NumberFormatter.isValidFormatDecimals(e.target.value, addTicker)) return
                if (parseFloat(e.target.value) > MAX_VALUES[addTicker]) return
                onChange(e.target.value)
              }}
              value={value}
              min={0}
              max={MAX_VALUES[addTicker]}
            />
            {addTicker && (
              <TickerInputGroupAddon
                activeTicker={addTicker}
                onChange={(option) => onChangeTicker?.(option!.value as TickerAddToLPFrom)}
                tokens={AddLiquidityFromTokens}
              />
            )}
          </TickerInputGroup>
        </SmartMargin>
      </FormGroup>
      <MarginWrapper>
        <Bs.Row noGutters>
          <Bs.Col xs={12} lg={6}>
            <SmartMargin right>
              <SecondaryButton wide outline onClick={back}>
                ← {t('common:Back')}
              </SecondaryButton>
            </SmartMargin>
          </Bs.Col>
          <Bs.Col xs={12} lg={6}>
            <SmartMargin left>
              <Button loading={approving} onClick={approve} {...getButtonProps()} />
            </SmartMargin>
          </Bs.Col>
        </Bs.Row>
      </MarginWrapper>
    </Bs.Form>
  )
}
