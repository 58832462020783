import * as Bs from '@bootstrap-styled/v4'
import { BigNumber } from 'bignumber.js'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Stat } from './Stat'

import { Card, Headline3 } from '~components'
import { BUSD } from '~config'
import { TickerBuySecurityFrom } from '~enums'
import { Security } from '~interfaces'
import { mediaQuery, NumberFormatter, WithRequired } from '~utils'

const Title = styled(Headline3)`
  margin-bottom: 12px;
`

const Col = styled(Bs.Col).attrs({ xs: 12, md: 6 })`
  border-bottom: ${({ border, theme }) =>
    border ? `1px solid ${theme.colors.secondaryShade1}` : 'none'};
  padding: 15px;
  ${mediaQuery.greaterThan('md')`
        &:nth-child(1),
  :nth-child(2) {
    padding-top: 0;
  }
  `}
`

export const OfferingDetails = ({
  contract,
  typeOfEquity,
  softcap
}: Pick<WithRequired<Security, 'contract'>, 'typeOfEquity' | 'contract' | 'softcap'>) => {
  const { t } = useTranslation('security')
  return (
    <Card withBorder className="h-auto">
      <Title>{t('Offering details')}</Title>
      <Bs.Row noGutters>
        <Col border>
          <Stat title={t('Unit Symbol')} value={contract.ticker} align="left" />
        </Col>
        <Col border>
          <Stat
            title={t('Initial Price')}
            value={`${NumberFormatter.toCustomDecimal(
              contract.initialPrice,
              BUSD.decimals - contract.decimals
            ).toFixed(2, BigNumber.ROUND_DOWN)} USD`}
            align="left"
          />
        </Col>
        <Col border>
          <Stat title={t('Type Of Equity')} value={typeOfEquity} align="left" />
        </Col>
        <Col border>
          <Stat title={t('1 Share Is Equal To')} value="1 BEP-20 Token" align="left" />
        </Col>
        <Col>
          <Stat
            title={t('Soft cap')}
            value={`${NumberFormatter.toDecimal(softcap, TickerBuySecurityFrom.BUSD).toFixed(
              0,
              BigNumber.ROUND_DOWN
            )} ${TickerBuySecurityFrom.BUSD}`}
            align="left"
          />
        </Col>
      </Bs.Row>
    </Card>
  )
}
