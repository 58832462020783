import BigNumber from 'bignumber.js'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { TickerBuySecurityFrom } from '~enums'
import { Security } from '~interfaces'
import {
  AppState,
  buySecuritiesActions,
  BuySecuritiesState,
  tokenizedSecuritiesActions,
  useAppDispatch
} from '~state'

export const useSecurities = (): Security[] | null => {
  const dispatch = useAppDispatch()
  const state = useSelector((state: AppState) => state.tokenizedSecurities)

  useEffect(() => {
    dispatch(tokenizedSecuritiesActions.startListening())
    return () => {
      dispatch(tokenizedSecuritiesActions.endListening())
    }
  }, [])

  return state.shares
}

export const useSecurity = (id: string): Security | undefined | null => {
  const securities = useSecurities()
  return securities ? securities.find((s) => s.id === id) : null
}

export const useSecurityFromRouter = (): Security | undefined | null => {
  const { securityId } = useParams<{ securityId: string }>()
  return useSecurity(securityId)
}

export const useBuySecurities = (
  securityId: string
): {
  fee?: BigNumber
  executeTrade?: () => void
  setFormSpendMax: () => void
  setFormSpendTicker: (value: TickerBuySecurityFrom) => void
  setFormSpendValue: (value: string) => void
  setFormReceiveValue: (value: string) => void
  gas?: BigNumber
  gasPrice?: BigNumber
  executing: boolean

  form: {
    spendTicker: TickerBuySecurityFrom
    spendValue: string
    receiveValue: string
  }
} & BuySecuritiesState => {
  const dispatch = useAppDispatch()
  const gasPrice = useSelector((state: AppState) => state.web3.gasPrice)
  const buyState = useSelector((state: AppState) => state.buySecurities)

  return {
    ...buyState,
    gasPrice,
    fee: gasPrice && buyState.gas?.multipliedBy(gasPrice),
    setFormSpendMax: () => dispatch(buySecuritiesActions.setFormSpendMaxValue()),
    executeTrade: () => dispatch(buySecuritiesActions.executePurchase()),
    setFormSpendTicker: (value) => dispatch(buySecuritiesActions.setFormSpendTicker(value)),
    setFormSpendValue: (value) =>
      dispatch(buySecuritiesActions.setFormSpendValue({ value, securityId })),
    setFormReceiveValue: (value) =>
      dispatch(buySecuritiesActions.setFormReceiveValue({ value, securityId }))
  }
}

export function useSecurityApprove(ticker: TickerBuySecurityFrom): [boolean, (() => void) | null] {
  const tokenizedSecurities = useSelector((state: AppState) => state.tokenizedSecurities)
  const security = useSecurityFromRouter()
  const address = security?.address
  if (!security || !address) return [false, null]
  const dispatch = useDispatch()
  return [
    tokenizedSecurities.approving,
    () =>
      dispatch(
        tokenizedSecuritiesActions.approve({
          ticker: ticker,
          spender: address
        })
      )
  ]
}
