import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { ModalSubTitle, PrimaryButton } from '../../ui'

import { Token } from './images'

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
`

export const NotEnoughStaked = ({ closeModal }: { closeModal: () => void }) => {
  const { t } = useTranslation('profile')

  return (
    <>
      <ModalSubTitle>
        <Trans
          t={t}
          i18nKey="You should have at least DeFi Curious Tier  (200 LTT) to a create profile"
        >
          <b />
        </Trans>
      </ModalSubTitle>
      <ImageWrapper>
        <Token />
      </ImageWrapper>
      <PrimaryButton to="/sale" onClick={() => closeModal()}>
        {t('Buy LTT')}
      </PrimaryButton>
    </>
  )
}
