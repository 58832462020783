import BigNumber from 'bignumber.js'

import { Tokens } from '~config'
import { Ticker } from '~enums'

export class NumberFormatter {
  static toDecimal(value: BigNumber, ticker: Ticker): BigNumber {
    const token = Tokens.find((t) => t.ticker === ticker)
    if (!token) throw new Error('Token is not supported')
    return this.toCustomDecimal(value, token.decimals)
  }

  static toCustomDecimal(value: BigNumber, decimals: number): BigNumber {
    return value.div(new BigNumber(10).pow(decimals))
  }

  static formatDecimal(
    value: BigNumber,
    ticker: Ticker,
    roundDirection: BigNumber.RoundingMode = BigNumber.ROUND_DOWN
  ) {
    const token = Tokens.find((t) => t.ticker === ticker)
    if (!token) throw new Error('Token is not supported')
    return this.toDecimal(value, ticker).toFixed(token.formatDecimals, roundDirection)
  }

  static formatCustomDecimal(
    value: BigNumber,
    decimals: number,
    formatDecimals?: number,
    roundDirection: BigNumber.RoundingMode = BigNumber.ROUND_DOWN
  ) {
    return this.toCustomDecimal(value, decimals).toFixed(formatDecimals ?? 2, roundDirection)
  }

  static fromDecimal(value: BigNumber | string | number, ticker: Ticker): BigNumber {
    const token = Tokens.find((t) => t.ticker === ticker)
    if (!token) throw new Error('Token is not supported')
    return this.fromCustomDecimal(value, token.decimals)
  }

  static fromCustomDecimal(value: BigNumber | string | number, decimals: number) {
    return new BigNumber(value).times(new BigNumber(10).pow(decimals))
  }

  static isValidFormatDecimals(value: string | number, ticker: Ticker): boolean {
    const token = Tokens.find((t) => t.ticker === ticker)
    if (!token) throw new Error('Token is not supported')
    const decimalPart = value.toString().split('.')[1]
    if (!decimalPart) return true
    return decimalPart.length <= token.formatDecimals
  }

  static toText(value: BigNumber, decimals: number, short = false) {
    const lookup = [
      { value: 1e3, symbol: 'K', longSymbol: 'thousand' },
      { value: 1e6, symbol: 'M', longSymbol: 'million' },
      { value: 1e9, symbol: 'B', longSymbol: 'billion' }
    ]
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
    const item = lookup
      .slice()
      .reverse()
      .find((num) => new BigNumber(value).gte(num.value))
    return item
      ? Number(value.div(item.value).toString().replace(rx, '$1')).toFixed(decimals) +
          (!short ? ' ' + item.longSymbol : item.symbol)
      : value.toFixed(decimals)
  }

  static toComma(value: string) {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
}
