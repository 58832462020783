import styled from 'styled-components'

import { News } from '~interfaces'
import { BodyM, Headline4, BodyS, HoverCard, Icon } from '~ui'

const Image = styled.img`
  border-radius: 20px;
  margin-bottom: 30px;
  width: 100%;
`

const Text = styled(BodyM)`
  color: ${({ theme }) => theme.colors.secondary};
  max-height: 134.4px;
  word-break: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 6;
  -moz-line-clamp: 6;
  -ms-line-clamp: 6;
  line-clamp: 6;
  overflow: hidden;
  margin-bottom: 30px;
`

const DateText = styled(BodyS)`
  color: ${({ theme }) => theme.colors.secondary};
`

const Title = styled(Headline4)`
  margin-bottom: 10px;
`

const StyledIcon = styled(Icon)`
  margin-right: 10px;
`

function convertDate(date: string) {
  const d = new Date(date)
  function pad(s: number) {
    return s < 10 ? '0' + s : s
  }
  return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('.')
}

export const Card = ({ image, title, content, date, link }: News[0]) => {
  return (
    <HoverCard
      hoverStyle="gradient"
      withBorder
      onClick={() => window.open(link, '_blank')}
      className="justify-content-start h-auto cursor-pointer"
    >
      <Image src={image} />
      <Title>{title}</Title>
      <Text>{content}</Text>
      <div className="d-flex justify-content-center align-items-center">
        <StyledIcon name="calendar" />
        <DateText>{convertDate(date)}</DateText>
      </div>
    </HoverCard>
  )
}
