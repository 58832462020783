import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Doc } from './Doc'

import { Headline3, Card } from '~components'
import { Security } from '~interfaces'

const Title = styled(Headline3)`
  margin-bottom: 10px;
`

const CardWithGap = styled(Card)`
  gap: 20px;
`
export const LegalPaperwork = ({
  docs: { license, agreement, certificate }
}: Pick<Security, 'docs'>) => {
  const { t } = useTranslation('security')
  return (
    <CardWithGap withBorder className="h-auto">
      <Title>{t('Legal paperwork')}</Title>
      <Doc title={t('Fund Manager License')} link={license} />
      <Doc title={t('Investment Management Agreement')} link={agreement} />
      <Doc title={t('Certificate of Incorporation of the Fund')} link={certificate} />
    </CardWithGap>
  )
}
