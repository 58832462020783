import BigNumber from 'bignumber.js'

import { Round, IdoRound } from '~interfaces'

export const ROUNDS: Array<Round | IdoRound> = [
  {
    name: 'Round A',
    startDate: new Date(2021, 8, 20),
    endDate: new Date(2021, 9, 31),
    tokenPrice: new BigNumber('100000000000000000'),
    amount: {
      value: new BigNumber('9624175000000000'),
      percent: 10
    },
    bgGradient: 'violet',
    monthlyUnlockPercent: 5
  },
  {
    name: 'IDO',
    startDate: new Date(2022, 0, 15),
    endDate: new Date(2022, 0, 15),
    tokenPrice: new BigNumber('200000000000000000'),
    amount: {
      value: new BigNumber('1774767000000000'),
      percent: 2
    },
    bgGradient: 'turquoise',
    monthlyUnlockPercent: 20
  },
  {
    name: 'Round B',
    startDate: new Date(2021, 10, 1),
    endDate: new Date('2022-01-19T13:00:00.000Z'),
    tokenPrice: [new BigNumber('200000000000000000'), new BigNumber('500000000000000000')],
    amount: {
      value: new BigNumber('2324479000000000'),
      percent: 22.5
    },
    active: true,
    bgGradient: 'primary',
    monthlyUnlockPercent: 10,
    monthCliff: 1
  }
]
