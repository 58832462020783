import * as Bs from '@bootstrap-styled/v4'
import Loader from 'react-spinners/PulseLoader'
import styled from 'styled-components'

export const TransparentButton = styled((props) => <Bs.Button {...props} />).attrs((props) => ({
  children: props.loading ? [<Loader color="white" size={10} />] : props.children
}))`
  background: transparent !important;
  box-shadow: none !important;
  border: none !important;
`
