import { toast } from 'react-toastify'
import styled from 'styled-components'

import { SecondaryButton, Toast } from '~ui'

const MarginedSecondaryButton = styled(SecondaryButton)`
  margin-top: 20px;
`

export const toastWaitForNetworkChange = () => {
  toast.error(
    <Toast
      title="Wrong network"
      value="Looks like the network was changed. Please reload the page."
    >
      <MarginedSecondaryButton outline onClick={() => window.location.reload()}>
        Reload page
      </MarginedSecondaryButton>
    </Toast>,
    {
      theme: 'colored',
      draggable: false,
      closeOnClick: false,
      icon: <div />,
      autoClose: false,
      closeButton: false,
      toastId: 'network-change-toast'
    }
  )
}
