import BigNumber from 'bignumber.js'
import { Contract } from 'ethers'
import { SagaIterator } from 'redux-saga'
import { put, call, takeLatest, select, all, throttle, fork } from 'redux-saga/effects'

import { AppState, blocksActions, contractsActions, unstakerActions } from '../store'

import { createTerminatedSaga } from './utils'

async function unstake(contract: Contract): Promise<void> {
  const tx = await contract.unstake()
  await tx.wait()
}

async function getAvailableToUnstake(contract: Contract, account: string): Promise<BigNumber> {
  const v = await contract.availableToUnstake(account)
  return new BigNumber(v.toString())
}

function* unstakerWorker(): SagaIterator {
  const {
    contracts: { general }
  }: AppState = yield select()
  if (!general.unstaker) throw new Error('No unstaker contract')
  try {
    yield call(unstake, general.unstaker)
    yield put(unstakerActions.unstaked())
  } catch (e) {
    yield put(unstakerActions.unstakeFailed())
  }
}

function* fetchAvailableToUnstake(): SagaIterator {
  const {
    contracts: { general },
    web3: { account }
  }: AppState = yield select()
  if (!general.unstaker || !account) return
  const value = yield call(getAvailableToUnstake, general.unstaker, account)
  yield put(unstakerActions.set({ value }))
}

// if new contracts without account reset (logout action)
function* onContractsSetGeneral(): SagaIterator {
  const {
    web3: { account }
  }: AppState = yield select()
  if (!account) yield put(unstakerActions.reset())
  else yield call(fetchAvailableToUnstake)
}

const terminatedSaga = createTerminatedSaga(
  { start: unstakerActions.startListening, end: unstakerActions.endListening },
  [
    throttle(60000, blocksActions.set, fetchAvailableToUnstake),
    takeLatest(unstakerActions.unstaked, fetchAvailableToUnstake),
    takeLatest(unstakerActions.unstakeFailed, fetchAvailableToUnstake)
  ]
)

export function* unstakerSaga(): SagaIterator {
  yield all([
    takeLatest(unstakerActions.unstake, unstakerWorker),
    takeLatest(contractsActions.setGeneral, onContractsSetGeneral)
  ])
  yield fork(terminatedSaga)
}
