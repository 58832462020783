import * as Bs from '@bootstrap-styled/v4'
import BigNumber from 'bignumber.js'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Block, Label, SmartMargin, Amount } from '../../../components'

import { LPFee } from '~config'
import { Ticker } from '~enums'
import { BodyS, Headline4, WithColor } from '~ui'
import { NumberFormatter } from '~utils'

export const Info = ({
  liquidity,
  volume,
  change,
  balance
}: {
  liquidity: BigNumber
  volume: number
  change: BigNumber
  balance: BigNumber | null
}) => {
  const { t } = useTranslation('lp')
  return (
    <>
      <Block direction="row">
        <Label>{t('Total Liquidity')}:</Label>{' '}
        <Amount>
          ${NumberFormatter.toText(NumberFormatter.toDecimal(liquidity, Ticker.BUSD), 2, true)}{' '}
          <WithColor $color={change.eq(0) ? 'white' : change.gt(0) ? 'green' : 'red'}>
            ({change.eq(0) ? '' : change.gt(0) ? '↑' : '↓'}
            {change.abs().multipliedBy(100).toFixed(2)}%)
          </WithColor>
        </Amount>
      </Block>
      <SmartMargin top>
        <Bs.Row noGutters>
          <Bs.Col xs={12} lg={4}>
            <SmartMargin className="h-100" right>
              <Block className="h-100" direction="column">
                <Headline4>
                  {new BigNumber(volume * 365 * LPFee)
                    .dividedBy(NumberFormatter.toDecimal(liquidity, Ticker.BUSD))
                    .multipliedBy(100)
                    .toFixed(2)}
                  %
                </Headline4>
                <SmartMargin top>
                  <BodyS>{t('LP Reward APR')}</BodyS>
                </SmartMargin>
              </Block>
            </SmartMargin>
          </Bs.Col>
          <Bs.Col xs={12} lg={8}>
            <SmartMargin left>
              <Block direction="row">
                <Label>{t('Volume 24h')}:</Label>{' '}
                <Amount>${NumberFormatter.toText(new BigNumber(volume), 2, true)}</Amount>
              </Block>
              <SmartMargin top>
                <Block direction="row">
                  <Label>{t('LP Rewards 24h')}:</Label>{' '}
                  <Amount>${NumberFormatter.toText(new BigNumber(volume * LPFee), 2, true)}</Amount>
                </Block>
              </SmartMargin>
            </SmartMargin>
          </Bs.Col>
        </Bs.Row>
      </SmartMargin>
      {balance && (
        <SmartMargin top>
          <Block direction="row">
            <Label>{t('Your {tickers} Balance', { tickers: 'BUSD-LTT LP' })}:</Label>{' '}
            <Amount>{NumberFormatter.formatDecimal(balance, Ticker.LP)} LP</Amount>
          </Block>
        </SmartMargin>
      )}
    </>
  )
}
