import styled from 'styled-components'

export const Toggle = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  &::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.3em;
    vertical-align: middle;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    transition: transform ease-in-out 0.2s;
    border-left: 0.3em solid transparent;
    ${({ active }) => active && 'transform: rotate(180deg);'}
  }
`
