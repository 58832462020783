import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'

import { Stat } from '../../Stat'

import { Icon } from '~components'
import { MediaQueryBreakpoint } from '~enums'
import { Security } from '~interfaces'
import { NumberFormatter } from '~utils'

const Arrow = styled(Icon)`
  margin-top: ${({ rotate }) => (rotate === 180 ? '20px' : '0')};
`

export const Stats = ({
  valuation,
  annualYieldPercent,
  foundationYear
}: Pick<Security, 'valuation' | 'annualYieldPercent' | 'foundationYear'>) => {
  const { t } = useTranslation('security')
  const isMd = useMediaQuery({ maxWidth: MediaQueryBreakpoint.md })
  const [open, setOpen] = useState(false)
  return (
    <>
      {(!isMd ? true : open) && (
        <div className="d-flex w-100">
          <Stat title={t('Valuation')} value={`$${NumberFormatter.toText(valuation, 1)}`} />
          <Stat title={t('Annual Yield')} value={`+${annualYieldPercent}%`} />
          <Stat title={t('Founded')} value={foundationYear} />
        </div>
      )}
      {isMd && <Arrow name="shortArrow" onClick={() => setOpen(!open)} rotate={open ? 180 : 0} />}
    </>
  )
}
