import BigNumber from 'bignumber.js'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Form } from './Form'

import { Ticker, TickerBuySecurityFrom } from '~enums'
import { useSecurityApprove, useBalance, useBuySecurities } from '~state'
import { NumberFormatter } from '~utils'

export const Buy = ({
  buyTicker,
  raised,
  total,
  balance,
  initialPrice,
  securityId,
  allowances,
  decimals,
  receiveTickerLogoURL,
  startDate,
  endDate
}: {
  allowances: Record<TickerBuySecurityFrom, BigNumber>
  buyTicker: string
  raised: BigNumber
  balance: BigNumber
  total: BigNumber
  sellable: boolean
  decimals: number
  initialPrice: BigNumber
  receiveTickerLogoURL: string
  securityId: string
  startDate: Date
  endDate: Date
}) => {
  const { t } = useTranslation('buy')

  const [activePercent, setActivePercent] = useState<number | undefined>()

  const {
    form,
    setFormSpendMax,
    setFormSpendValue,
    setFormSpendTicker,
    setFormReceiveValue,
    fee,
    executeTrade,
    executing
  } = useBuySecurities(securityId)

  const spendBalance = useBalance(form.spendTicker)
  const bnbBalance = useBalance(Ticker.BNB)
  const allowance = allowances[form.spendTicker]
  const allowed = allowance.gte(spendBalance)
  const [approving, approve] = useSecurityApprove(form.spendTicker)

  useEffect(() => {
    if (activePercent)
      setFormSpendValue(
        NumberFormatter.toDecimal(
          spendBalance.multipliedBy(activePercent).dividedBy(100),
          form.spendTicker
        ).toFixed(0, BigNumber.ROUND_DOWN)
      )
  }, [activePercent])

  const validate = () => {
    if (startDate.getTime() > Date.now()) {
      return t('Buy is not opened yet')
    }

    if (endDate.getTime() < Date.now()) {
      return t('Buy is closed')
    }

    if (fee && bnbBalance.lt(fee)) {
      return t(`Not enough {ticker} to pay the fee`, { ticker: Ticker.BNB })
    }

    if (spendBalance.lt(NumberFormatter.fromDecimal(form.spendValue, form.spendTicker)))
      return t('Insufficient balance')

    if (
      total
        .multipliedBy(initialPrice)
        .minus(raised)
        .lt(NumberFormatter.fromDecimal(form.spendValue, form.spendTicker))
    ) {
      return t('Not enough {ticker} to sell', { ticker: buyTicker })
    }

    return null
  }

  return (
    <Form
      spendBalance={spendBalance}
      receiveBalance={balance}
      spendValue={form.spendValue}
      decimals={decimals}
      spendTicker={form.spendTicker}
      receiveValue={form.receiveValue}
      receiveTicker={buyTicker}
      activePercent={activePercent}
      setActivePercent={setActivePercent}
      allowed={allowed}
      approve={approve}
      fee={fee}
      approving={approving}
      executing={executing}
      onSpendMax={!spendBalance.isZero() ? setFormSpendMax : undefined}
      onSpendValueChanged={setFormSpendValue}
      onReceiveValueChanged={setFormReceiveValue}
      onSpendTickerChanged={setFormSpendTicker}
      error={validate()}
      onSubmit={() => executeTrade?.()}
      receiveTickerLogoURL={receiveTickerLogoURL}
    />
  )
}
