import * as Bs from '@bootstrap-styled/v4'
import React from 'react'
import styled, { withTheme } from 'styled-components'

import { mediaQuery } from '~utils'

export const Modal = styled(
  withTheme((props) => (
    <Bs.Modal {...props} theme={{ ...props.theme, '$body-color': props.theme.colors.teriary }}>
      {props.children}
    </Bs.Modal>
  ))
)`
  .modal-content {
    border-radius: 24px;
    color: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme, borderColor }) => borderColor ?? theme.colors.secondaryShade1};
    background: ${({ theme }) => theme.colors.secondaryShade7};
    backdrop-filter: blur(34px);
    position: relative;
  }
  .modal-dialog {
    background: none;
    ${mediaQuery.lessThan('sm')`
        margin: 10px;
    `}
  }
  .modal-backdrop {
    min-height: 100vh;
  }
`
