import BigNumber from 'bignumber.js'
import { SagaIterator } from 'redux-saga'
import { call, put, select } from 'redux-saga/effects'

import { Ticker } from '~enums'
import { AppState, staticVaultsActions, SetSpendTickerPositionAction } from '~state'
import { getAllowance } from '~state/sagas/utils'

export function* onSetTicker({
  payload: { address, ticker }
}: SetSpendTickerPositionAction): SagaIterator {
  const {
    web3: { account },
    staticVaults,
    contracts: { bep20 }
  }: AppState = yield select()

  if (!bep20 || !account) throw new Error('No bep20 or account')

  if (ticker === Ticker.BNB) return

  if (!staticVaults[address].allowances[ticker]) {
    const allowance: BigNumber = yield call(getAllowance, bep20[ticker], address, account)
    yield put(staticVaultsActions.setAllowance({ address, ticker, allowance }))
  }
}
