import BigNumber from 'bignumber.js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { ThemeColor } from 'styled-components'

import { Ticker } from '~enums'
import { Label as LabelUI } from '~ui'
import { NumberFormatter } from '~utils'

const Balance = styled.div<{ hoverable: boolean }>`
  ${({ hoverable }) =>
    hoverable &&
    `
    &:hover {
      text-decoration: underline;
    }
    `}
  }
`

export const Label = styled(
  ({
    title,
    ticker,
    balance,
    className,
    decimals,
    tickerString,
    onClick
  }: {
    title: string
    balance: BigNumber
    className?: string
    onClick?: () => void
    labelColor?: ThemeColor
  } & (
    | {
        ticker: Ticker
        decimals?: never
        tickerString?: never
      }
    | { ticker?: never; tickerString: string; decimals: number }
  )) => {
    const { t } = useTranslation('common')
    return (
      <LabelUI className={className}>
        <div>{title}</div>
        <Balance onClick={onClick} hoverable={!!onClick && !balance.isZero()}>
          <span>{t('Balance')}:</span>{' '}
          {ticker
            ? NumberFormatter.formatDecimal(balance, ticker)
            : decimals
            ? NumberFormatter.formatCustomDecimal(balance, decimals)
            : ''}{' '}
          {ticker ?? tickerString}
        </Balance>
      </LabelUI>
    )
  }
)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  span {
    color: ${({ theme, labelColor }) => theme.colors[labelColor ?? 'tertiaryShade2']};
  }
`
