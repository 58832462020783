import { BaseApi } from '../BaseApi'

import { TokenExpiredError } from './errors'

import { User } from '~interfaces'

export class UserApi extends BaseApi {
  async getByAddress(address: string): Promise<User[]> {
    const response = await fetch(`${this.baseUrl}/users/?address=${address}`)
    const data = await response.json()
    return data
  }

  async create(address: string): Promise<User> {
    const response = await fetch(`${this.baseUrl}/users`, {
      method: 'POST',
      body: JSON.stringify({ address }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const data = await response.json()
    return data
  }

  async authenticate(address: string, signature: string): Promise<{ token: string }> {
    const response = await fetch(`${this.baseUrl}/users/auth`, {
      method: 'POST',
      body: JSON.stringify({ address, signature }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const data = await response.json()
    return data
  }

  async updatePersonal(personal: User['personal'], token: string): Promise<User> {
    const response = await fetch(`${this.baseUrl}/users/personal`, {
      method: 'PUT',
      body: JSON.stringify({ personal }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
    const data = await response.json()
    if (data.token === 'expired') throw new TokenExpiredError()
    return data
  }
}
