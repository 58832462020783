import BigNumber from 'bignumber.js'
import { SagaIterator } from 'redux-saga'
import { fork, select, call, put, all, takeLatest } from 'redux-saga/effects'

import { estimateCall, getCall, transformBN, getAllowance } from './utils'
import { onVaultsSetSpend, VaultsMethods, vaultsSaga } from './vaults'

import { StakingsApi, StakingsKind } from '~api'
import DynamicAPYFarming from '~contracts/DynamicAPYFarming.json'
import { DynamicVaultMetadata } from '~interfaces'
import { AppState, dynamicVaultsActions, DynamicVaultsState, blocksActions } from '~state'
import { getContract } from '~utils'

function* fetchDynamicVaults(): SagaIterator {
  const {
    dynamicVaults,
    web3: { library, account, gasPrice },
    contracts: { bep20 }
  }: AppState = yield select()

  if (!bep20 || !library || !gasPrice) return

  const fetched: DynamicVaultsState = {}

  const stakingsApi = new StakingsApi()

  const vaultsApi: DynamicVaultMetadata[] = yield call(() =>
    stakingsApi.getStakings(StakingsKind.DYNAMIC)
  )

  for (const v of vaultsApi) {
    yield call(async () => {
      const vaultContract = getContract(v.address, DynamicAPYFarming.abi, library, account)

      const [
        creationTimestamp,
        endTimestamp,
        unstakeTimestamp,
        totalReward,
        accRewardLTTperStakedLTT,
        lastRewardTimestamp,
        stakedTotal
      ] = await vaultContract.getInfo()

      const initialTicker = v.stakeTicker

      const initialTokenAllowance = account
        ? await getAllowance(bep20[initialTicker], vaultContract.address, account)
        : undefined

      const claimGas = await estimateCall(vaultContract, VaultsMethods.Claim)
      const claimCall = await getCall(vaultContract, VaultsMethods.Claim, gasPrice, claimGas)

      fetched[v.address] = {
        ...v,
        contract: {
          value: vaultContract,
          creationDate: new Date(transformBN(creationTimestamp).toNumber() * 1000),
          endDate: new Date(transformBN(endTimestamp).toNumber() * 1000),
          unstakeDate: new Date(transformBN(unstakeTimestamp).toNumber() * 1000),
          staked: account ? transformBN(await vaultContract.stakedOf(account)) : new BigNumber('0'),
          stakedTotal: transformBN(stakedTotal),
          totalReward: transformBN(totalReward),
          accRewardLTTperStakedLTT: transformBN(accRewardLTTperStakedLTT),
          lastRewardTimestamp: transformBN(lastRewardTimestamp),
          debt: account ? transformBN(await vaultContract.debt()) : new BigNumber('0'),
          totalClaimed: account
            ? transformBN(await vaultContract.totalClaimed())
            : new BigNumber('0')
        },
        allowances: {
          [initialTicker]: initialTokenAllowance,
          ...dynamicVaults[v.address]?.allowances
        },
        approving: false,
        claiming: false,
        staking: false,
        unstaking: false,
        claimCall,
        form: {
          stakeValue: dynamicVaults[v.address]?.form.stakeValue ?? new BigNumber(0),
          unstakeValue: dynamicVaults[v.address]?.form.unstakeValue ?? new BigNumber(0),
          spendTicker: v.stakeTicker,
          spendValue: dynamicVaults[v.address]?.form.spendValue ?? '',
          averagePrice: undefined
        }
      }
    })
  }

  yield put(dynamicVaultsActions.set(fetched))
}

export function* onBlockSet(): SagaIterator {
  const { dynamicVaults }: AppState = yield select()

  for (const v in dynamicVaults) {
    const spendValue = dynamicVaults[v].form.spendValue || '0'
    const spendTicker = dynamicVaults[v].form.spendTicker
    if (parseFloat(spendValue) !== 0 && spendTicker) {
      yield put(
        dynamicVaultsActions.setSpend({
          address: dynamicVaults[v].address,
          value: spendValue
        })
      )
    }
  }
}

export function* dynamicVaultsSaga(): SagaIterator {
  yield all([
    fork(() => vaultsSaga('dynamicVaults', dynamicVaultsActions, fetchDynamicVaults)),
    yield takeLatest(blocksActions.set, onBlockSet),
    yield takeLatest(dynamicVaultsActions.approveSuccess, onBlockSet),
    yield takeLatest(dynamicVaultsActions.setSpend, (action) =>
      onVaultsSetSpend(action, 'dynamicVaults', dynamicVaultsActions)
    )
  ])
}
