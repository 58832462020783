import * as Bs from '@bootstrap-styled/v4'
import styled from 'styled-components'

export const Input = styled(Bs.Input)`
  border-width: 2px !important;
  font-size: 16px !important;
  box-sizing: border-box;

  &:active {
    border-color: ${({ theme }) => theme.colors.secondary};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.white} !important;
  }

  &:disabled {
    opacity: 0.3 !important;
    background: ${({ theme }) => theme.colors.secondaryShade1} !important;
  }

  &.form-control-danger {
    border-color: ${({ theme }) => theme.colors.toastError} !important;
  }

  &.form-control-success {
    border-color: ${({ theme }) => theme.colors.toastSucess} !important;
  }

  &.form-control-warning {
    border-color: ${({ theme }) => theme.colors.toastWarning} !important;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }
`
