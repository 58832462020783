import BigNumber from 'bignumber.js'

import usa from '../images/usa.svg'

import logo from './images/logo.svg'

import { SecurityStatus } from '~enums'
import { Security } from '~interfaces'

export const airbnb: Security = {
  status: SecurityStatus.FINISHED,
  id: 'airbnb',
  name: 'Airbnb',
  description:
    'Airbnb, Inc. is a vacation rental online marketplace company, offering an arrangement for lodging, ' +
    'primarily homestays, or tourism experiences.',
  more: 'https://airbnb.com/',
  city: 'San Francisco',
  foundationYear: 2008,
  annualYieldPercent: 176,
  valuation: new BigNumber(18000000000),
  countryIconURL: usa,
  color: '#FF5A5F',
  typeOfEquity: '',
  industry: '',
  leadInvestors: [],
  softcap: new BigNumber(0),
  docs: {
    license: '',
    agreement: '',
    certificate: ''
  },
  tickerLogoURL: logo,
  logoURL: logo
}
