import { useEffect, useState } from 'react'

export const useCloudinaryWidget = () => {
  const [cloudinaryUrl, setCloudinaryUrl] = useState<string>()
  const [widget, setWidget] = useState<{ open: () => void } | null>(null)
  useEffect(() => {
    const w = (window as any).cloudinary.createUploadWidget(
      {
        cloudName: process.env.REACT_APP_CLOUDINARY_CLOUDNAME,
        uploadPreset: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET,
        multiple: false,
        maxFileSize: 5000000,
        cropping: true,
        croppingAspectRatio: 1,
        showSkipCropButton: false,
        resourceType: 'image'
      },
      (error: any, result: any) => {
        if (!error && result && result.event === 'success') {
          setCloudinaryUrl(result.info.url)
        }
      }
    )
    setWidget(w)
    return () => {
      w.destroy().then(() => setWidget((pw: any) => (pw === w ? null : pw)))
    }
  }, [])

  return { widget, cloudinaryUrl }
}
