import styled from 'styled-components'

import facebook from './images/facebook.svg'
import instagram from './images/instagram.svg'
import linkedin from './images/linkedin.svg'
import medium from './images/medium.svg'
import zendesk from './images/zendesk.svg'

import { mediaQuery } from '~utils'

const Icon = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
`

export const OtherSocialMedia = styled(({ className }: { className?: string }) => {
  return (
    <div className={className}>
      <Icon
        src={medium}
        alt="Medium"
        onClick={() => window.open('https://blog.localtrade.cc', '_blank')}
      />
      <Icon
        src={instagram}
        alt="Instagram"
        onClick={() => window.open('https://www.instagram.com/localtrade.cc', '_blank')}
      />
      <Icon
        src={facebook}
        alt="Facebook"
        onClick={() => window.open('https://www.facebook.com/localtrade.cc', '_blank')}
      />
      <Icon
        src={linkedin}
        alt="LinkedIn"
        onClick={() => window.open('https://www.linkedin.com/in/local-trade', '_blank')}
      />
      <Icon
        src={zendesk}
        alt="Zendesk"
        onClick={() => window.open('https://localtradesupport.zendesk.com/hc/en-us', '_blank')}
      />
    </div>
  )
})`
  display: flex;
  background: ${({ theme }) => theme.colors.secondaryShade1};
  align-items: center;
  padding: 10px 20px;
  border-radius: 12px;
  justify-content: space-between;
  box-sizing: border-box;

  width: 100%;
  ${mediaQuery.greaterThan('md')`
 max-width:260px;
 
 `};
`
