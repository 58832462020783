import BigNumber from 'bignumber.js'
import { Contract } from 'ethers'
import { SagaIterator } from 'redux-saga'
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'

import {
  createTerminatedSaga,
  estimateCall,
  getAllowance,
  getCall,
  onApprove,
  onExecute,
  onSetTXInfo,
  transformBN
} from '../utils'

import { Methods } from './methods'
import { onSetSpend, onSetTicker, onWeb3Set } from './workers'

import { StakingsApi, StakingsKind } from '~api'
import StaticAPYFarming from '~contracts/StaticAPYFarming.json'
import { StaticVaultMetadata, StaticVaultPosition } from '~interfaces'
import {
  AppState,
  blocksActions,
  contractsActions,
  PositionCallInfo,
  staticVaultsActions,
  StaticVaultsState,
  TransactionType,
  VaultsApproveSuccessAction,
  web3Actions
} from '~state/store'
import { getContract } from '~utils'

export async function fetchPositionsActionsInfo(
  vaultContract: Contract,
  positionsLength: number,
  gasPrice: BigNumber
) {
  const positionsActions: PositionCallInfo[] = []
  for (let i = 0; i < positionsLength; i++) {
    const unstakeGas = await estimateCall(vaultContract, Methods.UnstakeFromPosition, [
      i.toString()
    ])
    const unstakeCall = await getCall(
      vaultContract,
      Methods.UnstakeFromPosition,
      gasPrice,
      unstakeGas,
      [i.toString()]
    )
    positionsActions.push({ unstakeGas, unstakeCall, unstaking: false })
  }
  return positionsActions
}

export function* fetchStaticVaults(): SagaIterator {
  const {
    web3: { library, account, gasPrice },
    contracts: { bep20 },
    staticVaults
  }: AppState = yield select()

  if (!bep20 || !library || !gasPrice) return

  const fetched: Record<string, StaticVaultsState['']> = {}

  const stakingsApi = new StakingsApi()

  const vaultsApi: StaticVaultMetadata[] = yield call(() =>
    stakingsApi.getStakings(StakingsKind.STATIC)
  )

  for (const v of vaultsApi) {
    if (v.singleStaker && (!account || v.singleStaker !== account)) continue
    yield call(async () => {
      const vaultContract = getContract(v.address, StaticAPYFarming.abi, library, account)

      const [
        stakedTotal,
        capacity,
        unstakeOffset,
        secondlyUnlockPer1e16,
        rewardInterval,
        secondlyRewardPer1e16
      ] = await vaultContract.getInfo()

      const fetchedPositions: any[] = account ? await vaultContract.getPositions() : []
      const positions: StaticVaultPosition[] = fetchedPositions.map((p, index) => ({
        totalStaked: transformBN(p.totalStaked),
        staked: transformBN(p.staked),
        startDate: new Date(transformBN(p.startTimestamp).toNumber() * 1000),
        lastRewardDate: new Date(transformBN(p.lastRewardTimestamp).toNumber() * 1000),
        index
      }))

      const positionsCallsInfo = await fetchPositionsActionsInfo(
        vaultContract,
        positions.length,
        gasPrice
      )

      const claimGas = await estimateCall(vaultContract, Methods.Claim)
      const claimCall = await getCall(vaultContract, Methods.Claim, gasPrice, claimGas)

      const initialTicker = v.stakeTicker

      const initialTokenAllowance = account
        ? await getAllowance(bep20[initialTicker], vaultContract.address, account)
        : undefined

      fetched[v.address] = {
        ...v,
        contract: {
          staked: transformBN(positions.reduce((a, p) => a.plus(p.staked), new BigNumber(0))),
          value: vaultContract,
          stakedTotal: transformBN(stakedTotal),
          capacity: transformBN(capacity),
          totalReward: transformBN(await vaultContract.totalReward()),
          unstakeOffset: transformBN(unstakeOffset),
          secondlyUnlockPer1e16: transformBN(secondlyUnlockPer1e16),
          secondlyRewardPer1e16: transformBN(secondlyRewardPer1e16),
          rewardInterval: transformBN(rewardInterval),
          positions
        },
        allowances: {
          [initialTicker]: initialTokenAllowance,
          ...staticVaults[v.address]?.allowances
        },
        staking: false,
        approving: false,
        claimCall,
        claiming: false,
        positionsCallsInfo,
        form: {
          stakeValue: staticVaults[v.address]?.form.stakeValue ?? new BigNumber(0),
          spendTicker: v.stakeTicker,
          spendValue: staticVaults[v.address]?.form.spendValue ?? '',
          averagePrice: undefined
        }
      }
    })
  }
  yield put(staticVaultsActions.set(fetched))
}

const terminatedSaga = createTerminatedSaga(
  {
    start: staticVaultsActions.startListening,
    end: staticVaultsActions.endListening
  },
  [
    call(fetchStaticVaults),
    takeLatest(contractsActions.setGeneral, fetchStaticVaults),
    takeLatest(staticVaultsActions.stakeExecuted, fetchStaticVaults),
    takeLatest(staticVaultsActions.unstakeExecuted, fetchStaticVaults),
    takeLatest(staticVaultsActions.claimExecuted, fetchStaticVaults),
    takeLatest(web3Actions.set, fetchStaticVaults),
    takeLatest(web3Actions.setGasPrice, fetchStaticVaults)
  ]
)

function* onApproveSuccess({ payload: { ticker, address } }: VaultsApproveSuccessAction) {
  const {
    contracts: { bep20 },
    web3: { account }
  }: AppState = yield select()
  if (!bep20 || !account) return

  const allowance: BigNumber = yield call(() => getAllowance(bep20[ticker], address, account))

  yield put(staticVaultsActions.setAllowance({ address: address, ticker, allowance }))
  yield call(onBlockSet)
}

function* onBlockSet() {
  const { staticVaults }: AppState = yield select()
  for (const v in staticVaults) {
    const spendValue = staticVaults[v]?.form.spendValue || '0'
    const spendTicker = staticVaults[v]?.form.spendTicker
    if (parseFloat(spendValue) !== 0 && spendTicker) {
      yield put(
        staticVaultsActions.setSpend({
          address: v,
          value: spendValue
        })
      )
    }
  }
}

export function* staticVaultsSaga(): SagaIterator {
  yield fork(terminatedSaga)

  yield all([
    yield takeLatest(staticVaultsActions.approve, (action) =>
      onApprove(
        action,
        () =>
          staticVaultsActions.approveSuccess({
            address: action.payload.spender,
            ticker: action.payload.ticker
          }),
        () =>
          staticVaultsActions.approveFailure({
            address: action.payload.spender
          }),
        () => staticVaultsActions.approve(action.payload)
      )
    ),
    yield takeLatest(staticVaultsActions.executeUnstake, () =>
      onSetTXInfo(TransactionType.UNSTAKE)
    ),
    yield takeLatest(staticVaultsActions.executeUnstake, (action) =>
      onExecute(
        (s) =>
          s.staticVaults[action.payload.address].positionsCallsInfo[action.payload.index]
            .unstakeCall,
        () => staticVaultsActions.executeUnstake(action.payload),
        () => staticVaultsActions.unstakeExecuted(action.payload),
        () => staticVaultsActions.unstakeFailed(action.payload)
      )
    ),
    yield takeLatest(staticVaultsActions.executeStake, ({ payload: { address } }) =>
      onSetTXInfo(TransactionType.STAKE, (s) => [
        {
          value: s.staticVaults[address].form.spendValue,
          ticker: s.staticVaults[address].form.spendTicker
        }
      ])
    ),
    yield takeLatest(staticVaultsActions.executeStake, (action) =>
      onExecute(
        (s) => s.staticVaults[action.payload.address].stakeCall,
        () => staticVaultsActions.executeStake(action.payload),
        () => staticVaultsActions.stakeExecuted(action.payload),
        () => staticVaultsActions.stakeFailed(action.payload)
      )
    ),
    yield takeLatest(staticVaultsActions.executeClaim, ({ payload: { address } }) =>
      onSetTXInfo(TransactionType.CLAIM, undefined, (s) => s.staticVaults[address].rewardTicker)
    ),
    yield takeLatest(staticVaultsActions.executeClaim, (action) =>
      onExecute(
        (s) => s.staticVaults[action.payload.address].claimCall,
        () => staticVaultsActions.executeClaim(action.payload),
        () => staticVaultsActions.claimExecuted(action.payload),
        () => staticVaultsActions.claimFailed(action.payload)
      )
    ),
    yield takeLatest(staticVaultsActions.setSpend, onSetSpend),
    yield takeLatest(staticVaultsActions.setTicker, onSetTicker),
    yield takeLatest(staticVaultsActions.approveSuccess, onApproveSuccess),
    yield takeLatest(blocksActions.set, onBlockSet),
    yield takeLatest(web3Actions.set, onWeb3Set)
  ])
}
