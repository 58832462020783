import styled, { ThemeColor } from 'styled-components'

import * as icons from './assets'

export interface IconProps {
  name: keyof typeof icons
  color?: ThemeColor
  width?: number
  height?: number
  rotate?: number
}

export const Icon = styled.svg.attrs<IconProps>(({ name }) => ({
  as: icons[name]
}))<IconProps>`
  transform: rotate(${({ rotate }) => (rotate ? rotate + 'deg' : '0')});
  path {
    fill: ${({ color, theme }) => (color ? theme.colors[color] : undefined)};
  }
  ${({ width }) => width && `width: ${width}px`}
  ${({ height }) => height && `width: ${height}px`}
`

export * as icons from './assets'
