import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import Loader from 'react-spinners/MoonLoader'

import { ReferralNode } from './ReferralNode'

import { MediaQueryBreakpoint } from '~enums'
import { useActiveWeb3React } from '~hooks'
import { useReferralsInviter, useStaked, useTeam, useUser } from '~state'
import { getRank, shortenAddress } from '~utils'

export const ReferralsTree = () => {
  const { t } = useTranslation('referrals')
  const { account } = useActiveWeb3React()
  const { team, fetchTeam, removeTeam } = useTeam()
  const { inviter } = useReferralsInviter()
  const { data: userData } = useUser()
  const { stakedOf } = useStaked()
  const isSm = useMediaQuery({ maxWidth: MediaQueryBreakpoint.lg })

  if (inviter.data === undefined || inviter.loading || !team.data) {
    return (
      <div className="d-flex justify-content-center mt-3 mt-lg-0 mb-lg-3">
        <Loader size={isSm ? 30 : 40} color="white" />
      </div>
    )
  }

  const user = {
    personal: userData?.personal,
    address: account!,
    staked: stakedOf,
    rank: getRank(stakedOf)
  }

  return (
    <div className="tf-tree">
      <ReferralNode
        inviter={inviter.data ?? user}
        referrals={
          inviter.data
            ? {
                ...team.data,
                [inviter.data.address]: [user]
              }
            : team.data
        }
        onClick={(address) => {
          if (address === inviter.data?.address || address === account) return
          if (team.data?.[address]) {
            removeTeam(address)
          } else {
            fetchTeam(address)
          }
        }}
        buttonContent={({ address }: { address: string }) => {
          let description
          if (address === inviter.data?.address) description = t('Inviter')
          else if (address === account) description = t('You')

          return (
            <>
              {description && <span className="d-block mr-md-1">{description}:</span>}
              {shortenAddress(address, isSm ? 6 : 9)}
            </>
          )
        }}
        loadingReferralsOf={team.loadingOf}
      />
    </div>
  )
}
