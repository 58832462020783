import * as Bs from '@bootstrap-styled/v4'
import { useState } from 'react'
import { SyncLoader } from 'react-spinners'

import { theme } from '../../../../theme'
import { ColMargined } from '../../components'

import { CentralizedVaults } from './CentralizedVaults'
import { LoaderWrapper } from './components'
import { DynamicVaults } from './DynamicVaults'
import { StaticVaults } from './StaticVaults'

export const Vaults = () => {
  const [centralizedLoading, setCentralizedLoading] = useState(false)
  const [dynamicLoading, setDynamicLoading] = useState(false)
  const [staticLoading, setStaticLoading] = useState(false)

  return (
    <>
      <Bs.Row>
        {(centralizedLoading || dynamicLoading || staticLoading) && (
          <ColMargined xs={12} md={6} order={99}>
            <LoaderWrapper>
              <SyncLoader color={theme.$primary} />
            </LoaderWrapper>
          </ColMargined>
        )}
        <CentralizedVaults setLoading={(value: boolean) => setCentralizedLoading(value)} />
        <StaticVaults setLoading={(value: boolean) => setStaticLoading(value)} />
        <DynamicVaults setLoading={(value: boolean) => setDynamicLoading(value)} />
      </Bs.Row>
    </>
  )
}
