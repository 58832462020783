import * as Bs from '@bootstrap-styled/v4'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { ModalSubTitle, ModalTitle, ModalClose } from '../../ui'

import { WalletProviderCard, WalletProvider } from './WalletProviderCard'

import { mediaQuery } from '~utils'

const Col = styled(Bs.Col)`
  ${mediaQuery.lessThan('md')`
  margin-bottom:10px;
`}
  ${mediaQuery.greaterThan('md')`
   &:nth-child(1),
  &:nth-child(2) {
    margin-bottom: 40px;
  }
`}
`

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Connect: React.FC<{
  onMetamask: () => void
  onBSC: () => void
  onWalletConnect: () => void
  onClose: () => void
}> = ({ onMetamask, onBSC, onWalletConnect, onClose }) => {
  const { t } = useTranslation('account')

  return (
    <>
      <Flex>
        <ModalTitle>{t('Connect your wallet')}</ModalTitle>
        <ModalClose onClick={onClose} />
      </Flex>
      <ModalSubTitle>{t('Here you can change your account for another provider')}</ModalSubTitle>
      <Bs.Row>
        <Col md={6} xs={12}>
          <WalletProviderCard onClick={onMetamask} provider={WalletProvider.Metamask} />
        </Col>
        <Col md={6} xs={12}>
          <WalletProviderCard onClick={onWalletConnect} provider={WalletProvider.WalletConnect} />
        </Col>
        <Col md={6} xs={12}>
          <WalletProviderCard onClick={onBSC} provider={WalletProvider.BSC} />
        </Col>
      </Bs.Row>
    </>
  )
}
