import styled, { ThemeGradient } from 'styled-components'

import { BodyS, Headline4, WithGradient } from '~ui'
import { mediaQuery } from '~utils'

export const Stat = styled(
  ({
    label,
    percent,
    gradient,
    ...props
  }: {
    label: string
    percent: string
    gradient: ThemeGradient
  }) => {
    return (
      <div {...props}>
        <Headline4>
          <WithGradient $gradient={gradient}>{percent}</WithGradient>
        </Headline4>
        <BodyS>{label}</BodyS>
      </div>
    )
  }
)`
  ${mediaQuery.lessThan('lg')`
    width: 100%;
  `}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 17px;
  border: 1px solid ${({ theme }) => theme.colors.secondaryShade1};
  border-radius: 12px;
`
