import BigNumber from 'bignumber.js'
import { Contract } from 'ethers'
import { SagaIterator } from 'redux-saga'
import { put, call, takeLatest, select, throttle, fork } from 'redux-saga/effects'

import { AppState, blocksActions, contractsActions, stakedActions } from '../store'

import { createTerminatedSaga } from './utils'

async function getStaked(contract: Contract, account: string): Promise<BigNumber> {
  return new BigNumber((await contract.stakedOf(account)).toString())
}

async function getTotalStaked(contract: Contract): Promise<BigNumber> {
  return new BigNumber((await contract.stakedTotal()).toString())
}

function* fetchData(): SagaIterator {
  const {
    contracts: { general },
    web3: { account }
  }: AppState = yield select()

  if (general.stakingsManager) {
    const stakedTotal = yield call(getTotalStaked, general.stakingsManager)
    yield put(
      stakedActions.set({
        stakedTotal
      })
    )
    if (account && general.roundB && general.roundA && general.roundIDO) {
      const stakedRoundA = yield call(getStaked, general.roundA, account)
      const stakedRoundB = yield call(getStaked, general.roundB, account)
      const stakedRoundIDO = yield call(getStaked, general.roundIDO, account)
      const stakedOf = yield call(getStaked, general.stakingsManager, account)
      yield put(
        stakedActions.set({
          stakedOf: stakedOf,
          roundA: stakedRoundA,
          roundB: stakedRoundB,
          roundIDO: stakedRoundIDO,
          roundsTotal: stakedRoundA.plus(stakedRoundB).plus(stakedRoundIDO)
        })
      )
    }
  }
}

// if new contracts without account reset (logout action)
function* onContractsSetGeneral(): SagaIterator {
  const {
    web3: { account }
  }: AppState = yield select()
  if (!account) yield put(stakedActions.reset())
  // account change or reset
  else yield call(fetchData)
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const terminatedSaga = createTerminatedSaga(
  { start: stakedActions.startListening, end: stakedActions.endListening },
  [
    throttle(60000, blocksActions.set, fetchData),
    takeLatest(contractsActions.setGeneral, onContractsSetGeneral)
  ]
)
export function* stakedSaga(): SagaIterator {
  yield throttle(1000, contractsActions.setGeneral, onContractsSetGeneral)
  yield fork(terminatedSaga)
}
