import { Web3Provider } from '@ethersproject/providers'
import BigNumber from 'bignumber.js'
import { Contract } from 'ethers'
import { SagaIterator } from 'redux-saga'
import { select, put, call, takeLatest, throttle, fork } from 'redux-saga/effects'

import { AppState, blocksActions, tokensActions, contractsActions } from '../store'

import { createTerminatedSaga } from './utils'

import { Ticker, TickerBEP20, TickerStaking } from '~enums'

async function getTokens(
  contracts: Record<TickerBEP20, Contract>,
  library: Web3Provider,
  account: string
): Promise<Record<TickerStaking, { balance: BigNumber; allowance: BigNumber }>> {
  const entries = await Promise.all(
    Object.entries(contracts).map(async ([ticker, contract]) => [
      ticker,
      {
        balance: new BigNumber((await contract.balanceOf(account)).toString())
      }
    ])
  )
  const tokensBep20 = Object.fromEntries(entries)
  const balance = new BigNumber((await library.getBalance(account)).toString())
  return {
    ...tokensBep20,
    [Ticker.BNB]: {
      balance
    }
  }
}

function* fetchBalances(): SagaIterator {
  const {
    contracts: { bep20, general },
    web3: { account, library }
  }: AppState = yield select()

  if (!bep20 || !account || !library || !general.roundB) return
  const tokens = yield call(getTokens, bep20, library, account)
  yield put(tokensActions.set(tokens))
}

// if new contracts without account reset (logout action)
function* onContractsSetGeneral(): SagaIterator {
  const {
    web3: { account }
  }: AppState = yield select()
  if (!account) yield put(tokensActions.reset())
  else yield call(fetchBalances)
}

const terminatedSaga = createTerminatedSaga(
  { start: tokensActions.startListening, end: tokensActions.endListening },
  [throttle(150000, blocksActions.set, fetchBalances)]
)

export function* tokensSaga() {
  yield fork(terminatedSaga)
  yield takeLatest(contractsActions.setGeneral, onContractsSetGeneral)
}
