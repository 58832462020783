import React from 'react'
import { Helmet } from 'react-helmet'

import { Container } from '~components'
import {
  Heading,
  Partners,
  AvailableProducts,
  Advantages,
  HomeFaq,
  BridgingGap,
  SecurityAudit
} from '~components/home'

export const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>LocalTrade DeFi Lab</title>
        <meta
          name="description"
          content={'Put your lab coat on and start exploring the world of decentralized finance.'}
        />
      </Helmet>
      <Container>
        <Heading />
      </Container>
      <Container>
        <AvailableProducts />
      </Container>
      <Container>
        <SecurityAudit />
      </Container>
      <Container $withHeading>
        <Advantages />
      </Container>
      <Container $withHeading>
        <BridgingGap />
      </Container>
      <Container $withHeading>
        <Partners />
      </Container>
      <Container>
        <HomeFaq />
      </Container>
    </>
  )
}
