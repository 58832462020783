import BigNumber from 'bignumber.js'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { AppState, rewardsActions, RewardsTicker, useAppDispatch } from '../store'

export function useRewards(ticker: RewardsTicker): [BigNumber, () => {}, boolean] {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(rewardsActions.startListening())
    return () => {
      dispatch(rewardsActions.endListening())
    }
  }, [])

  const rewards = useSelector((state: AppState) => state.rewards)

  return [
    rewards[ticker].value,
    () => dispatch(rewardsActions.claim({ ticker })),
    rewards[ticker].claiming
  ]
}
