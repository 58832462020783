import BigNumber from 'bignumber.js'
import React, { HTMLProps } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Amount, Label } from '../../../components'

import { VaultMetadata } from '~interfaces'
import { NumberFormatter } from '~utils'

const Capacity = styled.span`
  color: ${({ theme }) => theme.colors.secondary};
`

export const Footer = styled(
  ({
    vault,
    ...props
  }: {
    vault: VaultMetadata & { contract: { stakedTotal: BigNumber; capacity?: BigNumber } }
  } & HTMLProps<HTMLDivElement>) => {
    const { t } = useTranslation('vault')
    return (
      <div {...props}>
        <Label>{t('Total Staked')}:</Label>
        <Amount>
          {NumberFormatter.toDecimal(vault.contract.stakedTotal, vault.stakeTicker).toFixed(2)}{' '}
          {vault.stakeTicker}
          {vault.contract.capacity && (
            <Capacity>
              {' '}
              /{' '}
              {NumberFormatter.toDecimal(
                vault.contract.capacity.plus(vault.contract.stakedTotal),
                vault.stakeTicker
              ).toFixed(2)}
            </Capacity>
          )}
        </Amount>
      </div>
    )
  }
)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`
