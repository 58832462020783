import { getChainId } from '~config/getChainId'

export const getPancakeSwapLink = (inputAddress: string, outputAddress: string) => {
  return (
    process.env.REACT_APP_PANCAKE_LINK +
    `swap?inputCurrency=${inputAddress}&outputCurrency=${outputAddress}`
  )
}

export const PANCAKE_LIQUIDITY_LINK =
  process.env.REACT_APP_PANCAKE_LINK + (getChainId() === 56 ? 'liquidity' : 'pool')
