import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'

export interface StakedState {
  roundA: BigNumber
  roundB: BigNumber
  roundIDO: BigNumber
  stakedOf: BigNumber
  roundsTotal: BigNumber
  stakedTotal: BigNumber
}

export type StakedSetAction = PayloadAction<Partial<StakedState>>

const initialState: StakedState = {
  roundA: new BigNumber('0'),
  roundB: new BigNumber('0'),
  roundIDO: new BigNumber('0'),
  stakedOf: new BigNumber('0'),
  roundsTotal: new BigNumber('0'),
  stakedTotal: new BigNumber('0')
}

export const stakedSlice = createSlice({
  name: 'staked',
  initialState: initialState,
  reducers: {
    startListening: (state) => state,
    endListening: (state) => state,
    set: (state, action: StakedSetAction) => {
      return { ...state, ...action.payload }
    },
    reset: () => {
      return initialState
    }
  }
})

export const { actions: stakedActions, reducer: stakedReducer } = stakedSlice
