import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'

import badge from './images/badge.svg'

import { MediaQueryBreakpoint } from '~enums'
import { BodyL, Icon, SecondaryButton } from '~ui'

const Label = styled(BodyL)`
  opacity: 0.8;
`

const PlayButton = styled((props) => <SecondaryButton primaryHover {...props} />)`
  background: ${({ theme }) => theme.colors.secondaryShade1} !important;
  border: 2px solid ${({ theme }) => theme.colors.secondaryShade1} !important;
  padding: 12px 12px 10px 16px !important;
`

const MarginLink = styled.a.attrs({
  href: 'https://www.youtube.com/watch?v=B-604iP_EPk',
  target: '_blank'
})`
  margin-right: 35px;
`

const Badge = styled.img.attrs({ src: badge, alt: 'Badge', height: 45, width: 45 })`
  margin-right: 22px;
`

export const TrustBadge = styled(({ className }) => {
  const { t } = useTranslation('home')
  const isSm = useMediaQuery({ maxWidth: MediaQueryBreakpoint.sm })
  return (
    <div className={className}>
      <MarginLink>
        <PlayButton>
          <Icon name="video" />
        </PlayButton>
      </MarginLink>
      {!isSm && <Badge />}
      <Label>{t('LocalTrade is Most Trusted Project 2021')}</Label>
    </div>
  )
})`
  display: flex;
  font-size: 18px;
  padding: 15px 20px;
  background: ${({ theme }) => theme.colors.secondaryShade1};
  align-items: center;
  border-radius: 24px;
  transition: all 0.2s ease-in-out;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transition-delay: 0s;
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.secondaryShade9};
  }
`
