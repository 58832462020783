import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { TransparentButton as TransparentButtonUI } from '../../ui'

import { useUser } from '~state'

const TransparentButton = styled(TransparentButtonUI)`
  font-weight: normal !important;
  margin-right: 22px;
  height: 54px !important;
  padding-right: 0px !important;
`

export const ProfileButton = (props: React.HTMLAttributes<HTMLButtonElement>) => {
  const { t } = useTranslation('profile')

  const user = useUser()

  return (
    <TransparentButton {...props}>
      {!user.data ? t('Create Profile') : t('Profile')} 🐊
    </TransparentButton>
  )
}
