import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import { Container } from '~components'
import { Headline1 } from '~ui'

export const MaintenancePage = () => {
  const { t } = useTranslation('common')
  return (
    <>
      <Helmet>
        <title>LocalTrade DeFi Lab</title>
      </Helmet>
      <Container>
        <Headline1>🛠 {t('The website is under maintenance.')}</Headline1>
      </Container>
    </>
  )
}
