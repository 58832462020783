import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Tab } from '../components'

import { LiquidityPools } from './LiquidityPools'
import { Vaults } from './Vaults'

import { Headline4, Icon } from '~ui'
import { mediaQuery } from '~utils'

const VaultsTabs = {
  STAKING: 'staking',
  PROVIDE_LIQUIDITY: 'provideLiquidity'
}

const NewIcon = styled(Icon)`
  ${mediaQuery.greaterThan('md')`
   margin-right: 10px;
  `}
`
export const VaultsAndLiquidityPools = () => {
  const { t } = useTranslation('staking')
  const [activeTab, setActiveTab] = useState(VaultsTabs.STAKING)
  return (
    <>
      <div className="d-flex">
        <Tab
          onClick={() => setActiveTab(VaultsTabs.STAKING)}
          active={activeTab === VaultsTabs.STAKING}
        >
          <Headline4>{t('Staking')}</Headline4>
        </Tab>
        <Tab
          onClick={() => setActiveTab(VaultsTabs.PROVIDE_LIQUIDITY)}
          active={activeTab === VaultsTabs.PROVIDE_LIQUIDITY}
        >
          <div className="d-flex align-items-center justify-content-center">
            <NewIcon name="new" />
            <Headline4>{t('Provide Liquidity')}</Headline4>
          </div>
        </Tab>
      </div>
      {activeTab === VaultsTabs.STAKING ? <Vaults /> : <LiquidityPools />}
    </>
  )
}
