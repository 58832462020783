import { useWeb3React } from '@web3-react/core'
import { useEffect } from 'react'

import { LocalStorageKey } from '~config'
import { ConnectorNames } from '~enums'
import { useAppDispatch } from '~state'
import { connectorsByName } from '~utils'

export const useInactiveListener = () => {
  const { account, chainId, connector } = useWeb3React()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (account && connector) {
      const handleDeactivate = () => {
        // This localStorage key is set by @web3-react/walletconnect-connector
        if (window.localStorage.getItem('walletconnect')) {
          connectorsByName[ConnectorNames.WalletConnect].close()
          connectorsByName[ConnectorNames.WalletConnect].walletConnectProvider = null
        }
        window.localStorage.removeItem(LocalStorageKey.ConnectorName)
      }

      connector.addListener('Web3ReactDeactivate', handleDeactivate)

      return () => {
        connector.removeListener('Web3ReactDeactivate', handleDeactivate)
      }
    }
    return undefined
  }, [account, chainId, dispatch, connector])
}
