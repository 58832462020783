import BigNumber from 'bignumber.js'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { SmartMargin, Block, Label, Amount } from '../../../components'
import { calculateReward } from '../utils'

import { CentralizedVault } from '~interfaces'
import { Icon } from '~ui'
import { NumberFormatter } from '~utils'

const IconWrapper = styled.div`
  width: 100%;
  min-width: 57px;
  cursor: pointer;
  max-width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  margin-left: 10px;
  border: ${({ theme }) => `1px solid ${theme.colors.secondaryShade1}`};
  &:hover {
    background-color: ${({ theme }) => theme.colors.secondaryShade3};
  }
  background-color: ${({ theme }) => theme.colors.secondaryShade1};
`

export const RewardsInfo = ({ vault }: { vault: CentralizedVault }) => {
  const { t } = useTranslation('vault')
  const availableReward = calculateReward(vault).plus(vault.contract.totalClaimed)
  return (
    <SmartMargin top className="d-flex">
      <Block direction="row" className="flex-grow-1">
        <Label>{t('Rewards')}:</Label>{' '}
        <Amount>
          {NumberFormatter.toDecimal(availableReward, vault.rewardTicker).toFixed(
            5,
            BigNumber.ROUND_DOWN
          )}{' '}
          {vault.rewardTicker}
        </Amount>
      </Block>
      <IconWrapper
        onClick={() =>
          window.open(
            'https://blog.localtrade.cc/hurry-up-to-earn-cru-with-dynamic-apr-meet-the-ltt-cru-farming-vault-a53abeead5d3',
            '_blank'
          )
        }
      >
        <Icon name="info" />
      </IconWrapper>
    </SmartMargin>
  )
}
