import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { NewsApi } from '~api'
import { News } from '~interfaces'

export const fetchNews = createAsyncThunk('news/fetch', async () => {
  const newsApi = new NewsApi()
  return newsApi.get()
})

export interface NewsState {
  data: News
  loading: boolean
}

const initialState: NewsState = {
  data: [],
  loading: false
}

export const newsSlice = createSlice({
  name: 'news',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchNews.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchNews.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload
    })
    builder.addCase(fetchNews.rejected, (state) => {
      state.loading = false
    })
  }
})

export const { actions: newsActions, reducer: newsReducer } = newsSlice
