import * as Bs from '@bootstrap-styled/v4'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { BodyL, Card, Headline3, TextWithButton } from '~components'
import { mediaQuery } from '~utils'

const Value = styled(Headline3)`
  ${mediaQuery.lessThan('md')`
     margin-top:20px;
  `}
  margin-bottom: 10px;
`
const Label = styled(BodyL)`
  color: ${({ theme }) => theme.colors.secondary};
`

const Stat = ({ value, label }: { value: string; label: string }) => {
  return (
    <div className="text-center">
      <Value>{value}</Value>
      <Label>{label}</Label>
    </div>
  )
}

export const WhyInvest = () => {
  const { t } = useTranslation(['security', 'balance'])
  return (
    <Card withBorder>
      <Bs.Row>
        <Bs.Col xs={12} md={8}>
          <TextWithButton
            text={t('Why invest in pre-IPO') + '?'}
            btnText={t('balance:Learn more')}
            link={
              'https://docs.localtrade.cc/products/neobroker-smart-fund/why-invest-in-pre-ipo-companies'
            }
          />
        </Bs.Col>
        <Bs.Col xs={6} md={2}>
          <Stat value="1033" label={t('Annual IPOs, 2021')} />
        </Bs.Col>
        <Bs.Col xs={6} md={2}>
          <Stat value="36%" label={t('Avg. first-day IPO returns, 2021')} />
        </Bs.Col>
      </Bs.Row>
    </Card>
  )
}
