import lg from './lg.jpg'
import md from './md.jpg'
import sm from './sm.jpg'
import xl from './xl.jpg'
import xs from './xs.jpg'

export const pt = {
  'pt-PT': {
    lg,
    xl,
    sm,
    xs,
    md
  }
}
