import styled from 'styled-components'

import busdLtt from './images/busdLtt.png'

const logoImages = {
  busdLtt
}

export type LogoImagesNames = keyof typeof logoImages

export const Logo = styled.img.attrs<{ name: LogoImagesNames }>(({ name }) => ({
  src: logoImages[name],
  alt: 'logo'
}))<{ name: LogoImagesNames }>`
  height: 77px;
`
