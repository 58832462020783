import * as Bs from '@bootstrap-styled/v4'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { ConnectButton } from '../../ConnectButton'

import applePay from './images/apple-pay.svg'
import logo from './images/logo.svg'
import { LanguageButton } from './LanguageButton'
import { ProductsButton } from './ProductsButton'
import { ProfileButton } from './ProfileButton'

import { MoonPayModal } from '~components'
import { routes } from '~config'
import { MediaQueryBreakpoint } from '~enums'
import { useActiveWeb3React } from '~hooks'
import { useAccountModal, useProfileModal } from '~state'
import { PrimaryButton, Icon, A2 } from '~ui'
import { mediaQuery, useToggle } from '~utils'

const IconButton = styled(PrimaryButton).attrs(() => ({
  children: <Icon name="user" />
}))`
  width: 40px;
  height: 40px;
  border-radius: 4px !important;
`

const NavbarBrand = styled(Bs.NavbarBrand)`
  &.navbar-brand {
    display: flex;
    margin: 0;
    ${mediaQuery.greaterThan('lg')`margin-right: 60px;`}
    padding: 0;
  }
`

const StyledLanguageButton = styled(LanguageButton)`
  ${mediaQuery.lessThan('lg')`
       border-bottom: 2px solid ${({ theme }) => theme['grid-border-color']};
    `}

  padding-right: 22px;
`

const NavItem = styled(Bs.NavItem)`
  ${mediaQuery.lessThan('lg')`
      width: -webkit-fill-available;
      border-bottom: 2px solid ${({ theme }) => theme.colors.tertiaryShade3};
      padding: 16px 20px;
      &:first-child {
        border-top: 2px solid ${({ theme }) => theme.colors.tertiaryShade3};
      }
    `}

  &:not(:last-child) {
    ${mediaQuery.greaterThan('lg')`
      margin-right: 40px;
    `}
  }
`

const FakeLink = styled.div<{ disabled?: boolean }>`
  color: ${({ theme }) => theme.colors.white} !important;
  &:hover {
    text-decoration: underline;
  }
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  align-items: center;
`

const ApplePayImg = styled.img.attrs({ src: applePay })`
  margin-left: 17px;
`

const NavLink = styled(A2)`
  color: ${({ theme }) => theme.colors.white};
  font-weight: normal;
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }
`

const WidthWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ${mediaQuery.greaterThan('lg')`
  width: auto;`}
  ${mediaQuery.lessThan('lg')`
  width: calc(100% - 32px);
  padding: 18px 16px;
  `}
`

const NavbarToggler = styled(Bs.NavbarToggler).attrs({ tag: PrimaryButton })`
  padding: 0 !important;
  border-radius: 4px !important;
  width: 40px;
  height: 40px;
  background: ${({ theme }) => theme.$primary} !important;
`

export const Navbar = styled(({ className }) => {
  const { t } = useTranslation('navbar')

  const { account } = useActiveWeb3React()
  const [, { open: openAccountModal }] = useAccountModal()
  const [, { open: openProfileModal }] = useProfileModal()
  const [navOpened, navToggle] = useToggle(false)
  const [tooltipOpened, toggleTooltip] = useToggle(false)
  const [moonPayModalOpened, moonPayModalToggle] = useToggle(false)
  const history = useHistory()
  const isSm = useMediaQuery({ maxWidth: MediaQueryBreakpoint.lg })

  return (
    <Bs.Navbar className={className} toggleable="md">
      <div className="d-flex align-items-center w-100 flex-column flex-lg-row">
        <WidthWrapper>
          <NavbarToggler className="d-lg-none" onClick={navToggle}>
            <Icon name="menu" />
          </NavbarToggler>
          <NavbarBrand tag="img" src={logo} alt={logo} onClick={() => history.push(routes.home)} />
          <IconButton className="d-lg-none" onClick={openAccountModal} />
        </WidthWrapper>
        <Bs.Collapse className="align-self-baseline w-100" navbar isOpen={navOpened}>
          <Bs.Nav navbar className="mr-auto w-100 align-items-baseline align-items-lg-center">
            <ProductsButton navToggle={navToggle} />
            <NavItem>
              <FakeLink
                onClick={() => {
                  if (account) moonPayModalToggle()
                }}
                disabled={!account}
              >
                <div id="buy-bnb" className="d-flex align-items-center">
                  <div>{t('Buy BNB')}</div>
                  <ApplePayImg />
                </div>
              </FakeLink>
              <Bs.Tooltip
                placement={isSm ? 'right' : 'bottom'}
                target="buy-bnb"
                isOpen={tooltipOpened && !account}
                toggle={toggleTooltip}
              >
                {t('Сonnect wallet to buy BNB')}
              </Bs.Tooltip>
            </NavItem>
            <NavItem onClick={navToggle}>
              <NavLink href="https://blog.localtrade.cc">{t('Medium Blog')}</NavLink>
            </NavItem>
            <NavItem onClick={navToggle}>
              <NavLink href="https://docs.localtrade.cc">{t('Docs')}</NavLink>
            </NavItem>
          </Bs.Nav>
          <StyledLanguageButton />
          {account && <ProfileButton onClick={openProfileModal} />}
          <ConnectButton onClick={openAccountModal} className="d-none d-lg-flex" />
        </Bs.Collapse>
        {account && (
          <MoonPayModal address={account} open={moonPayModalOpened} close={moonPayModalToggle} />
        )}
      </div>
    </Bs.Navbar>
  )
})`
  padding: 20px 60px !important;
  background-color: ${({ theme }) => theme.colors.body};
  ${mediaQuery.lessThan('lg')`
    padding: 0 !important;
  `}
  img {
    cursor: pointer;
  }
  ${mediaQuery.lessThan('md')`
    z-index: 1000;
  `}
`
