import React from 'react'
import Select, { Props as SelectProps, components } from 'react-select'
import styled, { ThemeColor } from 'styled-components'

import { theme } from '../../../theme'
import { Icon } from '../Icon'

import { Ticker } from '~enums'
import { Token } from '~interfaces'

const TickerIcon = styled((props: any) => <Icon {...props} />)`
  height: 22px !important;
  width: 22px !important;
  margin-right: 10px;
  ${({ colorfulActiveTicker, color }) =>
    colorfulActiveTicker &&
    ` background: ${color};
      border: 5px solid ${color};
      border-radius: 4px;
  `}
`

const Option = styled(
  ({
    ticker,
    className,
    src,
    colorfulActiveTicker,
    color
  }: {
    ticker: Ticker
    className?: string
    src?: string
    colorfulActiveTicker?: boolean
    color?: string
  }) => (
    <div className={className}>
      <TickerIcon
        ticker={ticker}
        src={src}
        colorfulActiveTicker={colorfulActiveTicker}
        color={color}
      />{' '}
      {ticker}
    </div>
  )
)`
  display: flex;
  align-items: center;
`

const StyledSelectContainer = styled(components.SelectContainer)`
  display: block;
  width: fit-content;
  position: absolute !important;
  top: 13px;
  right: 20px;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.secondaryShade3};
  backdrop-filter: blur(19px);
  padding: 6px 10px;
  border-radius: 4px;
`

const StyledControl = styled(components.Control)`
  border: none !important;
  min-height: unset !important;
  box-shadow: none !important;
  cursor: pointer !important;
  background-color: transparent !important;
`

const StyledIndicatorsContainer = styled(components.IndicatorsContainer)`
  ${({ isDisabled }) => isDisabled && 'display:none !important;'}
  & > div {
    padding: 0 !important;
    & > svg {
      path {
        fill: white;
      }
    }
  }
`

const StyledIndicatorSeparator = styled(components.IndicatorSeparator)`
  display: none !important;
`

const StyledSingleValue = ({ colorfulActiveTicker, color, ...props }: any) => {
  const data = props.getValue()[0]
  return (
    <>
      <Option
        ticker={data.value}
        src={data.src}
        colorfulActiveTicker={colorfulActiveTicker}
        color={color}
      />
      <components.SingleValue {...props} />
    </>
  )
}

const StyledMenu = styled(components.Menu)`
  width: fit-content !important;
`

const StyledMenuList = styled(components.MenuList)`
  padding: 0 !important;
  border-radius: 4px;
`

const StyledOption = styled((props) => {
  return (
    <components.Option {...props}>
      <Option ticker={props.data.value} src={props.data.src} />
    </components.Option>
  )
})`
  cursor: pointer !important;
  ${({ theme, rawColor }) => rawColor && `color: ${theme.colors.white} !important;`}
  &:hover {
    background-color: ${({ theme, isSelected, color, rawColor }) =>
      rawColor ?? theme.colors[isSelected ? color ?? 'primary' : 'hoverPrimary']} !important;
  }
  background-color: ${({ theme, isSelected, color, rawColor }) =>
    rawColor ?? theme.colors[isSelected ? color ?? 'primary' : 'body']} !important;
`

const SelectAddon: React.FC<
  SelectProps & { color?: ThemeColor; rawColor?: string; colorfulActiveTicker?: boolean }
> = (props) => (
  <Select
    {...props}
    theme={(t) => ({ ...t, colors: { ...t.colors, ...theme.colors } })}
    components={{
      SelectContainer: StyledSelectContainer as any,
      Control: StyledControl as any,
      IndicatorSeparator: StyledIndicatorSeparator as any,
      IndicatorsContainer: StyledIndicatorsContainer as any,
      Option: (optionProps: any) => (
        <StyledOption color={props.color} rawColor={props.rawColor} {...optionProps} />
      ),
      ValueContainer: (singleValueProps: any) => (
        <StyledSingleValue
          colorfulActiveTicker={props.colorfulActiveTicker}
          color={props.rawColor}
          {...singleValueProps}
        />
      ),
      Menu: StyledMenu as any,
      MenuList: StyledMenuList as any
    }}
  />
)

export const Addon = ({
  tokens,
  activeTicker,
  color,
  rawColor,
  colorfulActiveTicker,
  ...selectProps
}: {
  tokens: Token[]
  activeTicker: Ticker
  color?: ThemeColor
  rawColor?: string
  colorfulActiveTicker?: boolean
} & SelectProps) => {
  const toOption = (t: Token) => ({ label: t.ticker, value: t.ticker, src: t.src })
  const options = tokens.map(toOption)
  const activeValue = options.find((o) => o.value === activeTicker)

  return (
    <SelectAddon
      defaultValue={activeValue}
      colorfulActiveTicker={colorfulActiveTicker}
      value={activeValue}
      color={color}
      rawColor={rawColor}
      name="color"
      options={options}
      isSearchable={false}
      {...selectProps}
    />
  )
}
