import Countdown, { CountdownRenderProps, zeroPad } from 'react-countdown'
import { useTranslation, TFunction } from 'react-i18next'
import styled from 'styled-components'

import { Headline2, BodyM, Headline4 } from '~components'
import { mediaQuery } from '~utils'

const Value = styled(Headline2)`
  min-width: 70px;
  margin-bottom: 6px;
`
const Label = styled(BodyM)`
  color: ${({ theme }) => theme.colors.secondary};
`
const Wrapper = styled.div`
  text-align: center;
`

const Title = styled(Headline4)`
  margin-top: 30px;
  text-align: center;
  margin-bottom: 10px;
  ${mediaQuery.lessThan('md')`
        margin-top:20px; 
  `}
`
const CountdownRenderer = ({
  days,
  hours,
  minutes,
  seconds,
  t
}: CountdownRenderProps & { t: TFunction<string[]> }) => {
  const data = [
    {
      value: days,
      label: t('day')
    },
    {
      value: hours,
      label: t('hours')
    },
    {
      value: minutes,
      label: t('min')
    },
    {
      value: seconds,
      label: t('seconds')
    }
  ]
  return (
    <div className="d-flex justify-content-around">
      {data.map(({ value, label }, index) => {
        return (
          <Wrapper key={index}>
            <Value>{zeroPad(value, 2)}</Value>
            <Label>{label}</Label>
          </Wrapper>
        )
      })}
    </div>
  )
}

export const TokenSaleCountdown = styled(
  ({ endDate, className }: { endDate: Date; className?: string }) => {
    const { t } = useTranslation(['common', 'security'])
    return (
      <div className={className}>
        <Title>{t('security:Till the end of token sale')}</Title>
        <Countdown date={endDate} renderer={(props) => <CountdownRenderer t={t} {...props} />} />
      </div>
    )
  }
)`
  margin-bottom: 35px;
  ${mediaQuery.lessThan('md')`
       margin-bottom: 20px;
`}
`
