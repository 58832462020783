import 'react-toastify/dist/ReactToastify.css'

import { HTMLAttributes } from 'react'
import styled from 'styled-components'

import { Headline4, BodyM } from '../fonts'

const Title = styled(Headline4)`
  margin-top: 0;
  margin-bottom: 10px;
`

const Description = styled(BodyM)`
  color: ${({ theme }) => theme.colors.secondary};
`

export const Toast = styled(
  ({
    title,
    value,
    className,
    children
  }: {
    title?: string
    value: string
  } & HTMLAttributes<HTMLDivElement>) => {
    return (
      <div className={className}>
        {title && <Title>{title}</Title>}
        <Description>{value}</Description>
        {children}
      </div>
    )
  }
)`
  display: flex;
  flex-flow: column;
  height: 100%;
`
