import { TFunction } from 'react-i18next'

import { routes } from '../routes'

import downloadAppstore from './images/download-appstore.svg'
import downloadGooglePlay from './images/download-googleplay.svg'
import product1Img from './images/product1.png'
import product2Img from './images/product2.png'
import product3Img from './images/product3.png'
// import product4Img from './images/product4.png'
import product5Img from './images/product5.png'
import product6Img from './images/product6.png'
import product7Img from './images/product7.png'
import product8Img from './images/product8.png'

import { Product } from '~interfaces'

export const getProducts = (t: TFunction<string[]>, type: Product['type']): Product[] => {
  const products: Product[] = [
    {
      image: product1Img,
      type: 'available',
      title: t('LocalTrade Token'),
      desc: t('Catch the gathering storm with limited supply + deflationary model $LTT'),
      more: {
        link: 'https://docs.localtrade.cc/ltt-token',
        text: t('Read Tokenomics')
      },
      action: {
        button: {
          link: routes.sale,
          text: t('common:Claim') + ' LTT ->'
        }
      }
    },
    {
      image: product2Img,
      type: 'available',
      title: '0xPay ' + t('for Businesses'),
      desc: t('Be on the verge of financial system – start accepting crypto payments today') + '!',
      more: { disabled: true, link: '#', text: t('Release May 2022') },
      action: {
        button: {
          text: t('Apply for MVP'),
          href: 'https://forms.gle/kvLkP2Equ3birytr8'
        }
      },
      label: 'May 2022',
      info: {
        href: 'https://blog.localtrade.cc/meet-0xpay-a-crypto-gateway-from-localtrade-196f14516856'
      }
    },
    {
      image: product3Img,
      type: 'available',
      title: t('DeFi Wallet'),
      desc:
        t('The world’s first multi-currency crypto wallet with a built-in cross-chain exchange') +
        '.',
      actions: [
        {
          image: {
            src: downloadGooglePlay,
            href: 'https://play.google.com/store/apps/details?id=com.localtrade.wallet'
          }
        },
        {
          image: {
            src: downloadAppstore,
            href: 'https://apps.apple.com/app/localtrade-defi-wallet/id1602772298'
          }
        }
      ],
      info: {
        href: 'https://docs.localtrade.cc/products/defi-wallet-mvp-for-ios'
      }
    },
    // {
    //   image: product4Img,
    //   type: 'available',
    //   title: t('NeoBroker Smart Fund'),
    //   desc: t('Become a part-owner of pre-IPO companies by investing in tokenized shares') + '.',
    //   more: {
    //     link: 'https://docs.localtrade.cc/products/neobroker-smart-fund',
    //     text: t('About NeoBroker')
    //   },
    //   action: {
    //     button: {
    //       link: routes.marketplace,
    //       text: t('Buy Tokenized Shares')
    //     }
    //   }
    // },
    {
      image: product5Img,
      type: 'available',
      tvl: true,
      title: t('Farming Vault'),
      desc: t('Stake LTT and other crypto to earn rewards') + '.',
      more: {
        link: 'https://docs.localtrade.cc/products/yield-farming-aggregator',
        text: t('About Farming Vault')
      },
      action: {
        button: {
          link: routes.staking,
          text: t('Explore') + ' ->'
        }
      }
    },
    {
      image: product6Img,
      type: 'coming-soon',
      title: t('Launchpad'),
      desc:
        t(
          'We’re always searching for technologically advanced projects that can bring innovations to crypto space'
        ) + '.',
      label: 'Q2 2022'
    },
    {
      image: product7Img,
      type: 'coming-soon',
      title: t('Leveraged DEX'),
      desc:
        t(
          'Maximize your potential gains from decentralised trading — open positions with up to 5x leverage'
        ) + '.',
      label: 'Q3 2022'
    },
    {
      image: product8Img,
      type: 'coming-soon',
      title: t('NeoBank App'),
      desc: t('Buy/sell crypto in 1 click, and use VISA cards to pay anywhere in the world') + '.',
      label: 'Q4 2022'
    }
  ]
  return products.filter((v) => v.type === type)
}
