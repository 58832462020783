import BigNumber from 'bignumber.js'

export const ranks = [
  { name: 'No Rank', staked: new BigNumber('0') },
  { name: 'DeFi Сurious', staked: new BigNumber('200000000000') },
  { name: 'DeFi Adopter', staked: new BigNumber('400000000000') },
  { name: 'DeFi Enthusiast', staked: new BigNumber('800000000000') },
  { name: 'DeFi Enjoyer', staked: new BigNumber('1200000000000') },
  {
    name: 'DeFi Entrepreneur',
    staked: new BigNumber('2000000000000')
  },
  { name: 'DeFi Expert', staked: new BigNumber('2800000000000') },
  {
    name: 'DeFi Connoisseur',
    staked: new BigNumber('3600000000000')
  },
  {
    name: 'DeFi Ambassador',
    staked: new BigNumber('4400000000000')
  },
  { name: 'DeFi Visionary', staked: new BigNumber('5200000000000') },
  { name: 'DAO Member', staked: new BigNumber('6000000000000') },
  { name: 'DAO Influencer', staked: new BigNumber('6800000000000') },
  { name: 'DAO Advisor', staked: new BigNumber('7600000000000') },
  { name: 'DAO Governor', staked: new BigNumber('8400000000000') },
  {
    name: 'Venture Capitalist',
    staked: new BigNumber('9200000000000')
  },
  { name: 'Angel Investor', staked: new BigNumber('10000000000000') }
]
