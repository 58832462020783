import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'

import { ObjectValuesType, Ticker } from '~enums'

type Value = {
  value: BigNumber
  claiming: boolean
}

export interface RewardsState {
  [Ticker.LTT]: Value
  [Ticker.BUSD]: Value
}

export const RewardsTicker = {
  BUSD: 'BUSD',
  LTT: 'LTT'
} as const

export type RewardsTicker = ObjectValuesType<typeof RewardsTicker>

export type RewardsClaimAction = PayloadAction<{ ticker: RewardsTicker }>

export type RewardsClaimFailedAction = PayloadAction<{ ticker: RewardsTicker }>

export type RewardsSetAction = PayloadAction<{ ticker: RewardsTicker; value: BigNumber }>

export type RewardsClaimedAction = PayloadAction<{ ticker: RewardsTicker }>

const initialValue = { claiming: false, value: new BigNumber('0') }
const initialState: RewardsState = {
  [Ticker.LTT]: initialValue,
  [Ticker.BUSD]: initialValue
}

export const rewardsSlice = createSlice({
  name: 'rewards',
  initialState,
  reducers: {
    startListening: (state) => state,
    endListening: (state) => state,
    claim: (state, action: RewardsClaimAction) => {
      state[action.payload.ticker].claiming = true
    },
    claimed: (state, action: RewardsClaimedAction) => {
      state[action.payload.ticker].claiming = false
    },
    claimFailed: (state, action: RewardsClaimFailedAction) => {
      state[action.payload.ticker].claiming = false
    },
    set: (state, action: RewardsSetAction) => {
      state[action.payload.ticker].value = action.payload.value
    },
    reset: () => initialState
  }
})

export const { actions: rewardsActions, reducer: rewardsReducer } = rewardsSlice
