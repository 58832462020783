import * as Bs from '@bootstrap-styled/v4'
import BigNumber from 'bignumber.js'
import emailValidator from 'email-validator'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Block, Connect, MarginWrapper, SmartMargin } from '../../../components'
import {
  ChooseAction,
  DynamicAPR,
  Footer,
  Info,
  TillStart,
  UnstakeLTT,
  VaultProgress
} from '../components'
import { BaseVaultsProps, calculateReward } from '../utils'

import { Logo } from './Logo'
import { RewardsInfo } from './RewardsInfo'
import { Stake } from './Stake'
import { StakeModalForm } from './StakeModalForm'

import { Tokens } from '~config'
import { Ticker } from '~enums'
import { useActiveWeb3React } from '~hooks'
import { CentralizedVaultsState, useBalance } from '~state'
import { Headline3 as Headline3Ui, Card } from '~ui'

const Headline3 = styled(Headline3Ui)`
  color: ${({ theme }) => theme.colors.secondaryShade2};
  margin-bottom: 5px !important;
`

const Header = styled.div`
  margin-left: 30px;
  overflow: hidden;
  text-overflow: clip;
`

const LogoHeader = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
`

const CardSubtitle = styled(Bs.CardSubtitle)`
  color: ${({ theme }) => theme.colors.secondarySemi};
`

const StyledHr = styled.hr`
  border-color: ${({ theme }) => theme.colors.secondaryShade7};
`

export enum Action {
  Home = 'home',
  Add = 'add',
  Withdraw = 'withdraw'
}

type VaultProps = BaseVaultsProps &
  CentralizedVaultsState[''] & { setUserId: (userId: string) => void }

export const CentralizedVault: React.FC<VaultProps> = ({
  approving,
  approve,
  stakeFee,
  unstakeFee,
  executeClaim,
  executeStake,
  executeUnstake,
  stakeValue,
  spendTicker,
  spendValue,
  unstakeValue,
  onSetSpend,
  onSetTicker,
  onSetUnstakeValue,
  inviter,
  isInviterMutable,
  onInviterChanged,
  averagePrice,
  setUserId,
  ...v
}) => {
  const [action, setAction] = useState<Action>(Action.Home)
  const { account } = useActiveWeb3React()
  const bnbBalance = useBalance(Ticker.BNB)
  const spendBalance = useBalance(spendTicker)
  const { t } = useTranslation('vault')
  let actionNode = null
  const notStarted = v.contract.creationDate.getTime() > Date.now()

  const [{ decimals: rewardDecimals }] = Tokens.filter(({ ticker }) => ticker === v.rewardTicker)

  const [{ decimals: stakeDecimals }] = Tokens.filter(({ ticker }) => ticker === v.stakeTicker)

  const decimalsMultipler = new BigNumber(10).pow(rewardDecimals - stakeDecimals)

  if (account) {
    actionNode = (
      <>
        <MarginWrapper>
          <StyledHr />
        </MarginWrapper>
        <ChooseAction
          availableReward={calculateReward(v).plus(v.contract.totalClaimed)}
          vault={v}
          color={v.color}
          colorShade={v.colorShade}
          add={() => setAction(Action.Add)}
          withdraw={() => setAction(Action.Withdraw)}
          claiming={v.claiming}
          executeClaim={executeClaim}
        />
      </>
    )

    if (action === Action.Add) {
      const [userId] = v.contract.args

      actionNode = (
        <>
          <MarginWrapper>
            <StyledHr />
          </MarginWrapper>
          <MarginWrapper>
            <Stake
              approving={approving}
              vault={v}
              color={v.color}
              averagePrice={averagePrice}
              colorShade={v.colorShade}
              stakeFromTokens={v.stakeFromTokens}
              staking={v.staking}
              back={() => setAction(Action.Home)}
              inviter={inviter}
              isInviterMutable={isInviterMutable}
              onInviterChanged={onInviterChanged}
              bnbBalance={bnbBalance}
              spendBalance={spendBalance}
              stakeValue={stakeValue}
              approve={approve}
              allowances={v.allowances}
              spendTicker={spendTicker}
              spendValue={spendValue}
              stakeFee={stakeFee}
              executeStake={executeStake}
              onSetSpend={onSetSpend}
              onSetTicker={onSetTicker}
              validateModal={() => {
                if (!userId || !emailValidator.validate(userId)) return new Error('Invalid email')
              }}
              stakeModalChildren={<StakeModalForm setEmail={setUserId} email={userId} />}
            />
          </MarginWrapper>
        </>
      )
    } else if (action === Action.Withdraw) {
      actionNode = (
        <>
          <MarginWrapper>
            <StyledHr />
          </MarginWrapper>
          <MarginWrapper>
            <UnstakeLTT
              vault={v}
              color={v.color}
              colorShade={v.colorShade}
              unstaking={v.unstaking}
              back={() => setAction(Action.Home)}
              bnbBalance={bnbBalance}
              unstakeValue={unstakeValue}
              unstakeFee={unstakeFee}
              executeUnstake={executeUnstake}
              onUnstakeValueChanged={onSetUnstakeValue}
            />
          </MarginWrapper>
        </>
      )
    }
  }

  return (
    <Card withBorder borderColor={v.color} className="h-auto">
      <LogoHeader>
        <Logo {...v.logo} />
        <Header>
          <Headline3>{v.title}</Headline3>
          <CardSubtitle>{v.subtitle}</CardSubtitle>
        </Header>
      </LogoHeader>
      {notStarted ? (
        <Block margined>
          <TillStart startDate={v.contract.creationDate} color={v.color} />
        </Block>
      ) : (
        <>
          <Block margined>
            <DynamicAPR
              creationDate={v.contract.creationDate}
              endDate={v.contract.endDate}
              stakedTotal={v.contract.stakedTotal}
              totalReward={v.contract.totalReward}
              decimalsMultipler={decimalsMultipler}
              color={v.color}
            />
          </Block>
          <SmartMargin top>
            <Block>
              <VaultProgress
                color={v.color}
                creationDate={v.contract.creationDate}
                endDate={v.contract.endDate}
                unstakeDate={v.contract.unstakeDate}
              />
            </Block>
          </SmartMargin>
          {account ? (
            <>
              <MarginWrapper>
                <StyledHr />
              </MarginWrapper>
              <Info claimed={new BigNumber(0)} vault={v} children={<RewardsInfo vault={v} />} />
            </>
          ) : (
            <MarginWrapper>
              <Connect hoverBackgroundColor={v.color} backgroundColor={v.colorShade} />
            </MarginWrapper>
          )}
          {actionNode}
        </>
      )}
      <MarginWrapper>
        <Block>
          {t('You’ll be able to claim CRU rewards on LocalTrade CEX in the end of farming period')}
        </Block>
      </MarginWrapper>
      <MarginWrapper>
        <StyledHr />
      </MarginWrapper>
      <Footer vault={v} />
    </Card>
  )
}
