import styled from 'styled-components'

import { LTTPrice } from './LTTPrice'
import { SwapLinks } from './SwapLinks'

import { ContractInfo } from '~components/layout/SubHeader/ContractInfo'
import { LTTInfo } from '~components/layout/SubHeader/LTTInfo'
import { mediaQuery } from '~utils'

const NavItem = styled.div`
  &:not(:last-child) {
    padding-right: 21px;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    ${mediaQuery.lessThan('sm')`
      padding-right: 12px;
    `}
  }
  &:not(:first-child) {
    padding-left: 21px;
    ${mediaQuery.lessThan('sm')`
      padding-left: 12px;
    `}
  }
`

const Flexible = styled.div`
  display: flex;
  align-items: center;
  ${mediaQuery.lessThan('lg')`
    flex-basis: 100%;
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.secondaryShade3};`}
`

const NavItemMargined = styled(NavItem)`
  margin-left: auto;
`

export const SubHeader = styled((props) => {
  return (
    <div {...props}>
      <Flexible>
        <NavItem>
          <LTTPrice />
        </NavItem>
        <NavItem>
          <SwapLinks />
        </NavItem>
        <NavItemMargined>
          <LTTInfo />
        </NavItemMargined>
      </Flexible>
      <ContractInfo />
    </div>
  )
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  flex-wrap: wrap;
  padding: 9px 80px;
  ${mediaQuery.lessThan('lg')`
    padding: 12px 16px;
  `}
  background-color: ${({ theme }) => theme.colors.secondaryShade1};
  border-bottom: 1px solid ${({ theme }) => theme.colors.secondaryShade7};
`
