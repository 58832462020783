import BigNumber from 'bignumber.js'

import { ranks } from '~config'
import { Rank } from '~interfaces'

export const getRank = (staked: BigNumber): Rank => {
  const rank: Rank = {
    name: ranks[0].name,
    number: 0
  }

  for (const [index, r] of ranks.entries()) {
    if (staked.gte(r.staked)) {
      rank.name = r.name
      rank.number = index
    } else return rank
  }

  return rank
}
