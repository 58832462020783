import * as Bs from '@bootstrap-styled/v4'
import styled from 'styled-components'

import { mediaQuery } from '~utils'

export const Container = styled(Bs.Container)`
  padding-left: 20px;
  padding-right: 20px;
  padding-top: ${({ $withHeading }) => ($withHeading ? '100px' : '40px')};
  ${mediaQuery.lessThan('md')`
  padding-top: ${({ $withHeading }: any) => ($withHeading ? '50px' : '20px')};
`}
  ${mediaQuery.lessThan('sm')`
  width: auto;
`};
`
