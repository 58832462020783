import { Web3Provider } from '@ethersproject/providers'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'

import { ConnectorNames } from '~enums'

export interface Web3State {
  library?: Web3Provider
  account?: string | null
  gasPrice?: BigNumber
}

export type Web3WalletConnectAction = PayloadAction<{
  wallet: ConnectorNames
  address?: string
  failedReason?: string
}>

export type Web3SetGasPriceAction = PayloadAction<BigNumber>
export type Web3SetAction = PayloadAction<Web3State>

export const web3Slice = createSlice({
  name: 'web3',
  initialState: {} as Web3State,
  reducers: {
    set: (_state, action: Web3SetAction) => {
      return action.payload
    },
    // caught by gtmMiddleware
    walletConnect: (_state, _action: Web3WalletConnectAction) => {},
    setGasPrice: (state, action: Web3SetGasPriceAction) => {
      state.gasPrice = action.payload
    }
  }
})

export const { actions: web3Actions, reducer: web3Reducer } = web3Slice
