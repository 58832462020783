import { Pair } from '@uniswap/sdk'
import BigNumber from 'bignumber.js'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { LP_BROKER_ADDRESS } from '~config'
import { Ticker, TickerAddToLPFrom } from '~enums'
import { LPAction, AppState, lpActions, LPState } from '~state'

export const useLP = (): {
  approve: () => void
  onSetValue: (value: string) => void
  onSetMaxValue: () => void
  onSetTicker: (ticker: TickerAddToLPFrom) => void
  change: BigNumber
  pair?: Pair
  execute: () => void
  provideLiquidityFee: BigNumber | undefined
  setAction: (action: LPAction) => void
  refresh: () => void
} & LPState => {
  const dispatch = useDispatch()

  const state = useSelector((state: AppState) => state.lp)
  const pair = useSelector((state: AppState) => state.ltt.pair)
  const gasPrice = useSelector((state: AppState) => state.web3.gasPrice)

  useEffect(() => {
    dispatch(lpActions.startListening())
    return () => {
      dispatch(lpActions.endListening())
    }
  }, [])

  return {
    ...state,
    change: state.reserves.busd.dividedBy(state.lastReserves.busd).minus(1),
    approve: () => {
      if (state.form.ticker === Ticker.BNB) return
      dispatch(lpActions.approve({ ticker: state.form.ticker, spender: LP_BROKER_ADDRESS }))
    },
    onSetValue: (value) => dispatch(lpActions.setValue(value)),
    onSetMaxValue: () => dispatch(lpActions.setMaxValue(state.form.ticker)),
    onSetTicker: (ticker) => dispatch(lpActions.setTicker(ticker)),
    pair,
    execute: () => dispatch(lpActions.executeProvideLP()),
    provideLiquidityFee: gasPrice && state.form.provideLiquidityGas?.multipliedBy(gasPrice),
    setAction: (action: LPAction) => dispatch(lpActions.setFormState(action)),
    refresh: () => {
      dispatch(lpActions.setValue(state.form.value))
    }
  }
}
