import { Web3Provider } from '@ethersproject/providers'
import { eventChannel, SagaIterator } from 'redux-saga'
import { put, call, take, fork, cancel, cancelled } from 'redux-saga/effects'

import { blocksActions, web3Actions, Web3SetAction } from '~state'

function createBlocksChannel(library: Web3Provider) {
  return eventChannel((emit) => {
    const blockListener = (number: number) => {
      emit(number)
    }
    library.on('block', blockListener)
    return () => {
      library.off('block', blockListener)
    }
  })
}

function* updateBlock({ payload: { library } }: Web3SetAction): SagaIterator {
  if (!library) return
  const channel = yield call(createBlocksChannel, library)
  try {
    while (true) {
      const blockNumber: number = yield take(channel)
      yield put(blocksActions.set(blockNumber))
    }
  } finally {
    if (yield cancelled()) {
      channel.close()
    }
  }
}

export function* blocksSaga(): SagaIterator {
  let task: null | any = null

  while (true) {
    const action: Web3SetAction = yield take(web3Actions.set)
    if (task) yield cancel(task)
    task = yield fork(updateBlock, action)
  }
}
