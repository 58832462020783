import BigNumber from 'bignumber.js'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Ticker } from '~enums'
import { useLttUnlocked, useVaults } from '~state'
import { Card, CircularProgressBar as CircularProgressBarUi, Headline4 } from '~ui'

const CircularProgressBar = styled(CircularProgressBarUi)`
  text {
    font-size: 36px !important;
    font-weight: 600;
  }
`

const Title = styled(Headline4)`
  margin-top: 30px;
`

export const SupplyStaked = styled(({ className }: { className?: string }) => {
  const unlocked = useLttUnlocked()
  const vaults = useVaults()

  const { t } = useTranslation('balance')

  const lttVaults = vaults.filter((v) => v.stakeTicker === Ticker.LTT)
  const vaultsTotal = lttVaults.reduce(
    (acc, v) => acc.plus(v.contract.stakedTotal),
    new BigNumber(0)
  )

  const percentage = unlocked.gt(0)
    ? parseFloat(vaultsTotal.dividedBy(unlocked).multipliedBy(100).toFixed(0))
    : 0

  return (
    <Card withBorder className={className}>
      <CircularProgressBar
        strokeWidth={18}
        size={159}
        percentage={percentage}
        gradient="secondary"
      />
      <Title>{t('Of circulating supply is staked')}</Title>
    </Card>
  )
})`
  align-items: center;
`
