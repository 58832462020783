import { BigNumber } from 'bignumber.js'
import { select, put, call } from 'redux-saga/effects'

import { getAllowance } from '../../utils/getAllowance'

import { VaultsApproveSuccessAction, VaultsActions, AppState, VaultsState } from '~state'

export function* onVaultsApproveSuccess<S extends Record<string, VaultsState>>(
  { payload }: VaultsApproveSuccessAction,
  statePath: keyof AppState,
  actions: VaultsActions<S>
) {
  const state: AppState = yield select()
  const {
    contracts: { bep20 },
    web3: { account }
  } = state
  if (!bep20 || !account) return

  const vaults = state[statePath] as S

  const allowance: BigNumber = yield call(
    getAllowance,
    bep20[payload.ticker],
    payload.address,
    account
  )

  yield put(actions.setAllowance({ address: payload.address, ticker: payload.ticker, allowance }))

  // trigger onSetSpend to rerender form
  yield put(
    actions.setSpend({
      value: vaults[payload.address].form.spendValue,
      address: payload.address,
      args: vaults[payload.address].contract.args
    })
  )
}
