import * as Bs from '@bootstrap-styled/v4'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'

import { Headline2 } from '../../ui'

import bridgingTheGapMobileImg from './images/bridging-the-gap-mobile.png'
import bridgingTheGapImg from './images/bridging-the-gap.png'

import { MediaQueryBreakpoint } from '~enums'
import { mediaQuery } from '~utils'

const Title = styled(Headline2)`
  margin-bottom: 40px !important;
  span {
    color: ${({ theme }) => theme.colors.secondary};
  }
  ${mediaQuery.lessThan('md')`
    margin-bottom: 20px !important;
  `}
`

const ImgWrapper = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
`

export const BridgingGap = styled(({ className }: { className?: string }) => {
  const isSm = useMediaQuery({ maxWidth: MediaQueryBreakpoint.md })
  const { t } = useTranslation('home')

  return (
    <div className={className}>
      <Title>
        <span>{t('Bridging The Gap')}</span>
        <br />
        {t('Between DeFi & TradFi')}
      </Title>
      <Bs.Row className="justify-content-end">
        <ImgWrapper>
          {isSm ? (
            <img src={bridgingTheGapMobileImg} alt="Bridging Gap Between DeFi & TradFi" />
          ) : (
            <img src={bridgingTheGapImg} alt="Bridging Gap Between DeFi & TradFi" />
          )}
        </ImgWrapper>
      </Bs.Row>
    </div>
  )
})`
  img {
    max-width: 100%;
  }
`
