import { SagaIterator } from 'redux-saga'
import { takeLatest, call, all, fork } from 'redux-saga/effects'

import { createTerminatedSaga } from '../utils/createTerminatedSaga'

import {
  onVaultsApproveSuccess,
  onVaultsSetUnstakeValue,
  onVaultsSetTicker,
  onWeb3Set
} from './workers'

import {
  AppState,
  contractsActions,
  TransactionType,
  VaultsActions,
  VaultsState,
  web3Actions
} from '~state'
import { onApprove, onExecute, onSetTXInfo } from '~state/sagas/utils'

const createVaultsTerminatedSaga = <S extends Record<string, VaultsState>>(
  actions: VaultsActions<S>,
  fetchWorker: (...args: any) => any
) => {
  return createTerminatedSaga(
    {
      start: actions.startListening,
      end: actions.endListening
    },
    [
      call(fetchWorker),
      takeLatest(contractsActions.setGeneral, fetchWorker),
      takeLatest(actions.claimExecuted, fetchWorker),
      takeLatest(actions.stakeExecuted, fetchWorker),
      takeLatest(actions.unstakeExecuted, fetchWorker),
      takeLatest(web3Actions.set, fetchWorker),
      takeLatest(web3Actions.setGasPrice, fetchWorker)
    ]
  )
}

export function* vaultsSaga<S extends Record<string, VaultsState>>(
  statePath: keyof AppState,
  actions: VaultsActions<S>,
  fetchWorker: (...args: any) => any
): SagaIterator {
  yield fork(createVaultsTerminatedSaga<S>(actions, fetchWorker))
  yield all([
    yield takeLatest(actions.approve, (action) =>
      onApprove(
        action,
        () =>
          actions.approveSuccess({
            address: action.payload.spender,
            ticker: action.payload.ticker
          }),
        () =>
          actions.approveFailure({
            address: action.payload.spender
          }),
        () => actions.approve(action.payload)
      )
    ),
    yield takeLatest(actions.executeClaim, (action) =>
      onSetTXInfo(
        TransactionType.CLAIM,
        undefined,
        (s) => (s[statePath] as Record<string, VaultsState>)[action.payload.address].rewardTicker
      )
    ),
    yield takeLatest(actions.executeClaim, (action) =>
      onExecute(
        (s) => (s[statePath] as Record<string, VaultsState>)[action.payload.address].claimCall,
        () => actions.executeClaim(action.payload),
        () => actions.claimExecuted(action.payload),
        () => actions.claimFailed(action.payload)
      )
    ),
    yield takeLatest(actions.executeStake, (action) =>
      onSetTXInfo(TransactionType.STAKE, (s) => {
        const state = (s[statePath] as Record<string, VaultsState>)[action.payload.address]
        return [{ value: state.form.spendValue, ticker: state.form.spendTicker }]
      })
    ),
    yield takeLatest(actions.executeStake, (action) =>
      onExecute(
        (s) => (s[statePath] as Record<string, VaultsState>)[action.payload.address].stakeCall,
        () => actions.executeStake(action.payload),
        () => actions.stakeExecuted(action.payload),
        () => actions.stakeFailed(action.payload)
      )
    ),
    yield takeLatest(actions.executeUnstake, (action) =>
      onSetTXInfo(TransactionType.UNSTAKE, (s) => {
        const state = (s[statePath] as Record<string, VaultsState>)[action.payload.address]
        return [{ value: state.form.unstakeValue, ticker: state.stakeTicker }]
      })
    ),
    yield takeLatest(actions.executeUnstake, (action) =>
      onExecute(
        (s) => (s[statePath] as Record<string, VaultsState>)[action.payload.address].unstakeCall,
        () => actions.executeUnstake(action.payload),
        () => actions.unstakeExecuted(action.payload),
        () => actions.unstakeFailed(action.payload)
      )
    ),
    yield takeLatest(actions.setUnstakeValue, (action) =>
      onVaultsSetUnstakeValue(action, statePath, actions)
    ),
    yield takeLatest(actions.approveSuccess, (action) =>
      onVaultsApproveSuccess(action, statePath, actions)
    ),
    yield takeLatest(actions.setTicker, (action) => onVaultsSetTicker(action, statePath, actions)),
    yield takeLatest(web3Actions.set, () => onWeb3Set(actions))
  ])
}
