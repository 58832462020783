import React from 'react'
import { useTranslation } from 'react-i18next'

import { InfoBox } from './InfoBox'
import { MarginWrapper } from './ui'

import { Ticker } from '~enums'
import { TickerValue, TransactionType } from '~state'
import { Headline3, Icon } from '~ui'

export const AcceptBody = ({
  values,
  txType,
  ticker
}: {
  values: TickerValue[]
  txType: TransactionType
  ticker?: Ticker
}) => {
  const { t } = useTranslation('common')
  return (
    <>
      <Icon name="phone" />
      <MarginWrapper x={2}>
        <Headline3>{t('Accept in Wallet')}</Headline3>
      </MarginWrapper>
      <MarginWrapper>
        <InfoBox txType={txType} values={values} ticker={ticker} />
      </MarginWrapper>
    </>
  )
}
