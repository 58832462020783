import { useSelector } from 'react-redux'

import { accountModalActions, AppState, useAppDispatch } from '../store'

export function useAccountModal(): [
  boolean,
  { toggle: () => void; open: () => void; close: () => void }
] {
  const dispatch = useAppDispatch()

  const state = useSelector((state: AppState) => state.accountModal)

  return [
    state.opened,
    {
      toggle: () => dispatch(accountModalActions.toggle()),
      close: () => dispatch(accountModalActions.close()),
      open: () => dispatch(accountModalActions.open())
    }
  ]
}
