import { SagaIterator } from 'redux-saga'
import { call, put, select } from 'redux-saga/effects'

import { VaultsMethods } from '../methods'

import { AppState, VaultsSetUnstakeValueAction, VaultsActions, VaultsState } from '~state'
import { estimateCall, getCall } from '~state/sagas/utils'
import { NumberFormatter } from '~utils'

export function* onVaultsSetUnstakeValue<S extends Record<string, VaultsState>>(
  { payload: { address, value } }: VaultsSetUnstakeValueAction,
  statePath: keyof AppState,
  actions: VaultsActions<S>
): SagaIterator {
  if (!value || parseFloat(value) === 0) return

  const state: AppState = yield select()
  const vaults = state[statePath] as S
  const {
    web3: { library, gasPrice }
  } = state

  const vault = vaults[address]

  if (!library || !gasPrice) throw new Error('No library or gasPrice')

  const unstakeValue = NumberFormatter.fromDecimal(value, vault.stakeTicker)

  if (vault.contract.staked.lt(unstakeValue)) {
    return
  }

  const unstakeGas = yield call(() =>
    estimateCall(vault.contract.value, VaultsMethods.Unstake, [unstakeValue.toString()])
  )
  const unstakeCall = yield call(() =>
    getCall(vault.contract.value, VaultsMethods.Unstake, gasPrice, unstakeGas, [
      unstakeValue.toString()
    ])
  )

  yield put(actions.setUnstakeCallInfo({ address, gas: unstakeGas, call: unstakeCall }))
}
