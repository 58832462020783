import * as Bs from '@bootstrap-styled/v4'
import BigNumber from 'bignumber.js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import { Block, Label, MarginWrapper, SmartMargin, Amount, Button } from '../../../components'

import { calculateReward, calculateUnstake } from './utils'

import { MediaQueryBreakpoint, Ticker } from '~enums'
import { StaticVault, StaticVaultPosition } from '~interfaces'
import { NumberFormatter, useToggle } from '~utils'

export type PositionInfoProps = Pick<StaticVault, 'stakeTicker' | 'rewardTicker'> &
  Pick<
    StaticVault['contract'],
    'unstakeOffset' | 'rewardInterval' | 'secondlyUnlockPer1e16' | 'secondlyRewardPer1e16'
  > & {
    hasDao: boolean
    position: StaticVaultPosition
    unstakeFee?: BigNumber
    unstaking: boolean
    executeUnstake: () => void
    bnbBalance: BigNumber
  }

export const PositionInfo = ({
  position: { startDate, lastRewardDate, staked, totalStaked },
  unstakeOffset,
  secondlyUnlockPer1e16,
  secondlyRewardPer1e16,
  rewardInterval,
  rewardTicker,
  stakeTicker,
  bnbBalance,
  unstakeFee,
  unstaking,
  hasDao,
  executeUnstake,
  ...props
}: PositionInfoProps) => {
  const { t } = useTranslation('vault')
  const [tooltipOpened, tooltipToggle] = useToggle(false)

  const isLg = useMediaQuery({ maxWidth: MediaQueryBreakpoint.lg })

  const isWithdrawDisabled = Date.now() <= startDate.getTime() + unstakeOffset.toNumber() * 1000
  const availableToWithdraw = isWithdrawDisabled
    ? new BigNumber(0)
    : calculateUnstake({ unstakeOffset, secondlyUnlockPer1e16 }, startDate, totalStaked, staked)

  const fullReward = calculateReward(
    { rewardInterval, secondlyRewardPer1e16, hasDao },
    { staked, startDate, lastRewardDate },
    true
  )

  return (
    <MarginWrapper {...props}>
      <Bs.Row noGutters>
        <Bs.Col xs={12} lg={6}>
          <SmartMargin right>
            <Block direction="row">
              <Label>{t('Staked')}:</Label>{' '}
              <Amount>
                {NumberFormatter.toDecimal(staked, stakeTicker).toFixed(2, BigNumber.ROUND_DOWN)}{' '}
                {stakeTicker}
              </Amount>
            </Block>
          </SmartMargin>
        </Bs.Col>
        <Bs.Col xs={12} lg={6}>
          <SmartMargin top={isLg} left>
            <Block direction="row">
              <Label>{t('Est. earn')}:</Label>{' '}
              <Amount>
                {NumberFormatter.toDecimal(fullReward, rewardTicker).toFixed(
                  2,
                  BigNumber.ROUND_DOWN
                )}{' '}
                {rewardTicker}
              </Amount>
            </Block>
          </SmartMargin>
        </Bs.Col>
      </Bs.Row>
      <SmartMargin top>
        <Button
          wide
          id="withdraw"
          outline={!(isWithdrawDisabled || unstakeFee?.gt(bnbBalance))}
          loading={unstaking}
          disabled={isWithdrawDisabled || unstakeFee?.gt(bnbBalance)}
          onClick={executeUnstake}
        >
          {t('common:Withdraw')}{' '}
          {NumberFormatter.toDecimal(availableToWithdraw, stakeTicker).toFixed(5)} {rewardTicker}
        </Button>
        {unstakeFee?.gt(bnbBalance) && (
          <Bs.Tooltip
            target="withdraw"
            placement="bottom"
            isOpen={tooltipOpened}
            toggle={tooltipToggle}
          >
            {t(`You don't have enough BNB to pay network fee. Need {value} BNB`, {
              value: NumberFormatter.toDecimal(unstakeFee, Ticker.BNB).toFixed(6)
            })}
          </Bs.Tooltip>
        )}
      </SmartMargin>
    </MarginWrapper>
  )
}
