import * as Bs from '@bootstrap-styled/v4'
import BigNumber from 'bignumber.js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import { Block, Label, MarginWrapper, SmartMargin, Amount, Button } from '../../../components'

import { calculateReward } from './utils'

import { MediaQueryBreakpoint } from '~enums'
import { StaticVault } from '~interfaces'
import { NumberFormatter } from '~utils'

export const Info = ({
  vault,
  claiming,
  executeClaim
}: {
  vault: StaticVault
  claiming: boolean
  executeClaim: () => void
}) => {
  const { t } = useTranslation('vault')
  const isLg = useMediaQuery({ maxWidth: MediaQueryBreakpoint.lg })

  const calculatePositionRewards = (final?: boolean) =>
    vault.contract.positions.reduce(
      (acc, el) =>
        acc.plus(
          calculateReward(
            {
              secondlyRewardPer1e16: vault.contract.secondlyRewardPer1e16,
              rewardInterval: vault.contract.rewardInterval,
              hasDao: !vault.singleStaker
            },
            el,
            final
          )
        ),
      new BigNumber(0)
    )

  const fullReward = calculatePositionRewards(true)
  const claimableReward = calculatePositionRewards()

  const staked = vault.contract.positions.reduce((acc, el) => acc.plus(el.staked), new BigNumber(0))

  return (
    <MarginWrapper>
      <Bs.Row noGutters>
        <Bs.Col xs={12} lg={6}>
          <SmartMargin right>
            <Block direction="row">
              <Label>{t('Your Positions')}:</Label>{' '}
              <Amount>
                {NumberFormatter.toDecimal(staked, vault.stakeTicker).toFixed(
                  2,
                  BigNumber.ROUND_DOWN
                )}{' '}
                {vault.stakeTicker}
              </Amount>
            </Block>
          </SmartMargin>
        </Bs.Col>
        <Bs.Col xs={12} lg={6}>
          <SmartMargin top={isLg} left>
            <Block direction="row">
              <Label>{t('Est. earn')}:</Label>{' '}
              <Amount>
                {NumberFormatter.toDecimal(fullReward, vault.rewardTicker).toFixed(
                  2,
                  BigNumber.ROUND_DOWN
                )}{' '}
                {vault.rewardTicker}
              </Amount>
            </Block>
          </SmartMargin>
        </Bs.Col>
      </Bs.Row>
      <SmartMargin top>
        <Button wide outline loading={claiming} onClick={executeClaim}>
          {t('common:Claim')}{' '}
          {NumberFormatter.toDecimal(claimableReward, vault.stakeTicker).toFixed(5)}{' '}
          {vault.rewardTicker}
        </Button>
      </SmartMargin>
    </MarginWrapper>
  )
}
