import React from 'react'
import styled from 'styled-components'

import { Connect } from './Connect'
import { Connected } from './Connected'

import { ConnectorNames } from '~enums'
import { useActiveWeb3React, useAuth } from '~hooks'
import { useAccountModal } from '~state'
import { Modal as ModalUI, ModalBody } from '~ui'
import { mediaQuery } from '~utils'

const StyledModal = styled(ModalUI)<{ $connected: boolean }>`
  ${mediaQuery.greaterThan('sm')`
    .modal-dialog {
      max-width: ${(props: any) => !props.$connected && '613px'};
    }
  `}
`

export const AccountModal = () => {
  const { account } = useActiveWeb3React()
  const [isOpen, { close }] = useAccountModal()
  const { login, logout, loginLC } = useAuth()

  return (
    <StyledModal onClose={close} isOpen={isOpen} $connected={!!account}>
      <ModalBody>
        {account ? (
          <Connected logout={() => logout()} onClose={() => close()} />
        ) : (
          <Connect
            onClose={() => close()}
            onMetamask={() => login(ConnectorNames.Injected)}
            onWalletConnect={() => loginLC(ConnectorNames.WalletConnect)}
            onBSC={() => login(ConnectorNames.BSC)}
          />
        )}
      </ModalBody>
    </StyledModal>
  )
}
