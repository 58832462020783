import BigNumber from 'bignumber.js'
import { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest, fork, throttle } from 'redux-saga/effects'

import { Api } from '../../api'
import { AppState, blocksActions, contractsActions, tokenizedSecuritiesActions } from '../store'

import { transformBN, createTerminatedSaga } from './utils'

import TokenizedSecurity from '~contracts/TokenizedSecurity.json'
import { SecurityStatus, TickerBuySecurityFrom } from '~enums'
import { Security } from '~interfaces'
import { FinishedSecuritiesMock } from '~mocks'
import { onApprove } from '~state/sagas/utils/approve'
import { getContract } from '~utils'

export function* fetchShares(): SagaIterator {
  const {
    web3: { library, account },
    contracts: { bep20 }
  }: AppState = yield select()

  if (!bep20) return
  if (!library) return

  const api = new Api()
  const securities = yield call(() => api.getSecurities())
  // const timeNow = Date.now() / 1000

  const fetched: Security[] = []

  for (const s of securities) {
    fetched.push(
      yield call(async () => {
        if (!s.address)
          return {
            ...s,
            status: SecurityStatus.SOON,
            valuation: new BigNumber(s.valuation)
          }
        const securityContract = getContract(s.address, TokenizedSecurity.abi, library, account)
        const [_startDate, _endDate, decimals, initialPrice, sellable, supply, raised, symbol] =
          await securityContract.getInfo()
        const balance = account
          ? transformBN(await securityContract.balanceOf(account))
          : new BigNumber('0')

        const startDate = new Date(parseInt(_startDate.toString()) * 1000)
        const endDate = new Date(parseInt(_endDate.toString()) * 1000)
        const timeNow = new Date()
        const status =
          timeNow < startDate
            ? SecurityStatus.SOON
            : timeNow < endDate
            ? sellable
              ? SecurityStatus.AVAILABLE
              : SecurityStatus.ACTIVE
            : SecurityStatus.FINISHED

        return {
          ...s,
          contract: {
            raised: transformBN(raised),
            balance,
            sellable,
            allowance: account
              ? {
                  [TickerBuySecurityFrom.LUSD]: transformBN(
                    await bep20.LUSD.allowance(account, s.address)
                  ),
                  [TickerBuySecurityFrom.BUSD]: transformBN(
                    await bep20.BUSD.allowance(account, s.address)
                  )
                }
              : {
                  [TickerBuySecurityFrom.LUSD]: new BigNumber('0'),
                  [TickerBuySecurityFrom.BUSD]: new BigNumber('0')
                },
            total: transformBN(supply),
            value: securityContract,
            initialPrice: transformBN(initialPrice),
            decimals: transformBN(decimals).toNumber(),
            startDate,
            endDate,
            ticker: symbol
          },
          status,
          valuation: new BigNumber(s.valuation)
        }
      })
    )
  }
  yield put(tokenizedSecuritiesActions.set([...fetched, ...FinishedSecuritiesMock]))
}

const terminatedSaga = createTerminatedSaga(
  {
    start: tokenizedSecuritiesActions.startListening,
    end: tokenizedSecuritiesActions.endListening
  },
  [
    call(fetchShares),
    takeLatest(contractsActions.setGeneral, fetchShares),
    throttle(60000, blocksActions.set, fetchShares)
  ]
)

export function* tokenizedSecuritiesSaga(): SagaIterator {
  yield fork(terminatedSaga)
  yield takeLatest(tokenizedSecuritiesActions.approve, (action) =>
    onApprove(
      action,
      tokenizedSecuritiesActions.approveSuccess,
      tokenizedSecuritiesActions.approveFailure,
      () => tokenizedSecuritiesActions.approve(action.payload)
    )
  )
  yield takeLatest(tokenizedSecuritiesActions.approveSuccess, fetchShares)
}
