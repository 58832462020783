import * as Bs from '@bootstrap-styled/v4'
import { useTranslation } from 'react-i18next'
import { SyncLoader } from 'react-spinners'
import styled from 'styled-components'

import { theme } from '../../theme'

import { Card } from '~components/securities/Card'
import { SecurityStatus } from '~enums'
import { useSecurities } from '~state'
import { Headline2 } from '~ui'
import { mediaQuery } from '~utils'

const Col = styled(Bs.Col)`
  margin-bottom: 16px !important;
  ${mediaQuery.greaterThan('sm')`
        margin-bottom: 40px !important;
    `}
`

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  width: 100%;
`

export const Securities = () => {
  const securities = useSecurities()

  const { t } = useTranslation('security')

  return !securities ? (
    <LoaderWrapper>
      <SyncLoader color={theme.$primary} />
    </LoaderWrapper>
  ) : (
    <>
      <Bs.Row>
        <>
          {securities
            .filter((s) => s.status === SecurityStatus.ACTIVE)
            .map((s) => (
              <Col xs={12} md={6} lg={4} key={s.id}>
                <Card {...s} />
              </Col>
            ))}
          {securities
            .filter((s) => s.status === SecurityStatus.SOON)
            .map((s) => (
              <Col xs={12} md={6} lg={4} key={s.id}>
                <Card disabled={!s.contract} {...s} />
              </Col>
            ))}
        </>
      </Bs.Row>
      <Bs.Row>
        <Col xs={12}>
          <Headline2>{t('Finished')}</Headline2>
        </Col>
        {securities
          .filter((s) => s.status === SecurityStatus.FINISHED)
          .map((s) => (
            <Col xs={12} md={6} lg={4} key={s.id}>
              <Card disabled={!s.contract} {...s} />
            </Col>
          ))}
      </Bs.Row>
    </>
  )
}
