import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import { Header } from './Header'

import {
  HoverCard as HoverCardUI,
  HoverCardProps,
  Headline4,
  BodyL,
  BodyM,
  Icon
} from '~components'
import { RaisedProgress } from '~components/security/MainInfo/Info/RaisedProgress'
import { routes } from '~config'
import { MediaQueryBreakpoint, SecurityStatus } from '~enums'
import { Security } from '~interfaces'
import { mediaQuery, NumberFormatter } from '~utils'

const Divider = styled.div`
  margin: 30px 0;
  border-bottom: 2px solid ${({ theme }) => theme.colors.secondaryShade1};
`

const StyledHeader = styled(Header)`
  margin-bottom: 30px;
`
const Arrow = styled(Icon)`
  margin-top: 30px;
`

const Title = styled(Headline4)`
  margin-bottom: 10px;
`

const SubTitle = styled(BodyL)`
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.secondary};
`

const Description = styled(BodyM)`
  margin-bottom: 30px !important;
  height: 125px;
  ${mediaQuery.lessThan('md')`
    height: 80px;
  `}
`

const Label = styled(BodyM)`
  color: ${({ theme }) => theme.colors.secondary};
`

const Value = styled(BodyL).attrs({ $bold: true })`
  color: ${({ theme }) => theme.colors.white};
`

const Stat = styled.div`
  display: flex;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`
const CountryIcon = styled.img.attrs({ alt: 'Raised country' })`
  width: 18px;
  ${mediaQuery.greaterThan('sm')`
    width: 22px;
  `}
`

const HoverCard = styled(({ disabled, ...props }: HoverCardProps & { disabled?: boolean }) => (
  <HoverCardUI {...props} />
))`
  ${({ theme, disabled }) =>
    !disabled &&
    `
      cursor: pointer;
      &:hover {
        box-shadow: -11px 11px 4px ${theme.colors.tertiaryShade7};
      }
  `}
`

export const Card = ({
  status,
  color,
  name,
  description,
  countryIconURL,
  city,
  id,
  contract,
  annualYieldPercent,
  foundationYear,
  valuation,
  logoURL,
  disabled
}: Security & { disabled?: boolean }) => {
  const { t } = useTranslation('security')
  const history = useHistory()
  const isMd = useMediaQuery({ maxWidth: MediaQueryBreakpoint.md })
  const [open, setOpen] = useState(false)

  return (
    <HoverCard
      hoverStyle="gradient"
      withBorder
      disabled={disabled}
      className="justify-content-start"
      onClick={() => !disabled && history.push(routes.neoBroker + '/' + id)}
    >
      <StyledHeader status={status} rawBgColor={color} logoUrl={logoURL} />
      <Title>{name}</Title>
      <SubTitle className="d-block">{t('In Partnership with Ambisafe')}</SubTitle>
      <Description>{description}</Description>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <CountryIcon className="mr-2" src={countryIconURL} />
          <Value>{city}</Value>
        </div>
      </div>
      {(!isMd ? true : open) && (
        <>
          <Divider />
          {contract && status === SecurityStatus.ACTIVE && (
            <RaisedProgress
              value={contract.raised}
              initialPrice={contract.initialPrice}
              total={contract.total}
              decimals={contract.decimals}
              bgColor="secondaryShade5"
              progressColor="secondaryShade2"
            />
          )}
          <Stat>
            <Label>{t('Valuation')}:</Label>
            <Value>${NumberFormatter.toText(valuation, 1)}</Value>
          </Stat>
          <Stat>
            <Label>{t('Current Annual Yield')}:</Label>
            <Value>+{annualYieldPercent}%</Value>
          </Stat>
          <Stat>
            <Label>{t('Founded')}:</Label>
            <Value>{foundationYear}</Value>
          </Stat>
        </>
      )}
      {isMd && (
        <div
          className="d-flex justify-content-center"
          onClick={(e) => {
            e.stopPropagation()
            setOpen(!open)
          }}
        >
          <Arrow name="shortArrow" rotate={open ? 180 : 0} />
        </div>
      )}
    </HoverCard>
  )
}
