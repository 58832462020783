import { TransactionResponse } from '@ethersproject/providers'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'

import { AddressedPayloadAction } from './interfaces'
import {
  VaultsSetAllowanceAction,
  VaultsApproveAction,
  VaultsApproveSuccessAction,
  VaultsApproveFailureAction,
  VaultsSetCallInfoAction,
  VaultsExecuteClaimAction,
  VaultsClaimFailedAction,
  VaultsClaimExecutedAction,
  VaultsSetAveragePriceAction
} from './vaults'

import { TickerStaking } from '~enums'
import { StaticVault } from '~interfaces'

export type PositionCallInfo = {
  unstaking: boolean
  unstakeCall?: () => Promise<TransactionResponse>
  unstakeGas?: BigNumber
}

export type StaticVaultsState = Record<
  string,
  StaticVault & {
    positionsCallsInfo: PositionCallInfo[]
    staking: boolean
    claiming: boolean
    approving: boolean
    stakeCall?: () => Promise<TransactionResponse>
    claimCall?: () => Promise<TransactionResponse>
    form: {
      stakeGas?: BigNumber
      stakeValue: BigNumber
      spendTicker: TickerStaking
      spendValue: string
      averagePrice?: BigNumber
    }
  }
>

export type AddressedPositionIndexedPayloadAction = AddressedPayloadAction<{ index: number }>

export type ExecuteStakePositionAction = AddressedPayloadAction
export type StakePositionFailedAction = AddressedPayloadAction
export type StakePositionExecutedAction = AddressedPayloadAction
export type SetStakePositionValueAction = AddressedPayloadAction<{ value: BigNumber }>
export type SetSpendPositionAction = AddressedPayloadAction<{
  value: string
}>
export type SetSpendTickerPositionAction = AddressedPayloadAction<{
  ticker: TickerStaking
}>

export type ExecuteUnstakeFromPositionAction = AddressedPositionIndexedPayloadAction
export type UnstakePositionFailedAction = AddressedPositionIndexedPayloadAction
export type UnstakePositionExecutedAction = AddressedPositionIndexedPayloadAction

const initialState: StaticVaultsState = {}

export const staticVaultsSlice = createSlice({
  name: 'staticVaults',
  initialState: initialState,
  reducers: {
    startListening: (state) => state,
    endListening: (state) => state,
    set: (state, action: PayloadAction<StaticVaultsState>) => {
      return action.payload
    },
    setAllowance: (
      state,
      { payload: { address, ticker, allowance } }: VaultsSetAllowanceAction
    ) => {
      state[address].allowances[ticker] = allowance
    },
    approve: (state, { payload: { spender } }: VaultsApproveAction) => {
      state[spender].approving = true
    },
    approveSuccess: (state, { payload: { address } }: VaultsApproveSuccessAction) => {
      state[address].approving = false
    },
    approveFailure: (state, { payload: { address } }: VaultsApproveFailureAction) => {
      state[address].approving = false
    },
    executeStake: (state, action: ExecuteStakePositionAction) => {
      state[action.payload.address].staking = true
    },
    stakeFailed: (state, action: StakePositionFailedAction) => {
      state[action.payload.address].staking = false
    },
    stakeExecuted: (state, action: StakePositionExecutedAction) => {
      state[action.payload.address].staking = false
      state[action.payload.address].form.stakeValue = new BigNumber(0)
    },
    executeUnstake: (state, action: ExecuteUnstakeFromPositionAction) => {
      state[action.payload.address].positionsCallsInfo[action.payload.index].unstaking = true
    },
    unstakeFailed: (state, action: UnstakePositionFailedAction) => {
      state[action.payload.address].positionsCallsInfo[action.payload.index].unstaking = false
    },
    unstakeExecuted: (state, action: UnstakePositionExecutedAction) => {
      state[action.payload.address].positionsCallsInfo[action.payload.index].unstaking = false
    },
    setStakeValue: (state, { payload: { address, value } }: SetStakePositionValueAction) => {
      state[address].form.stakeValue = value
    },
    setStakeCallInfo: (state, { payload: { address, call, gas } }: VaultsSetCallInfoAction) => {
      state[address].form.stakeGas = gas
      state[address].stakeCall = call
    },
    executeClaim: (state, action: VaultsExecuteClaimAction) => {
      state[action.payload.address].claiming = true
    },
    claimFailed: (state, action: VaultsClaimFailedAction) => {
      state[action.payload.address].claiming = false
    },
    claimExecuted: (state, action: VaultsClaimExecutedAction) => {
      state[action.payload.address].claiming = false
    },
    setSpend: (state, { payload: { address, value } }: SetSpendPositionAction) => {
      state[address].form.stakeGas = undefined
      state[address].stakeCall = undefined
      state[address].form.spendValue = value
    },
    setTicker: (state, { payload: { address, ticker } }: SetSpendTickerPositionAction) => {
      state[address].form = {
        ...state[address].form,
        spendTicker: ticker,
        spendValue: '',
        stakeGas: undefined
      }
      state[address].stakeCall = undefined
    },
    setAveragePrice: (state, { payload: { address, value } }: VaultsSetAveragePriceAction) => {
      state[address].form.averagePrice = value
    },
    reset: (state) => {
      Object.keys(state).forEach((address) => {
        state[address].allowances = {}
        state[address].form = {
          spendValue: '',
          spendTicker: state[address].stakeTicker,
          averagePrice: undefined,
          stakeValue: new BigNumber(0),
          stakeGas: undefined
        }
        state[address].claimCall = undefined
        state[address].stakeCall = undefined
      })
    }
  }
})

export const { actions: staticVaultsActions, reducer: staticVaultsReducer } = staticVaultsSlice
