import * as Bs from '@bootstrap-styled/v4'
import styled from 'styled-components'

import { mediaQuery } from '~utils'

export const MarginWrapper = styled.div`
  margin-top: 30px;
  ${mediaQuery.lessThan('lg')`
  margin-top: 20px;`}
`

export const SmartMargin = styled.div<{ left?: boolean; right?: boolean; top?: boolean }>`
  ${({ left, right }) => mediaQuery.greaterThan('lg')`
    ${left && 'margin-left: 5px;'}
    ${right && 'margin-right: 5px;'}}
  `}
  ${({ top }) => (top ? 'margin-top: 10px;' : 'margin-top: 0;')}
`

export const ColMargined = styled(Bs.Col)<{ order?: number }>`
  order: ${({ order }) => order ?? 999};
  margin-bottom: 16px !important;
  ${mediaQuery.greaterThan('sm')`
      margin-bottom: 40px !important;
  `}
`
