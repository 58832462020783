import createSagaMiddleware from 'redux-saga'

import { toastError } from '~utils'

export const sagaMiddleware = createSagaMiddleware({
  onError: (error, sagaStack) => {
    console.log('Saga stack:', sagaStack)
    console.error(error)
    toastError(error.constructor.name, error.message)
  }
})
