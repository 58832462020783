import * as Bs from '@bootstrap-styled/v4'
import chunk from 'lodash.chunk'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import SliderUi from 'react-slick'
import { SyncLoader } from 'react-spinners'
import styled from 'styled-components'

import { theme } from '../../../theme'

import { Card } from './Card'

import { MediaQueryBreakpoint } from '~enums'
import { useNews } from '~state'
import { CarouselControlButtons, Headline2 } from '~ui'
import { mediaQuery } from '~utils'

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  ${mediaQuery.lessThan('md')`
   margin-bottom: 20px;
  `}
`

const Slider = styled(SliderUi)`
  & .slick-slide {
    height: 100%;
    box-sizing: border-box;
  }
`

export const News = () => {
  const news = useNews()
  const slider = useRef<SliderUi>(null)
  const { t } = useTranslation('home')
  const isMd = useMediaQuery({ maxWidth: MediaQueryBreakpoint.md })
  const CHUNK_LENGTH = isMd ? 1 : 3
  return (
    <>
      <TitleWrapper>
        <Headline2>{t('Recent news')}</Headline2>
        <CarouselControlButtons
          onPrevClick={() => slider.current?.slickPrev()}
          onNextClick={() => slider.current?.slickNext()}
        />
      </TitleWrapper>
      {news.loading ? (
        <div className="d-flex justify-content-center">
          <SyncLoader color={theme.$primary} />
        </div>
      ) : (
        <Slider ref={slider} arrows={false}>
          {chunk(news.data, CHUNK_LENGTH).map((chunk, i) => (
            <div>
              <Bs.Row className="d-flex" key={i}>
                {chunk.map((c, j) => (
                  <Bs.Col xs={12} md={4} key={j}>
                    <Card {...c} />
                  </Bs.Col>
                ))}
              </Bs.Row>
            </div>
          ))}
        </Slider>
      )}
    </>
  )
}
