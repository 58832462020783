import { TransactionResponse } from '@ethersproject/providers'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TokenAmount } from '@uniswap/sdk'
import BigNumber from 'bignumber.js'

import { UniswapTokens } from '~config'
import { TickerBEP20, TickerAddToLPFrom, Ticker, TickerAddToLPFromWithoutBNB } from '~enums'

export enum LPAction {
  Home = 'home',
  AddLiquidity = 'addLiquidity',
  RemoveLiquidity = 'removeLiquidity',
  Confirm = 'confirm'
}

export interface LPState {
  lastReserves: {
    busd: BigNumber
    ltt: BigNumber
  }
  reserves: {
    busd: BigNumber
    ltt: BigNumber
  }
  volume: number
  balance: BigNumber
  totalSupply: BigNumber
  allowances: Partial<Record<TickerAddToLPFromWithoutBNB, BigNumber>>
  approving: boolean
  executing: boolean
  provideLiquidityCall?: () => Promise<TransactionResponse>
  form: {
    state: LPAction
    ticker: TickerAddToLPFrom
    value: string
    lttValue: TokenAmount
    busdValue: TokenAmount
    provideLiquidityGas?: BigNumber
  }
}

export type LPSetUnlocked = PayloadAction<BigNumber>

export type LPSetLPCacheInfo = PayloadAction<{
  volume: number
  reserves: { busd: BigNumber; ltt: BigNumber }
}>

export type LPSetReserves = PayloadAction<{
  busd: BigNumber
  ltt: BigNumber
}>

export type LPSetBalance = PayloadAction<BigNumber>
export type LPSetAllowance = PayloadAction<{
  ticker: TickerAddToLPFromWithoutBNB
  allowance: BigNumber
}>
export type LPSetBigNumber = PayloadAction<{
  value: BigNumber
}>

export type LPApprove = PayloadAction<{ ticker: TickerAddToLPFromWithoutBNB; spender: string }>
export type LPApproveSuccess = PayloadAction<TickerAddToLPFromWithoutBNB>
export type LPApproveFailed = PayloadAction

export type LPSetValue = PayloadAction<string>
export type LPSetTicker = PayloadAction<TickerAddToLPFrom>

export type LPSetMaxValue = PayloadAction<TickerAddToLPFrom>

export type LPSetProvideValues = PayloadAction<{ ltt: TokenAmount; busd: TokenAmount }>

export type LPSetCallInfo = PayloadAction<{
  gas: BigNumber
  call: () => Promise<TransactionResponse>
}>

export type LPExecuteProvide = PayloadAction
export type LPProvideSuccess = PayloadAction
export type LPProvideFailed = PayloadAction

export type LPSetFormState = PayloadAction<LPAction>

const initialState: LPState = {
  reserves: {
    busd: new BigNumber(0),
    ltt: new BigNumber(0)
  },
  lastReserves: {
    busd: new BigNumber('0'),
    ltt: new BigNumber('0')
  },
  volume: 0,
  totalSupply: new BigNumber(0),
  balance: new BigNumber(0),
  allowances: {
    [TickerBEP20.BUSD]: new BigNumber(0),
    [TickerBEP20.WBNB]: new BigNumber(0)
  },
  executing: false,
  approving: false,
  form: {
    state: LPAction.Home,
    ticker: Ticker.BUSD,
    value: '',
    lttValue: new TokenAmount(UniswapTokens.LTT, '0'),
    busdValue: new TokenAmount(UniswapTokens.BUSD, '0')
  }
}

export const lpSlice = createSlice({
  name: 'lp',
  initialState,
  reducers: {
    startListening: (state) => state,
    endListening: (state) => state,
    setCachedInfo: (state, action: LPSetLPCacheInfo) => {
      state.lastReserves = action.payload.reserves
      state.volume = action.payload.volume
    },
    setReserves: (state, action: LPSetReserves) => {
      state.reserves = action.payload
    },
    setAllowance: (state, action: LPSetAllowance) => {
      state.allowances[action.payload.ticker] = action.payload.allowance
    },
    setBalance: (state, action: LPSetBigNumber) => {
      state.balance = action.payload.value
    },
    setTotalSupply: (state, action: LPSetBigNumber) => {
      state.totalSupply = action.payload.value
    },
    approve: (state, _action: LPApprove) => {
      state.approving = true
    },
    approveSuccess: (state, _action: LPApproveSuccess) => {
      state.approving = false
    },
    approveFailure: (state, _action: LPApproveFailed) => {
      state.approving = false
    },
    setValue: (state, action: LPSetValue) => {
      state.form.provideLiquidityGas = undefined
      state.provideLiquidityCall = undefined
      state.form.value = action.payload
    },
    setTicker: (state, action: LPSetTicker) => {
      state.form = {
        ...state.form,
        ticker: action.payload,
        value: '',
        provideLiquidityGas: undefined
      }
      state.provideLiquidityCall = undefined
    },
    setMaxValue: (state, action: LPSetMaxValue) => {
      state.form.ticker = action.payload
    },
    setProvideValues: (state, action: LPSetProvideValues) => {
      state.form.lttValue = action.payload.ltt
      state.form.busdValue = action.payload.busd
    },
    setProvideLiquidityCallInfo: (state, action: LPSetCallInfo) => {
      state.form.provideLiquidityGas = action.payload.gas
      state.provideLiquidityCall = action.payload.call
    },
    executeProvideLP: (state, _action: LPExecuteProvide) => {
      state.executing = true
    },
    provideLPExecuted: (state, _action: LPProvideSuccess) => {
      state.executing = false
    },
    provideLPFailed: (state, _action: LPProvideFailed) => {
      state.executing = false
    },
    setFormState: (state, action: LPSetFormState) => {
      state.form.state = action.payload
    },
    reset: (state) => {
      state.allowances = {}
      state.form = {
        ...state.form,
        ticker: Ticker.BUSD,
        value: '',
        provideLiquidityGas: undefined
      }
    }
  }
})

export const { actions: lpActions, reducer: lpReducer } = lpSlice
