import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Headline4 } from '~components'
import { SocialMedia, OtherSocialMedia } from '~components/socials'
import { mediaQuery } from '~utils'

const StyledSocialMedia = styled(SocialMedia)`
  ${mediaQuery.lessThan('md')`
    flex-direction:column;
    gap:20px;
    align-items:start !important;
   `};
  & > div {
    &:not(:last-child) {
      padding-right: 30px;
    }
  }
`

export const Social = styled(({ className }: { className?: string }) => {
  const { t } = useTranslation('social')
  return (
    <div className={className}>
      <Headline4>{t("We're on social media")}:</Headline4>
      <StyledSocialMedia />
      <OtherSocialMedia />
    </div>
  )
})`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  ${mediaQuery.lessThan('md')`
  flex-direction:column;
  gap:30px;
 
 `};
`
