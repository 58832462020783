import React from 'react'
import { useTranslation } from 'react-i18next'

import { Card } from './Card'
import { Col } from './Col'
import { Row } from './Row'

import { getProducts } from '~config'

export const AvailableProducts = () => {
  const { t } = useTranslation(['products', 'common'])
  const PRODUCTS = getProducts(t, 'available')

  const odd = PRODUCTS.filter((v, i) => (i + 1) % 2)
  const even = PRODUCTS.filter((v, i) => !((i + 1) % 2))

  return (
    <Row>
      <Col>
        {odd.map((p, i) => (
          <Card {...p} key={i} />
        ))}
      </Col>
      <Col>
        {even.map((p, i) => (
          <Card {...p} key={i} />
        ))}
      </Col>
    </Row>
  )
}
