import * as Bs from '@bootstrap-styled/v4'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'

import { MediaQueryBreakpoint } from '~enums'
import { useActiveWeb3React } from '~hooks'
import { Card, PrimaryButton, SecondaryButton, AssetInput, Headline3, BodyM, Icon } from '~ui'
import { registerCustomToken } from '~utils'

const Title = styled(Headline3)`
  margin-bottom: 30px;
`

const Label = styled(BodyM)`
  color: ${({ theme }) => theme.colors.secondary};
  margin-bottom: 10px;
  margin-top: 30px;
`

const IconWrapper = styled.div.attrs({ withBorder: true })`
  height: 50px;
  width: 100%;
  cursor: pointer;
  max-width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  margin-left: 15px;
  border: ${({ theme }) => `1px solid ${theme.colors.secondaryShade1}`};
  background-color: ${({ theme }) => theme.colors.secondaryShade1};
`

const Button = styled(PrimaryButton)`
  flex-grow: 1;
`

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 15px;
`

export const ContractInfo = ({
  address,
  decimals,
  ticker,
  icon
}: {
  address: string
  ticker: string
  decimals: number
  icon: string
}) => {
  const { t } = useTranslation('securities')
  const { account } = useActiveWeb3React()
  const isMd = useMediaQuery({ maxWidth: MediaQueryBreakpoint.md })

  return (
    <Card withBorder className="h-auto">
      <Bs.Form>
        <Title>{t(`${ticker} Contract Address`)}</Title>
        <Wrapper>
          <Button onClick={() => window.open(`https://bscscan.com/address/${address}`, '_blank')}>
            {`${t('Watch on BSCScan')} →`}
          </Button>
          {!isMd && (
            <IconWrapper
              onClick={() =>
                window.open('https://docs.localtrade.cc/products/neobroker-smart-fund', '_blank')
              }
            >
              <Icon name="info" />
            </IconWrapper>
          )}
        </Wrapper>
        {account && (
          <SecondaryButton
            wide
            outline
            onClick={() => registerCustomToken(address, ticker, decimals, icon)}
          >{`${t(`Add ${ticker} to MetaMask`)} →`}</SecondaryButton>
        )}
        <Label>{t('Add to other wallet')}</Label>
        <AssetInput address={address} src={icon} />
      </Bs.Form>
    </Card>
  )
}
