import React from 'react'
import styled from 'styled-components'

import { Card as CardUI } from '../../ui'

import { icons } from './icons'

import { mediaQuery } from '~utils'

export enum WalletProvider {
  BSC,
  Metamask,
  WalletConnect
}

const ProviderIcon = {
  [WalletProvider.BSC]: icons.bsc,
  [WalletProvider.Metamask]: icons.metamask,
  [WalletProvider.WalletConnect]: icons.walletconnect
}

const ProviderText = {
  [WalletProvider.BSC]: 'BinanceChain',
  [WalletProvider.Metamask]: 'Metamask',
  [WalletProvider.WalletConnect]: 'WalletConnect'
}

const Card = styled(CardUI)`
  cursor: pointer;
  background: ${({ theme }) => theme.colors.secondary};
`

const CardBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mediaQuery.lessThan('md')`
  flex-direction: row;
  justify-content: space-around;
  `}
`

const StyledImg = styled.img`
  width: 100px;
  height: 100px;
  ${mediaQuery.lessThan('md')`
  width: 50px;
  height: 50px;
  `}
`

export const WalletProviderCard: React.FC<{
  provider: WalletProvider
  onClick: () => unknown
}> = ({ provider, onClick, ...props }) => {
  return (
    <Card onClick={onClick} {...props}>
      <CardBlock>
        <StyledImg alt="provider icon" src={ProviderIcon[provider]} />
        <span>{ProviderText[provider]}</span>
      </CardBlock>
    </Card>
  )
}
