import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { web3Actions } from '../store'

import { useActiveWeb3React } from '~hooks'

export const useWeb3Update = () => {
  const { account, library } = useActiveWeb3React()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(web3Actions.set({ account, library }))
  }, [account, library, dispatch])
}
