import BigNumber from 'bignumber.js'
import { useTranslation } from 'react-i18next'

import { StaticVault } from '~interfaces'
import { CircularProgressBar } from '~ui'

export const CircularOccupiedProgress = ({
  vault,
  progress: originalProgress,
  ...props
}: {
  vault: StaticVault
  progress?: BigNumber
}) => {
  const { t } = useTranslation('vault')
  const progress =
    originalProgress ??
    (vault.contract.capacity.eq(0)
      ? 100
      : vault.contract.stakedTotal.dividedBy(vault.contract.capacity).multipliedBy(100))
  return (
    <CircularProgressBar
      strokeWidth={10}
      size={121}
      percentage={Number(progress.toFixed(0))}
      label={t('Occupied')}
    />
  )
}
