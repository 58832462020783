import { useState } from 'react'
import SliderUi from 'react-slick'
import styled, { keyframes } from 'styled-components'

import { RoadmapBanner } from './RoadmapBanner'
import { UtilityBanner } from './UtilityBanner'
import { WalletBanner } from './WalletBanner'

const Slider = styled(SliderUi)`
  & .slick-slide {
    height: 100%;
    box-sizing: border-box;
  }
  .slick-dots {
    li {
      button {
        ::before {
          color: #fff;
        }
      }
    }
  }
`

const BANNERS_SPEED = 10000

const rotate = keyframes`
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
`

const Progress = styled.div`
  height: 4px;
  position: absolute;
  bottom: 20px;
  width: 90%;
  &::after {
    content: '';
    animation: ${rotate} ${BANNERS_SPEED / 1000 - 0.5}s linear infinite;
    position: absolute;
    top: 0;
    left: 0;
    height: 4px;
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.secondaryShade5};
  }
`

export const BannersCarousel = () => {
  const [current, setCurrent] = useState(0)
  return (
    <Slider
      infinite
      autoplay
      autoplaySpeed={BANNERS_SPEED}
      arrows={false}
      dots={true}
      pauseOnDotsHover={false}
      pauseOnFocus={false}
      pauseOnHover={false}
      afterChange={setCurrent}
    >
      {[<WalletBanner />, <UtilityBanner />, <RoadmapBanner />].map((b, i) => (
        <div className="position-relative d-flex justify-content-center">
          {b}
          {i === current && <Progress />}
        </div>
      ))}
    </Slider>
  )
}

export * from './WalletBanner'
export * from './RoadmapBanner'
export * from './UtilityBanner'
