import BigNumber from 'bignumber.js'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { ConnectButton } from '../../ConnectButton'

import { Ticker } from '~enums'
import { useActiveWeb3React } from '~hooks'
import { useAccountModal, useVaults } from '~state'
import { Card, Headline1, Headline4 } from '~ui'
import { NumberFormatter } from '~utils'

const Title = styled(Headline4)`
  color: ${({ theme }) => theme.colors.secondary};
  margin-bottom: 10px;
`

const ButtonWrapper = styled.div`
  margin-top: 20px;
`

export const TotalLocked = () => {
  const { t } = useTranslation('balance')
  const { account } = useActiveWeb3React()
  const vaults = useVaults()
  const lttVaults = vaults.filter((v) => v.stakeTicker === Ticker.LTT)
  const [, { open: openAccountModal }] = useAccountModal()
  return (
    <Card withBorder>
      <div>
        <Title>{t('Total Volume Locked')}:</Title>
      </div>
      <Headline1>
        {NumberFormatter.formatCustomDecimal(
          lttVaults.reduce((acc, v) => acc.plus(v.contract.stakedTotal), new BigNumber(0)),
          9,
          2
        )}{' '}
        {Ticker.LTT}
      </Headline1>
      {!account && (
        <ButtonWrapper>
          <ConnectButton onClick={openAccountModal} className="w-100" />
        </ButtonWrapper>
      )}
    </Card>
  )
}
