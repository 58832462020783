import BigNumber from 'bignumber.js'
import { SagaIterator } from 'redux-saga'
import { call, put, select } from 'redux-saga/effects'

import { VaultsMethods } from '../methods'

import { ZERO_ADDRESS } from '~config'
import { Ticker } from '~enums'
import { AppState, VaultsActions, VaultsSetSpendAction, VaultsState } from '~state'
import { estimateCall, getCall, getTrade, getTradeValues } from '~state/sagas/utils'
import { NumberFormatter } from '~utils'

export function* onVaultsSetSpend<S extends Record<string, VaultsState>>(
  { payload: { address, value, args } }: VaultsSetSpendAction,
  statePath: keyof AppState,
  actions: VaultsActions<S>
): SagaIterator {
  if (!value || parseFloat(value) === 0)
    return yield put(actions.setStakeValue({ address, value: new BigNumber(0) }))

  const state: AppState = yield select()
  const vaults = state[statePath] as S
  const {
    web3: { library, gasPrice, account },
    contracts: { bep20 },
    inviter,
    tokens
  } = state

  const vault = vaults[address]
  const ticker = vault.form.spendTicker

  if (!library || !gasPrice || !bep20 || !account)
    throw new Error('No library or gasPrice or bep20 or account')

  const spendValue = NumberFormatter.fromDecimal(value, ticker)

  if (tokens.data[ticker].balance.lt(spendValue)) {
    return
  }

  if (
    ticker !== Ticker.BNB &&
    (!vault.allowances[ticker] || vault.allowances[ticker]?.lt(tokens.data[ticker].balance))
  ) {
    return
  }

  if (ticker === vault.stakeTicker) {
    yield put(actions.setStakeValue({ address, value: spendValue }))

    const callArgs = [spendValue.toString(), inviter.value || ZERO_ADDRESS]
    if (args) callArgs.push(...args)

    const stakeGas = yield call(() =>
      estimateCall(vault.contract.value, VaultsMethods.Stake, callArgs)
    )
    const stakeCall = yield call(() =>
      getCall(vault.contract.value, VaultsMethods.Stake, gasPrice, stakeGas, callArgs)
    )

    return yield put(actions.setStakeCallInfo({ address, gas: stakeGas, call: stakeCall }))
  }

  const trade = yield call(() => getTrade(spendValue, ticker, library))

  const { amountOutMin, path, averagePrice } = yield call(() => getTradeValues(trade))

  yield put(actions.setAveragePrice({ address, value: new BigNumber(averagePrice) }))

  yield put(actions.setStakeValue({ address, value: amountOutMin }))

  const deadline = Math.floor(Date.now() / 1000) + 60 * 20 // 20 minutes from the current Unix time

  if (ticker === Ticker.BNB) {
    const callArgs = [amountOutMin.toString(), path, deadline, inviter.value || ZERO_ADDRESS]
    if (args) callArgs.push(...args)
    const stakeGas = yield call(() =>
      estimateCall(vault.contract.value, VaultsMethods.StakeETH, callArgs, spendValue.toString())
    )

    const stakeCall = yield call(() =>
      getCall(
        vault.contract.value,
        VaultsMethods.StakeETH,
        gasPrice,
        stakeGas,
        callArgs,
        spendValue.toString()
      )
    )

    return yield put(actions.setStakeCallInfo({ address, gas: stakeGas, call: stakeCall }))
  }

  const callArgs = [
    spendValue.toString(),
    amountOutMin.toString(),
    path,
    deadline,
    inviter.value || ZERO_ADDRESS
  ]

  if (args) callArgs.push(...args)

  const stakeGas = yield call(() =>
    estimateCall(vault.contract.value, VaultsMethods.StakeAnyToken, callArgs)
  )

  const stakeCall = yield call(() =>
    getCall(vault.contract.value, VaultsMethods.StakeAnyToken, gasPrice, stakeGas, callArgs)
  )

  return yield put(actions.setStakeCallInfo({ address, gas: stakeGas, call: stakeCall }))
}
