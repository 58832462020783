import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import styled from 'styled-components'

import { Icon } from './Icon'
import { Input } from './Input'

import { TickerIcon as TickerIconUI } from '~components/ticker'
import { Ticker } from '~enums'
import { shortenAddress } from '~utils'

const TickerIcon = styled(TickerIconUI)`
  width: 40px;
  height: 40px;
  position: absolute;
  top: 10px;
  left: 10px;
`

const CopyButton = styled.div.attrs(() => ({ children: <Icon name="copy" /> }))`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  right: 10px;
  top: 10px;
  background: ${({ theme }) => theme.colors.secondaryShade1};
  &:hover {
    background: ${({ theme }) => theme.colors.secondaryShade3};
  }
  cursor: pointer;
  border-radius: 4px !important;
`

export const AssetInput = styled(
  ({
    className,
    address,
    ticker,
    src
  }: {
    className?: string
    src?: string
    ticker?: Ticker
    address: string
  }) => {
    return (
      <div className={className}>
        <TickerIcon ticker={ticker} src={src} />
        <Input value={shortenAddress(address)} readonly />
        <CopyToClipboard text={address}>
          <CopyButton />
        </CopyToClipboard>
      </div>
    )
  }
)`
  position: relative;
  input {
    padding-left: 60px !important;
  }
`
