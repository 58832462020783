import * as Bs from '@bootstrap-styled/v4'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import { MarginWrapper, SmartMargin, Button, SecondaryButton } from '../../../components'

import { MediaQueryBreakpoint } from '~enums'

export const ChooseAction = ({ add, remove }: { add: () => void; remove: () => void }) => {
  const { t } = useTranslation(['lp', 'common'])
  const isLg = useMediaQuery({ maxWidth: MediaQueryBreakpoint.lg })

  return (
    <MarginWrapper>
      <Bs.Row noGutters>
        <Bs.Col xs={12} lg={6}>
          <SmartMargin top={isLg} right>
            <SecondaryButton outline wide onClick={remove}>
              {t('Remove Liquidity')}
            </SecondaryButton>
          </SmartMargin>
        </Bs.Col>
        <Bs.Col xs={12} lg={6}>
          <SmartMargin left>
            <Button onClick={add}>+ {t('Add Liquidity')}</Button>
          </SmartMargin>
        </Bs.Col>
      </Bs.Row>
    </MarginWrapper>
  )
}
