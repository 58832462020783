import React from 'react'
import styled from 'styled-components'

import { Icon } from '~ui'

export const Close = styled((props: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div {...props}>
      <Icon name="close" color="white" />
    </div>
  )
})`
  border-radius: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
