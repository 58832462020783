import BigNumber from 'bignumber.js'
import React, { HTMLProps } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Amount } from '../../../components/Amount'
import { Label } from '../../../components/Label'

import { Ticker } from '~enums'
import { NumberFormatter } from '~utils'

export const Footer = styled(
  ({
    reserveBUSD,
    reserveLTT,
    ...props
  }: {
    reserveBUSD: BigNumber
    reserveLTT: BigNumber
  } & HTMLProps<HTMLDivElement>) => {
    const { t } = useTranslation('lp')
    return (
      <div {...props}>
        <Label>{t('Liquidity in Pool')}:</Label>
        <Amount>
          {Ticker.LTT} -{' '}
          {NumberFormatter.toText(NumberFormatter.toDecimal(reserveLTT, Ticker.LTT), 2, true)},{' '}
          {Ticker.BUSD} -{' '}
          {NumberFormatter.toText(NumberFormatter.toDecimal(reserveBUSD, Ticker.BUSD), 2, true)}
        </Amount>
      </div>
    )
  }
)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`
