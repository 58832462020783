import { SagaIterator } from 'redux-saga'
import { put } from 'redux-saga/effects'

import { VaultsActions, VaultsState } from '~state'

export function* onWeb3Set<S extends Record<string, VaultsState>>(
  actions: VaultsActions<S>
): SagaIterator {
  yield put(actions.reset())
}
