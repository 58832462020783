import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'

import { COLLAPSE_SCREEN_WIDTH } from './collapseText'

import { BSC_SCAN_URL, LTT_ADDRESS } from '~config'
import { MediaQueryBreakpoint } from '~enums'
import { A, Icon } from '~ui'
import { mediaQuery } from '~utils'

const CoinMarketCapIcon = styled((props) => <Icon name="coinmarketcap" {...props} />)`
  margin-right: 8px;
  ${mediaQuery.lessThan('sm')`
    margin-right: 0;
  `}
`

const CoinMarketCapLink = styled((props) => {
  const isXl = useMediaQuery({ minWidth: MediaQueryBreakpoint.xl })
  const isLgToBreak = useMediaQuery({
    minWidth: MediaQueryBreakpoint.lg,
    maxWidth: COLLAPSE_SCREEN_WIDTH
  })
  const isMdLg = useMediaQuery({
    minWidth: MediaQueryBreakpoint.md,
    maxWidth: MediaQueryBreakpoint.lg
  })
  return (
    <A
      href="https://coinmarketcap.com/currencies/localtrade"
      color="white"
      target="_blank"
      {...props}
    >
      <CoinMarketCapIcon />
      {(isXl || isMdLg) && !isLgToBreak && 'CoinMarketCap'}
    </A>
  )
})`
  display: flex;
  margin-left: 21px;
  font-weight: 400;
  text-decoration: none;
  ${mediaQuery.lessThan('sm')`
    margin-left: 10px;
  `}
`

const BSCScanIcon = styled((props) => <Icon name="bscscan" {...props} />)`
  margin-right: 8px;
  ${mediaQuery.lessThan('sm')`
    margin-right: 0;
  `}
`

const BSCScanLink = styled((props) => {
  const isXl = useMediaQuery({ minWidth: MediaQueryBreakpoint.xl })
  const isLgToBreak = useMediaQuery({
    minWidth: MediaQueryBreakpoint.lg,
    maxWidth: COLLAPSE_SCREEN_WIDTH
  })
  const isMdLg = useMediaQuery({
    minWidth: MediaQueryBreakpoint.md,
    maxWidth: COLLAPSE_SCREEN_WIDTH
  })
  return (
    <A href={BSC_SCAN_URL + '/token/' + LTT_ADDRESS} color="white" target="_blank" {...props}>
      <BSCScanIcon />
      {(isXl || isMdLg) && !isLgToBreak && 'BscScan'}
    </A>
  )
})`
  display: flex;
  font-weight: 400;
  text-decoration: none;
`

export const LTTInfo = () => (
  <div className="d-flex">
    <BSCScanLink />
    <CoinMarketCapLink />
  </div>
)
