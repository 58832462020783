import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { Config } from '~interfaces/api'

export const config = createApi({
  reducerPath: 'config',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NODE_ENV === 'development' ? '/api' : process.env.REACT_APP_API_URL
  }),
  endpoints: (builder) => ({
    getConfig: builder.query<Config, void>({
      query: () => '/client-config'
    })
  })
})
