import { TransactionResponse } from '@ethersproject/providers'
import {
  AnyAction,
  CaseReducer,
  CaseReducerActions,
  createSlice,
  CreateSliceOptions,
  PayloadAction
} from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'

import { AddressedPayloadAction } from '../interfaces'

import { TickerBEP20, TickerStaking } from '~enums'
import { Vault } from '~interfaces'

export type VaultsExecuteClaimAction = AddressedPayloadAction

export type VaultsClaimFailedAction = AddressedPayloadAction

export type VaultsClaimExecutedAction = AddressedPayloadAction

export type VaultsExecuteStakeAction = AddressedPayloadAction

export type VaultsStakeFailedAction = AddressedPayloadAction

export type VaultsStakeExecutedAction = AddressedPayloadAction

export type VaultsExecuteUnstakeAction = AddressedPayloadAction

export type VaultsUnstakeFailedAction = AddressedPayloadAction

export type VaultsUnstakeExecutedAction = AddressedPayloadAction

export type VaultsSetCallInfoAction = AddressedPayloadAction<{
  call: () => Promise<TransactionResponse>
  gas: BigNumber
}>

export type VaultsApproveSuccessAction = AddressedPayloadAction<{ ticker: TickerBEP20 }>

export type VaultsApproveFailureAction = AddressedPayloadAction

export type VaultsApproveAction = PayloadAction<{
  ticker: TickerBEP20
  spender: string
}>

export type VaultsSetStakeValueAction = AddressedPayloadAction<{ value: BigNumber }>

export type VaultsSetSpendAction = AddressedPayloadAction<{
  value: string
  args?: string[]
}>

export type VaultsSetTickerAction = AddressedPayloadAction<{ ticker: TickerStaking }>

export type VaultsSetAllowanceAction = AddressedPayloadAction<{
  ticker: TickerBEP20
  allowance: BigNumber
}>

export type VaultsSetAveragePriceAction = AddressedPayloadAction<{
  value: BigNumber
}>

export type VaultsSetUnstakeValueAction = AddressedPayloadAction<{ value: string }>

export type VaultsState = {
  claiming: boolean
  staking: boolean
  unstaking: boolean
  approving: boolean
  claimCall?: () => Promise<TransactionResponse>
  stakeCall?: () => Promise<TransactionResponse>
  unstakeCall?: () => Promise<TransactionResponse>
  form: {
    stakeGas?: BigNumber
    unstakeGas?: BigNumber
    stakeValue: BigNumber
    spendTicker: TickerStaking
    spendValue: string
    unstakeValue: string
    averagePrice?: BigNumber
  }
} & Vault

export type CaseReducers<State> = {
  [K: string]: CaseReducer<State, PayloadAction<any>>
}
interface VaultsCaseReducers<State> extends CaseReducers<State> {
  startListening: CaseReducer<State, AnyAction>
  endListening: CaseReducer<State, AnyAction>
  approve: CaseReducer<State, VaultsApproveAction>
  approveSuccess: CaseReducer<State, VaultsApproveSuccessAction>
  approveFailure: CaseReducer<State, VaultsApproveFailureAction>
  set: CaseReducer<State, PayloadAction<State>>
  setAllowance: CaseReducer<State, VaultsSetAllowanceAction>
  executeClaim: CaseReducer<State, VaultsExecuteClaimAction>
  claimFailed: CaseReducer<State, VaultsClaimFailedAction>
  claimExecuted: CaseReducer<State, VaultsClaimExecutedAction>
  executeStake: CaseReducer<State, VaultsExecuteStakeAction>
  stakeFailed: CaseReducer<State, VaultsStakeFailedAction>
  stakeExecuted: CaseReducer<State, VaultsStakeExecutedAction>
  executeUnstake: CaseReducer<State, VaultsExecuteUnstakeAction>
  unstakeFailed: CaseReducer<State, VaultsUnstakeFailedAction>
  unstakeExecuted: CaseReducer<State, VaultsUnstakeExecutedAction>
  setStakeValue: CaseReducer<State, VaultsSetStakeValueAction>
  setStakeCallInfo: CaseReducer<State, VaultsSetCallInfoAction>
  setUnstakeValue: CaseReducer<State, VaultsSetUnstakeValueAction>
  setUnstakeCallInfo: CaseReducer<State, VaultsSetCallInfoAction>
  setSpend: CaseReducer<State, VaultsSetSpendAction>
  setTicker: CaseReducer<State, VaultsSetTickerAction>
  setAveragePrice: CaseReducer<State, VaultsSetAveragePriceAction>
  reset: CaseReducer<State, AnyAction>
}

export const createVaultsSlice = <S extends Record<string, VaultsState>>(
  props: CreateSliceOptions<S>
) =>
  createSlice<S, VaultsCaseReducers<S>>({
    ...props,
    reducers: {
      ...props.reducers,
      startListening: (state) => state,
      endListening: (state) => state,
      approve: (state, { payload: { spender } }) => {
        state[spender].approving = true
      },
      approveSuccess: (state, { payload: { address } }) => {
        state[address].approving = false
      },
      approveFailure: (state, { payload: { address } }) => {
        state[address].approving = false
      },
      set: (state, action) => {
        return action.payload
      },
      setAllowance: (state, { payload: { address, ticker, allowance } }) => {
        state[address].allowances[ticker] = allowance
      },
      executeClaim: (state, action) => {
        state[action.payload.address].claiming = true
      },
      claimFailed: (state, action) => {
        state[action.payload.address].claiming = false
      },
      claimExecuted: (state, action) => {
        state[action.payload.address].claiming = false
      },
      executeStake: (state, action) => {
        state[action.payload.address].staking = true
      },
      stakeFailed: (state, action) => {
        state[action.payload.address].staking = false
      },
      stakeExecuted: (state, action) => {
        state[action.payload.address].staking = false
        state[action.payload.address].form.stakeValue = new BigNumber(0)
      },
      executeUnstake: (state, action) => {
        state[action.payload.address].unstaking = true
      },
      unstakeFailed: (state, action) => {
        state[action.payload.address].unstaking = false
      },
      unstakeExecuted: (state, action) => {
        state[action.payload.address].unstaking = false
        state[action.payload.address].form.unstakeValue = ''
      },
      setStakeValue: (state, { payload: { address, value } }) => {
        state[address].form.stakeValue = value
      },
      setStakeCallInfo: (state, { payload: { address, call, gas } }) => {
        state[address].form.stakeGas = gas
        state[address].stakeCall = call
      },
      setUnstakeValue: (state, { payload: { address, value } }) => {
        state[address].form.unstakeValue = value
      },
      setUnstakeCallInfo: (state, { payload: { address, call, gas } }) => {
        state[address].form.unstakeGas = gas
        state[address].unstakeCall = call
      },
      setSpend: (state, { payload: { address, value } }) => {
        state[address].form.stakeGas = undefined
        state[address].stakeCall = undefined
        state[address].form.spendValue = value
      },
      setTicker: (state, { payload: { address, ticker } }) => {
        state[address].form = {
          ...state[address].form,
          spendTicker: ticker,
          spendValue: '',
          stakeGas: undefined
        }
        state[address].stakeCall = undefined
      },
      setAveragePrice: (state, { payload: { address, value } }) => {
        state[address].form.averagePrice = value
      },
      reset: (state) => {
        Object.keys(state).forEach((address) => {
          state[address].allowances = {}
          state[address].form = {
            spendValue: '',
            spendTicker: state[address].stakeTicker,
            unstakeValue: '',
            averagePrice: undefined,
            stakeValue: new BigNumber(0),
            stakeGas: undefined,
            unstakeGas: undefined
          }
          state[address].claimCall = undefined
          state[address].stakeCall = undefined
          state[address].unstakeCall = undefined
        })
      }
    }
  })

export type VaultsActions<State> = CaseReducerActions<VaultsCaseReducers<State>>
