import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Referral } from '~interfaces'

export interface ReferralsState {
  team: {
    data?: Record<string, Referral[] | undefined>
    loadingOf?: string
  }
  inviter: {
    data?: Referral | null
    loading?: boolean
  }
}

type TeamSetAction = PayloadAction<{
  inviter: string
  referrals: Referral[]
}>

type InviterSetAction = PayloadAction<Referral>

export type TeamFetchAction = PayloadAction<string>

type TeamRemoveAction = PayloadAction<string>

const initialState: ReferralsState = {
  team: {},
  inviter: {}
}

export const referralsSlice = createSlice({
  name: 'referrals',
  initialState: initialState,
  reducers: {
    startListening: (state) => state,
    endListening: (state) => state,
    setTeam: (state, action: TeamSetAction) => {
      const newTeam = { ...state.team.data, [action.payload.inviter]: action.payload.referrals }
      return {
        ...state,
        team: {
          data: newTeam
        }
      }
    },
    fetchTeam: (state, action: TeamFetchAction) => {
      state.team.loadingOf = action.payload
    },
    removeTeam: (state, action: TeamRemoveAction) => {
      const newTeam = { ...state.team.data, [action.payload]: undefined }
      return {
        ...state,
        team: {
          data: newTeam
        }
      }
    },
    fetchInviter: (state) => {
      state.inviter.loading = true
    },
    setInviter: (state, action: InviterSetAction) => {
      state.inviter.loading = false
      state.inviter.data = action.payload
    },
    reset: () => {
      return initialState
    }
  }
})

export const { actions: referralsActions, reducer: referralsReducer } = referralsSlice
