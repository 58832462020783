import { TFunction } from 'react-i18next'

import * as images from './images'

import { Advantages } from '~interfaces'

export const getAdvantages = (t: TFunction<'advantages'>): Advantages[] => [
  {
    title: t('Own the keys. Own the funds.'),
    description: t(
      'Connect your own wallet and be in full control of your funds. There are no central intermediaries that hold your private keys. Fail-proof smart contracts secure your operations at all times when using LocalTrade DeFi Lab.'
    ),
    image: { normal: images.own, small: images.ownSmall }
  },
  {
    title: t('Synergetic Sharing Economy'),
    description: t(
      'Being a community-centric platform, LocalTrade has created the infinitely profitable DAO Sharing Economy. Stake LTT tokens to enhance your APY across DeFi lab and to receive additional rewards from your referrals.'
    ),
    image: { normal: images.economy, small: images.economySmall }
  },
  {
    title: t('Hyper-optimized Smart Contracts, Audited by Experts'),
    description: t(
      'Extreme security of our smart contracts is ensured through rigorous internal scrutiny and thorough audits performed by expert security firms.'
    ),
    image: { normal: images.contracts, small: images.contractsSmall }
  }
]
