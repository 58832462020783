import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Card, Icon, Headline4, BodyM } from '~components'
import { mediaQuery } from '~utils'

const Pdf = styled.div`
  width: 60px;
  height: 60px;
  min-width: 60px;
  border-radius: 12px;
  border: 2px solid ${({ theme }) => theme.colors.secondaryShade1};
  margin-right: 20px;
  color: ${({ theme }) => theme.colors.secondary};
`

const DownloadBtn = styled.div`
  width: 60px;
  height: 60px;
  min-width: 60px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  svg {
    height: 24px;
  }
  ${mediaQuery.lessThan('md')`
    width: 100%;
    margin-top:12px;
`}
`

const InnerCard = styled(Card)`
  padding: 20px;
  ${mediaQuery.greaterThan('md')`
   flex-direction:row !important;
`}
`

export const Doc = ({ title, link }: { title: string; link: string }) => {
  const { t } = useTranslation('common')
  return (
    <InnerCard withBorder className="h-auto justify-content-between">
      <div className="d-flex  align-items-center">
        <Pdf className="d-flex justify-content-center align-items-center">
          <BodyM $bold>{t('PDF')}</BodyM>
        </Pdf>
        <Headline4>{title}</Headline4>
      </div>
      <DownloadBtn
        className="d-flex justify-content-center align-items-center"
        onClick={() => window.open(link, '_blank')}
      >
        <Icon name="download" />
      </DownloadBtn>
    </InnerCard>
  )
}
