import { BigNumber } from 'bignumber.js'

import { BaseApi } from './BaseApi'

import { ReferralDataApi } from '~interfaces'

export class ReferralsApi extends BaseApi {
  async getTeam(address: string): Promise<ReferralDataApi[]> {
    const response = await fetch(`${this.baseUrl}/referrals/?address=${address}`)
    const data = await response.json()
    return data.map((d: any) => ({
      ...d,
      staked: new BigNumber(d.staked)
    }))
  }

  async getInviter(address: string): Promise<ReferralDataApi | null> {
    const response = await fetch(`${this.baseUrl}/referrals/inviter/?address=${address}`)
    if (response.status === 204) return null
    const data = await response.json()
    return { ...data, staked: new BigNumber(data.staked) }
  }
}
