import * as Bs from '@bootstrap-styled/v4'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { BalanceInfo } from './BalanceInfo'
import { DAOInfo } from './DAOInfo'
import { LocalBalanceInfo } from './LocalBalanceInfo'
import { RankInfo } from './RankInfo'
import { ReferralLink } from './ReferralLink'
import { ReferralsTree } from './ReferralsTree'
import { TokenInfo } from './TokenInfo'

import { Headline3, Card, SecondaryButton } from '~ui'
import { mediaQuery } from '~utils'

const CardWithGap = styled(Card)`
  gap: 30px;
  ${mediaQuery.lessThan('md')`
    gap: 20px;
      `}
`

const Col = styled(Bs.Col)`
  padding: 0 15px !important;
`

const Row = styled(Bs.Row)`
  margin: 0 -15px !important;
  ${mediaQuery.lessThan('md')`
    gap: 20px;
      `}
`

const Button = styled((props) => <SecondaryButton primaryHover {...props} />)`
  background: ${({ theme }) => theme.colors.tertiaryShade8} !important;
  padding: 11px 62.5px !important;
  width: fit-content;
`

export enum StakingAndDaoRewardTabsOption {
  BALANCE,
  DAO_TREE
}

const BalanceTab = ({ onDAOButtonClick }: { onDAOButtonClick: () => void }) => {
  const { t } = useTranslation('balance')
  return (
    <CardWithGap withBorder>
      <Headline3>{t('Your balance')}</Headline3>
      <Row>
        <Col xs={12} md={4}>
          <RankInfo onDAOButtonClick={() => onDAOButtonClick()} />
        </Col>
        <Col xs={12} md={4}>
          <BalanceInfo />
        </Col>
        <Col xs={12} md={4}>
          <TokenInfo />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <DAOInfo />
        </Col>
        <Col xs={12} md={6}>
          <LocalBalanceInfo />
        </Col>
      </Row>
    </CardWithGap>
  )
}

const ReferralTreeTab = ({ onBackToBalancesClick }: { onBackToBalancesClick: () => void }) => {
  const { t } = useTranslation('balance')
  return (
    <CardWithGap withBorder>
      <Headline3>{t('Team Statistics')}</Headline3>
      <Button onClick={() => onBackToBalancesClick()}>← {t('Back to Balances')}</Button>
      <Row>
        <Col xs={12} md={6}>
          <ReferralLink />
        </Col>
        <Col xs={12} md={6}>
          <DAOInfo />
        </Col>
      </Row>
      <ReferralsTree />
    </CardWithGap>
  )
}

export const BalanceAndReferralsTree = () => {
  const [activeTab, setTab] = useState(StakingAndDaoRewardTabsOption.BALANCE)
  return activeTab === StakingAndDaoRewardTabsOption.BALANCE ? (
    <BalanceTab onDAOButtonClick={() => setTab(StakingAndDaoRewardTabsOption.DAO_TREE)} />
  ) : (
    <ReferralTreeTab onBackToBalancesClick={() => setTab(StakingAndDaoRewardTabsOption.BALANCE)} />
  )
}
