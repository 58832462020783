import { useDispatch, useSelector } from 'react-redux'

import { AppState, userActions } from '../store'

import { User } from '~interfaces'

export function useUpdatePersonal(): [boolean, (payload: User['personal']) => void] {
  const userState = useSelector((state: AppState) => state.user)
  const dispatch = useDispatch()

  const updatePersonal = (payload: User['personal']) =>
    dispatch(userActions.updatePersonal(payload))

  return [userState.updatePersonal.loading, updatePersonal]
}
