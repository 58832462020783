import BigNumber from 'bignumber.js'

import { TickerBEP20, TickerStaking } from '~enums'
import { CentralizedVault, DynamicVault } from '~interfaces'

const decimalMultiplier = new BigNumber(10).pow(12)
const referralSharePercent = 0.1

const updateFarmFullValue = (
  vault: CentralizedVault | DynamicVault,
  final = false,
  possibleStakeValue?: BigNumber
) => {
  const dateNow = new BigNumber(Date.now()).dividedBy(1000)
  const endDate = new BigNumber(vault.contract.endDate.getTime()).dividedBy(1000)
  const creationDate = new BigNumber(vault.contract.creationDate.getTime()).dividedBy(1000)
  const acc = vault.contract.accRewardLTTperStakedLTT
  const lastRewardTimestamp = vault.contract.lastRewardTimestamp
  const reward = new BigNumber(final ? endDate : dateNow)
    .minus(lastRewardTimestamp)
    .multipliedBy(vault.contract.totalReward)
    .dividedBy(endDate.minus(creationDate))

  if (!vault.contract.stakedTotal.eq(0))
    return acc.plus(
      reward
        .multipliedBy(decimalMultiplier)
        .dividedBy(vault.contract.stakedTotal.plus(possibleStakeValue ?? 0))
    )

  return acc
}

const updateFarm = (vault: CentralizedVault | DynamicVault) => {
  const dateNow = new BigNumber(Date.now()).dividedBy(1000)
  const endDate = new BigNumber(vault.contract.endDate.getTime()).dividedBy(1000)
  const creationDate = new BigNumber(vault.contract.creationDate.getTime()).dividedBy(1000)
  const acc = vault.contract.accRewardLTTperStakedLTT
  const lastRewardTimestamp = vault.contract.lastRewardTimestamp

  if (dateNow.lte(lastRewardTimestamp) || vault.contract.stakedTotal.eq(0)) return acc

  const reward = new BigNumber(dateNow.gte(endDate) ? endDate : dateNow)
    .minus(lastRewardTimestamp)
    .multipliedBy(vault.contract.totalReward)
    .dividedBy(endDate.minus(creationDate))

  return acc.plus(reward.multipliedBy(decimalMultiplier).dividedBy(vault.contract.stakedTotal))
}

export const calculateReward = (
  vault: CentralizedVault | DynamicVault,
  final = false,
  value?: BigNumber
): BigNumber => {
  const acc = final ? updateFarmFullValue(vault, final, value) : updateFarm(vault)
  const reward = (value ?? vault.contract.staked)
    .multipliedBy(acc)
    .minus(vault.contract.debt ?? new BigNumber(0))
    .dividedBy(decimalMultiplier)
    .multipliedBy(1 - referralSharePercent)

  if (reward.gt(0)) return reward
  return new BigNumber(0)
}

export type BaseVaultsProps = {
  approve: (ticker: TickerBEP20) => void
  executeClaim: () => void
  executeStake: () => void
  executeUnstake: () => void
  allowances: Partial<Record<TickerBEP20, BigNumber>>
  stakeFee?: BigNumber
  unstakeFee?: BigNumber
  inviter: string
  isInviterMutable: boolean
  onInviterChanged: (inviter: string) => void
  stakeValue: BigNumber
  unstakeValue: string
  onSetSpend: (value: string) => void
  onSetTicker: (ticker: TickerStaking) => void
  spendValue: string
  spendTicker: TickerStaking
  onSetUnstakeValue: (value: string) => void
  averagePrice: BigNumber | undefined
}
