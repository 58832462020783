import { Pair } from '@uniswap/sdk'
import { SagaIterator } from 'redux-saga'
import { call, put, select } from 'redux-saga/effects'

import { getBUSDPair, getLTTPair, LPBroker } from './utils'

import { Ticker } from '~enums'
import { AppState, lpActions, LPSetValue } from '~state/store'
import { NumberFormatter } from '~utils'

export function* onSetValue({ payload: value }: LPSetValue): SagaIterator {
  if (!value || parseFloat(value) === 0) return

  const {
    web3: { library, gasPrice, account },
    lp: {
      allowances,
      form: { ticker }
    },
    contracts: {
      bep20,
      general: { lpBroker: contract }
    },
    tokens
  }: AppState = yield select()

  if (!library || !gasPrice || !bep20 || !contract || !account)
    throw new Error('No library or gasPrice or bep20 or lpBroker or account')

  const spendValue = NumberFormatter.fromDecimal(value, ticker)

  if (tokens.data[ticker].balance.lt(spendValue)) {
    return
  }

  if (
    ticker !== Ticker.BNB &&
    (!allowances[ticker] || allowances[ticker]?.lt(tokens.data[ticker].balance))
  ) {
    return
  }

  const lttPair: Pair = yield call(getLTTPair, library)
  const busdPair: Pair | null =
    ticker === Ticker.BUSD ? null : yield call(getBUSDPair, ticker, library)
  const lpBroker = new LPBroker(contract, ticker, busdPair, lttPair, spendValue)

  yield put(lpActions.setProvideValues(lpBroker.getProvideValues()))
  const provideLiquidityGas = yield call(() => lpBroker.estimateCall(account))
  const provideLiquidityCall = yield call(() =>
    lpBroker.getProvideLiquidityCall(account, gasPrice, provideLiquidityGas)
  )
  yield put(
    lpActions.setProvideLiquidityCallInfo({
      gas: provideLiquidityGas,
      call: provideLiquidityCall
    })
  )
}
