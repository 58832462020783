import { TransactionResponse } from '@ethersproject/providers'
import BigNumber from 'bignumber.js'
import { Contract } from 'ethers'

import { TickerBuySecurityFrom } from '~enums'
import { getCall, transformBN } from '~state/sagas/utils'

export type EstimatedCall = { gas: BigNumber; execute: () => Promise<unknown> }

export async function estimateTokenizedSecurityBuyCall(
  contract: Contract,
  ticker: TickerBuySecurityFrom,
  value: BigNumber,
  account: string
): Promise<BigNumber> {
  return transformBN(
    await contract.estimateGas[getMethod(ticker)](value.toString(), { from: account })
  )
    .multipliedBy(1.1)
    .decimalPlaces(0)
}

export function getTokenizedSecurityBuyCall(
  contract: Contract,
  ticker: TickerBuySecurityFrom,
  value: BigNumber,
  gasPrice: BigNumber,
  gas: BigNumber
): () => Promise<TransactionResponse> {
  return getCall(contract, getMethod(ticker), gasPrice, gas, [value.toString()])
}

enum Method {
  BUSD = 'buyBUSD',
  LUSD = 'buyLUSD'
}

function getMethod(ticker: TickerBuySecurityFrom): string {
  if (ticker === TickerBuySecurityFrom.BUSD) return Method.BUSD
  return Method.LUSD
}
