import { TokenAmount } from '@uniswap/sdk'
import BigNumber from 'bignumber.js'

import { UniswapTokens } from '~config'
import { Ticker, TickerAddToLPFrom } from '~enums'

export const bnToTokenAmount = (b: BigNumber, ticker: TickerAddToLPFrom) =>
  new TokenAmount(UniswapTokens[ticker === Ticker.BNB ? Ticker.WBNB : ticker], b.toString())

export const tokenAmountToBN = (t: TokenAmount) => new BigNumber(t.raw.toString())
