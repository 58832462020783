import { SagaIterator } from 'redux-saga'
import { call, put, select } from 'redux-saga/effects'

import { Ticker } from '~enums'
import { AppState, LPSetMaxValue } from '~state'
import { getBUSDPair, getLTTPair, LPBroker } from '~state/sagas/lp/workers/utils'
import { lpActions } from '~state/store'
import { NumberFormatter } from '~utils'

export function* onSetMaxValue({ payload: ticker }: LPSetMaxValue): SagaIterator {
  const {
    web3: { library, gasPrice, account },
    contracts: {
      bep20,
      general: { lpBroker: contract }
    },
    tokens
  }: AppState = yield select()

  if (!library || !gasPrice || !bep20 || !contract || !account)
    throw new Error('No library or gasPrice or bep20 or lpBroker or account')

  const spendValue = tokens.data[ticker].balance
  if (ticker !== Ticker.BNB) {
    return yield put(lpActions.setValue(NumberFormatter.formatDecimal(spendValue, ticker)))
  }

  const lttPair = yield call(getLTTPair, library)
  const busdPair = yield call(getBUSDPair, ticker, library)
  const lpBroker = new LPBroker(contract, ticker, busdPair, lttPair, spendValue)

  try {
    const provideLiquidityGas = yield call(() => lpBroker.estimateProvideLiquidityETHGas(account))

    return yield put(
      lpActions.setValue(
        NumberFormatter.formatDecimal(
          spendValue.minus(provideLiquidityGas.multipliedBy(gasPrice)),
          ticker
        )
      )
    )
  } catch {
    console.info('On-chain amount to swap calculations exceed uint256, max amount not registered')
  }
}
