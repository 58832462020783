import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Card } from './Card'
import { Col } from './Col'
import { Row } from './Row'

import { getProducts } from '~config'
import { Headline2 } from '~ui'
import { mediaQuery } from '~utils'

const Title = styled(Headline2)`
  margin-bottom: 40px;
  ${mediaQuery.lessThan('md')`
        margin-bottom: 20px;
       
    `}
`

export const ComingSoonProducts = () => {
  const { t } = useTranslation(['products', 'common'])
  const PRODUCTS = getProducts(t, 'coming-soon')

  const odd = PRODUCTS.filter((v, i) => (i + 1) % 2)
  const even = PRODUCTS.filter((v, i) => !((i + 1) % 2))

  return (
    <>
      <Title>{t('Coming soon')}...</Title>
      <Row>
        <Col>
          {odd.map((p, i) => (
            <Card {...p} key={i} />
          ))}
        </Col>
        <Col>
          {even.map((p, i) => (
            <Card {...p} key={i} />
          ))}
        </Col>
      </Row>
    </>
  )
}
