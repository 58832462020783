import BigNumber from 'bignumber.js'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'

import { MediaQueryBreakpoint, Ticker } from '~enums'
import { useRank, useNextRank, useActiveWeb3React } from '~hooks'
import { useStaked } from '~state'
import { SecondaryButton, Tooltip, A2, CircularProgressBar, Headline4, BodyM, Card } from '~ui'
import { NumberFormatter } from '~utils'

const NextStakeWrapper = styled(BodyM)`
  color: ${({ theme }) => theme.colors.secondary};
  display: block;
  margin-bottom: 5px;
`

const RankName = styled(Headline4)`
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 5px;
  margin-right: 10px;
`

const Content = styled.div`
  padding: 20px 0;
  text-align: center;
`

export const RankInfo = ({ onDAOButtonClick }: { onDAOButtonClick: () => void }) => {
  const { stakedOf } = useStaked()
  const { rank, cap } = useRank()
  const { t } = useTranslation('balance')
  const nextRankWithCap = useNextRank()
  const { account } = useActiveWeb3React()

  const isSm = useMediaQuery({ maxWidth: MediaQueryBreakpoint.md })

  const progress = stakedOf.isZero()
    ? 0
    : nextRankWithCap
    ? stakedOf.minus(cap).dividedBy(nextRankWithCap.cap.minus(cap)).multipliedBy(100).toNumber()
    : 100

  return (
    <Card className="align-items-center">
      <CircularProgressBar strokeWidth={10} size={107} percentage={Number(progress.toFixed(2))} />
      <Content>
        <div className="d-flex align-items-center justify-content-center">
          <RankName>
            {t('Tier: {rank}', {
              rank: t(`rankNames.${rank.name}`),
              nsSeparator: false
            })}
          </RankName>
          <Tooltip name="rank-info" color="primary" placement="right" text={t('Locked + Staked')} />
        </div>
        <NextStakeWrapper>
          {!nextRankWithCap
            ? t('You have the biggest rank – {rank}', { rank: t(`rankNames.${rank.name}`) })
            : t('Stake {amount} more LTT to unlock {nextRank} Tier', {
                amount: NumberFormatter.toDecimal(
                  nextRankWithCap.cap.minus(stakedOf),
                  Ticker.LTT
                ).toFixed(0, BigNumber.ROUND_UP),
                nextRank: t(`rankNames.${nextRankWithCap?.rank.name}`)
              })}
        </NextStakeWrapper>
        <div>
          <A2 href="https://docs.localtrade.cc/products/dao-sharing-economy">
            {t(isSm ? 'More' : 'Learn more about Tiers')}
            {'->'}
          </A2>
        </div>
      </Content>
      <SecondaryButton wide outline onClick={account && onDAOButtonClick} disabled={!account}>
        {t('DAO Program ->')}
      </SecondaryButton>
    </Card>
  )
}
