import { useMediaQuery } from 'react-responsive'

import { MediaQueryBreakpoint } from '~enums'

export const useMediaQueries = (): Record<keyof typeof MediaQueryBreakpoint, number> =>
  Object.fromEntries(
    Object.entries(MediaQueryBreakpoint).map(([key, value]) => [
      key,
      useMediaQuery({ minWidth: value })
    ])
  ) as unknown as Record<keyof typeof MediaQueryBreakpoint, number>
