import { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { AppState, web3Actions } from '~state'
import { transformBN } from '~state/sagas/utils'

export function* onWeb3Set(): SagaIterator {
  const {
    web3: { library }
  }: AppState = yield select()
  if (!library) return
  const providerGasPrice = yield call([library, library.getGasPrice])
  yield put(web3Actions.setGasPrice(transformBN(providerGasPrice)))
}

export function* web3Saga() {
  yield takeLatest(web3Actions.set, onWeb3Set)
}
