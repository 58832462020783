import { createGlobalStyle } from 'styled-components'

import { GILROY_FONT } from './fonts/Gilroy'

export const GlobalStyles = createGlobalStyle`
  ${GILROY_FONT.map(
    ({ file, weight, italic }) => `
    @font-face {
      font-family: 'Gilroy';
      src: url(${file}) format('woff');
      font-weight: ${weight};
      ${italic ? 'font-style: italic;' : ''}
    }`
  )}
    html {
        overflow-x: hidden; 
    }
    body {
        overflow-x: hidden;
        margin: 0;
        background: ${({ theme }) => theme.colors.body};
        &.overflow {
            overflow: hidden;
        }
    }

 h1, h2, h3, h4 {
        font-family: 'Gilroy' !important;
    }

  #root {
    overflow: hidden;
  }
`
