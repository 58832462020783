import BigNumber from 'bignumber.js'

import { StaticVault, StaticVaultPosition } from '~interfaces'
const referralSharePercent = 0.15

const E16 = new BigNumber(10).pow(16)

export const calculateReward = (
  {
    secondlyRewardPer1e16,
    rewardInterval,
    hasDao
  }: Pick<StaticVault['contract'], 'secondlyRewardPer1e16' | 'rewardInterval'> & {
    hasDao: boolean
  },
  {
    lastRewardDate,
    startDate,
    staked
  }: Pick<StaticVaultPosition, 'lastRewardDate' | 'startDate' | 'staked'>,
  final?: boolean
): BigNumber => {
  const netResult = staked
    .multipliedBy(secondlyRewardPer1e16)
    .multipliedBy(
      final
        ? rewardInterval.minus((lastRewardDate.getTime() - startDate.getTime()) / 1000)
        : (Date.now() - lastRewardDate.getTime()) / 1000
    )
    .dividedBy(E16)
  if (!hasDao) return netResult
  return netResult.multipliedBy(1 - referralSharePercent)
}

export const calculateUnstake = (
  {
    secondlyUnlockPer1e16,
    unstakeOffset
  }: Pick<StaticVault['contract'], 'secondlyUnlockPer1e16' | 'unstakeOffset'>,
  startDate: Date,
  totalStaked: BigNumber,
  staked: BigNumber
): BigNumber => {
  const secondsPassed = new BigNumber(Date.now() - startDate.getTime())
    .dividedBy(1000)
    .minus(unstakeOffset)

  const calculatedUnstakePer1e16 = secondsPassed.multipliedBy(secondlyUnlockPer1e16)
  const readyToUnstakePer1e16 = calculatedUnstakePer1e16.lt(E16) ? calculatedUnstakePer1e16 : E16

  return totalStaked
    .multipliedBy(readyToUnstakePer1e16)
    .dividedBy(E16)
    .minus(totalStaked.minus(staked))
}
