import { TransactionResponse } from '@ethersproject/providers'
import BigNumber from 'bignumber.js'
import { Contract } from 'ethers'

import { transformBN } from '~state/sagas/utils'

export type EstimatedCall = { gas: BigNumber; execute: () => Promise<unknown> }

export async function estimateCall(
  contract: Contract,
  method: string,
  args: any[] = [],
  value?: string
): Promise<BigNumber> {
  return transformBN(
    await contract.estimateGas[method](...args, {
      // if ticker is BNB, we need to subtract the fee cost
      ...(value
        ? {
            value
          }
        : {})
    })
  )
    .multipliedBy(1.1)
    .decimalPlaces(0)
}

export function getCall(
  contract: Contract,
  method: string,
  gasPrice: BigNumber,
  gas: BigNumber,
  args: any[] = [],
  value?: string
): () => Promise<TransactionResponse> {
  return () =>
    contract[method](...args, {
      gasLimit: gas.toString(),
      gasPrice: gasPrice.toString(),
      // if ticker is BNB, we need to subtract the fee cost
      ...(value
        ? {
            value
          }
        : {})
    })
}
