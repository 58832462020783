import { VaultsState, createVaultsSlice } from './vaults'

import { DynamicVault } from '~interfaces'

export type DynamicVaultsState = Record<string, DynamicVault & VaultsState>

const initialState: DynamicVaultsState = {}

export const dynamicVaultsSlice = createVaultsSlice({
  name: 'dynamicVaults',
  initialState: initialState,
  reducers: {}
})

export const { actions: dynamicVaultsActions, reducer: dynamicVaultsReducer } = dynamicVaultsSlice
