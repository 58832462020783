import { generateMedia } from 'styled-media-query'

import { MediaQueryBreakpoint } from '~enums'

export const mediaQuery = generateMedia({
  xxl: `${MediaQueryBreakpoint.xxl}px`,
  xl: `${MediaQueryBreakpoint.xl}px`,
  lg: `${MediaQueryBreakpoint.lg}px`,
  md: `${MediaQueryBreakpoint.md}px`,
  sm: `${MediaQueryBreakpoint.sm}px`,
  xs: `${MediaQueryBreakpoint.xs}px`
})
