import { SagaIterator } from 'redux-saga'
import { all, call, put, select, takeLatest, fork } from 'redux-saga/effects'

import { ReferralsApi } from '../../api'
import { AppState, referralsActions, TeamFetchAction, web3Actions } from '../store'

import { createTerminatedSaga } from './utils'

import { Referral } from '~interfaces'
import { getRank } from '~utils'

async function getTeam(inviter: string): Promise<Referral[]> {
  const referralsApi = new ReferralsApi()
  const team = await referralsApi.getTeam(inviter)
  return Promise.all(
    team.map(async ({ address, staked, personal }) => {
      return {
        staked,
        rank: getRank(staked),
        personal,
        address
      }
    })
  )
}

async function getInviter(user: string): Promise<Referral | null> {
  const referralsApi = new ReferralsApi()
  const inviter = await referralsApi.getInviter(user)
  if (!inviter) return null
  return {
    ...inviter,
    rank: getRank(inviter.staked)
  }
}

export function* onFetchTeam(action: TeamFetchAction): SagaIterator {
  const inviter = action.payload
  const team = yield call(getTeam, inviter)
  yield put(referralsActions.setTeam({ inviter, referrals: team }))
}

export function* onFetchInviter(): SagaIterator {
  const {
    web3: { account }
  }: AppState = yield select()
  if (!account) return
  const inviter = yield call(getInviter, account)
  yield put(referralsActions.setInviter(inviter))
}

// if new contracts without account reset (logout action)
function* initialFetch(): SagaIterator {
  const {
    web3: { account }
  }: AppState = yield select()
  if (!account) yield put(referralsActions.reset())
  else {
    yield put(referralsActions.fetchInviter())
    yield put(referralsActions.fetchTeam(account))
  }
}

const terminatedSaga = createTerminatedSaga(
  { start: referralsActions.startListening, end: referralsActions.endListening },
  [call(initialFetch), takeLatest(web3Actions.set, initialFetch)]
)

export function* referralsSaga(): SagaIterator {
  yield all([
    takeLatest(referralsActions.fetchTeam, onFetchTeam),
    takeLatest(referralsActions.fetchInviter, onFetchInviter)
  ])
  yield fork(terminatedSaga)
}
