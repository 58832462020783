import BigNumber from 'bignumber.js'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { AppState, unstakerActions, useAppDispatch } from '../store'

export function useUnstaker(): [BigNumber, () => {}, boolean] {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(unstakerActions.startListening())
    return () => {
      dispatch(unstakerActions.endListening())
    }
  }, [])

  const unstaker = useSelector((state: AppState) => state.unstaker)

  return [unstaker.value, () => dispatch(unstakerActions.unstake()), unstaker.unstaking]
}
