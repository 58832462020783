import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppState, fetchNews, NewsState } from '../store'

export function useNews(): NewsState {
  const dispatch = useDispatch()
  const news = useSelector((state: AppState) => state.news)

  useEffect(() => {
    dispatch(fetchNews())
  }, [])

  return news
}
