import * as Bs from '@bootstrap-styled/v4'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'

import { ConnectButton } from '../../ConnectButton'

import image from './image.svg'

import { Headline1, Card, BodyL } from '~components'
import { AmbisafePartnership } from '~components/security/AmbisafePartnership'
import { MediaQueryBreakpoint } from '~enums'
import { useActiveWeb3React } from '~hooks'
import { useAccountModal } from '~state'

const Title = styled(Headline1)`
  margin-bottom: 15px;
`

const Button = styled(ConnectButton)`
  width: 100%;
`

const Text = styled(BodyL)`
  margin-top: 70px;
`
const Column = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`
export const Jumbotron = () => {
  const { t } = useTranslation('security')
  const { account } = useActiveWeb3React()
  const [, { open: openAccountModal }] = useAccountModal()
  const isMd = useMediaQuery({ maxWidth: MediaQueryBreakpoint.md })

  return (
    <Card withBorder>
      <Bs.Row>
        <Bs.Col xs={12} md={6}>
          <Column>
            <div>
              <Title>{t('NeoBroker Smart Fund')}</Title>
              <AmbisafePartnership direction="row" />
              <Text>
                {t(
                  'Access the global VC market and build your own venture portfolio. Invest in pre-IPO stocks, starting with $1.'
                )}
              </Text>
            </div>
            {!account && <Button onClick={openAccountModal} />}
          </Column>
        </Bs.Col>
        {!isMd && (
          <Bs.Col xs={6}>
            <div>
              <img src={image} className="d-block" />
            </div>
          </Bs.Col>
        )}
      </Bs.Row>
    </Card>
  )
}
