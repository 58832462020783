import { all, takeLatest } from 'redux-saga/effects'

import { onSetFormReceiveValue, onSetFormSpendValue } from './workers'

import { buySecuritiesActions, TransactionType } from '~state'
import { onExecute, onSetTXInfo } from '~state/sagas/utils'

export function* buySecuritiesSaga() {
  yield all([
    takeLatest(buySecuritiesActions.setFormSpendValue, onSetFormSpendValue),
    takeLatest(buySecuritiesActions.setFormReceiveValue, onSetFormReceiveValue),
    takeLatest(buySecuritiesActions.executePurchase, () =>
      onSetTXInfo(TransactionType.PURCHASE, (s) => [
        { value: s.buySecurities.form.spendValue, ticker: s.buySecurities.form.spendTicker }
      ])
    ),
    takeLatest(buySecuritiesActions.executePurchase, (_action) =>
      onExecute(
        (s) => {
          const purchase = s.buySecurities.purchase
          if (!s.buySecurities.gas || !s.web3.gasPrice) return
          return purchase?.getCall(s.buySecurities.gas, s.web3.gasPrice)
        },
        buySecuritiesActions.executePurchase,
        buySecuritiesActions.purchaseExecuted,
        buySecuritiesActions.purchaseFailed
      )
    )
  ])
}
