import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Label } from './Label'
import { Value } from './Value'

import { BUSD_ADDRESS, LTT_ADDRESS, get1inchSwapLink } from '~config'
import { Ticker } from '~enums'
import { useLttPrice } from '~state'
import { Icon as IconUi, PrimaryButton, Card } from '~ui'
import { NumberFormatter } from '~utils'

const LabelAndValueWrapper = styled.div`
  margin-bottom: 20px;
`

const Icon = styled(IconUi)`
  margin-right: 5px;
`

export const TokenInfo = () => {
  const { t } = useTranslation('balance')
  const price = useLttPrice()

  return (
    <Card>
      <LabelAndValueWrapper>
        <Label>{t('LTT Price')}:</Label>
        <Value>
          {NumberFormatter.formatDecimal(price, Ticker.BUSD)} {Ticker.BUSD}
        </Value>
      </LabelAndValueWrapper>
      {/* <LabelAndValueWrapper>
        <Label>{t('Market Cap')}:</Label>
        <Value>$ 8,127,473.00</Value>
      </LabelAndValueWrapper> */}
      <div>
        <Label>{t('Buy LTT')}:</Label>
        <PrimaryButton
          block
          onClick={() => window.open(get1inchSwapLink(BUSD_ADDRESS, LTT_ADDRESS))}
        >
          <Icon name="oneInch" width={20} height={20} />
          1inch Swap
        </PrimaryButton>
      </div>
    </Card>
  )
}
