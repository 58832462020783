import * as Bs from '@bootstrap-styled/v4'
import { Link } from 'react-router-dom'
import Loader from 'react-spinners/PulseLoader'
import styled, { ThemeColor } from 'styled-components'

import { theme } from '../../../theme'

import { mediaQuery } from '~utils'

export const SecondaryButton = styled(({ to, loading, ...props }) =>
  to ? (
    <Link to={to} style={{ textDecoration: 'none' }}>
      <Bs.Button {...props} />
    </Link>
  ) : (
    <Bs.Button {...props} />
  )
).attrs((props) => ({
  children: props.loading
    ? [
        <Loader
          size={10}
          color={props.$rawColor ?? theme.colors[(props.$color as ThemeColor) ?? 'white']}
        />
      ]
    : props.children
}))`
  border: 2px solid ${({ theme, outline }) => (outline ? theme.colors.white : 'transparent')} !important;
  background: transparent !important;
  ${({ wide }) => wide && 'width: 100%;'}
  color: ${({ theme }) => theme.colors.white} !important;
  ${mediaQuery.lessThan('md')`
    font-size: 14px !important;
  `}

  &:hover {
    ${({ primaryHover, theme }) =>
      primaryHover
        ? `
          background: ${theme.colors.hoverPrimary} !important;
          `
        : `
          color: ${theme.colors.black} !important;
          background: ${theme.colors.white} !important;
          `}
  }
`
