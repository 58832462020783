import * as Bs from '@bootstrap-styled/v4'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'

import { TrustBadge } from '../TrustBadge'

import Selection from './images/circle.svg'
import cover from './images/cover.png'
import { WhitepaperButton } from './WhitepaperButton'

import { SocialMedia } from '~components/socials'
import { routes } from '~config'
import { MediaQueryBreakpoint } from '~enums'
import { BodyL, Headline1, SecondaryButton } from '~ui'
import { mediaQuery } from '~utils'

const Title = styled(Headline1)`
  margin-left: 13.5px;
  ${mediaQuery.lessThan('sm')`
    margin-top: 10px;
    margin-bottom: 20px;
  `}
`

const Desc = styled(BodyL)`
  margin-top: 28px;
  opacity: 80%;
`

const Circle = styled.img`
  z-index: -1;
  ${mediaQuery.lessThan('md')`
    height: 100%;`}
`

const CircleWrapper = styled.span`
  padding-right: 22px;
  justify-content: center;
`

const Cover = styled.img.attrs({ src: cover })`
  margin-top: 30px;
  object-fit: cover;
  height: 100%;
  border-radius: 24px;
  width: 100%;
`

const TrustBadgeMargined = styled((props: { className?: string }) => <TrustBadge {...props} />)`
  ${mediaQuery.lessThan('lg')`
    margin-bottom: 30px;
  `}
`

const PaddingWrapper = styled.div<{ left?: boolean; right?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  ${({ left }) => left && 'padding-left: 20px;'}
  ${({ right }) => right && 'padding-right: 20px;'}
`

const SocialMediaMargined = styled(SocialMedia)`
  margin-top: 20px;
  ${mediaQuery.lessThan('lg')`
    margin-top: 30px;
  `}
`

const ButtonsPairRow = styled(Bs.Row).attrs({ noGutters: true })`
  ${mediaQuery.lessThan('lg')`
    margin-top: 30px;
  `}
`

const SmallSecondaryButton = styled(SecondaryButton)`
  height: 50px;
`

export const Heading = styled(({ className }: { className?: string }) => {
  const isSm = useMediaQuery({ maxWidth: MediaQueryBreakpoint.sm })
  const isLg = useMediaQuery({ minWidth: MediaQueryBreakpoint.lg })
  const { t } = useTranslation('home')
  return (
    <Bs.Row className={className} noGutters>
      <Bs.Col lg={6}>
        <PaddingWrapper right={isLg}>
          <TrustBadgeMargined />
          <div className={`d-flex align-items-center align-items-md-start flex-column`}>
            <Title>
              {t('LocalTrade')}{' '}
              <CircleWrapper className="d-inline-flex align-items-center position-relative">
                {t('DeFi')}
                <Circle className="position-absolute" src={Selection} alt="selected" />
              </CircleWrapper>
              {t('Lab')} {isSm && <>🔥</>}
            </Title>
            <Desc>{t('The easiest way to dive into the decentralized reality')}</Desc>
          </div>
          <ButtonsPairRow>
            <Bs.Col className="pr-sm-2" sm={6}>
              <WhitepaperButton className="w-100" />
            </Bs.Col>
            <Bs.Col className="pl-sm-2 mt-3 mt-sm-0" sm={6}>
              <SmallSecondaryButton outline to={routes.staking} className="w-100">
                {t('Staking →')}
              </SmallSecondaryButton>
            </Bs.Col>
          </ButtonsPairRow>
        </PaddingWrapper>
      </Bs.Col>
      <Bs.Col lg={6}>
        <PaddingWrapper left={isLg}>
          <SocialMediaMargined />
          {isLg && <Cover />}
        </PaddingWrapper>
      </Bs.Col>
    </Bs.Row>
  )
})`
  background: ${({ theme }) => theme.colors.secondaryShade1};
  border-radius: 24px;
  padding: 30px;
  ${mediaQuery.lessThan('md')`
    padding: 20px;
    text-align: center;
  `}
`
