import { BigNumber } from 'bignumber.js'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Label } from './Label'
import { Value } from './Value'

import { Ticker } from '~enums'
import { useBalance, useLttPrice, useStaked, useUnstaker, useVaults } from '~state'
import { Card, PrimaryButton, BodyS } from '~ui'
import { NumberFormatter } from '~utils'

const Converted = styled(BodyS)`
  margin-left: 5px;
`

const Divider = styled.div`
  margin-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.secondaryShade5};
`
const ValueAndConvertedWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`

export const BalanceInfo = () => {
  const balance = useBalance(Ticker.LTT)
  const { roundsTotal } = useStaked()
  const vaults = useVaults()
  const lttVaults = vaults.filter((v) => v.stakeTicker === Ticker.LTT)

  const [availableToUnstake, unstake, unstaking] = useUnstaker()

  const { t } = useTranslation('balance')

  const price = useLttPrice()

  return (
    <Card>
      <Label>{t('LTT Balance')}:</Label>
      <ValueAndConvertedWrapper>
        <Value>
          {NumberFormatter.toDecimal(balance, Ticker.LTT).toFixed(2, BigNumber.ROUND_DOWN)}{' '}
          {Ticker.LTT}
        </Value>
        <Converted>
          ~
          {NumberFormatter.formatCustomDecimal(
            balance.times(price),
            27,
            2,
            BigNumber.ROUND_DOWN
          ).toString()}
          $
        </Converted>
      </ValueAndConvertedWrapper>
      <Divider />
      <Label>{t('Staked LTT')}:</Label>
      <ValueAndConvertedWrapper>
        <Value>
          {NumberFormatter.toDecimal(
            lttVaults.reduce((acc, v) => acc.plus(v.contract.staked), new BigNumber(0)),
            Ticker.LTT
          ).toFixed(2, BigNumber.ROUND_DOWN)}{' '}
          {Ticker.LTT}
        </Value>
        <Converted>
          ~
          {NumberFormatter.formatCustomDecimal(
            lttVaults
              .reduce((acc, v) => acc.plus(v.contract.staked), new BigNumber(0))
              .times(price),
            27,
            2,
            BigNumber.ROUND_DOWN
          ).toString()}{' '}
          $
        </Converted>
      </ValueAndConvertedWrapper>
      <Divider />
      <Label>{t('Tokensale Locked LTT')}:</Label>
      <ValueAndConvertedWrapper>
        <Value>
          {NumberFormatter.toDecimal(roundsTotal, Ticker.LTT).toFixed(2, BigNumber.ROUND_DOWN)}{' '}
          {Ticker.LTT}
        </Value>
        <Converted>
          ~
          {NumberFormatter.formatCustomDecimal(
            roundsTotal.times(price),
            27,
            2,
            BigNumber.ROUND_DOWN
          ).toString()}
          $
        </Converted>
      </ValueAndConvertedWrapper>
      <PrimaryButton
        size="sm"
        disabled={availableToUnstake.eq(0)}
        onClick={() => unstake()}
        loading={unstaking}
      >
        {t('Claim Available')} {NumberFormatter.formatDecimal(availableToUnstake, Ticker.LTT)}{' '}
        {Ticker.LTT}
      </PrimaryButton>
    </Card>
  )
}
