import styled from 'styled-components'

import { BodyL, BodyM } from '~components'

const Label = styled(BodyM)`
  color: ${({ theme }) => theme.colors.secondary};
  margin-bottom: 5px;
`

const Value = styled(BodyL).attrs({ $bold: true })`
  color: ${({ theme }) => theme.colors.white};
`

const Wrapper = styled.div<{ align: 'center' | 'left' | 'right' }>`
  flex-grow: 1;
  text-align: ${({ align }) => align};
`

export const Stat = ({
  title,
  value = '-',
  align = 'center'
}: {
  title: string
  value?: string | number
  align?: 'center' | 'left' | 'right'
}) => {
  return (
    <Wrapper align={align}>
      <Label>{title}:</Label>
      <Value className="d-block">{value}</Value>
    </Wrapper>
  )
}
