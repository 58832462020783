import BigNumber from 'bignumber.js'

import { ranks } from '~config'
import { Rank } from '~interfaces'

export const getNextRank = (staked: BigNumber): Rank | null => {
  let rank: Rank | null = null

  for (const [index, r] of [...ranks.slice(1), null].entries()) {
    if (!r) return null
    if (staked.gte(ranks[index].staked)) {
      rank = {
        name: r.name,
        number: index + 1
      }
    } else return rank
  }

  return rank
}
