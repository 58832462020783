import * as Bs from '@bootstrap-styled/v4'
import chunk from 'lodash.chunk'
import React, { useRef } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import SliderUi from 'react-slick'
import styled from 'styled-components'
import 'slick-carousel/slick/slick.css'

import { getAdvantages } from '~config'
import { MediaQueryBreakpoint } from '~enums'
import { HoverCard, CarouselControlButtons, Headline2, Headline4, BodyM } from '~ui'
import { mediaQuery } from '~utils'

const Title = styled(Headline2)`
  margin-bottom: 40px !important;
  span {
    color: ${({ theme }) => theme.colors.primary};
  }
  ${mediaQuery.lessThan('md')`
    text-align:left;
    margin-bottom: 20px !important;
  `}
`
const CardTitle = styled(Headline4)`
  margin-bottom: 10px;
`

const CardText = styled(BodyM)`
  color: ${({ theme }) => theme.colors.secondary};
`

const Col = styled(Bs.Col)<{ $margin?: boolean; $isMd?: boolean }>`
  margin-bottom: ${({ $margin, $isMd }) => $margin && ($isMd ? '20px' : '40px')};
`

const Slider = styled(SliderUi)`
  & .slick-slide {
    height: 100%;
    box-sizing: border-box;
  }
`

const ImageWrapper = styled.div<{ $isLg?: boolean }>`
  ${({ $isLg }) => ($isLg ? 'margin-bottom:20px;' : 'margin-right:30px;')};
`

export const Advantages = () => {
  const slider = useRef<SliderUi>(null)
  const isMd = useMediaQuery({ maxWidth: MediaQueryBreakpoint.md })
  const isLg = useMediaQuery({ maxWidth: MediaQueryBreakpoint.lg })
  const { t } = useTranslation('advantages')

  const CHUNK_LENGTH = isMd ? 1 : isLg ? 2 : 4
  const ADVANTAGES = getAdvantages(t)
  return (
    <>
      <div className="d-flex justify-content-between">
        <Title>
          <Trans t={t} i18nKey="DeFi advantages of LocalTrade">
            <span />
          </Trans>
        </Title>
        <CarouselControlButtons
          onPrevClick={() => slider.current?.slickPrev()}
          onNextClick={() => slider.current?.slickNext()}
        />
      </div>
      <Slider ref={slider} arrows={false}>
        {chunk(ADVANTAGES, CHUNK_LENGTH).map((chunk, i) => (
          <div>
            <Bs.Row className="d-flex" key={i}>
              {chunk.map(
                ({ title, description, image: { normal: NormalImage, small: SmallImage } }, j) => (
                  <Col $margin={j % 4 <= 1} xs={12} lg={isLg ? 12 : 6} $isMd={isMd} key={j}>
                    <HoverCard
                      hoverStyle="grey"
                      withBorder
                      className="flex-md-row h-100 align-items-center align-items-md-start"
                    >
                      <ImageWrapper $isLg={isLg}>
                        {!isLg ? <NormalImage /> : <SmallImage />}
                      </ImageWrapper>
                      <div className="text-center text-md-left">
                        <CardTitle>{title}</CardTitle>
                        <CardText>{description}</CardText>
                      </div>
                    </HoverCard>
                  </Col>
                )
              )}
            </Bs.Row>
          </div>
        ))}
      </Slider>
    </>
  )
}
