import * as Bs from '@bootstrap-styled/v4'
import Loader from 'react-spinners/PulseLoader'
import styled from 'styled-components'

import { mediaQuery } from '~utils'

export const SemiTransparentButton = styled((props) => <Bs.Button {...props} />).attrs((props) => ({
  children: props.loading ? [<Loader color="white" size={10} />] : props.children
}))`
  border: 2px solid rgba(255, 255, 255, 0.2) !important;
  background: rgba(255, 255, 255, 0.04) !important;
  ${mediaQuery.lessThan('md')`
    font-size: 14px !important;
  `}
`
