import BigNumber from 'bignumber.js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Headline3, WithGradient } from '~ui'

const Color = styled.span<{ color: string }>`
  color: ${({ color }) => color};
`

const MS_IN_YEAR = 31536000000

export const DynamicAPR = ({
  creationDate,
  endDate,
  decimalsMultipler = new BigNumber(1),
  stakedTotal,
  totalReward,
  color,
  addedAPR = new BigNumber(0)
}: {
  creationDate: Date
  endDate: Date
  stakedTotal: BigNumber
  totalReward: BigNumber
  decimalsMultipler?: BigNumber
  color?: string
  addedAPR?: BigNumber
}) => {
  const { t } = useTranslation('vault')

  const apr = stakedTotal.eq(0)
    ? '∞'
    : totalReward
        .dividedBy(endDate.getTime() - creationDate.getTime())
        .multipliedBy(MS_IN_YEAR)
        .dividedBy(stakedTotal)
        .multipliedBy(100)
        .dividedBy(decimalsMultipler)
        .plus(addedAPR)
        .toFixed(2) + '%'

  return (
    <Headline3>
      {endDate.getTime() < Date.now() ? (
        t('Finished') + ' 🏁'
      ) : (
        <>
          {color ? (
            <Color color={color}>{apr}</Color>
          ) : (
            <WithGradient $gradient="flame">{apr}</WithGradient>
          )}{' '}
          APR
        </>
      )}
    </Headline3>
  )
}
