import { Token } from '@uniswap/sdk'

import { getChainId } from './getChainId'
import { BUSD, WBNB, LTT, LP, LUSD } from './Tokens'

import { TickerBEP20 } from '~enums'

export const UniswapTokens = {
  [TickerBEP20.BUSD]: new Token(getChainId() as any, BUSD.address, BUSD.decimals),
  [TickerBEP20.WBNB]: new Token(getChainId() as any, WBNB.address, WBNB.decimals),
  [TickerBEP20.LTT]: new Token(getChainId() as any, LTT.address, LTT.decimals),
  [TickerBEP20.LP]: new Token(getChainId() as any, LP.address, LP.decimals),
  [TickerBEP20.LUSD]: new Token(getChainId() as any, LUSD.address, LUSD.decimals)
}
