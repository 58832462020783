import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useActiveWeb3React } from '~hooks'
import { Headline4, PrimaryButton, WithColor, Card } from '~ui'
import { toastSuccess } from '~utils'

const ReferralInput = styled.div`
  margin-top: 10px;
  padding: 10px 20px;
  background: ${({ theme }) => theme.colors.secondaryShade1};
  border-radius: 12px;
  border: 2px solid ${({ theme }) => theme.colors.secondaryShade1};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const SmallPrimaryButton = styled(PrimaryButton).attrs({ size: 'sm' })`
  padding-left: 33.5px !important;
  padding-right: 33.5px !important;
`

export const ReferralLink = () => {
  const { account } = useActiveWeb3React()

  if (!account) return <></>

  const { t } = useTranslation('referrals')

  const refLink = process.env.REACT_APP_BASE_URL + '/ref/' + account
  return (
    <Card>
      <div className="d-flex justify-content-between align-items-center flex-wrap flex-lg-nowrap">
        <Headline4>{t('Your referral link')}:</Headline4>
        <CopyToClipboard text={refLink}>
          <SmallPrimaryButton
            className="mt-2 mt-lg-0"
            onClick={() =>
              toastSuccess(`${t('Copied')}!`, `${t('Your referral link was copied successfully')}.`)
            }
          >
            {t('Copy link')}
          </SmallPrimaryButton>
        </CopyToClipboard>
      </div>
      <ReferralInput>
        {process.env.REACT_APP_BASE_URL + '/ref/'}
        <WithColor as="span" $color="white">
          <b>{account}</b>
        </WithColor>
      </ReferralInput>
    </Card>
  )
}
