import { Fraction, JSBI, Percent, TokenAmount } from '@uniswap/sdk'

const slippage = new Percent('1', '100')

export const applySlippage = (amount: TokenAmount) =>
  new TokenAmount(
    amount.token,
    new Fraction(JSBI.BigInt(1))
      .add(slippage)
      .invert()
      .multiply(amount.raw.toString())
      .quotient.toString()
  )
