import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from './Buttons'

import { useAccountModal } from '~state'

export const Connect = (props: { backgroundColor?: string; hoverBackgroundColor?: string }) => {
  const { t } = useTranslation('account')
  const [, { open }] = useAccountModal()

  return (
    <Button {...props} block onClick={open}>
      {t('account:Connect wallet')}
    </Button>
  )
}
