import { Token, TokenAmount } from '@uniswap/sdk'
import BigNumber from 'bignumber.js'

import { tokenAmountToBN } from './amounts'

import { FEES_DENOMINATOR, FEES_NUMERATOR } from '~config'

const calculateProportionalFutureLiquidityAmountNeeded = (
  amount: BigNumber,
  reserve: BigNumber
): BigNumber => {
  const D = amount
    .multipliedBy(FEES_NUMERATOR)
    .multipliedBy(FEES_DENOMINATOR)
    .multipliedBy(reserve)
    .multipliedBy(4)
    .plus(FEES_NUMERATOR.plus(FEES_DENOMINATOR).multipliedBy(reserve).pow(2))
  return D.sqrt()
    .minus(FEES_NUMERATOR.plus(FEES_DENOMINATOR).multipliedBy(reserve))
    .dividedBy(FEES_NUMERATOR.multipliedBy(2))
}

export const calculateAmount = (amount: BigNumber, reserve: TokenAmount, inputToken: Token) => {
  return new TokenAmount(
    inputToken,
    calculateProportionalFutureLiquidityAmountNeeded(amount, tokenAmountToBN(reserve)).toFixed(0)
  )
}
