import { ObjectValuesType } from '~enums'

export const StakingsKind = {
  DYNAMIC: 'dynamic',
  CENTRALIZED: 'centralized',
  STATIC: 'static',
  ROUND: 'round'
}

export type StakingsKind = ObjectValuesType<typeof StakingsKind>
