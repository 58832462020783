import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'

import { CircularOccupiedProgress } from './CircularOccupiedProgress'
import { Stat } from './Stat'

import { MediaQueryBreakpoint } from '~enums'
import { StaticVault } from '~interfaces'
import { mediaQuery } from '~utils'

const StyledOccupiedProgress = styled(CircularOccupiedProgress)`
  margin-left: 30px;
`

const StyledStat = styled(Stat)`
  margin-left: 30px;
  ${mediaQuery.lessThan('md')`
    margin-left: 20px;
  `}
`

export const VaultStats = ({ vault, className }: { vault: StaticVault; className?: string }) => {
  const { t } = useTranslation('vault')
  const isLg = useMediaQuery({ maxWidth: MediaQueryBreakpoint.lg })
  const progress = vault.contract.stakedTotal
    .dividedBy(vault.contract.capacity.plus(vault.contract.stakedTotal))
    .multipliedBy(100)
  return (
    <div className={`w-100 d-flex ${className}`}>
      {isLg ? (
        <Stat gradient="yellowGreenish" percent={`${progress.toFixed(0)}%`} label={t('Occupied')} />
      ) : (
        <StyledOccupiedProgress vault={vault} progress={progress} />
      )}
      <StyledStat gradient="greenBlueish" percent={`${vault.apr}%`} label="APR" />
    </div>
  )
}
