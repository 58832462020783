export const MediaQueryBreakpoint = {
  xs: 375,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440
}

export const MEDIA_QUERY_BREAKPOINTS = Object.keys(MediaQueryBreakpoint) as Array<
  keyof typeof MediaQueryBreakpoint
>
