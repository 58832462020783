import React, { HTMLProps, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import SliderUi from 'react-slick'
import styled from 'styled-components'

import { MEDIA_BLOCKS } from '~config'
import { MediaQueryBreakpoint } from '~enums'
import { HoverCard as HoverCardUi, CarouselControlButtons, Headline2, BodyM, BodyL } from '~ui'
import { mediaQuery } from '~utils'

const Title = styled(Headline2)`
  margin-bottom: 40px;
  ${mediaQuery.lessThan('md')`
     margin-bottom:20px;
  `}
`

const Card = styled(HoverCardUi)`
  max-width: 720px;
  height: 100%;
`

const Avatar = styled.img`
  width: 52px;
  height: 52px;
  margin-right: 16px;
  border-radius: 50%;
`

const AuthorPost = styled(BodyM)`
  color: ${({ theme }) => theme.colors.secondary};
`

const Logo = styled.img`
  margin-bottom: 32px;
  ${mediaQuery.lessThan('md')`
    max-width: 200px;
  `}
`

const Link = styled(({ href, ...rest }: { href: string } & HTMLProps<HTMLDivElement>) => (
  <div {...rest} onClick={() => window.open(href, '_blank')} />
))`
  margin-top: 16px;
  margin-bottom: 32px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 700;

  &:hover {
    text-decoration: underline;
  }
`

const Slider = styled(SliderUi)`
  & .slick-slide {
    padding: 0 20px;
    box-sizing: border-box;
  }
`

const AuthorWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  align-items: baseline;
`

const ContentWrapper = styled.div`
  padding: 0 20px;
  ${mediaQuery.lessThan('md')`
    text-align: left;
  `}
`

export const MediaBlock = () => {
  const slider = useRef<SliderUi>(null)

  const isSm = useMediaQuery({ maxWidth: MediaQueryBreakpoint.lg })

  const { t } = useTranslation('home')
  return (
    <>
      <div className="d-flex justify-content-between">
        <Title>{t('They write about us')}</Title>
        <CarouselControlButtons
          onPrevClick={() => slider.current?.slickPrev()}
          onNextClick={() => slider.current?.slickNext()}
        />
      </div>
      <Slider
        centerMode={!isSm}
        variableWidth={!isSm}
        autoplay
        autoplaySpeed={10000}
        arrows={false}
        ref={slider}
      >
        {MEDIA_BLOCKS.map(({ content, link, logo, author }, i) => (
          <div key={i}>
            <Card
              withBorder
              hoverStyle="grey"
              className="align-items-center text-center justify-content-between"
            >
              <Logo src={logo} alt="Logo" />
              <ContentWrapper>{content}</ContentWrapper>
              <Link href={link}>{t('Learn more...')}</Link>
              {author && (
                <div className="d-flex align-items-center">
                  {author.avatar && <Avatar src={author.avatar} />}
                  <AuthorWrapper>
                    <BodyL>{author.name}</BodyL>
                    {author.position && <AuthorPost>{author.position}</AuthorPost>}
                  </AuthorWrapper>
                </div>
              )}
            </Card>
          </div>
        ))}
      </Slider>
    </>
  )
}
