import BigNumber from 'bignumber.js'

import usa from '../images/usa.svg'

import logo from './images/logo.svg'

import { SecurityStatus } from '~enums'
import { Security } from '~interfaces'

export const robinhood: Security = {
  status: SecurityStatus.FINISHED,
  id: 'robinhood',
  name: 'Robinhood',
  description:
    'Robinhood is a company developing a stock brokerage application that offers the ability to invest in stocks, ' +
    'ETFs, and digital assets trading.',
  more: 'https://robinhood.com/',
  city: 'Menlo Park',
  foundationYear: 2013,
  annualYieldPercent: 97,
  valuation: new BigNumber(11200000000),
  countryIconURL: usa,
  color: '#00C805',
  typeOfEquity: '',
  industry: '',
  leadInvestors: [],
  softcap: new BigNumber(0),
  docs: {
    license: '',
    agreement: '',
    certificate: ''
  },
  tickerLogoURL: logo,
  logoURL: logo
}
