import * as Bs from '@bootstrap-styled/v4'
import { Link } from 'react-router-dom'
import Loader from 'react-spinners/PulseLoader'
import styled, { ThemeColor } from 'styled-components'

import { theme } from '../../../theme'

import { mediaQuery } from '~utils'

const WideButton = styled(Bs.Button)`
  width: 100%;
`

export const PrimaryButton = styled(({ to, loading, ...props }) =>
  to ? (
    <Link to={to} style={{ textDecoration: 'none' }}>
      <WideButton {...props} />
    </Link>
  ) : (
    <Bs.Button {...props} />
  )
).attrs((props) => ({
  children: props.loading
    ? [
        <Loader
          size={10}
          color={props.$rawColor ?? theme.colors[(props.$color as ThemeColor) ?? 'white']}
        />
      ]
    : props.children
}))`
  display: flex !important;
  justify-content: center;
  align-items: center;
  border: 2px solid ${({ theme, outline }) => (outline ? theme.colors.primary : 'transparent')} !important;
  color: ${({ disabled }) => disabled && '#7C8793 !important'};
  background-color: ${({ disabled }) => disabled && '#E1E5EA !important'};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  &:hover {
    ${({ theme, whiteHover }) =>
      whiteHover
        ? `
      background-color: ${theme.colors.white} !important;
      color: ${theme.colors.primary} !important;
    `
        : `
      background-color: ${theme.colors.hoverPrimary} !important;
      border: 2px solid transparent !important;
    `}
  }
  &:active {
    border: 2px solid ${({ theme }) => theme.colors.primary} !important;
  }
  svg {
    flex: none;
  }

  ${mediaQuery.lessThan('md')`
    font-size: 14px !important;
  `}
`
