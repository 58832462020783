import Cookie from 'js-cookie'

const WAValidator = require('multicoin-address-validator')

export const specialLinks: Record<string, string | undefined> = {
  '/info': '0x3129f6B4e5236d81d18a10ddBeE1C91F2b202EDa'
}

// has set cookie side effect !!!
export function resolveInviterFromPath(): string | null {
  const specialLink = specialLinks[window.location.pathname]
  if (specialLink) return specialLink
  const splitted = window.location.pathname.split('/')
  if (splitted[splitted.length - 2] !== 'ref') return null
  if (!splitted[splitted.length - 1] || !WAValidator.validate(splitted[splitted.length - 1], 'ETH'))
    return null
  Cookie.set('inviter', splitted[splitted.length - 1], { expires: 365 })
  return splitted[splitted.length - 1]
}

export function getInviterFromCookie(): string | null {
  const inviter = Cookie.get('inviter')
  if (!inviter || !WAValidator.validate(inviter, 'ETH')) return null
  return inviter
}

export function resolveGlobalInviter(): string | null {
  const pathInviter = resolveInviterFromPath()
  if (pathInviter) return pathInviter
  return getInviterFromCookie()
}
