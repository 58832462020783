import { AddressedPayloadAction } from './interfaces'
import { VaultsState, createVaultsSlice } from './vaults'

import { CentralizedVault } from '~interfaces'

export type CentralizedVaultsState = Record<string, CentralizedVault & VaultsState>

const initialState: CentralizedVaultsState = {}

export type CentralizedVaultsSetUserIdAction = AddressedPayloadAction<{ userId: string }>

export const centralizedVaultsSlice = createVaultsSlice({
  name: 'centralizedVaults',
  initialState: initialState,
  reducers: {
    setUserId: (state, { payload: { userId, address } }: CentralizedVaultsSetUserIdAction) => {
      state[address].contract.args = [userId]
    }
  }
})

export const { actions: centralizedVaultsActions, reducer: centralizedVaultsReducer } =
  centralizedVaultsSlice
