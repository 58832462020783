import styled from 'styled-components'

import { PrimaryButton, SecondaryButton as SecondaryButtonUI } from '~ui'

export const Button = styled(PrimaryButton)<{
  backgroundColor?: string
  hoverBackgroundColor?: string
}>`
  width: 100%;
  padding: 10px !important;
  ${({ backgroundColor }) => backgroundColor && 'background:' + backgroundColor + ' !important;'}
  &:hover {
    ${({ hoverBackgroundColor }) =>
      hoverBackgroundColor && 'background:' + hoverBackgroundColor + ' !important;'}
  }
  &:active {
    ${({ backgroundColor }) =>
      backgroundColor && 'border: 2px solid' + backgroundColor + ' !important;'}
  }
`

export const SecondaryButton = styled(SecondaryButtonUI)`
  padding: 10px !important;
`
