import * as Bs from '@bootstrap-styled/v4'
import styled from 'styled-components'

export const NavItem = styled(Bs.NavItem)<{ nested?: boolean }>`
  width: -webkit-fill-available;
  ${({ theme, nested }) =>
    nested
      ? `
        cursor: pointer;
        &:hover {
          background-color: ${theme.colors.tertiaryShade3}; 
        }
        &:last-child {
          border-bottom: 2px solid ${theme.colors.tertiaryShade3};
        }`
      : `border-bottom: 2px solid ${theme.colors.tertiaryShade3};`}
  padding: 16px 20px;
  list-style: none;
  &:first-child {
    ${({ theme, nested }) => !nested && `border-top: 2px solid ${theme.colors.tertiaryShade3};`}
  }
`
