import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppState, referralsActions, ReferralsState } from '../store'

export function useTeam(): {
  team: ReferralsState['team']
  fetchTeam: (inviter: string) => void
  removeTeam: (inviter: string) => void
} {
  const team = useSelector((state: AppState) => state.referrals.team)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(referralsActions.startListening())
    return () => {
      dispatch(referralsActions.endListening())
    }
  }, [])

  return {
    team,
    fetchTeam: (inviter: string) => dispatch(referralsActions.fetchTeam(inviter)),
    removeTeam: (inviter: string) => dispatch(referralsActions.removeTeam(inviter))
  }
}

export function useReferralsInviter(): {
  inviter: ReferralsState['inviter']
  fetchInviter: () => void
} {
  const inviter = useSelector((state: AppState) => state.referrals.inviter)
  const dispatch = useDispatch()

  return {
    inviter,
    fetchInviter: () => dispatch(referralsActions.fetchInviter())
  }
}
