import { Web3ReactProvider } from '@web3-react/core'
import React from 'react'
import { Provider } from 'react-redux'

import { store } from '~state'
import { getLibrary } from '~utils'

export const Providers: React.FC = ({ children }) => {
  return (
    <Provider store={store}>
      <Web3ReactProvider getLibrary={getLibrary}>{children}</Web3ReactProvider>
    </Provider>
  )
}
