import * as images from './images'

import { MediaBlock } from '~interfaces/MediaBlock'

export const MEDIA_BLOCKS: MediaBlock[] = [
  {
    content:
      '...Moins de 1,3% de la population mondiale détient actuellement une crypto-monnaie, « ce qui en fait un ' +
      'moment très excitant pour les entrepreneurs de commencer à tirer parti de la technologie blockchain et crypto ' +
      '». ...La scène fintech montante est précisément ce sur quoi LocalTrade mise pour développer un produit ' +
      'pratique et accessible.',
    link: 'https://www.forbes.fr/business/simplifier-le-monde-de-la-finance-decentralisee-le-nouveau-management-de-localtrade/',
    logo: images.forbes,
    author: {
      name: 'Jean-Baptiste GERARD',
      position: 'Contributeur Forbes France',
      avatar: images.jean
    }
  },
  {
    content:
      'Almost $20.5 billion was invested in DeFi as of 2021, and that number is only growing. Investors trust its ' +
      'potential. It’s now the turn of apps like LocalTrade to make sure the public trusts DeFi too.',
    link: 'https://www.entrepreneur.com/article/388049',
    logo: images.entrepreneur,
    author: {
      name: 'Markuss Kohs',
      position: 'International consultant and advisor',
      avatar: images.markuss
    }
  },
  {
    content:
      'With their visionary ambition for LocalTrade, the new team aims to disrupt the established FinTech scene and ' +
      'bring unification to personal finance, merging advanced compliance and fiat payments solutions from CeFi with ' +
      'extreme performance and transparency of DeFi.',
    link: 'https://www.investing.com/news/cryptocurrency-news/how-a-crypto-exchange-platform-is-going-global-the-rise-of-localtrade-2591587',
    logo: images.investing
  },
  {
    content:
      "LocalTrade gives the world an insider's preview on the unfolding future of finance and has already started " +
      'the decentralized revolution. As a part of the plan, the company also launches educational programs through ' +
      'LocalTrade University... and develops community-oriented projects in emerging markets.',
    link: 'https://finance.yahoo.com/news/localtrade-exchange-shares-latest-strategic-175000265.html?guccounter=1',
    logo: images.yahoo
  },

  {
    content:
      'DeFi, как самая прогрессивная сфера криптовалютного рынка, определенно стоит изучения. А с LocalTrade можно ' +
      'начать пошаговое освоение нового финансового мира. Самое главное ваше преимущество в том, что как бы медленно ' +
      'вы ни продвигались, вы все равно будете на десяток шагов впереди подавляющего большинства.',
    link: 'https://vc.ru/u/185546-ikrom-ergashev/290483-mozhet-li-novichok-zarabotat-v-defi-kak-osvoit-6-pribylnyh-instrumentov-dohodnogo-fermerstva',
    logo: images.vc,
    author: {
      name: 'Ikrom Ergashev',
      avatar: images.ikrom
    }
  },
  {
    content:
      'Various cryptocurrency firms, such as LocalTrade, are trying to change the perception of the industry. ' +
      'LocalTrade is on the mission to make crypto and DeFi accessible for all investors, not only the hardcore ones.',
    link: 'https://btcmanager.com/localtrades-literacy-crypto-defi/',
    logo: images.btcManager,
    author: {
      name: 'Ogwu Osaemezu Emmanuel'
    }
  },
  {
    content:
      'Building trust and supporting investors entering the DeFi space is a challenge that crypto and ' +
      'blockchain-based companies are working hard to solve. From Uniswap to CeFi/DeFi initiative LocalTrade, ' +
      'there are many pioneers opening the door to decentralized finance.',
    link: 'https://finance.yahoo.com/news/door-decentralized-finance-localtrade-defi-105407166.html',
    logo: images.yahoo,
    author: {
      name: 'Tanvir Zafar'
    }
  },
  {
    content:
      'LocalTrade’s card offering is an attempt to make it more convenient and accessible to use crypto day-to-day. ' +
      'Right now, only a handful of places currently accept payments in crypto, and this fact, when coupled with ' +
      'long transaction processing time, is what slows down global adoption.',
    link: 'https://btcmanager.com/localtrades-literacy-crypto-defi/',
    logo: images.btcManager,
    author: {
      name: 'Ogwu Osaemezu Emmanuel'
    }
  },
  {
    content:
      'Legacy mechanisms in the traditional financial system have proven outdated and incapable of adjusting to ' +
      'current consumer needs. ...DeFi pioneers like LocalTrade have envisioned a revolution in the financial ' +
      'system where all services are offered low-cost, fairly and securely without any middlemen.',
    link: 'https://www.ibtimes.com/defi-mass-adoption-can-only-happen-if-platforms-make-things-easier-users-localtrade-3301297',
    logo: images.ibt
  },
  {
    content:
      'Companies that bring DeFi into the mainstream are doing a huge service not only to the world of ' +
      'technology but also to the entire human society. This is exactly the task that LocalTrade has taken upon ' +
      'itself, providing users with a full set of tools that will allow them to enter the world of DeFi as smoothly ' +
      'as possible.',
    link: 'https://www.newsbtc.com/news/company/can-decentralized-finance-become-the-futures-new-digital-economy/',
    logo: images.newsBtc
  }
]
