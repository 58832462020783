import * as Bs from '@bootstrap-styled/v4'
import styled from 'styled-components'

import { mediaQuery } from '~utils'

export const Headline1 = styled.h1`
  font-weight: 600;
  font-size: 72px;
  line-height: 120%;
  margin: 0;
  ${mediaQuery.lessThan('md')`
  font-size: 35px;`}
`

export const Headline2 = styled.h2`
  font-weight: 600;
  font-size: 55px;
  line-height: 120%;
  margin: 0;
  ${mediaQuery.lessThan('md')`
  font-size: 30px;`}
`

export const Headline3 = styled.h3`
  font-weight: 600;
  font-size: 36px;
  line-height: 120%;
  margin: 0;
  ${mediaQuery.lessThan('md')`
  font-size: 24px;`}
`

export const Headline4 = styled.h4`
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  margin: 0;
  ${mediaQuery.lessThan('md')`
  font-size: 18px;`}
`

export const BodyL = styled.p<{ $bold?: boolean }>`
  font-size: 18px;
  line-height: 140%;
  font-weight: ${({ $bold }) => ($bold ? 600 : 400)};
  margin: 0;
  ${mediaQuery.lessThan('md')`
  font-size: 16px;`}
`

export const BodyM = styled.p<{ $bold?: boolean }>`
  font-size: 16px;
  line-height: 140%;
  font-weight: ${({ $bold }) => ($bold ? 600 : 400)};
  margin: 0;
  ${mediaQuery.lessThan('md')`
  font-size: 14px;`}
`

export const BodyS = styled.small<{ $bold?: boolean }>`
  font-size: 14px;
  line-height: 140%;
  font-weight: ${({ $bold }) => ($bold ? 600 : 400)};
  margin: 0;
`

export const ButtonText = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
`

export const A = styled(Bs.A)`
  color: ${({ theme, color }) => theme.colors[color]};
  text-decoration: none;
  font-weight: 700;
  cursor: pointer;
`

export const A2 = ({ ...props }: any) => <A target="_blank" {...props} />
