import * as Bs from '@bootstrap-styled/v4'
import BigNumber from 'bignumber.js'
import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import { Amount, Block, Label, MarginWrapper, SmartMargin } from '../../../components'
import { calculateReward } from '../utils'

import { MediaQueryBreakpoint } from '~enums'
import { CentralizedVault, DynamicVault } from '~interfaces'
import { NumberFormatter } from '~utils'

export const Info = ({
  vault,
  claimed,
  children
}: PropsWithChildren<{
  vault: DynamicVault | CentralizedVault
  claimed: BigNumber
}>) => {
  const { t } = useTranslation('vault')

  const isLg = useMediaQuery({ maxWidth: MediaQueryBreakpoint.lg })

  const fullReward = calculateReward(vault, true)

  return (
    <MarginWrapper>
      <Bs.Row noGutters>
        <Bs.Col xs={12} lg={6}>
          <SmartMargin right>
            <Block direction="row">
              <Label>{t('Staked')}:</Label>{' '}
              <Amount>
                {NumberFormatter.toDecimal(vault.contract.staked, vault.stakeTicker).toFixed(
                  4,
                  BigNumber.ROUND_DOWN
                )}{' '}
                {vault.stakeTicker}
              </Amount>
            </Block>
          </SmartMargin>
        </Bs.Col>
        <Bs.Col xs={12} lg={6}>
          <SmartMargin top={isLg} left>
            <Block direction="row">
              <Label>{t('Est. earn')}:</Label>{' '}
              <Amount>
                {NumberFormatter.toDecimal(
                  fullReward.lt(0) ? new BigNumber(0) : fullReward,
                  vault.rewardTicker
                ).toFixed(2, BigNumber.ROUND_DOWN)}{' '}
                {vault.rewardTicker}
              </Amount>
            </Block>
          </SmartMargin>
        </Bs.Col>
      </Bs.Row>
      <SmartMargin top>
        <Block direction="row">
          <Label>{t('Claimed')}:</Label>{' '}
          <Amount>
            {NumberFormatter.toDecimal(claimed, vault.rewardTicker).toFixed(
              5,
              BigNumber.ROUND_DOWN
            )}{' '}
            {vault.rewardTicker}
          </Amount>
        </Block>
      </SmartMargin>
      {children}
    </MarginWrapper>
  )
}
