import BigNumber from 'bignumber.js'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { TickerBEP20, TickerStaking } from '~enums'
import { Vault } from '~interfaces'
import {
  AppState,
  dynamicVaultsActions,
  staticVaultsActions,
  useAppDispatch,
  DynamicVaultsState,
  StaticVaultsState,
  CentralizedVaultsState,
  centralizedVaultsActions
} from '~state'

export const useDynamicVaults = (): {
  dynamicVaults: Array<DynamicVaultsState['']>
  allowances: (address: string) => Partial<Record<TickerBEP20, BigNumber>>
  approve: (address: string, ticker: TickerBEP20) => void
  stakeFee: (address: string) => BigNumber | undefined
  unstakeFee: (address: string) => BigNumber | undefined
  executeClaim: (address: string) => void
  executeStake: (address: string) => void
  executeUnstake: (address: string) => void
  stakeValue: (address: string) => BigNumber
  onSetSpend: (address: string, value: string) => void
  onSetTicker: (address: string, ticker: TickerStaking) => void
  spendValue: (address: string) => string
  spendTicker: (address: string) => TickerStaking
  unstakeValue: (address: string) => string
  onSetUnstakeValue: (address: string, value: string) => void
  averagePrice: (address: string) => BigNumber | undefined
} => {
  const dispatch = useAppDispatch()
  const dynamicVaults = useSelector((state: AppState) => state.dynamicVaults)
  const gasPrice = useSelector((state: AppState) => state.web3.gasPrice)

  useEffect(() => {
    dispatch(dynamicVaultsActions.startListening())
    return () => {
      dispatch(dynamicVaultsActions.endListening())
    }
  }, [])

  return {
    dynamicVaults: Object.values(dynamicVaults),
    stakeValue: (address) => dynamicVaults[address].form.stakeValue,
    unstakeValue: (address) => dynamicVaults[address].form.unstakeValue,
    onSetSpend: (address, value) =>
      dispatch(
        dynamicVaultsActions.setSpend({
          value,
          address
        })
      ),
    onSetTicker: (address, ticker) =>
      dispatch(
        dynamicVaultsActions.setTicked({
          ticker,
          address
        })
      ),
    spendTicker: (address) => dynamicVaults[address].form.spendTicker,
    spendValue: (address) => dynamicVaults[address].form.spendValue,
    averagePrice: (address) => dynamicVaults[address].form.averagePrice,
    onSetUnstakeValue: (address, value) =>
      dispatch(
        dynamicVaultsActions.setUnstakeValue({
          value,
          address
        })
      ),
    stakeFee: (address) => gasPrice && dynamicVaults[address].form.stakeGas?.multipliedBy(gasPrice),
    unstakeFee: (address) =>
      gasPrice && dynamicVaults[address].form.unstakeGas?.multipliedBy(gasPrice),
    allowances: (address) => dynamicVaults[address].allowances,
    approve: (address, ticker) =>
      dispatch(
        dynamicVaultsActions.approve({
          ticker,
          spender: address
        })
      ),
    executeClaim: (address: string) => dispatch(dynamicVaultsActions.executeClaim({ address })),
    executeStake: (address: string) => dispatch(dynamicVaultsActions.executeStake({ address })),
    executeUnstake: (address: string) => dispatch(dynamicVaultsActions.executeUnstake({ address }))
  }
}

export const useCentralizedVaults = (): {
  centralizedVaults: Array<CentralizedVaultsState['']>
  allowances: (address: string) => Partial<Record<TickerBEP20, BigNumber>>
  approve: (address: string, ticker: TickerBEP20) => void
  stakeFee: (address: string) => BigNumber | undefined
  unstakeFee: (address: string) => BigNumber | undefined
  executeClaim: (address: string) => void
  executeStake: (address: string) => void
  executeUnstake: (address: string) => void
  stakeValue: (address: string) => BigNumber
  onSetSpend: (address: string, value: string) => void
  onSetTicker: (address: string, ticker: TickerStaking) => void
  spendValue: (address: string) => string
  spendTicker: (address: string) => TickerStaking
  unstakeValue: (address: string) => string
  onSetUnstakeValue: (address: string, value: string) => void
  averagePrice: (address: string) => BigNumber | undefined
  setUserId: (address: string, userId: string) => void
} => {
  const dispatch = useAppDispatch()
  const centralizedVaults = useSelector((state: AppState) => state.centralizedVaults)
  const gasPrice = useSelector((state: AppState) => state.web3.gasPrice)

  useEffect(() => {
    dispatch(centralizedVaultsActions.startListening())
    return () => {
      dispatch(centralizedVaultsActions.endListening())
    }
  }, [])

  return {
    centralizedVaults: Object.values(centralizedVaults),
    stakeValue: (address) => centralizedVaults[address].form.stakeValue,
    unstakeValue: (address) => centralizedVaults[address].form.unstakeValue,
    onSetSpend: (address, value) =>
      dispatch(
        centralizedVaultsActions.setSpend({
          value,
          address
        })
      ),
    onSetTicker: (address, ticker) =>
      dispatch(
        centralizedVaultsActions.setTicker({
          ticker,
          address
        })
      ),
    spendTicker: (address) => centralizedVaults[address].form.spendTicker,
    spendValue: (address) => centralizedVaults[address].form.spendValue,
    onSetUnstakeValue: (address, value) =>
      dispatch(
        centralizedVaultsActions.setUnstakeValue({
          value,
          address
        })
      ),
    averagePrice: (address) => centralizedVaults[address].form.averagePrice,
    stakeFee: (address) =>
      gasPrice && centralizedVaults[address].form.stakeGas?.multipliedBy(gasPrice),
    unstakeFee: (address) =>
      gasPrice && centralizedVaults[address].form.unstakeGas?.multipliedBy(gasPrice),
    allowances: (address) => centralizedVaults[address].allowances,
    approve: (address, ticker) =>
      dispatch(
        centralizedVaultsActions.approve({
          ticker,
          spender: address
        })
      ),
    executeClaim: (address: string) => dispatch(centralizedVaultsActions.executeClaim({ address })),
    executeStake: (address: string) => dispatch(centralizedVaultsActions.executeStake({ address })),
    executeUnstake: (address: string) =>
      dispatch(centralizedVaultsActions.executeUnstake({ address })),
    setUserId: (address: string, userId: string) =>
      dispatch(centralizedVaultsActions.setUserId({ address, userId }))
  }
}

export const useStaticVaults = (): {
  staticVaults: Array<StaticVaultsState['']>
  allowances: (address: string) => Partial<Record<TickerBEP20, BigNumber>>
  approve: (address: string, ticker: TickerBEP20) => void
  unstakeFee: (address: string, index: number) => BigNumber | undefined
  executeUnstake: (address: string, index: number) => void
  stakeFee: (address: string) => BigNumber | undefined
  executeStake: (address: string) => void
  stakeValue: (address: string) => BigNumber
  onSetSpend: (address: string, value: string) => void
  onSetTicker: (address: string, ticker: TickerStaking) => void
  spendValue: (address: string) => string
  spendTicker: (address: string) => TickerStaking
  executeClaim: (address: string) => void
  averagePrice: (address: string) => BigNumber | undefined
} => {
  const dispatch = useAppDispatch()
  const staticVaults = useSelector((state: AppState) => state.staticVaults)
  const gasPrice = useSelector((state: AppState) => state.web3.gasPrice)

  useEffect(() => {
    dispatch(staticVaultsActions.startListening())
    return () => {
      dispatch(staticVaultsActions.endListening())
    }
  }, [])

  return {
    staticVaults: Object.values(staticVaults),
    unstakeFee: (address, index) =>
      gasPrice &&
      staticVaults[address].positionsCallsInfo[index].unstakeGas?.multipliedBy(gasPrice),
    executeUnstake: (address, index) =>
      dispatch(staticVaultsActions.executeUnstake({ address, index })),
    stakeFee: (address) => gasPrice && staticVaults[address].form.stakeGas?.multipliedBy(gasPrice),
    stakeValue: (address) => staticVaults[address].form.stakeValue,
    executeStake: (address) => dispatch(staticVaultsActions.executeStake({ address })),
    onSetSpend: (address, value) =>
      dispatch(
        staticVaultsActions.setSpend({
          value,
          address
        })
      ),
    onSetTicker: (address, ticker) =>
      dispatch(
        staticVaultsActions.setTicker({
          address,
          ticker
        })
      ),
    spendTicker: (address) => staticVaults[address].form.spendTicker,
    spendValue: (address) => staticVaults[address].form.spendValue,
    averagePrice: (address) => staticVaults[address].form.averagePrice,
    executeClaim: (address) => dispatch(staticVaultsActions.executeClaim({ address })),
    allowances: (address) => staticVaults[address].allowances,
    approve: (address, ticker) =>
      dispatch(
        staticVaultsActions.approve({
          ticker,
          spender: address
        })
      )
  }
}

export const useVaults = (): Vault[] => {
  const { staticVaults } = useStaticVaults()
  const { dynamicVaults } = useDynamicVaults()
  const { centralizedVaults } = useCentralizedVaults()

  return [
    ...Object.values(staticVaults),
    ...Object.values(dynamicVaults),
    ...Object.values(centralizedVaults)
  ]
}
