import * as Bs from '@bootstrap-styled/v4'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import React, { PropsWithChildren, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'

import { NavItem } from './NavItem'
import { Toggle } from './Toggle'

import { LANGUAGES } from '~config'
import { MediaQueryBreakpoint } from '~enums'
import { Icon } from '~ui'
import { mediaQuery } from '~utils'

const DropdownToggle = styled(Bs.DropdownToggle)`
  padding: 0 !important;
  margin: 0 !important;
  line-height: 0 !important;
  border: none !important;
  background: none !important;
  display: flex !important;
  box-shadow: none !important;
  align-items: center;
  font-weight: normal !important;

  ${mediaQuery.lessThan('lg')`
    height: auto;
    line-height: 24px !important;
  `}

  &::after {
    transition: transform ease-in-out 0.2s;
  }

  ${({ active }) =>
    active &&
    `
    &::after {
      transform: rotate(180deg);
    }
  `}
`

const DropdownMenu = styled(Bs.DropdownMenu)`
  top: 90% !important;
  left: unset !important;
  min-width: unset !important;
  border-radius: 24px !important;
  border: 2px solid ${({ theme }) => theme.colors.secondaryShade1} !important;
  background: ${({ theme }) => theme.colors.secondaryShade1} !important;
  padding: 0 !important;
  backdrop-filter: blur(50px);

  ${mediaQuery.lessThan('lg')`
    top: 80% !important;
  `}
`

const DropdownItem = styled(Bs.DropdownItem)`
  padding: 5px 15px !important;
  font-size: 16px;
  cursor: pointer;
  text-align: center !important;
  margin: 0 !important;
  color: ${({ theme }) => theme.colors.white} !important;
  &:active {
    background: none !important;
  }

  &:first-child {
    border-radius: 24px 24px 0 0 !important;
    padding-top: 11px !important;
    &:hover {
      padding-top: 11px !important;
      border-top: none !important;
    }
  }

  &:last-child {
    border-radius: 0 0 24px 24px !important;
    padding-bottom: 11px !important;
    &:hover {
      padding-bottom: 11px !important;
      border-bottom: none !important;
    }
  }

  &:hover {
    padding: 3px 15px !important;
    border-top: 2px solid ${({ theme }) => theme.colors.secondaryShade1} !important;
    border-bottom: 2px solid ${({ theme }) => theme.colors.secondaryShade1} !important;
    background: ${({ theme }) => theme.colors.secondaryShade1} !important;
  }
`

const LangBlock = ({
  value,
  onItemClick,
  children,
  className,
  caret
}: Required<
  PropsWithChildren<{
    value: string
    className?: string
    onItemClick: (value: string) => unknown
  }>
> & { caret?: boolean }) => {
  const [isOpen, setIsOpen] = useState(false)

  const { i18n } = useTranslation()

  const languages = i18n.languages.map((l) => ({
    code: l,
    value: LANGUAGES[l as keyof typeof LANGUAGES]
  }))

  const isLg = useMediaQuery({ minWidth: MediaQueryBreakpoint.lg })

  return isLg ? (
    <Bs.Dropdown
      className={`${className} d-flex justify-content-lg-center`}
      onClick={(e: any) => {
        e.stopPropagation()
      }}
      isOpen={isOpen}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      toggle={() => setIsOpen(!isOpen)}
    >
      <DropdownToggle active={isOpen} caret={caret}>
        {children}
      </DropdownToggle>
      <DropdownMenu>
        {languages
          .filter((l) => l.code !== value)
          .map((l) => (
            <DropdownItem
              onClick={() => {
                onItemClick(l.code)
                setIsOpen(false)
              }}
              key={l.code}
            >
              {getUnicodeFlagIcon(l.code.substr(3))} {l.value}
            </DropdownItem>
          ))}
      </DropdownMenu>
    </Bs.Dropdown>
  ) : (
    <>
      <NavItem>
        <Toggle active={isOpen} onClick={() => setIsOpen(!isOpen)}>
          {children}
        </Toggle>
      </NavItem>
      <Bs.Collapse className="w-100" isOpen={isOpen}>
        {languages.map((l, i) => (
          <NavItem nested key={l.code}>
            <div
              onClick={() => {
                onItemClick(l.code)
                setIsOpen(false)
              }}
            >
              {getUnicodeFlagIcon(l.code.substr(3))} {l.value}
            </div>
          </NavItem>
        ))}
      </Bs.Collapse>
    </>
  )
}

export const LanguageButton = styled(({ className }) => {
  const { i18n, t } = useTranslation('navbar')

  const [lang, setLang] = useState(i18n.language)

  const isLg = useMediaQuery({ minWidth: MediaQueryBreakpoint.lg })

  return (
    <LangBlock
      className={className}
      value={lang}
      onItemClick={(l) => {
        setLang(l)
        i18n.changeLanguage(l)
      }}
      caret={!isLg}
    >
      {isLg ? <Icon className="p-0" name="language" /> : <div>{t('Language')}</div>}
    </LangBlock>
  )
})`
  text-transform: uppercase;
  padding: 8px 10px !important;
  justify-content: space-between;
  ${mediaQuery.lessThan('lg')`
    display: block !important;
    padding: 16px 20px !important;
    font-weight: normal !important;
  `}
`
