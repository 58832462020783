import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface BlocksState {
  number?: number
}

export type BlocksSetAction = PayloadAction<number>

export const blocksSlice = createSlice({
  name: 'blocks',
  initialState: {} as BlocksState,
  reducers: {
    set: (state, action: BlocksSetAction) => {
      state.number = action.payload
    }
  }
})

export const { actions: blocksActions, reducer: blocksReducer } = blocksSlice
