import GoogleTagManager from '@redux-beacon/google-tag-manager'
import { createMiddleware, EventDefinition, EventsMapper } from 'redux-beacon'

import { ConnectorNames } from '~enums'
import { web3Actions } from '~state'

const gtm = GoogleTagManager()

const cnToLabel = {
  [ConnectorNames.BSC]: 'Binance Wallet',
  [ConnectorNames.Injected]: 'Metamask',
  [ConnectorNames.WalletConnect]: 'WalletConnect'
}

const walletConnectEvent: EventDefinition = ({ payload: { wallet, failedReason, address } }) => ({
  event: 'Wallet Connect',
  action: failedReason ? `Failed - ${failedReason}` : 'Success',
  Wallet: cnToLabel[wallet as ConnectorNames],
  ...(address ? { Address: address } : {})
})

const eventsMap: EventsMapper = (action) => {
  return {
    [web3Actions.walletConnect.type]: walletConnectEvent
  }[action.type]
}

export const gtmMiddleware = createMiddleware(eventsMap, gtm)
