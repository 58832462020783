import { ConnectButton } from '../../ConnectButton'

import { Buy } from './Buy'
import { Info } from './Info'
import { TokenSaleCountdown } from './TokenSaleCountdown'

import { Card } from '~components'
import { useActiveWeb3React } from '~hooks'
import { Security } from '~interfaces'
import { useAccountModal } from '~state'
import { WithRequired } from '~utils'

export const MainInfo = ({ security }: { security: WithRequired<Security, 'contract'> }) => {
  const { account } = useActiveWeb3React()
  const [, { open: openAccountModal }] = useAccountModal()
  return (
    <Card withBorder className="h-auto">
      <Info
        color={security.color}
        title={security.name}
        contract={security.contract}
        logoURL={security.logoURL}
        valuation={security.valuation}
        annualYieldPercent={security.annualYieldPercent}
        foundationYear={security.foundationYear}
      />
      {account ? (
        <Buy
          securityId={security.id}
          allowances={security.contract.allowance}
          decimals={security.contract.decimals}
          balance={security.contract.balance}
          sellable={security.contract.sellable}
          buyTicker={security.contract.ticker}
          initialPrice={security.contract.initialPrice}
          raised={security.contract.raised}
          total={security.contract.total}
          receiveTickerLogoURL={security.tickerLogoURL}
          startDate={security.contract.startDate}
          endDate={security.contract.endDate}
        />
      ) : (
        <>
          <TokenSaleCountdown endDate={security.contract.endDate} />
          <ConnectButton onClick={openAccountModal} />
        </>
      )}
    </Card>
  )
}
