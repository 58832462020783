import BigNumber from 'bignumber.js'

import usa from '../images/usa.svg'

import logo from './images/logo.svg'

import { SecurityStatus } from '~enums'
import { Security } from '~interfaces'

export const digitalOcean: Security = {
  status: SecurityStatus.FINISHED,
  id: 'digitalOcean',
  name: 'DigitalOcean',
  description:
    'Digital Ocean is a New York based cloud platform for application development teams.',
  more: 'https://digitalocean.com/',
  city: 'New York',
  foundationYear: 2012,
  annualYieldPercent: 31,
  valuation: new BigNumber(1100000000),
  countryIconURL: usa,
  color: '#0069FF',
  typeOfEquity: '',
  industry: '',
  leadInvestors: [],
  softcap: new BigNumber(0),
  docs: {
    license: '',
    agreement: '',
    certificate: ''
  },
  tickerLogoURL: logo,
  logoURL: logo
}
