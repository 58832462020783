import * as Bs from '@bootstrap-styled/v4'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router'
import { Redirect } from 'react-router-dom'
import { SyncLoader } from 'react-spinners'
import styled from 'styled-components'

import { theme } from '../../theme'

import {
  AboutCompany,
  Container,
  OfferingDetails,
  TextWithButton,
  LegalPaperwork,
  MainInfo,
  Icon
} from '~components'
import { routes } from '~config'
import { MediaQueryBreakpoint } from '~enums'
import { Security } from '~interfaces'
import { useSecurityFromRouter } from '~state'
import { mediaQuery, WithRequired } from '~utils'

const LoaderWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const BackButton = styled.div`
  width: 146px;
  height: 108px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.secondaryShade1};
  border: 1px solid ${({ theme }) => theme.colors.secondaryShade1};
  border-radius: 24px;
  cursor: pointer;
  position: absolute;
  left: -46px;
  ${Icon} {
    margin-left: 46px;
  }
`

const Col = styled(Bs.Col)`
  & > div {
    margin-bottom: 40px;
    ${mediaQuery.lessThan('md')`
         margin-bottom: 20px;
    `}
  }
`

export const SecurityPage = () => {
  const { t } = useTranslation('security')
  const security = useSecurityFromRouter()
  const history = useHistory()
  const isMd = useMediaQuery({ maxWidth: MediaQueryBreakpoint.md })

  // if securities not fetched, hook returns null, if fetched but not found - undefined
  if (security === null)
    return (
      <LoaderWrapper>
        <SyncLoader color={theme.$primary} />
      </LoaderWrapper>
    )

  if (!security || !security.contract || !security.address)
    return <Redirect to={routes.neoBroker} />

  return (
    <>
      <Helmet>
        <title>LocalTrade DeFi Lab | NeoBroker Smart Fund</title>
        <meta
          name="description"
          content="The only DeFi platform to buy & trade tokenized securities of private companies at pre-IPO phase."
        />
      </Helmet>
      <Container>
        {!isMd && (
          <BackButton onClick={() => history.push(routes.neoBroker)}>
            <Icon name="arrow" width={20} height={20} />
          </BackButton>
        )}
        <Bs.Row>
          <Col lg={6}>
            <MainInfo security={security as WithRequired<Security, 'contract'>} />
            <TextWithButton
              text={t('How to buy') + '?'}
              btnText={t('Guide me') + '!'}
              link="https://docs.localtrade.cc/products/neobroker-smart-fund/user-guide"
            />
            {/* <ContractInfo
              address={security.address}
              decimals={security.contract.decimals}
              icon={security.tickerLogoURL}
              ticker={security.contract.ticker}
            /> */}
            <LegalPaperwork docs={security.docs} />
          </Col>
          <Col>
            <OfferingDetails
              contract={security.contract}
              typeOfEquity={security.typeOfEquity}
              softcap={security.softcap}
            />
            <AboutCompany
              more={security.more}
              valuation={security.valuation}
              description={security.description}
              foundationYear={security.foundationYear}
              industry={security.industry}
              leadInvestors={security.leadInvestors}
              annualYieldPercent={security.annualYieldPercent}
            />
            <TextWithButton
              text={t('More info on crunchbase')}
              btnText={t('Explore')}
              link={'https://www.crunchbase.com/organization/' + security.name.toLowerCase()}
            />
          </Col>
        </Bs.Row>
      </Container>
    </>
  )
}
