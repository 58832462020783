import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppState, userActions, UserState } from '../store'

export function useUser(): UserState {
  const userState = useSelector((state: AppState) => state.user)
  const account = useSelector((state: AppState) => state.web3.account)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!account) return
    dispatch(userActions.getByAddress(account))
  }, [account])

  return userState
}
