import BigNumber from 'bignumber.js'

import { AddStakingProps, AddStaking } from '../components'

import { calculateReward } from '~components/staking/VaultsAndLiquidityPools/Vaults/StaticVault/utils'
import { StaticVault } from '~interfaces'
import { NumberFormatter } from '~utils'

export const Stake = ({
  stakeValue,
  vault,
  ...props
}: Omit<AddStakingProps, 'estEarn'> & { vault: StaticVault }) => {
  const currentDate = new Date()
  const estEarn = stakeValue.eq(0)
    ? new BigNumber(0)
    : calculateReward(
        {
          rewardInterval: vault.contract.rewardInterval,
          secondlyRewardPer1e16: vault.contract.secondlyRewardPer1e16,
          hasDao: !vault.singleStaker
        },
        {
          staked: stakeValue,
          lastRewardDate: currentDate,
          startDate: currentDate
        },
        true
      )

  return (
    <AddStaking
      stakeValue={stakeValue}
      vault={vault}
      estEarn={NumberFormatter.formatDecimal(estEarn, vault.rewardTicker)}
      {...props}
    />
  )
}
