import { BaseApi } from '../BaseApi'

import { StakingsKind } from './constants'

import { Tokens } from '~config'
import { StakingsDataApi } from '~interfaces'

export class StakingsApi extends BaseApi {
  async getStakings(kind: StakingsKind): Promise<StakingsDataApi[]> {
    const response = await fetch(`${this.baseUrl}/stakings/?kind=${kind}`)
    const data = await response.json()
    return data.map((d: any) => ({
      address: d.address,
      ...d.meta,
      stakeFromTokens: Tokens.filter(({ ticker }) => d.meta.stakeFromTickers.includes(ticker))
    }))
  }
}
