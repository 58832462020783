import React from 'react'
import { Helmet } from 'react-helmet'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { useErrorBoundary, withErrorBoundary } from 'react-use-error-boundary'

import { SubHeader, Navbar, AccountModal, Footer, Spinner, UserProfileModal } from '~components'
import { TransactionModal } from '~components/modals/TransactionModal'
import { routes } from '~config'
import { useEagerConnect, useInactiveListener } from '~hooks'
import {
  HomePage,
  MaintenancePage,
  NotFoundPage,
  SalePage,
  SecuritiesPage,
  SecurityPage,
  StakingPage
} from '~pages'
import { useWeb3Update, useGetConfigQuery } from '~state'
import { specialLinks, ScrollToTop } from '~utils'

const App = withErrorBoundary(() => {
  const { data, isLoading } = useGetConfigQuery()
  useEagerConnect()
  useWeb3Update()
  useInactiveListener()
  const [error] = useErrorBoundary((error) => alert(error.message))

  if (isLoading || !data) return <Spinner />

  if (error) {
    return <div>Ooops, looks like something went wrong. The team was notified.</div>
  }
  return (
    <>
      <Helmet>
        <title>LocalTrade DeFi Lab Tokensale. Buy LTT Token</title>
        <meta
          name="description"
          content={
            'LocalTrade Token (LTT) is a BEP-20 standard token minted on the Binance Smart Chain. ' +
            'LTT token combines utility and governance functionality, powering up the whole LocalTrade ecosystem.'
          }
        />
      </Helmet>
      <Router>
        {!data.maintenance ? (
          <>
            <AccountModal />
            <UserProfileModal />
            <TransactionModal />
            <SubHeader />
            <Navbar />
            <ScrollToTop />
            <Switch>
              <Route path={routes.sale} exact>
                <SalePage />
              </Route>
              <Route path={[routes.home, ...Object.keys(specialLinks)]} exact>
                <HomePage />
              </Route>
              <Redirect from={routes.oldSale} to={routes.sale} />
              <Route path={routes.home + 'ref/:id'} exact>
                <HomePage />
              </Route>
              <Redirect from={routes.marketplace} to={routes.neoBroker} exact />
              <Redirect
                from={routes.marketplace + '/:securityId'}
                to={routes.neoBroker + '/:securityId'}
              />
              <Route path={routes.neoBroker} exact>
                <SecuritiesPage />
              </Route>
              <Route path={routes.neoBroker + '/:securityId'} exact>
                <SecurityPage />
              </Route>
              <Route path={routes.staking} exact>
                <StakingPage />
              </Route>
              <Route path={routes.staking + '/ref/:id'} exact>
                <StakingPage />
              </Route>
              <Route path="*">
                <NotFoundPage />
              </Route>
            </Switch>
          </>
        ) : (
          <MaintenancePage />
        )}

        <Footer />
      </Router>
    </>
  )
})

export default App
