import bold from './Gilroy-Bold.woff'
import boldItalic from './Gilroy-BoldItalic.woff'
import extraBold from './Gilroy-ExtraBold.woff'
import extraBoldItalic from './Gilroy-ExtraBoldItalic.woff'
import heavy from './Gilroy-Heavy.woff'
import heavyItalic from './Gilroy-HeavyItalic.woff'
import light from './Gilroy-Light.woff'
import lightItalic from './Gilroy-LightItalic.woff'
import medium from './Gilroy-Medium.woff'
import mediumItalic from './Gilroy-MediumItalic.woff'
import regular from './Gilroy-Regular.woff'
import regularItalic from './Gilroy-RegularItalic.woff'
import semiBold from './Gilroy-SemiBold.woff'
import semiBoldItalic from './Gilroy-SemiBoldItalic.woff'
import thin from './Gilroy-Thin.woff'
import thinItalic from './Gilroy-ThinItalic.woff'
import ultraLight from './Gilroy-UltraLight.woff'
import ultraLightItalic from './Gilroy-UltraLightItalic.woff'

export const GILROY_FONT = [
  { file: thin, weight: 100 },
  { file: thinItalic, weight: 100, italic: true },
  { file: ultraLight, weight: 200 },
  { file: ultraLightItalic, weight: 200, italic: true },
  { file: light, weight: 300 },
  { file: lightItalic, weight: 300, italic: true },
  { file: regular, weight: 400 },
  { file: regularItalic, weight: 400, italic: true },
  { file: medium, weight: 500 },
  { file: mediumItalic, weight: 500, italic: true },
  { file: semiBold, weight: 600 },
  { file: semiBoldItalic, weight: 600, italic: true },
  { file: bold, weight: 700 },
  { file: boldItalic, weight: 700, italic: true },
  { file: extraBold, weight: 800 },
  { file: extraBoldItalic, weight: 800, italic: true },
  { file: heavy, weight: 900 },
  { file: heavyItalic, weight: 900, italic: true }
]
