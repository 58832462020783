import { Web3Provider } from '@ethersproject/providers'
import { Pair, Route, TokenAmount, Trade, TradeType } from '@uniswap/sdk'
import BigNumber from 'bignumber.js'

import { UniswapTokens } from '~config'
import { Ticker, TickerStaking } from '~enums'

export async function getTrade(
  value: BigNumber,
  ticker: TickerStaking,
  library: Web3Provider
): Promise<Trade> {
  const pairs = []
  const from = UniswapTokens[ticker === Ticker.BNB ? Ticker.WBNB : ticker]
  // BASE PAIR BUSD/LTT
  const busd = UniswapTokens.BUSD
  const ltt = UniswapTokens.LTT
  const pair1: Pair = await Pair.fetchData(busd, ltt, library)

  if (ticker !== Ticker.BUSD) {
    // ANY_TICKER/BUSD
    const pair2: Pair = await Pair.fetchData(from, busd, library)
    pairs.unshift(pair2)
  }

  pairs.push(pair1)

  const route = new Route(pairs, from)

  const trade = new Trade(route, new TokenAmount(from, value.toString()), TradeType.EXACT_INPUT)

  return trade
}
