import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import logo from './logo.svg'

import { BodyM } from '~ui'
import { mediaQuery } from '~utils'

const AllRightsReserved = styled(BodyM)`
  order: 2;
  color: ${({ theme }) => theme.colors.secondary};
`

const Image = styled.img.attrs(() => ({
  src: logo,
  alt: 'Logo'
}))`
  order: 1;
`

export const Info = styled(({ className }: { className?: string }) => {
  const { t } = useTranslation('common')

  return (
    <div className={className}>
      <AllRightsReserved>{t('© LocalTrade. All rights reserved')}</AllRightsReserved>
      <Image />
    </div>
  )
})`
  display: flex;
  padding: 30px 40px;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.colors.secondaryShade1};
  border-radius: 24px 24px 0px 0px;
  p {
    margin: 0;
  }
  ${mediaQuery.lessThan('md')`
        gap:20px;
        padding: 30px;
        flex-direction: column;
        justify-content: center;
  
    `};
`
