import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { resolveGlobalInviter } from '~utils'

export interface InviterState {
  value: string
  mutable: boolean
}

export type SetInviterAction = PayloadAction<{
  value: string
  mutable: boolean
}>

const initialState: InviterState = {
  value: resolveGlobalInviter() ?? '',
  mutable: true
}

export const inviterSlice = createSlice({
  name: 'inviter',
  initialState,
  reducers: {
    startListening: (state) => state,
    endListening: (state) => state,
    setInviter(state, action: SetInviterAction) {
      return action.payload
    }
  }
})

export const { actions: inviterActions, reducer: inviterReducer } = inviterSlice
