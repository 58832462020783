import { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { MEDIA_QUERY_BREAKPOINTS, MediaQueryBreakpoint } from '~enums'
import { mediaQuery } from '~utils'

interface Sides {
  left?: number
  right?: number
  top?: number
  bottom?: number
}

export type FloatingTextProps = PropsWithChildren<
  { inPercent?: boolean } & Sides & Partial<Record<keyof typeof MediaQueryBreakpoint, Sides>>
>

export const FloatingText = styled.div<FloatingTextProps>`
  position: absolute;
  line-height: 0.8;
  left: ${({ left, inPercent }) => left !== undefined && `${left}${inPercent ? '%' : 'px'}`};
  right: ${({ right, inPercent }) => right !== undefined && `${right}${inPercent ? '%' : 'px'}`};
  top: ${({ top, inPercent }) => top !== undefined && `${top}${inPercent ? '%' : 'px'}`};
  bottom: ${({ bottom, inPercent }) =>
    bottom !== undefined && `${bottom}${inPercent ? '%' : 'px'}`};
  ${(props) =>
    MEDIA_QUERY_BREAKPOINTS.map(
      (b) =>
        props[b] &&
        mediaQuery.greaterThan(b)`
    left: ${props[b]?.left !== undefined && `${props[b]?.left}${props.inPercent ? '%' : 'px'}`};
    right: ${props[b]?.right !== undefined && `${props[b]?.right}${props.inPercent ? '%' : 'px'}`};
    top: ${props[b]?.top !== undefined && `${props[b]?.top}${props.inPercent ? '%' : 'px'}`};
    bottom: ${
      props[b]?.bottom !== undefined && `${props[b]?.bottom}${props.inPercent ? '%' : 'px'}`
    };
  `
    )}
`
