import React from 'react'
import { Helmet } from 'react-helmet'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Container } from '~components'
import { A, A2, Headline1, Headline4 } from '~ui'

export const NotFoundPage = () => {
  const { t } = useTranslation('common')
  return (
    <>
      <Helmet>
        <title>LocalTrade DeFi Lab</title>
      </Helmet>
      <Container>
        <Headline1>😬 {t('Oops! This page was not found')}</Headline1>
        <Headline4>
          <Trans
            t={t}
            i18nKey="Don't worry. You can head over to our homepage, read the docs, or check out our social pages"
          >
            <Link component={A} to="/" />
            <A2 href="https://docs.localtrade.cc" />
          </Trans>
        </Headline4>
      </Container>
    </>
  )
}
