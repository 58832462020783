import styled from 'styled-components'

import { SecondaryButton, Card, Headline3 } from '~components'

const Button = styled(SecondaryButton)`
  width: 100%;
  max-width: 260px;
`

export const TextWithButton = ({
  text,
  btnText,
  link
}: {
  text: string
  btnText: string
  link: string
}) => {
  return (
    <Card className="flex-row justify-content-between h-auto align-items-center" withBorder>
      <Headline3>{text}</Headline3>
      <Button outline onClick={() => window.open(link, '_blank')}>
        {btnText}
      </Button>
    </Card>
  )
}
