export const MASTER_CROCO_ADDRESS = process.env.REACT_APP_MASTER_CROCO_ADDRESS

export const LUSD_ADDRESS = process.env.REACT_APP_LUSD_ADDRESS

export const LTT_ADDRESS = process.env.REACT_APP_LTT_ADDRESS

export const REWARDS_LTT_ADDRESS = process.env.REACT_APP_REWARDS_LTT_ADDRESS

export const REWARDS_BUSD_ADDRESS = process.env.REACT_APP_REWARDS_BUSD_ADDRESS

export const REFERRALS_TREE_STORAGE_ADDRESS = process.env.REACT_APP_REFERRALS_TREE_STORAGE_ADDRESS

export const STAKINGS_MANAGER_ADDRESS = process.env.REACT_APP_STAKINGS_MANAGER_ADDRESS

export const ZERO_ADDRESS = process.env.REACT_APP_ZERO_ADDRESS

export const BUSD_ADDRESS = process.env.REACT_APP_BUSD_ADDRESS

export const WBNB_ADDRESS = process.env.REACT_APP_WBNB_ADDRESS

export const ROUND_A_ADDRESS = process.env.REACT_APP_ROUND_A_ADDRESS

export const ROUND_B_ADDRESS = process.env.REACT_APP_ROUND_B_ADDRESS

export const ROUND_IDO_ADDRESS = process.env.REACT_APP_ROUND_IDO_ADDRESS

export const UNSTAKER_ADDRESS = process.env.REACT_APP_UNSTAKER_ADDRESS

export const IGNORE_ADDRESS_LIST = process.env.REACT_APP_IGNORE_ADDRESS_LIST.split(',')

export const LP_BROKER_ADDRESS = process.env.REACT_APP_LP_BROKER_ADDRESS

export const CAKE_LP_LTT_BUSD_ADDRESS = process.env.REACT_APP_CAKE_LP_LTT_BUSD_ADDRESS
