import * as Bs from '@bootstrap-styled/v4'
import BigNumber from 'bignumber.js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import { MarginWrapper, SmartMargin, Button, SecondaryButton } from '../../../components'

import { MediaQueryBreakpoint } from '~enums'
import { CentralizedVault, DynamicVault } from '~interfaces'
import { NumberFormatter } from '~utils'

type VaultType = DynamicVault | CentralizedVault

export const ChooseAction = ({
  vault,
  claiming,
  executeClaim,
  add,
  availableReward,
  color,
  colorShade,
  withdraw
}: {
  vault: VaultType
  claiming: boolean
  executeClaim: () => void
  add: () => void
  colorShade?: string
  availableReward: BigNumber
  color?: string
  withdraw: () => void
}) => {
  const { t } = useTranslation(['vault', 'common'])
  const isLg = useMediaQuery({ maxWidth: MediaQueryBreakpoint.lg })

  return (
    <>
      <MarginWrapper>
        <Bs.Row noGutters>
          <Bs.Col xs={12} lg={6}>
            <SmartMargin right>
              <Button
                backgroundColor={colorShade}
                hoverBackgroundColor={color}
                onClick={withdraw}
                disabled={vault.contract.unstakeDate.getTime() > Date.now()}
              >
                {t('Withdraw')} {vault.stakeTicker}
              </Button>
            </SmartMargin>
          </Bs.Col>
          <Bs.Col xs={12} lg={6}>
            <SmartMargin top={isLg} left>
              <Button
                onClick={add}
                hoverBackgroundColor={color}
                backgroundColor={colorShade}
                disabled={vault.contract.endDate.getTime() < Date.now()}
              >
                {t('Add')} {vault.stakeTicker}
              </Button>
            </SmartMargin>
          </Bs.Col>
        </Bs.Row>
      </MarginWrapper>
      {!vault.claimHidden && (
        <SmartMargin top>
          <SecondaryButton
            wide
            outline
            loading={claiming}
            disabled={vault.claimHidden || availableReward.lte(0)}
            onClick={executeClaim}
          >
            {t('common:Claim')}{' '}
            {availableReward.lte(0)
              ? ''
              : NumberFormatter.toDecimal(availableReward, vault.rewardTicker).toFixed(5)}{' '}
            {vault.rewardTicker}
          </SecondaryButton>
        </SmartMargin>
      )}
    </>
  )
}
