import BigNumber from 'bignumber.js'

import { AddStakingProps, AddStaking } from '../components'

import { CentralizedVault } from '~interfaces'
import { NumberFormatter } from '~utils'

export const Stake = ({
  stakeValue,
  vault,
  ...props
}: Omit<AddStakingProps, 'estEarn'> & { vault: CentralizedVault }) => {
  const estEarn = stakeValue.eq(0)
    ? new BigNumber(0)
    : vault.contract.stakedTotal.eq(0)
    ? vault.contract.totalReward
    : stakeValue
        .multipliedBy(vault.contract.totalReward)
        .dividedBy(vault.contract.stakedTotal.plus(stakeValue))
        .multipliedBy(vault.contract.endDate.getTime() - Date.now())
        .dividedBy(vault.contract.endDate.getTime() - vault.contract.creationDate.getTime())

  const estEarnFormatted = NumberFormatter.formatDecimal(
    estEarn.gt(vault.contract.totalReward) ? vault.contract.totalReward : estEarn,
    vault.rewardTicker
  )
  return <AddStaking stakeValue={stakeValue} vault={vault} estEarn={estEarnFormatted} {...props} />
}
