import React, { HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Label } from './ui'

import { Ticker } from '~enums'
import { TickerValue, TransactionType } from '~state'
import { BodyL, WithColor } from '~ui'

const txTypeToI18nKey: Record<TransactionType, string> = {
  [TransactionType.STAKE]: 'Stake',
  [TransactionType.CLAIM]: 'Claim',
  [TransactionType.UNSTAKE]: 'Unstake',
  [TransactionType.ADD_LIQUIDITY]: 'lp:Add Liquidity',
  [TransactionType.ALLOW]: 'Allow',
  [TransactionType.PURCHASE]: 'Buy'
}

export const InfoBox = styled(
  ({
    values,
    txType,
    ticker,
    ...rest
  }: {
    values: TickerValue[]
    txType: TransactionType
    ticker?: Ticker
  } & HTMLAttributes<HTMLDivElement>) => {
    const { t } = useTranslation(['common', 'lp'])

    if (txType === TransactionType.ALLOW || txType === TransactionType.CLAIM)
      return (
        <div {...rest}>
          <BodyL $bold>
            {values.length !== 0
              ? values.map(({ ticker, value }) => `${value} ${ticker}`).join(', ')
              : `${t(txTypeToI18nKey[txType])}${ticker ? ' ' + ticker : ''}`}
          </BodyL>
        </div>
      )
    return (
      <div {...rest}>
        <Label>
          <WithColor $color="secondary">{t(txTypeToI18nKey[txType])}:</WithColor>
        </Label>
        <BodyL $bold>{values.map(({ ticker, value }) => `${value} ${ticker}`).join(', ')}</BodyL>{' '}
      </div>
    )
  }
)`
  border-radius: 12px;
  padding: 20px;
  width: -webkit-fill-available;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.secondaryShade1};
`
