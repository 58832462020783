import * as Bs from '@bootstrap-styled/v4'
import { useState } from 'react'
import styled, { ThemeColor } from 'styled-components'

import { BodyM } from './fonts'
import { Icon } from './Icon'

export interface TooltipProps {
  color: ThemeColor
  name: string
  text: string
  placement: 'right' | 'left' | 'top' | 'bottom'
}

const StyledTooltip = styled(Bs.Tooltip)`
  &.bs-tether-element-attached-left .tooltip-inner:before {
    left: -5px !important ;
  }
  &.bs-tether-element-attached-right .tooltip-inner:before {
    right: -5px !important;
  }
  &.bs-tether-element-attached-top .tooltip-inner:before {
    top: -5px !important;
  }
  &.bs-tether-element-attached-bottom .tooltip-inner:before {
    bottom: -5px !important;
  }
  .tooltip-inner {
    &,
    &:before {
      backdrop-filter: blur(24px);
    }
    border: 1px solid ${({ theme }) => theme.colors.secondaryShade1};
  }
`

export const Tooltip = ({ color, name, text, placement }: TooltipProps) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <Icon
        className="cursor-pointer"
        name="info"
        width={14}
        height={14}
        color={color}
        id={`tooltip-${name}`}
      />
      <StyledTooltip
        placement={placement}
        isOpen={isOpen}
        target={`tooltip-${name}`}
        toggle={() => setIsOpen(!isOpen)}
      >
        <BodyM>{text}</BodyM>
      </StyledTooltip>
    </>
  )
}
