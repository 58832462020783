import * as Bs from '@bootstrap-styled/v4'
import BigNumber from 'bignumber.js'
import React, { useState } from 'react'
import styled from 'styled-components'

import { Block, Connect, MarginWrapper, SmartMargin } from '../../../components'
import {
  ChooseAction,
  DynamicAPR,
  Footer,
  Info,
  TillStart,
  UnstakeLTT,
  VaultProgress
} from '../components'
import { BaseVaultsProps, calculateReward } from '../utils'

import { Logo, LogoImagesNames } from './Logo'
import { Stake } from './Stake'

import { LPFee } from '~config'
import { Ticker } from '~enums'
import { useActiveWeb3React } from '~hooks'
import { DynamicVaultsState, useBalance, useLP } from '~state'
import { Headline3 as Headline3Ui, Card, Icon } from '~ui'
import { NumberFormatter } from '~utils'

const Headline3 = styled(Headline3Ui)`
  color: ${({ theme }) => theme.colors.secondaryShade2};
  margin-bottom: 5px !important;
`

const CardSubtitle = styled(Bs.CardSubtitle)`
  color: ${({ theme }) => theme.colors.secondarySemi};
`

const Header = styled.div`
  margin-left: 30px;
  overflow: hidden;
  text-overflow: clip;
`

const LogoHeader = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
`

const Flames = styled((props) => <Icon name="flames" {...props} />)`
  height: 89px;
  width: 93px;
  position: absolute;
  right: 0;
  margin-top: -28px;
  transform: rotate(24deg);
  margin-right: -26px;
`

const StyledHr = styled.hr`
  border-color: ${({ theme }) => theme.colors.secondaryShade7};
`

export enum Action {
  Home = 'home',
  Add = 'add',
  Withdraw = 'withdraw'
}

type VaultProps = BaseVaultsProps & DynamicVaultsState['']

export const DynamicVault: React.FC<VaultProps> = ({
  approving,
  approve,
  stakeFee,
  unstakeFee,
  executeClaim,
  executeStake,
  executeUnstake,
  stakeValue,
  spendTicker,
  spendValue,
  unstakeValue,
  onSetSpend,
  onSetTicker,
  onSetUnstakeValue,
  inviter,
  isInviterMutable,
  onInviterChanged,
  averagePrice,
  ...v
}) => {
  const [action, setAction] = useState<Action>(Action.Home)
  const { account } = useActiveWeb3React()
  const bnbBalance = useBalance(Ticker.BNB)
  const spendBalance = useBalance(spendTicker)
  let actionNode = null
  const notStarted = v.contract.creationDate.getTime() > Date.now()

  let totalStaked = v.contract.stakedTotal
  let addedAPR = new BigNumber(0)

  if (v.stakeTicker === Ticker.LP) {
    const { totalSupply, reserves, volume } = useLP()
    totalStaked = v.contract.stakedTotal.dividedBy(totalSupply).multipliedBy(reserves.ltt)
    addedAPR = new BigNumber(volume * 365 * LPFee)
      .dividedBy(NumberFormatter.toDecimal(reserves.busd, Ticker.BUSD))
      .multipliedBy(100)
  }

  if (account) {
    actionNode = (
      <>
        <MarginWrapper>
          <StyledHr />
        </MarginWrapper>
        <ChooseAction
          vault={v}
          availableReward={calculateReward(v)}
          add={() => setAction(Action.Add)}
          withdraw={() => setAction(Action.Withdraw)}
          claiming={v.claiming}
          executeClaim={executeClaim}
        />
      </>
    )

    if (action === Action.Add)
      actionNode = (
        <>
          <MarginWrapper>
            <StyledHr />
          </MarginWrapper>
          <MarginWrapper>
            <Stake
              approving={approving}
              vault={v}
              averagePrice={averagePrice}
              stakeFromTokens={v.stakeFromTokens}
              staking={v.staking}
              back={() => setAction(Action.Home)}
              inviter={inviter}
              isInviterMutable={isInviterMutable}
              onInviterChanged={onInviterChanged}
              bnbBalance={bnbBalance}
              spendBalance={spendBalance}
              stakeValue={stakeValue}
              approve={approve}
              allowances={v.allowances}
              spendTicker={spendTicker}
              spendValue={spendValue}
              stakeFee={stakeFee}
              executeStake={executeStake}
              onSetSpend={onSetSpend}
              onSetTicker={onSetTicker}
            />
          </MarginWrapper>
        </>
      )
    else if (action === Action.Withdraw) {
      actionNode = (
        <>
          <MarginWrapper>
            <StyledHr />
          </MarginWrapper>
          <MarginWrapper>
            <UnstakeLTT
              vault={v}
              unstaking={v.unstaking}
              back={() => setAction(Action.Home)}
              bnbBalance={bnbBalance}
              unstakeValue={unstakeValue}
              unstakeFee={unstakeFee}
              executeUnstake={executeUnstake}
              onUnstakeValueChanged={onSetUnstakeValue}
            />
          </MarginWrapper>
        </>
      )
    }
  }

  return (
    <>
      {v.flames && <Flames />}
      <Card withBorder className="h-auto">
        <LogoHeader>
          {v.logo && <Logo name={v.logo as LogoImagesNames} />}
          <Header>
            <Headline3>{v.title}</Headline3>
            <CardSubtitle>{v.subtitle}</CardSubtitle>
          </Header>
        </LogoHeader>
        {notStarted ? (
          <Block margined>
            <TillStart color={v.color} startDate={v.contract.creationDate} />
          </Block>
        ) : (
          <>
            <Block margined>
              <DynamicAPR
                creationDate={v.contract.creationDate}
                endDate={v.contract.endDate}
                stakedTotal={totalStaked}
                totalReward={v.contract.totalReward}
                addedAPR={addedAPR}
                color={v.color}
              />
            </Block>
            <SmartMargin top>
              <Block>
                <VaultProgress
                  creationDate={v.contract.creationDate}
                  endDate={v.contract.endDate}
                  unstakeDate={v.contract.unstakeDate}
                  color={v.color}
                />
              </Block>
            </SmartMargin>
            {account ? (
              <>
                <MarginWrapper>
                  <StyledHr />
                </MarginWrapper>
                <Info claimed={v.contract.totalClaimed} vault={v} />
              </>
            ) : (
              <MarginWrapper>
                <Connect />
              </MarginWrapper>
            )}
            {actionNode}
          </>
        )}
        <MarginWrapper>
          <StyledHr />
        </MarginWrapper>
        <Footer vault={v} />
      </Card>
    </>
  )
}
