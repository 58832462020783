import { useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

function ScrollToTopComponent({ history }: RouteComponentProps) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0)
    })
    return () => {
      unlisten()
    }
  }, [])

  return null
}

export const ScrollToTop = withRouter(ScrollToTopComponent)
