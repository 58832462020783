import * as Bs from '@bootstrap-styled/v4'
import { useWeb3React } from '@web3-react/core'
import { InjectedConnector } from '@web3-react/injected-connector'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { TickerIcon } from '~components/ticker'
import { LTT_ADDRESS, LUSD_ADDRESS } from '~config'
import { Ticker } from '~enums'
import {
  ModalTitle,
  SecondaryButton,
  PrimaryButton as PrimaryButtonUi,
  ModalClose,
  AssetInput,
  BodyM
} from '~ui'
import { registerToken } from '~utils'

const Note = styled(BodyM)`
  display: block;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.secondary};
`

const BtnTickerIcon = styled(TickerIcon)`
  width: 22px;
  height: 22px;
  margin-right: 4px;
`

const ButtonsWrapper = styled.div`
  display: flex;
  margin-bottom: 30px;
`

const TickerButton = styled(SecondaryButton)`
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  &:first-child {
    margin-right: 15px;
  }
  &:last-child {
    margin-left: 15px;
  }
`
const PrimaryButton = styled(PrimaryButtonUi)`
  margin-top: 30px;
`

const AssetInputWithMargin = styled(AssetInput)`
  margin-bottom: 10px;
`

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Connected: React.FC<{
  logout: () => void
  onClose: () => void
}> = ({ logout, onClose }) => {
  const { t } = useTranslation('account')
  const { connector } = useWeb3React()

  return (
    <Bs.Form>
      <Flex>
        <ModalTitle>{t('Add token to your wallet')}</ModalTitle>
        <ModalClose onClick={onClose} />
      </Flex>
      {connector instanceof InjectedConnector && (
        <>
          <Note>{t('Add to Metamask')}:</Note>
          <ButtonsWrapper>
            <TickerButton onClick={() => registerToken(Ticker.LTT)} outline>
              <BtnTickerIcon ticker={Ticker.LTT} />+ {Ticker.LTT}
            </TickerButton>
            <TickerButton onClick={() => registerToken(Ticker.LUSD)} outline>
              <BtnTickerIcon ticker={Ticker.LUSD} />+ {Ticker.LUSD}
            </TickerButton>
          </ButtonsWrapper>
          <Note>{t('Add to another wallet')}:</Note>
        </>
      )}
      <AssetInputWithMargin ticker={Ticker.LTT} address={LTT_ADDRESS} />
      <AssetInput ticker={Ticker.LUSD} address={LUSD_ADDRESS} />
      <PrimaryButton block onClick={() => logout()}>
        {t('Disconnect wallet')}
      </PrimaryButton>
    </Bs.Form>
  )
}
