import { useMediaQuery } from 'react-responsive'
import { ToastContainer as ToastifyToastContainer } from 'react-toastify'
import styled from 'styled-components'

import { MediaQueryBreakpoint } from '~enums'
import { mediaQuery } from '~utils'

export const ToastContainer = styled((props) => {
  const isLg = useMediaQuery({ minWidth: MediaQueryBreakpoint.lg })
  return <ToastifyToastContainer {...props} position={isLg ? 'top-right' : 'bottom-center'} />
})`
  ${mediaQuery.greaterThan('lg')`
    width: 674px;
  `}
  ${mediaQuery.lessThan('lg')`
    padding: 20px;
  `}

  .Toastify__toast--error {
    background-color: ${({ theme }) => theme.colors.toastError};
  }

  .Toastify__toast--info {
    background-color: ${({ theme }) => theme.colors.toastInfo};
  }

  .Toastify__toast--success {
    background-color: ${({ theme }) => theme.colors.toastSuccess};
  }

  .Toastify__toast--warning {
    background-color: ${({ theme }) => theme.colors.toastWarning};
  }

  .Toastify__toast-icon {
    margin-top: 12px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  .Toastify__toast--success .Toastify__toast-icon {
    background-color: ${({ theme }) => theme.colors.toastSuccess};
  }

  .Toastify__toast--error .Toastify__toast-icon {
    background-color: ${({ theme }) => theme.colors.toastError};
  }

  .Toastify__toast--info .Toastify__toast-icon {
    background-color: ${({ theme }) => theme.colors.toastInfo};
  }

  .Toastify__toast--warning .Toastify__toast-icon {
    background-color: ${({ theme }) => theme.colors.toastWarning};
  }

  .Toastify__toast {
    width: 100%;
    border-radius: 12px !important;
    background: ${({ theme }) => theme.colors.secondaryShade5} !important;
    backdrop-filter: blur(34px);
  }

  .Toastify__progress-bar--error {
    background-color: ${({ theme }) => theme.colors.toastError};
  }

  .Toastify__progress-bar--success {
    background-color: ${({ theme }) => theme.colors.toastSuccess};
  }

  .Toastify__progress-bar--info {
    background-color: ${({ theme }) => theme.colors.toastInfo};
  }

  .Toastify__progress-bar--warning {
    background-color: ${({ theme }) => theme.colors.toastWarning};
  }

  .Toastify__progress-bar {
    bottom: 28px;
    left: 30px;
    border-radius: 6px;
    width: calc(100% - 60px);
    height: 2px;
  }

  .Toastify__toast-body {
    padding: 22px;
    margin-bottom: 20px;
    ${mediaQuery.lessThan('sm')`
       padding: 12px;
    `}
    align-items: stretch;
  }

  .Toastify__close-button {
    align-self: center;
    margin-right: 30px;
    ${mediaQuery.lessThan('sm')`
      margin-right: 20px;
    `}
    & > svg {
      height: 27px;
      width: 27px;
    }
  }
`
