import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'

import { Icon } from '../Icon'

import { MediaQueryBreakpoint } from '~enums'
import { mediaQuery } from '~utils'

type Directions = 'left' | 'right' | 'up' | 'down'

const transformDegrees: Record<Directions, number> = {
  left: 0,
  right: 180,
  up: 90,
  down: 270
}

export const ArrowButton = styled.div.attrs(() => {
  const isMd = useMediaQuery({ maxWidth: MediaQueryBreakpoint.md })
  return {
    children: <Icon width={isMd ? 15 : 20} height={isMd ? 15 : 20} name="arrow" />
  }
})`
  transform: rotate(
    ${({ direction = 'left' }: { direction?: Directions }) => transformDegrees[direction] + 'deg'}
  );
  &:hover {
    background: ${({ theme }) => theme.colors.secondaryShade1};
  }
  cursor: pointer;
  width: 60px;
  height: 60px;
  ${mediaQuery.lessThan('md')`
       width: 45px;
    height: 45px;`}
  border-radius: 12px;
  display: flex !important;
  justify-content: center;
  align-items: center;
`
