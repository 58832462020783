import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import tgImg from './images/tg.svg'
import twitterImg from './images/twitter.svg'
import youTubeImg from './images/youtube.svg'

import { BodyS } from '~components'
import { mediaQuery } from '~utils'

const IconWrapper = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  background-color: ${({ theme }) => theme.colors.secondaryShade1};
  border-radius: 50%;
  margin-right: 6px;
  justify-content: center;
  align-items: center;
`

const Icon = styled.img`
  width: 16px;
`

const Block = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  &:not(:last-child) {
    padding-right: 20px;
  }
  white-space: nowrap;
`

export const SocialMedia = styled(({ className, ...props }: any) => {
  const { t } = useTranslation('social')
  return (
    <div className={`d-flex justify-content-between ${className}`} {...props}>
      <Block onClick={() => window.open('https://t.me/lt_community', '_blank')}>
        <IconWrapper>
          <Icon src={tgImg} alt="Telegram Chat" />
        </IconWrapper>
        <BodyS>{t('TG Chat')}</BodyS>
      </Block>
      <Block onClick={() => window.open('https://t.me/localtradecc', '_blank')}>
        <IconWrapper>
          <Icon src={tgImg} alt="Telegram Group" />
        </IconWrapper>
        <BodyS>{t('TG Group')}</BodyS>
      </Block>
      <Block
        onClick={() =>
          window.open('https://www.youtube.com/channel/UCCwIJIDNvj2ZMWM2JTPPj9A', '_blank')
        }
      >
        <IconWrapper>
          <Icon src={youTubeImg} alt="YouTube" />
        </IconWrapper>
        <BodyS>{t('YouTube')}</BodyS>
      </Block>
      <Block onClick={() => window.open('https://twitter.com/LocaltradeC', '_blank')}>
        <IconWrapper>
          <Icon src={twitterImg} alt="Twitter" />
        </IconWrapper>
        <BodyS>{t('Twitter')}</BodyS>
      </Block>
    </div>
  )
})`
  ${mediaQuery.lessThan('sm')`
    overflow-x: scroll;
  `}
`
