import React from 'react'
import styled from 'styled-components'

import { Icon as IconUI } from '~ui'

const Line = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.tertiaryShade1};
`

const Icon = styled(({ className }: { className?: string }) => {
  return (
    <div className={className}>
      <IconUI name="convert" />
    </div>
  )
})`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  background-color: ${({ theme }) => theme.colors.primary};
`

const IconWrapper = styled.div`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background-color: ${({ theme }) => theme.colors.tertiaryShade1};
`

export const ConverterLine = styled(({ className }: { className?: string }) => {
  return (
    <div className={className}>
      <Line />
      <IconWrapper>
        <Icon />
      </IconWrapper>
    </div>
  )
})`
  display: flex;
  justify-content: center;
  position: relative;
  ${Line} {
    position: absolute;
    top: calc(50% - 1px);
    z-index: 1;
  }
  ${IconWrapper} {
    z-index: 2;
  }
`
