import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'

import { Ticker, TickerStaking } from '~enums'

export type TokensState = {
  gasPrice?: BigNumber
  data: Record<TickerStaking, { balance: BigNumber }>
}

const initialState: TokensState = {
  data: {
    [Ticker.LUSD]: {
      balance: new BigNumber('0')
    },
    [Ticker.BNB]: {
      balance: new BigNumber('0')
    },
    [Ticker.BUSD]: {
      balance: new BigNumber('0')
    },
    [Ticker.LTT]: {
      balance: new BigNumber('0')
    },
    [Ticker.WBNB]: {
      balance: new BigNumber('0')
    },
    [Ticker.LP]: {
      balance: new BigNumber('0')
    }
  }
}

export type TokensSetDataAction = PayloadAction<
  Record<
    TickerStaking,
    {
      balance: BigNumber
    }
  >
>

export const { reducer: tokensReducer, actions: tokensActions } = createSlice({
  name: 'tokens',
  initialState,
  reducers: {
    startListening: (state) => state,
    endListening: (state) => state,
    set: (state, action: TokensSetDataAction) => {
      state.data = action.payload
    },
    reset: () => {
      return initialState
    }
  }
})
