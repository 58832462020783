import { toast } from 'react-toastify'

import { Toast } from '~ui'

export const toastError = (title: string, value: string) =>
  toast.error(<Toast title={title} value={value} />, {
    theme: 'colored',
    icon: <div />,
    autoClose: 10000
  })

export const toastInfo = (title: string, value: string) =>
  toast.info(<Toast title={title} value={value} />, {
    theme: 'colored',
    icon: <div />,
    autoClose: 10000
  })

export const toastWarning = (title: string, value: string) =>
  toast.warning(<Toast title={title} value={value} />, {
    theme: 'colored',
    icon: <div />,
    autoClose: 10000
  })

export const toastSuccess = (title: string, value: string) =>
  toast.success(<Toast title={title} value={value} />, {
    theme: 'colored',
    icon: <div />,
    autoClose: 10000
  })
