import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Ticker } from '~enums'

export enum TransactionType {
  ALLOW,
  PURCHASE,
  STAKE,
  UNSTAKE,
  CLAIM,
  ADD_LIQUIDITY
}

export enum TransactionStatus {
  ACCEPT,
  PENDING,
  SUCCESS,
  FAIL
}

export type TickerValue = { ticker: Ticker; value: string }

export interface TransactionState {
  status: TransactionStatus
  txType: TransactionType
  inputValues: TickerValue[]
  txHash: string
  ticker?: Ticker
  retryCall?: () => any
}
const initialState: TransactionState = {
  status: TransactionStatus.ACCEPT,
  txType: TransactionType.STAKE,
  inputValues: [],
  txHash: ''
}

export type SetTXHashAction = PayloadAction<string>
export type SetTXInfoAction = PayloadAction<{
  values: TickerValue[]
  type: TransactionType
  ticker?: Ticker
}>
export type SetStatusAction = PayloadAction<TransactionStatus>
export type SetRetryCallAction = PayloadAction<() => void>

export const { reducer: transactionReducer, actions: transactionActions } = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    setTXHash: (state, { payload }: SetTXHashAction) => {
      state.txHash = payload
    },
    setTXInfo: (state, { payload: { values, type, ticker } }: SetTXInfoAction) => {
      state.inputValues = values
      state.txType = type
      state.ticker = ticker
    },
    setStatus: (state, { payload }: SetStatusAction) => {
      state.status = payload
    },
    setRetryCall: (state, { payload }: SetRetryCallAction) => {
      state.retryCall = payload
    }
  }
})
