import BigNumber from 'bignumber.js'
import { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { ScaleLoader } from 'react-spinners'
import styled, { keyframes, DefaultTheme } from 'styled-components'

import { theme } from '../../../theme'

import { WithColorInline } from './WithColorInline'

import { MediaQueryBreakpoint, Ticker } from '~enums'
import { useLttPrice } from '~state'
import { Icon } from '~ui'
import { NumberFormatter } from '~utils'

const FADE_DURATION_MS = 2000

enum LTTPriceHighlight {
  STALE,
  UP,
  DOWN
}

const highlightAnimation = ({
  highlight,
  theme
}: {
  highlight: LTTPriceHighlight
  theme: DefaultTheme
}) => keyframes`
  0% {
    color: ${
      highlight === LTTPriceHighlight.DOWN
        ? theme.colors.red
        : highlight === LTTPriceHighlight.UP
        ? theme.colors.green
        : '#fff'
    };
  }
  100% {
    color: #fff;
  }
`

const LTTIcon = styled((props) => <Icon name="ltt" {...props} />)`
  margin-right: 8px;
`

const PriceContainer = styled(
  ({
    children,
    resetState,
    className
  }: PropsWithChildren<{
    className?: string
    highlight: LTTPriceHighlight
    resetState: () => void
  }>) => {
    useEffect(() => {
      const handle = setTimeout(() => {
        resetState()
      }, FADE_DURATION_MS)
      return () => {
        clearTimeout(handle)
      }
    }, [])
    return <div className={className}>{children}</div>
  }
)`
  display: inline;
  -webkit-transition: ${FADE_DURATION_MS}ms linear all;
  transition: ${FADE_DURATION_MS}ms linear all;
  animation-name: ${({ highlight, theme }) => highlightAnimation({ highlight, theme })};
  animation-duration: ${FADE_DURATION_MS}ms;
  animation-fill-mode: forwards;
`

export const LTTPrice = () => {
  const [highlight, setHighlight] = useState(LTTPriceHighlight.STALE)
  const price = useLttPrice()
  const ref = useRef(price)
  const isSm = useMediaQuery({
    maxWidth: MediaQueryBreakpoint.sm
  })
  useEffect(() => {
    if (!ref.current.eq(0) && !price.eq(ref.current)) {
      setHighlight(price.gt(ref.current) ? LTTPriceHighlight.UP : LTTPriceHighlight.DOWN)
    }
    ref.current = price
  }, [price])
  return (
    <div className="d-flex align-items-center">
      <LTTIcon />
      <WithColorInline className="mr-1" $color="secondary">
        {Ticker.LTT}:
      </WithColorInline>
      {ref.current.eq(0) ? (
        <ScaleLoader color={theme.colors.white} height={10} />
      ) : (
        <PriceContainer
          highlight={highlight}
          resetState={() => setHighlight(LTTPriceHighlight.STALE)}
        >
          $
          {NumberFormatter.toDecimal(ref.current, Ticker.BUSD).toFixed(
            isSm ? 2 : 4,
            BigNumber.ROUND_DOWN
          )}{' '}
        </PriceContainer>
      )}
    </div>
  )
}
