import BigNumber from 'bignumber.js'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppState, tokensActions } from '../store'

import { TickerStaking } from '~enums'

export function useBalance(ticker: TickerStaking): BigNumber {
  const tokens = useSelector((state: AppState) => state.tokens)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(tokensActions.startListening())
    return () => {
      dispatch(tokensActions.endListening())
    }
  }, [])

  return tokens.data[ticker].balance
}
