import BigNumber from 'bignumber.js'
import { SagaIterator } from 'redux-saga'
import { call, put, select } from 'redux-saga/effects'

import { Methods } from '../methods'

import { ZERO_ADDRESS } from '~config'
import { Ticker } from '~enums'
import { AppState, staticVaultsActions, SetSpendPositionAction } from '~state'
import { estimateCall, getAllowance, getCall, getTrade, getTradeValues } from '~state/sagas/utils'
import { NumberFormatter } from '~utils'

export function* onSetSpend({ payload: { address, value } }: SetSpendPositionAction): SagaIterator {
  if (!value || parseFloat(value) === 0)
    return yield put(staticVaultsActions.setStakeValue({ address, value: new BigNumber(0) }))

  const {
    web3: { library, gasPrice, account },
    staticVaults,
    contracts: { bep20 },
    inviter,
    tokens
  }: AppState = yield select()

  if (!library || !gasPrice || !bep20 || !account)
    throw new Error('No library or gasPrice or bep20 or account')

  const vault = staticVaults[address]
  const ticker = vault.form.spendTicker

  const spendValue = NumberFormatter.fromDecimal(value, ticker)

  if (tokens.data[ticker].balance.lt(spendValue)) {
    return
  }

  let allowance = ticker !== Ticker.BNB ? vault.allowances[ticker] : undefined
  if (!allowance && ticker !== Ticker.BNB) {
    const fetchedAllowance: BigNumber = yield call(getAllowance, bep20[ticker], address, account)
    yield put(
      staticVaultsActions.setAllowance({
        address: vault.address,
        ticker,
        allowance: fetchedAllowance
      })
    )
    allowance = fetchedAllowance
  }

  if (ticker === vault.stakeTicker) {
    yield put(staticVaultsActions.setStakeValue({ address, value: spendValue }))

    if (allowance?.lt(tokens.data[ticker].balance)) {
      return
    }

    const stakeGas = yield call(() =>
      estimateCall(vault.contract.value, Methods.StakePosition, [
        spendValue.toString(),
        inviter.value || ZERO_ADDRESS
      ])
    )
    const stakeCall = yield call(() =>
      getCall(vault.contract.value, Methods.StakePosition, gasPrice, stakeGas, [
        spendValue.toString(),
        inviter.value || ZERO_ADDRESS
      ])
    )

    return yield put(
      staticVaultsActions.setStakeCallInfo({ address, gas: stakeGas, call: stakeCall })
    )
  }

  const trade = yield call(() => getTrade(spendValue, ticker, library))

  const { amountOutMin, path, averagePrice } = yield call(() => getTradeValues(trade))

  yield put(staticVaultsActions.setAveragePrice({ address, value: new BigNumber(averagePrice) }))

  yield put(staticVaultsActions.setStakeValue({ address, value: amountOutMin }))

  const deadline = Math.floor(Date.now() / 1000) + 60 * 20 // 20 minutes from the current Unix time

  if (ticker === Ticker.BNB) {
    const stakeGas = yield call(() =>
      estimateCall(
        vault.contract.value,
        Methods.StakePositionETH,
        [amountOutMin.toString(), path, deadline, inviter.value || ZERO_ADDRESS],
        spendValue.toString()
      )
    )

    const stakeCall = yield call(() =>
      getCall(
        vault.contract.value,
        Methods.StakePositionETH,
        gasPrice,
        stakeGas,
        [amountOutMin.toString(), path, deadline, inviter.value || ZERO_ADDRESS],
        spendValue.toString()
      )
    )

    return yield put(
      staticVaultsActions.setStakeCallInfo({ address, gas: stakeGas, call: stakeCall })
    )
  }

  if (allowance?.lt(tokens.data[ticker].balance)) {
    return
  }

  const stakeGas = yield call(() =>
    estimateCall(vault.contract.value, Methods.StakePositionAnyToken, [
      spendValue.toString(),
      amountOutMin.toString(),
      path,
      deadline,
      inviter.value || ZERO_ADDRESS
    ])
  )

  const stakeCall = yield call(() =>
    getCall(vault.contract.value, Methods.StakePositionAnyToken, gasPrice, stakeGas, [
      spendValue.toString(),
      amountOutMin.toString(),
      path,
      deadline,
      inviter.value || ZERO_ADDRESS
    ])
  )

  return yield put(
    staticVaultsActions.setStakeCallInfo({ address, gas: stakeGas, call: stakeCall })
  )
}
