import * as Bs from '@bootstrap-styled/v4'
import { HTMLAttributes } from 'react'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'

import { MediaQueryBreakpoint } from '~enums'

export const FluidRow = styled(
  ({
    breakPoint,
    ...props
  }: { breakPoint: keyof typeof MediaQueryBreakpoint } & HTMLAttributes<HTMLDivElement>) => {
    const breaks = useMediaQuery({ maxWidth: MediaQueryBreakpoint[breakPoint] })
    return breaks ? <Bs.Row {...props} /> : <div {...props} />
  }
)`
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
`

export const FluidCol = styled(
  ({
    breakPoint,
    ...props
  }: { breakPoint: keyof typeof MediaQueryBreakpoint } & HTMLAttributes<HTMLDivElement> &
    Partial<Record<keyof typeof MediaQueryBreakpoint, number>>) => {
    const breaks = useMediaQuery({ maxWidth: MediaQueryBreakpoint[breakPoint] })
    return breaks ? <Bs.Col {...props} /> : <div {...props} />
  }
)`
  display: flex;
  align-items: center;
`
