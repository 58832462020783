import * as Bs from '@bootstrap-styled/v4'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'

import amlbot from './images/amlbot.svg'
import crystal from './images/crystal.png'
import hacken from './images/hacken.svg'
import purefi from './images/purefi.svg'

import { MediaQueryBreakpoint } from '~enums'
import { Headline2, BodyS } from '~ui'
import { mediaQuery } from '~utils'

const Row = styled(Bs.Row)`
  ${mediaQuery.lessThan('md')`
   gap:10px;
  `}
`

const Logo = styled.img`
  object-fit: contain;
  max-width: 200px;
  &.hacken {
    height: 35px;
    width: 90%;
  }
  ${mediaQuery.between('md', 'lg')`
    &.hacken {
      padding: 0 30px;
    }
  `}
  ${mediaQuery.lessThan('md')`
    height: 100%;
  `}
  ${mediaQuery.lessThan('sm')`
    width: 100%;
  `}
`

const Title = styled(Headline2)`
  margin-bottom: 40px;
  ${mediaQuery.lessThan('md')`
   margin-bottom: 20px;
  `}
`

const ImageBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
  border-radius: 16px;
  ${mediaQuery.lessThan('md')`
   height: 85px;
  `}
  cursor: pointer;
  padding: 25px;
  background: ${({ theme }) => theme.gradients.lightPrimary};
`

const BlockLabel = styled(BodyS)`
  color: ${({ theme }) => theme.colors.tertiary};
  position: absolute;
  bottom: 20px;
`

export const Partners = () => {
  const isSm = useMediaQuery({ maxWidth: MediaQueryBreakpoint.lg })
  const { t } = useTranslation('home')
  return (
    <>
      <Title>{t('Our Partners')}</Title>
      <Row className="align-items-center">
        <Bs.Col xs={12} lg={3}>
          <ImageBlock>
            <Logo src={crystal} alt="Crystal" />
            {!isSm && <BlockLabel>#compliance</BlockLabel>}
          </ImageBlock>
        </Bs.Col>
        <Bs.Col xs={12} lg={3}>
          <ImageBlock onClick={() => window.open('https://purefi.io/', '_blank')}>
            <Logo src={purefi} alt="Purefi" />
            {!isSm && <BlockLabel>#compliance</BlockLabel>}
          </ImageBlock>
        </Bs.Col>
        <Bs.Col xs={12} lg={3}>
          <ImageBlock onClick={() => window.open('https://hacken.io/', '_blank')}>
            <Logo src={hacken} className="hacken" alt="Hacken" />
            {!isSm && <BlockLabel>#security</BlockLabel>}
          </ImageBlock>
        </Bs.Col>
        <Bs.Col xs={12} lg={3}>
          <ImageBlock onClick={() => window.open('https://amlbot.com/ru/', '_blank')}>
            <Logo src={amlbot} alt="AMLBot" />
            {!isSm && <BlockLabel>#compliance</BlockLabel>}
          </ImageBlock>
        </Bs.Col>
      </Row>
    </>
  )
}
