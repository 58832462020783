import {
  BUSD_ADDRESS,
  CAKE_LP_LTT_BUSD_ADDRESS,
  LTT_ADDRESS,
  LUSD_ADDRESS,
  WBNB_ADDRESS
} from './constants'

import { Ticker } from '~enums'
import { Token, TokenBEP20 } from '~interfaces'

export const BNB: Token = {
  ticker: Ticker.BNB,
  decimals: 18,
  formatDecimals: 6
}

export const WBNB: TokenBEP20 = {
  ticker: Ticker.WBNB,
  address: WBNB_ADDRESS,
  decimals: 18,
  formatDecimals: 6
}

export const BUSD: TokenBEP20 = {
  ticker: Ticker.BUSD,
  address: BUSD_ADDRESS,
  decimals: 18,
  formatDecimals: 6
}

export const LTT: TokenBEP20 = {
  ticker: Ticker.LTT,
  address: LTT_ADDRESS,
  decimals: 9,
  formatDecimals: 6
}

export const LUSD: TokenBEP20 = {
  ticker: Ticker.LUSD,
  address: LUSD_ADDRESS,
  decimals: 18,
  formatDecimals: 6
}

export const CRU: Token = {
  ticker: Ticker.CRU,
  decimals: 18,
  formatDecimals: 6
}

export const LP: TokenBEP20 = {
  ticker: Ticker.LP,
  address: CAKE_LP_LTT_BUSD_ADDRESS,
  decimals: 18,
  formatDecimals: 6
}

export const TokensNative: Token[] = [BNB]

export const TokensBEP20: TokenBEP20[] = [WBNB, BUSD, LTT, LUSD, LP]

export const StakeFromTokens: Token[] = [WBNB, BUSD, BNB, LTT]

export const AddLiquidityFromTokens: Token[] = [WBNB, BUSD, BNB]

export const RewardTokens: Token[] = [CRU]

export const BuySecurityFromTokens: Token[] = [BUSD, LUSD]

export const BuyTokens: Token[] = [LTT]

export const Tokens: Token[] = [...TokensNative, ...TokensBEP20, ...RewardTokens]
