import BigNumber from 'bignumber.js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Ticker } from '~enums'
import { Referral } from '~interfaces'
import { PrimaryButton, Headline4, BodyL } from '~ui'
import { mediaQuery, NumberFormatter } from '~utils'

const Card = styled.div`
  min-width: 350px;
  padding: 25px 28px 20px !important;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.colors.tertiaryShade3};
  border: none !important;
  &:before {
    border-left-style: dashed !important;
  }
  ${mediaQuery.lessThan('lg')`
    padding: 22px 15px 19px !important;
    min-width: 182px !important;
    ${PrimaryButton} {
      height: 50px;
      line-height: 1.2;
    }
    `}
`

const Avatar = styled.img`
  width: 78px;
  height: 78px;
  border-radius: 24px;
  margin-right: 22px;
`

const RankComponent = styled(BodyL)`
  color: ${({ theme }) => theme.colors.secondary};
`

export const ReferralNode = ({
  inviter,
  referrals,
  onClick,
  loadingReferralsOf,
  buttonContent: ButtonContent
}: {
  inviter: Referral
  referrals: Record<string, Referral[] | undefined>
  onClick: (inviter: string) => unknown
  loadingReferralsOf?: string
  buttonContent: React.ComponentType<{ address: string }>
}) => {
  const { t } = useTranslation('balance')

  const rankName = t(`rankNames.${inviter.rank.name}`)

  return (
    <li className="tf-dashed-children">
      <Card className="tf-nc">
        <div className="d-flex align-items-center mb-3">
          {inviter.personal?.pictureUrl && <Avatar src={inviter.personal?.pictureUrl} />}
          <div className="flex-grow-1">
            {inviter.personal?.name && (
              <Headline4 className="text-center">{inviter.personal?.name}</Headline4>
            )}
            <PrimaryButton
              loading={loadingReferralsOf === inviter.address}
              className="flex-column flex-lg-row"
              size="sm"
              block
              onClick={() => {
                onClick(inviter.address)
              }}
            >
              <ButtonContent address={inviter.address} />
            </PrimaryButton>
          </div>
        </div>
        <div className="d-flex flex-column flex-lg-row justify-content-between align-items-center">
          <Headline4 className="mb-1 mb-md-0">
            {NumberFormatter.toDecimal(inviter.staked, Ticker.LTT).toFixed(0, BigNumber.ROUND_DOWN)}{' '}
            {Ticker.LTT}
          </Headline4>
          <RankComponent className="mb-0">
            {inviter.rank.number ? `${inviter.rank.number}. ${rankName}` : rankName}
          </RankComponent>
        </div>
      </Card>
      {!!referrals[inviter.address]?.length && (
        <ul>
          {referrals[inviter.address]?.map((r) => (
            <ReferralNode
              key={r.address}
              inviter={r}
              referrals={referrals}
              onClick={onClick}
              buttonContent={ButtonContent}
            />
          ))}
        </ul>
      )}
    </li>
  )
}
