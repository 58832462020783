import { useState } from 'react'
import styled from 'styled-components'

import { ButtonText, Icon, IconProps } from '~ui'

const Wrapper = styled.div<{ $text: boolean }>`
  background: ${({ theme }) => theme.colors.secondaryShade1};
  border: 1px solid ${({ theme }) => theme.colors.secondaryShade1};
  border-radius: 51px;
  backdrop-filter: blur(19px);
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 77px;
  height: 77px;
  white-space: nowrap;
  ${({ $text }) => $text && 'padding: 28px 50px;'}
  transition: width 0.3s ease-in-out;
  cursor: pointer;
`

export const Logo = ({
  name,
  link,
  text
}: {
  name: IconProps['name']
  link: string
  text: string
}) => {
  const [showLink, setShowLink] = useState(false)
  return (
    <Wrapper onMouseLeave={() => setShowLink(false)} $text={showLink}>
      {showLink ? (
        <ButtonText onClick={() => window.open(link, '_blank')}>{text}</ButtonText>
      ) : (
        <Icon
          name={name}
          onClick={() => window.open(link, '_blank')}
          onMouseEnter={() => setShowLink(true)}
          onMouseLeave={() => setShowLink(false)}
        />
      )}
    </Wrapper>
  )
}
