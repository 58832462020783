import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { AppState, inviterActions, useAppDispatch } from '~state'

export function useInviter(): [
  {
    value: string
    mutable: boolean
  },
  (inviter: string) => void
] {
  const dispatch = useAppDispatch()
  const inviterState = useSelector((state: AppState) => state.inviter)

  useEffect(() => {
    dispatch(inviterActions.startListening())
    return () => {
      dispatch(inviterActions.endListening())
    }
  }, [dispatch])

  return [
    inviterState,
    (inviter) => dispatch(inviterActions.setInviter({ value: inviter, mutable: true }))
  ]
}
