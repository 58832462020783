import styled from 'styled-components'

import { Ticker } from '~enums'
import { Icon as IconUI, icons, IconProps } from '~ui'

const IconName: Record<Ticker, keyof typeof icons> = {
  [Ticker.BNB]: 'bnb',
  [Ticker.LTT]: 'ltt',
  [Ticker.BUSD]: 'busd',
  [Ticker.WBNB]: 'bnb',
  [Ticker.LUSD]: 'lusd',
  [Ticker.LP]: 'lp',
  [Ticker.CRU]: 'cru'
}

const IconImg = styled.img`
  height: 22px;
  width: 22px;
  margin-right: 10px;
`

export const Icon = ({
  className,
  ticker,
  src,
  ...props
}: {
  className?: string
  ticker?: Ticker
  src?: string
} & Omit<IconProps, 'name'>) => {
  return src ? (
    <IconImg className={className} src={src} alt="ticker" />
  ) : ticker ? (
    <IconUI className={className} name={IconName[ticker]} {...props} />
  ) : null
}
