import { TransactionResponse } from '@ethersproject/providers'
import { Pair, TokenAmount } from '@uniswap/sdk'
import BigNumber from 'bignumber.js'
import { Contract } from 'ethers'

import { Methods } from '../../methods'

import { bnToTokenAmount, tokenAmountToBN } from './amounts'
import { calculateAmount } from './math'
import { applySlippage } from './slippage'

import { UniswapTokens } from '~config'
import { Ticker, TickerAddToLPFrom } from '~enums'
import { estimateCall, getCall } from '~state/sagas/utils'

export class LPBroker {
  readonly deadline: number

  constructor(
    private readonly contract: Contract,
    readonly ticker: TickerAddToLPFrom,
    readonly busdPair: Pair | null,
    readonly lttPair: Pair,
    readonly value: BigNumber
  ) {
    this.deadline = Date.now() + 60 * 20
  }

  protected getAmountBUSDFull(): BigNumber {
    if (this.ticker === Ticker.BUSD || !this.busdPair) return this.value
    const amountBUSDFullNotSlippaged = this.busdPair.getOutputAmount(
      bnToTokenAmount(this.value, this.ticker)
    )[0]

    return tokenAmountToBN(applySlippage(amountBUSDFullNotSlippaged))
  }

  protected getProvideLiquidityBUSDArgs(account: string) {
    return [this.value.toString(), account, this.deadline]
  }

  protected getProvideLiquidityETHArgs(account: string) {
    return [
      this.getAmountBUSDFull().toString(),
      [UniswapTokens.WBNB.address, UniswapTokens.BUSD.address],
      account,
      this.deadline
    ]
  }

  protected getProvideLiquidityAnyTokenArgs(account: string) {
    if (this.ticker === Ticker.BNB) return []
    return [
      this.value.toString(),
      this.getAmountBUSDFull().toString(),
      [UniswapTokens[this.ticker].address, UniswapTokens.BUSD.address],
      account,
      this.deadline
    ]
  }

  getProvideValues(): { busd: TokenAmount; ltt: TokenAmount } {
    const amountBUSDFull = this.getAmountBUSDFull()
    const amountBUSDInLTT = calculateAmount(
      amountBUSDFull,
      this.lttPair.reserve1,
      UniswapTokens.BUSD
    )
    const amountBUSD = new TokenAmount(
      UniswapTokens.BUSD,
      amountBUSDFull.minus(amountBUSDInLTT.raw.toString()).toFixed(0)
    )

    const amountLTT = this.lttPair.getOutputAmount(amountBUSDInLTT)[0]
    return { busd: amountBUSD, ltt: amountLTT }
  }

  protected estimateProvideLiquidityBUSDGas(account: string): Promise<BigNumber> {
    return estimateCall(
      this.contract,
      Methods.ProvideBUSDLiquidity,
      this.getProvideLiquidityBUSDArgs(account)
    )
  }

  protected getProvideLiquidityBUSDCall(
    account: string,
    gasPrice: BigNumber,
    gas: BigNumber
  ): () => Promise<TransactionResponse> {
    return getCall(
      this.contract,
      Methods.ProvideBUSDLiquidity,
      gasPrice,
      gas,
      this.getProvideLiquidityBUSDArgs(account)
    )
  }

  estimateProvideLiquidityETHGas(account: string): Promise<BigNumber> {
    return estimateCall(
      this.contract,
      Methods.ProvideETHLiquidity,
      this.getProvideLiquidityETHArgs(account),
      this.value.toString()
    )
  }

  protected getProvideLiquidityETHCall(
    account: string,
    gasPrice: BigNumber,
    gas: BigNumber
  ): () => Promise<TransactionResponse> {
    return getCall(
      this.contract,
      Methods.ProvideETHLiquidity,
      gasPrice,
      gas,
      this.getProvideLiquidityETHArgs(account),
      this.value.toString()
    )
  }

  protected estimateProvideLiquidityAnyTokenGas(account: string): Promise<BigNumber> {
    return estimateCall(
      this.contract,
      Methods.ProvideTokensLiquidity,
      this.getProvideLiquidityAnyTokenArgs(account)
    )
  }

  protected getProvideLiquidityAnyTokenCall(
    account: string,
    gasPrice: BigNumber,
    gas: BigNumber
  ): () => Promise<TransactionResponse> {
    return getCall(
      this.contract,
      Methods.ProvideTokensLiquidity,
      gasPrice,
      gas,
      this.getProvideLiquidityAnyTokenArgs(account)
    )
  }

  estimateCall(account: string): Promise<BigNumber> {
    switch (this.ticker) {
      case Ticker.BUSD:
        return this.estimateProvideLiquidityBUSDGas(account)
      case Ticker.BNB:
        return this.estimateProvideLiquidityETHGas(account)
      default:
        return this.estimateProvideLiquidityAnyTokenGas(account)
    }
  }

  getProvideLiquidityCall(
    account: string,
    gasPrice: BigNumber,
    gas: BigNumber
  ): () => Promise<TransactionResponse> {
    switch (this.ticker) {
      case Ticker.BUSD:
        return this.getProvideLiquidityBUSDCall(account, gasPrice, gas)
      case Ticker.BNB:
        return this.getProvideLiquidityETHCall(account, gasPrice, gas)
      default:
        return this.getProvideLiquidityAnyTokenCall(account, gasPrice, gas)
    }
  }
}
