import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { ReactComponent as AmbisafeLogoRaw } from './ambisafe.svg'

import { BodyM } from '~components'

const AmbisafeLogo = styled(AmbisafeLogoRaw)`
  cursor: pointer;
`
const Text = styled(BodyM)<{ direction: 'row' | 'column' }>`
  ${({ direction }) => (direction === 'row' ? 'margin-right:10px;' : ' margin-bottom:10px;')};
`

const Wrapper = styled.div<{ direction: 'row' | 'column' }>`
  color: ${({ theme }) => theme.colors.secondary};
  display: flex;
  align-items: center;
  flex-direction: ${({ direction }) => direction};
`

export const AmbisafePartnership = ({ direction = 'column' }: { direction?: 'row' | 'column' }) => {
  const { t } = useTranslation('security')

  return (
    <Wrapper direction={direction}>
      <Text direction={direction}>{t('In Partnership with')}</Text>
      <AmbisafeLogo onClick={() => window.open('https://ambisafe.com/', '_blank')} />
    </Wrapper>
  )
}
