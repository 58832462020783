import { Pair, Route } from '@uniswap/sdk'
import { BigNumber } from 'bignumber.js'
import { Contract } from 'ethers'
import { SagaIterator } from 'redux-saga'
import { put, select, call, fork, throttle } from 'redux-saga/effects'

import { AppState, lttActions, blocksActions } from '../store'

import { createTerminatedSaga } from './utils'

import { UniswapTokens, IGNORE_ADDRESS_LIST, TOTAL_SUPPLY } from '~config'
import { Ticker } from '~enums'
import { NumberFormatter } from '~utils'

function* fetchPrice(): SagaIterator {
  const {
    web3: { library }
  }: AppState = yield select()

  const from = UniswapTokens.LTT

  const to = UniswapTokens.BUSD

  const pair: Pair = yield call(() => Pair.fetchData(from, to, library))

  yield put(lttActions.setPair(pair))

  const route = new Route([pair], from)
  const price = route.midPrice.toSignificant(18)

  yield put(lttActions.setPrice(NumberFormatter.fromDecimal(price, Ticker.BUSD)))
}

async function getBalance(contract: Contract, account: string): Promise<BigNumber> {
  return new BigNumber((await contract.balanceOf(account)).toString())
}

function* getUnlocked(): SagaIterator {
  const {
    contracts: { general }
  }: AppState = yield select()
  if (!general.ltt) return

  const unlockedValue = yield call(async () => {
    const IGNORE_BALANCES = await Promise.all(
      IGNORE_ADDRESS_LIST.map((a) => getBalance(general.ltt!, a)) // null check required???
    )
    return IGNORE_BALANCES.reduce((a, b) => a.minus(b), new BigNumber(TOTAL_SUPPLY))
  })

  return yield put(lttActions.setUnlocked(unlockedValue))
}

const terminatedSaga = createTerminatedSaga(
  { start: lttActions.startListening, end: lttActions.endListening },
  [
    call(fetchPrice),
    throttle(1000, blocksActions.set, fetchPrice),
    call(getUnlocked),
    throttle(1000, blocksActions.set, getUnlocked)
  ]
)

export function* lttSaga(): SagaIterator {
  yield fork(terminatedSaga)
}
