import { BigNumber } from 'bignumber.js'
import { useTranslation } from 'react-i18next'
import styled, { ThemeColor } from 'styled-components'

import { Progress } from './Progress'

import { BodyM, WithColor, BodyL } from '~components/ui'
import { Ticker } from '~enums'
import { NumberFormatter } from '~utils'

const StyledProgress = styled(Progress)`
  margin: 5px 0 40px;
`

export const RaisedProgress = ({
  value,
  total,
  initialPrice,
  bgColor,
  progressColor,
  decimals
}: {
  value: BigNumber
  initialPrice: BigNumber
  total: BigNumber
  bgColor?: ThemeColor
  progressColor?: ThemeColor
  decimals: number
}) => {
  const { t } = useTranslation('security')

  return (
    <>
      <div className="d-flex justify-content-between w-100">
        <BodyM>{t('Raised')}</BodyM>
        <div>
          <BodyL $bold>
            {Ticker.BUSD}{' '}
            {NumberFormatter.toDecimal(value, Ticker.BUSD).toFixed(2, BigNumber.ROUND_DOWN)}
            <WithColor className="d-inline" $color="secondary">
              {' '}
              /{' '}
              {NumberFormatter.toDecimal(total.multipliedBy(initialPrice), Ticker.BUSD).toFixed(
                2,
                BigNumber.ROUND_DOWN
              )}
            </WithColor>
          </BodyL>
        </div>
      </div>
      <StyledProgress
        className="w-100"
        value={value.dividedBy(total.multipliedBy(initialPrice)).multipliedBy(100).toNumber()}
        bgColor={bgColor}
        progressColor={progressColor}
      />
    </>
  )
}
