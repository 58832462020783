import React from 'react'
import { Helmet } from 'react-helmet'

import { Securities } from './Securities'

import { Container, Jumbotron, WhyInvest } from '~components'

export const SecuritiesPage = () => {
  return (
    <>
      <Helmet>
        <title>LocalTrade DeFi Lab | NeoBroker Smart Fund</title>
        <meta
          name="description"
          content="The only DeFi platform to buy & trade tokenized securities of private companies at pre-IPO phase."
        />
      </Helmet>
      <Container>
        <Jumbotron />
      </Container>
      <Container>
        <WhyInvest />
      </Container>
      <Container>
        <Securities />
      </Container>
    </>
  )
}
