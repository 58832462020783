import { SagaIterator } from 'redux-saga'
import { all, takeLatest } from 'redux-saga/effects'

import { rewardsActions, userActions, unstakerActions } from '~state'
import { toastError, toastSuccess } from '~utils'

function* claimReward(): SagaIterator {
  toastSuccess('Reward', 'Success')
}

function* claimRewardFailed(): SagaIterator {
  toastError('Reward', 'Failed')
}

function* unstaked(): SagaIterator {
  toastSuccess('Unstake', 'Success')
}

function* unstakeFailed(): SagaIterator {
  toastError('Unstake', 'Failure. Check your latest transaction on Metamask.')
}

function* userUpdateSuccessAction(): SagaIterator {
  toastSuccess('User update', 'Success')
}

function* userUpdateFailedAction(): SagaIterator {
  toastError('User update', 'Failed')
}

export function* toastsSaga(): SagaIterator {
  yield all([
    takeLatest(rewardsActions.claimed, claimReward),
    takeLatest(rewardsActions.claimFailed, claimRewardFailed),
    takeLatest(userActions.updatePersonalSuccess, userUpdateSuccessAction),
    takeLatest(userActions.updatePersonalFailed, userUpdateFailedAction),
    takeLatest(unstakerActions.unstaked, unstaked),
    takeLatest(unstakerActions.unstakeFailed, unstakeFailed)
  ])
}
