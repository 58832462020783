import BigNumber from 'bignumber.js'
import { Contract } from 'ethers'
import { SagaIterator } from 'redux-saga'
import { put, call, takeLatest, select, all, throttle, fork } from 'redux-saga/effects'

import {
  AppState,
  blocksActions,
  contractsActions,
  rewardsActions,
  RewardsClaimAction
} from '../store'

import { createTerminatedSaga } from './utils'

import { Ticker } from '~enums'

async function claim(contract: Contract, value: BigNumber, token?: string): Promise<void> {
  const tx = await contract.claim(...(token ? [token, value.toString()] : [value.toString()]))
  await tx.wait()
}

function* claimWorker(action: RewardsClaimAction): SagaIterator {
  const {
    contracts: { general },
    rewards
  }: AppState = yield select()
  if (!general.rewards || !general.ltt) throw new Error('No rewards contract')
  const contract = general.rewards[action.payload.ticker]
  try {
    if (action.payload.ticker === Ticker.LTT)
      yield call(claim, contract, rewards[action.payload.ticker].value, general.ltt.address)
    else yield call(claim, contract, rewards[action.payload.ticker].value)
    yield put(rewardsActions.claimed({ ticker: action.payload.ticker }))
  } catch (e) {
    yield put(rewardsActions.claimFailed({ ticker: action.payload.ticker }))
  }
}

async function getRewards(contract: Contract, account: string, token?: string): Promise<BigNumber> {
  return new BigNumber(
    (await contract.rewards(...(token ? [token, account] : [account]))).toString()
  )
}

function* fetchRewards(): SagaIterator {
  const {
    contracts: { general },
    web3: { account }
  }: AppState = yield select()
  if (!general.rewards || !general.ltt || !account) return
  const rewardBUSD = yield call(getRewards, general.rewards[Ticker.BUSD], account)
  yield put(rewardsActions.set({ ticker: Ticker.BUSD, value: rewardBUSD }))
  const rewardLTT = yield call(
    getRewards,
    general.rewards[Ticker.LTT],
    account,
    general.ltt.address
  )
  yield put(rewardsActions.set({ ticker: Ticker.LTT, value: rewardLTT }))
}

// if new contracts without account reset (logout action)
function* onContractsSetGeneral(): SagaIterator {
  const {
    web3: { account }
  }: AppState = yield select()
  if (!account) yield put(rewardsActions.reset())
  else yield call(fetchRewards)
}

const terminatedSaga = createTerminatedSaga(
  { start: rewardsActions.startListening, end: rewardsActions.endListening },
  [
    throttle(60000, blocksActions.set, fetchRewards),
    takeLatest(rewardsActions.claimed, fetchRewards)
  ]
)

export function* rewardsSaga(): SagaIterator {
  yield all([
    takeLatest(rewardsActions.claim, claimWorker),
    takeLatest(rewardsActions.claimed, fetchRewards),
    takeLatest(contractsActions.setGeneral, onContractsSetGeneral)
  ])
  yield fork(terminatedSaga)
}
