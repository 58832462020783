import { ranks } from '~config'
import { RankWithCap } from '~interfaces'
import { useStaked } from '~state'
import { getRank, getNextRank } from '~utils'

export const useRank = (): RankWithCap => {
  const { stakedOf } = useStaked()
  const rank = getRank(stakedOf)
  return { rank, cap: ranks[rank.number].staked }
}

export const useNextRank = (): RankWithCap | null => {
  const { stakedOf } = useStaked()
  const nextRank = getNextRank(stakedOf)
  if (!nextRank) return null
  return { rank: nextRank, cap: ranks[nextRank.number].staked }
}
