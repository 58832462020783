import styled, { ThemeColor } from 'styled-components'

export const Progress = styled(
  ({
    value,
    className
  }: {
    value: number
    bgColor?: ThemeColor
    progressColor?: ThemeColor
    className?: string
  }) => {
    return (
      <div className={className}>
        <div style={{ width: value + '%' }} />
      </div>
    )
  }
)`
  background-color: ${({ theme, bgColor }) => theme.colors[bgColor ?? 'secondaryShade1']};
  overflow: hidden;
  border-radius: 10px;
  & > div {
    height: 20px;
    background-color: ${({ theme, progressColor }) => theme.colors[progressColor ?? 'primary']};
  }
`
