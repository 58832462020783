import BigNumber from 'bignumber.js'
import { Contract } from 'ethers'

import { transformBN } from '~state/sagas/utils/transformBN'

export async function getAllowance(
  contract: Contract,
  spenderAddress: string,
  account: string
): Promise<BigNumber> {
  return transformBN(await contract?.allowance(account, spenderAddress))
}
