import decodeJwt from 'jwt-decode'
import { SagaIterator } from 'redux-saga'
import { call, select, put, takeLatest, all } from 'redux-saga/effects'

import { UserApi, UserApiErrors } from '../../api'
import {
  AppState,
  profileModalActions,
  UpdatePersonalAction,
  userActions,
  web3Actions,
  Web3SetAction
} from '../store'

function* updatePersonal(action: UpdatePersonalAction): SagaIterator {
  const {
    web3: { account, library },
    user: { data, token }
  }: AppState = yield select()
  if (!account || !library) return

  const userApi = new UserApi()

  if (!data) {
    const createdUser = yield call(() => userApi.create(account))
    yield put(userActions.setUser(createdUser))
    return yield call(() => updatePersonal(action))
  }

  if (!token) {
    const msg = `0x${Buffer.from(data.nonce, 'utf8').toString('hex')}`

    const sign = yield call(() => {
      if (!library.provider.request) return
      return library.provider.request({
        method: 'personal_sign',
        params: [msg, data.publicAddress, 'Test']
      })
    })

    const createdToken = yield call(() => userApi.authenticate(account, sign))

    yield put(userActions.setToken(createdToken))

    return yield call(() => updatePersonal(action))
  }

  try {
    const user = yield call(() => userApi.updatePersonal(action.payload, token))

    yield all([
      put(userActions.setUser(user)),
      put(profileModalActions.close()),
      put(userActions.updatePersonalSuccess())
    ])
  } catch (e: any) {
    if (e instanceof UserApiErrors.TokenExpiredError) {
      yield put(userActions.removeToken())
      return yield call(() => updatePersonal(action))
    }
    return yield put(userActions.updatePersonalFailed())
  }
}

function* onWeb3Set(action: Web3SetAction): SagaIterator {
  const {
    user: { token }
  }: AppState = yield select()
  if (token && action.payload.account) {
    const decoded = decodeJwt<{ user: string }>(token)
    if (decoded.user !== action.payload.account) {
      yield put(userActions.removeToken())
    }
  }
}

export function* userSage(): SagaIterator {
  yield takeLatest(userActions.updatePersonal, updatePersonal)
  yield takeLatest(web3Actions.set, onWeb3Set)
}
