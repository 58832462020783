import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { UserApi } from '../../api'

import { User } from '~interfaces'

export interface UserState {
  data?: User | null
  token: string | null
  updatePersonal: {
    loading: boolean
  }
  error?: any
}
const initialState: UserState = {
  token: localStorage.getItem('token'),
  updatePersonal: { loading: false }
}

type UserSetAction = PayloadAction<User>
export type UpdatePersonalAction = PayloadAction<User['personal']>
type TokenSetAction = PayloadAction<{ token: string }>

const getByAddress = createAsyncThunk('user/getByAddress', async (address: string, getThunkAPI) => {
  const userApi = new UserApi()
  const response = await userApi.getByAddress(address)
  if (!response.length) return null
  return response[0]
})

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updatePersonal: (state, action: UpdatePersonalAction) => {
      state.updatePersonal.loading = true
    },
    updatePersonalSuccess: (state) => {
      state.updatePersonal.loading = false
    },
    updatePersonalFailed: (state) => {
      state.updatePersonal.loading = false
    },
    setUser: (state, action: UserSetAction) => {
      state.data = action.payload
    },
    setToken: (state, action: TokenSetAction) => {
      localStorage.setItem('token', action.payload.token)
      state.token = action.payload.token
    },
    removeToken: (state) => {
      localStorage.removeItem('token')
      state.token = null
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getByAddress.fulfilled, (state, action) => {
      state.data = action.payload
    })
    builder.addCase(getByAddress.rejected, (state, action) => {
      state.error = action.payload
    })
  }
})

const { actions, reducer } = userSlice

export const userActions = { ...actions, getByAddress }
export const userReducer = reducer
