import { useEffect } from 'react'

import { ColMargined } from '../../components'

import { StaticVault } from './StaticVault'

import { TickerBEP20 } from '~enums'
import { useInviter, useStaticVaults } from '~state'

export const StaticVaults = ({ setLoading }: { setLoading: (value: boolean) => void }) => {
  const {
    staticVaults,
    unstakeFee,
    executeUnstake,
    stakeValue,
    executeStake,
    onSetSpend,
    onSetTicker,
    stakeFee,
    executeClaim,
    approve,
    allowances,
    spendValue,
    spendTicker,
    averagePrice
  } = useStaticVaults()
  const [inviter, setInviter] = useInviter()
  useEffect(() => {
    if (staticVaults.length !== 0) return setLoading(false)
    setLoading(true)
  }, [staticVaults.length])
  return (
    <>
      {staticVaults.map((v, i) => (
        <ColMargined xs={12} md={6} key={i} order={v.order}>
          <StaticVault
            {...v}
            allowances={allowances(v.address)}
            approve={(ticker: TickerBEP20) => approve(v.address, ticker)}
            unstakeFee={(index: number) => unstakeFee(v.address, index)}
            executeUnstake={(index: number) => executeUnstake(v.address, index)}
            stakeValue={stakeValue(v.address)}
            executeStake={() => executeStake(v.address)}
            onSetSpend={(value) => onSetSpend(v.address, value)}
            onSetTicker={(ticker) => onSetTicker(v.address, ticker)}
            stakeFee={stakeFee(v.address)}
            inviter={inviter.value}
            isInviterMutable={inviter.mutable}
            onInviterChanged={setInviter}
            executeClaim={() => executeClaim(v.address)}
            spendValue={spendValue(v.address)}
            spendTicker={spendTicker(v.address)}
            averagePrice={averagePrice(v.address)}
          />
        </ColMargined>
      ))}
    </>
  )
}
