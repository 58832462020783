import * as Bs from '@bootstrap-styled/v4'
import styled from 'styled-components'

import { mediaQuery } from '~utils'

export type CardProps = React.HTMLAttributes<HTMLDivElement> & {
  withBorder?: boolean
  borderColor?: string
}

export const Card = styled(Bs.Card)`
  padding: 30px;
  ${mediaQuery.lessThan('md')`
     padding: 20px;
  `}
  justify-content: center;
  height: 100%;
  border-radius: 24px !important;
  border: ${({ theme, withBorder, borderColor }) =>
    withBorder ? `1px solid ${borderColor ?? theme.colors.secondaryShade1}` : 'none'} !important;
  background-color: ${({ theme }) => theme.colors.secondaryShade1} !important;
  color: ${({ theme }) => theme.colors.white};
  backdrop-filter: blur(19px);
`
