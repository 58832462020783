import React from 'react'
import { useTranslation } from 'react-i18next'

import { MarginWrapper, BodySubtitle } from './ui'

import { Headline3, Icon } from '~ui'

export const PendingBody = () => {
  const { t } = useTranslation('common')
  return (
    <>
      <Icon name="pending" />
      <MarginWrapper x={2}>
        <Headline3>{t('Pending')}...</Headline3>
      </MarginWrapper>
      <BodySubtitle>{t('Wait a few minutes')}</BodySubtitle>
    </>
  )
}
