import BigNumber from 'bignumber.js'
import { SagaIterator } from 'redux-saga'
import { fork, takeLatest, call, all, select, put } from 'redux-saga/effects'

import { getAllowance, transformBN } from './utils'
import { onVaultsSetSpend, vaultsSaga } from './vaults'

import { StakingsApi, StakingsKind } from '~api'
import CentralizedAPYFarming from '~contracts/CentralizedDynamicAPYFarming.json'
import { Ticker } from '~enums'
import {
  AppState,
  blocksActions,
  centralizedVaultsActions,
  CentralizedVaultsSetUserIdAction,
  CentralizedVaultsState,
  VaultsSetSpendAction
} from '~state'
import { getContract } from '~utils'

function* fetchCentralizedVaults(): SagaIterator {
  const {
    centralizedVaults,
    web3: { library, account, gasPrice },
    contracts: { bep20 }
  }: AppState = yield select()

  if (!bep20 || !library || !gasPrice) return

  const fetched: CentralizedVaultsState = {}

  const stakingsApi = new StakingsApi()

  const vaultsApi = yield call(() => stakingsApi.getStakings(StakingsKind.CENTRALIZED))

  for (const v of vaultsApi) {
    yield call(async () => {
      const vaultContract = getContract(v.address, CentralizedAPYFarming.abi, library, account)

      const [
        creationTimestamp,
        endTimestamp,
        unstakeTimestamp,
        totalReward,
        accRewardLTTperStakedLTT,
        lastRewardTimestamp,
        stakedTotal
      ] = await vaultContract.getInfo()

      const initialTicker = Ticker.LTT

      const initialTokenAllowance = account
        ? await getAllowance(bep20[initialTicker], vaultContract.address, account)
        : undefined

      fetched[v.address] = {
        ...v,
        contract: {
          value: vaultContract,
          creationDate: new Date(transformBN(creationTimestamp).toNumber() * 1000),
          endDate: new Date(transformBN(endTimestamp).toNumber() * 1000),
          unstakeDate: new Date(transformBN(unstakeTimestamp).toNumber() * 1000),
          staked: account ? transformBN(await vaultContract.stakedOf(account)) : new BigNumber('0'),
          stakedTotal: transformBN(stakedTotal),
          totalReward: transformBN(totalReward),
          accRewardLTTperStakedLTT: transformBN(accRewardLTTperStakedLTT),
          lastRewardTimestamp: transformBN(lastRewardTimestamp),
          debt: account ? transformBN(await vaultContract.debt(account)) : new BigNumber('0'),
          args: account && [await vaultContract.userId(account)],
          totalClaimed: account
            ? transformBN(await vaultContract.totalClaimed(account))
            : new BigNumber('0')
        },
        allowances: {
          [initialTicker]: initialTokenAllowance,
          ...centralizedVaults[v.address]?.allowances
        },
        approving: false,
        claiming: false,
        staking: false,
        unstaking: false,
        form: {
          stakeValue: centralizedVaults[v.address]?.form.stakeValue ?? new BigNumber(0),
          unstakeValue: centralizedVaults[v.address]?.form.unstakeValue ?? new BigNumber(0),
          spendTicker: initialTicker,
          spendValue: centralizedVaults[v.address]?.form.spendValue ?? '',
          averagePrice: undefined
        }
      }
    })
  }

  yield put(centralizedVaultsActions.set(fetched))
}

export function* onBlockSet(): SagaIterator {
  const { centralizedVaults }: AppState = yield select()

  for (const v in centralizedVaults) {
    const spendValue = centralizedVaults[v].form.spendValue || '0'
    const spendTicker = centralizedVaults[v].form.spendTicker
    const args = centralizedVaults[v].contract.args
    if (parseFloat(spendValue) !== 0 && spendTicker) {
      yield put(
        centralizedVaultsActions.setSpend({
          address: v,
          value: spendValue,
          args
        })
      )
    }
  }
}

export function* onSetUserId({
  payload: { address, userId }
}: CentralizedVaultsSetUserIdAction): SagaIterator {
  const { centralizedVaults }: AppState = yield select()

  const spendValue = centralizedVaults[address].form.spendValue || '0'
  const spendTicker = centralizedVaults[address].form.spendTicker
  if (parseFloat(spendValue) !== 0 && spendTicker) {
    yield put(
      centralizedVaultsActions.setSpend({
        address,
        value: spendValue,
        args: [userId]
      })
    )
    yield put(
      centralizedVaultsActions.setTicker({
        address,
        ticker: spendTicker
      })
    )
  }
}

export function* onSetSpend(action: VaultsSetSpendAction): SagaIterator {
  const { centralizedVaults }: AppState = yield select()
  action.payload.args = centralizedVaults[action.payload.address].contract.args
  return yield call(() => onVaultsSetSpend(action, 'centralizedVaults', centralizedVaultsActions))
}

export function* centralizedVaultsSaga(): SagaIterator {
  yield all([
    yield fork(() =>
      vaultsSaga('centralizedVaults', centralizedVaultsActions, fetchCentralizedVaults)
    ),
    yield takeLatest(centralizedVaultsActions.setUserId, onSetUserId),
    yield takeLatest(blocksActions.set, onBlockSet),
    yield takeLatest(centralizedVaultsActions.approveSuccess, onBlockSet),
    yield takeLatest(centralizedVaultsActions.setSpend, onSetSpend)
  ])
}
