import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'

import { TickerBuySecurityFrom } from '~enums'
import { PurchaseSecurity } from '~state/sagas/buySecurities/utils/PurchaseSecurity'

export interface BuySecuritiesState {
  purchase?: PurchaseSecurity
  gas?: BigNumber
  executing: boolean

  // for form rendering
  form: {
    spendTicker: TickerBuySecurityFrom
    spendValue: string
    receiveValue: string
  }
}

export type BuySecuritiesSetFormSpendTickerAction = PayloadAction<TickerBuySecurityFrom>

export type BuySecuritiesSetFormSpendValueAction = PayloadAction<{
  securityId: string
  skipEffects?: boolean
  value: string
}>

export type BuySecuritiesSetFormReceiveValueAction = PayloadAction<{
  securityId: string
  skipEffects?: boolean
  value: string
}>

export type SecurityInfo = { securityId: string; decimals: number }

export type SetSecurityInfo = PayloadAction<SecurityInfo>

export type SetSecuritiesPurchase = PayloadAction<PurchaseSecurity>

export type SetSecuritiesGas = PayloadAction<BigNumber>

export type SetDecimals = PayloadAction<number>

const initialState: BuySecuritiesState = {
  form: {
    spendValue: '',
    receiveValue: '',
    spendTicker: TickerBuySecurityFrom.BUSD
  },
  executing: false
}

const buySecuritiesSetFormValueReducer: CaseReducer<
  BuySecuritiesState,
  BuySecuritiesSetFormReceiveValueAction | BuySecuritiesSetFormReceiveValueAction
> = (state, action) => {
  if (action.payload.value === '' || parseFloat(action.payload.value) === 0) {
    return {
      ...initialState,
      form: {
        ...initialState.form,
        spendTicker: state.form.spendTicker,
        receiveValue: action.payload.value,
        spendValue: action.payload.value
      }
    }
  }
}

export const buySecuritiesSlice = createSlice({
  name: 'buySecurities',
  initialState,
  reducers: {
    executePurchase: (state) => {
      state.executing = true
    },
    purchaseFailed: (state) => {
      state.executing = false
    },
    purchaseExecuted: (state) => {
      return {
        ...initialState,
        form: {
          ...initialState.form
        }
      }
    },
    setFormSpendMaxValue: (state) => state,
    setFormSpendValue: buySecuritiesSetFormValueReducer,
    setFormReceiveValue: buySecuritiesSetFormValueReducer,
    setFormSpendTicker(state, action: BuySecuritiesSetFormSpendTickerAction) {
      // reset form on set ticker
      return {
        ...initialState,
        form: {
          ...initialState.form,
          spendTicker: action.payload
        }
      }
    },
    setPurchase(state, action: SetSecuritiesPurchase) {
      state.purchase = action.payload
    },
    setGas(state, action: SetSecuritiesGas) {
      state.gas = action.payload
    }
  }
})

export const { actions: buySecuritiesActions, reducer: buySecuritiesReducer } = buySecuritiesSlice
