import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Faq, FaqItem, A2 } from '~ui'

// TODO refactor A
const A = styled(A2)`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.secondaryShade2};
  &:hover {
    color: ${({ theme }) => theme.colors.secondaryShade2};
    font-weight: 700;
  }
`
export const HomeFaq = () => {
  const { t } = useTranslation('faq')
  return (
    <Faq>
      <FaqItem title={t('What is the LocalTrade Token?')}>
        {t(
          'LTT is the utility token of DeFi Lab, created to support and power up the ecosystem and our DAO reward system.'
        )}
        <A href="https://docs.localtrade.cc/tokenomics/ltt-token">
          {t('You can learn more about the LocalTrade token here.')}
        </A>
      </FaqItem>
      <FaqItem title={t('How do I connect a wallet?')}>
        <Trans
          t={t}
          tOptions={{ nsSeparator: false }}
          i18nKey="It’s really simple: press a corresponding button and you’re good to go. Anyway, here’s a detailed guide for your convenience."
        >
          <A href="https://docs.localtrade.cc/getting-started" />
        </Trans>
      </FaqItem>
      <FaqItem title={t('Which wallets does DeFi Lab support?')}>
        {t(
          'You’ll need a BEP-20 compatible crypto wallet to connect to DeFi Lab, either a Metamask wallet or WalletConnect compatible. We reccomend using either Metamask or Trust Wallet.'
        )}
      </FaqItem>
    </Faq>
  )
}
