import BigNumber from 'bignumber.js'
import { SagaIterator } from 'redux-saga'
import { call, put, select } from 'redux-saga/effects'

import { Ticker } from '~enums'
import { AppState, VaultsActions, VaultsSetTickerAction, VaultsState } from '~state'
import { getAllowance } from '~state/sagas/utils'

export function* onVaultsSetTicker<S extends Record<string, VaultsState>>(
  { payload: { address, ticker } }: VaultsSetTickerAction,
  statePath: keyof AppState,
  actions: VaultsActions<S>
): SagaIterator {
  const state: AppState = yield select()
  const vaults = state[statePath] as S
  const {
    web3: { account },
    contracts: { bep20 }
  } = state

  if (!bep20 || !account) throw new Error('No bep20 or account')

  if (ticker === Ticker.BNB) return

  if (!vaults[address].allowances[ticker]) {
    const allowance: BigNumber = yield call(getAllowance, bep20[ticker], address, account)
    yield put(actions.setAllowance({ address, ticker, allowance }))
  }
}
