import './utils/BigNumberConfig'
import BootstrapProvider from '@bootstrap-styled/provider/lib/BootstrapProvider'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'

// needs to be bundled
import '~utils/i18n'

import 'treeflex/dist/css/treeflex.css'

import App from './App'
import { Providers } from './Providers'
import reportWebVitals from './reportWebVitals'
import { theme } from './theme'

import { GlobalStyles } from '~components'
import { Spinner } from '~components/Spinner'
import { ToastContainer } from '~ui'

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <BootstrapProvider theme={theme}>
        <ToastContainer />
        <Suspense fallback={<Spinner />}>
          <App />
        </Suspense>
        <GlobalStyles />
      </BootstrapProvider>
    </Providers>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
