import styled from 'styled-components'

import { mediaQuery } from '~utils'

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 20px;
  gap: 40px;
  flex: 0 0 50%;
  ${mediaQuery.lessThan('md')`
     flex: 0 0 100%;
     gap: 20px;
  `}
`
