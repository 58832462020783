import { Web3Provider } from '@ethersproject/providers'
import { Contract } from 'ethers'
import { all, put, takeLatest } from 'redux-saga/effects'

import {
  LTT_ADDRESS,
  LUSD_ADDRESS,
  MASTER_CROCO_ADDRESS,
  REFERRALS_TREE_STORAGE_ADDRESS,
  REWARDS_BUSD_ADDRESS,
  REWARDS_LTT_ADDRESS,
  ROUND_A_ADDRESS,
  STAKINGS_MANAGER_ADDRESS,
  TokensBEP20,
  ROUND_B_ADDRESS,
  UNSTAKER_ADDRESS,
  ROUND_IDO_ADDRESS,
  LP_BROKER_ADDRESS
} from '~config'
import ERC20 from '~contracts/ERC20.json'
import LPBroker from '~contracts/LiquidityPoolBroker.json'
import LTT from '~contracts/LTT.json'
import LUSD from '~contracts/LUSD.json'
import MasterCroco from '~contracts/MasterCroco.json'
import ReferralsTreeStorage from '~contracts/ReferralsTreeStorage.json'
import Rewards from '~contracts/Rewards.json'
import RewardsV2 from '~contracts/RewardsV2.json'
import RoundA from '~contracts/RoundA.json'
import RoundB from '~contracts/RoundB.json'
import RoundIDO from '~contracts/RoundIDO.json'
import StakingsManager from '~contracts/StakingsManagerV2.json'
import Unstaker from '~contracts/Unstaker.json'
import { Ticker, TickerBEP20 } from '~enums'
import { contractsActions, GeneralContractsState, web3Actions, Web3SetAction } from '~state'
import { getContract } from '~utils'

export function getTokenContracts(
  library: Web3Provider,
  account?: string | null
): Record<TickerBEP20, Contract> {
  return TokensBEP20.map((t) => ({
    value: getContract(t.address, ERC20.abi, library, account),
    ticker: t.ticker
    // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
  })).reduce((a, e) => ({ ...a, [e.ticker]: e.value }), {} as Record<TickerBEP20, Contract>)
}

export function getGeneralContracts(
  library: Web3Provider,
  account?: string | null
): GeneralContractsState {
  return {
    masterCroco: getContract(MASTER_CROCO_ADDRESS, MasterCroco.abi, library, account),
    ltt: getContract(LTT_ADDRESS, LTT.abi, library, account),
    lusd: getContract(LUSD_ADDRESS, LUSD.abi, library, account),
    referralsTreeStorage: getContract(
      REFERRALS_TREE_STORAGE_ADDRESS,
      ReferralsTreeStorage.abi,
      library,
      account
    ),
    stakingsManager: getContract(STAKINGS_MANAGER_ADDRESS, StakingsManager.abi, library, account),
    rewards: {
      [Ticker.LTT]: getContract(REWARDS_LTT_ADDRESS, RewardsV2.abi, library, account),
      [Ticker.BUSD]: getContract(REWARDS_BUSD_ADDRESS, Rewards.abi, library, account)
    },
    roundA: getContract(ROUND_A_ADDRESS, RoundA.abi, library, account),
    roundB: getContract(ROUND_B_ADDRESS, RoundB.abi, library, account),
    roundIDO: getContract(ROUND_IDO_ADDRESS, RoundIDO.abi, library, account),
    unstaker: getContract(UNSTAKER_ADDRESS, Unstaker.abi, library, account),
    lpBroker: getContract(LP_BROKER_ADDRESS, LPBroker.abi, library, account)
  }
}

function* updateContracts({ payload: { account, library } }: Web3SetAction) {
  if (!library) return

  yield put(contractsActions.setGeneral(getGeneralContracts(library, account)))
  yield put(contractsActions.setBep20(getTokenContracts(library, account)))
}

export function* contractsSaga() {
  yield all([takeLatest(web3Actions.set, updateContracts)])
}
