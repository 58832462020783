import { SagaIterator } from 'redux-saga'
import { call, put, select, take } from 'redux-saga/effects'

import { PurchaseSecurity } from '../utils/PurchaseSecurity'

import { BUSD } from '~config'
import {
  AppState,
  buySecuritiesActions,
  BuySecuritiesSetFormReceiveValueAction,
  tokenizedSecuritiesActions
} from '~state'
import { Direction } from '~state/sagas/utils'
import { NumberFormatter } from '~utils'

export function* onSetFormReceiveValue(
  action: BuySecuritiesSetFormReceiveValueAction
): SagaIterator {
  if (action.payload.skipEffects || !action.payload.value || parseFloat(action.payload.value) === 0)
    return

  const {
    tokenizedSecurities: { shares },
    buySecurities: {
      form: { spendTicker }
    },
    web3: { account }
  }: AppState = yield select()

  const security = shares?.find((s) => s.id === action.payload.securityId)

  if (!security || !account || !security.contract)
    throw new Error('No securityId or contracts or decimals or data or account')

  const { contract } = security

  const receiveValue = NumberFormatter.fromCustomDecimal(
    action.payload.value,
    security.contract.decimals
  )

  const purchase = new PurchaseSecurity(contract, spendTicker, receiveValue, Direction.OUT)
  const value = yield call([purchase, purchase.getReceiveValue])

  yield put(
    buySecuritiesActions.setFormSpendValue({
      value: NumberFormatter.formatCustomDecimal(value, BUSD.decimals, contract.decimals),
      securityId: action.payload.securityId,
      skipEffects: true
    })
  )

  yield put(buySecuritiesActions.setPurchase(purchase))

  if (contract.allowance[spendTicker].lt(value)) {
    yield take(tokenizedSecuritiesActions.approveSuccess)
  }

  try {
    const gas = yield call([purchase, purchase.estimateCallGas], account)
    yield put(buySecuritiesActions.setGas(gas))
  } catch (e) {
    console.error(e)
  }
}
