import styled from 'styled-components'

import { Input } from '~ui'

export const InputGroup = styled.div`
  position: relative;
  ${Input} {
    width: -webkit-fill-available;
    padding-right: 105px;
  }
`
