import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import { COLLAPSE_SCREEN_WIDTH } from './collapseText'

import { LTT_ADDRESS, routes } from '~config'
import { MediaQueryBreakpoint } from '~enums'
import { Icon, SecondaryButton, WithColor } from '~ui'
import { mediaQuery, shortenAddress } from '~utils'

const CopyButton = styled((props) => <Icon name="copy2" {...props} />)`
  cursor: pointer;
  margin-left: 11px;
`

const ContractAddress = styled((props) => {
  const { t } = useTranslation('common')
  const isXl = useMediaQuery({ minWidth: MediaQueryBreakpoint.xl })
  const isLg = useMediaQuery({
    minWidth: MediaQueryBreakpoint.md
  })
  const isLgToBreak = useMediaQuery({
    minWidth: MediaQueryBreakpoint.lg,
    maxWidth: COLLAPSE_SCREEN_WIDTH
  })
  const isMd = useMediaQuery({
    minWidth: MediaQueryBreakpoint.xs,
    maxWidth: MediaQueryBreakpoint.sm
  })
  const isXs = useMediaQuery({ maxWidth: MediaQueryBreakpoint.xs })
  return (
    <div {...props}>
      <WithColor $color="secondary">
        {t('Contract Address')
          .split(' ')
          .slice(0, isLgToBreak ? 1 : undefined)
          .join(' ')}
        {!isXs && (
          <>: {shortenAddress(LTT_ADDRESS, isXl ? 6 : isLg ? 6 : isMd ? 2 : isXs ? 2 : 4)}</>
        )}
      </WithColor>
      <CopyToClipboard text={LTT_ADDRESS}>
        <CopyButton />
      </CopyToClipboard>
    </div>
  )
})`
  display: flex;
  white-space: nowrap;
`

const StakeButton = styled((props) => <SecondaryButton primaryHover {...props} />)`
  background: ${({ theme }) => theme.colors.tertiaryShade8} !important;
  width: auto;
  margin-left: 30px;
  padding: 6px 25px !important;
`

export const ContractInfo = styled((props) => {
  const history = useHistory()
  return (
    <div {...props}>
      <ContractAddress />
      <StakeButton size="sm" onClick={() => history.push(routes.staking)}>
        Stake & Earn
      </StakeButton>
    </div>
  )
})`
  display: flex;
  align-items: center;
  ${mediaQuery.lessThan('lg')`
    flex-basis: 100%;
    justify-content: space-between;
  `}
`
