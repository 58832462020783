import { PropsWithChildren } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  flex: 1 0 50%;
  text-align: center;
  height: 55px;
  margin-bottom: 40px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Highlight = styled.div<{ $active: boolean }>`
  height: ${({ $active }) => ($active ? '6px' : '2px')};
  border-radius: 6px 6px 0px 0px;
  background-color: ${({ theme, $active }) =>
    $active ? theme.colors.primary : theme.colors.secondaryShade3};
`

export const Tab = ({
  active,
  onClick,
  children
}: PropsWithChildren<{
  active: boolean
  onClick: () => void
}>) => {
  return (
    <Wrapper onClick={onClick}>
      {children}
      <Highlight $active={active} />
    </Wrapper>
  )
}
