import React from 'react'
import { Helmet } from 'react-helmet'

import { Container } from '~components'
import { BalanceAndReferralsTree, StakingInfo, VaultsAndLiquidityPools } from '~components/staking'

export const StakingPage = () => {
  return (
    <>
      <Helmet>
        <title>LocalTrade DeFi Lab | Farming Vaults</title>
        <meta
          name="description"
          content={'Easiest way to get more tokens.' + 'Stake tokens in vaults and earn high APR.'}
        />
      </Helmet>
      <Container>
        <StakingInfo />
      </Container>
      <Container>
        <BalanceAndReferralsTree />
      </Container>
      <Container className="overflow-hidden" $withHeading>
        <VaultsAndLiquidityPools />
      </Container>
    </>
  )
}
